// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-detailed-info .list-item[class*="list-item"][data-v-4262832a] {
  min-height: 55px;
  height: initial;
  padding: 0 20px;
  margin-bottom: 0;
}
.contact-detailed-info .list-item[class*="list-item"].info-content[data-v-4262832a] {
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0px 0, rgba(0, 0, 0, 0.15) 0 0px 0px 0px;
}
.contact-detailed-info .list-item[class*="list-item"][data-v-4262832a] .item-content .item-text {
  margin: auto 0;
  padding: 10px 0;
  max-width: 100%;
}
.contact-detailed-info .list-item[class*="list-item"][data-v-4262832a] .item-content .item-text .item-header {
  color: var(--elem-grey-color);
}
.contact-detailed-info .action-container[data-v-4262832a] {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/ContactDetailedInfo.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,6EAA6E;AAC/E;AACA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".contact-detailed-info .list-item[class*=\"list-item\"][data-v-4262832a] {\n  min-height: 55px;\n  height: initial;\n  padding: 0 20px;\n  margin-bottom: 0;\n}\n.contact-detailed-info .list-item[class*=\"list-item\"].info-content[data-v-4262832a] {\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0px 0, rgba(0, 0, 0, 0.15) 0 0px 0px 0px;\n}\n.contact-detailed-info .list-item[class*=\"list-item\"][data-v-4262832a] .item-content .item-text {\n  margin: auto 0;\n  padding: 10px 0;\n  max-width: 100%;\n}\n.contact-detailed-info .list-item[class*=\"list-item\"][data-v-4262832a] .item-content .item-text .item-header {\n  color: var(--elem-grey-color);\n}\n.contact-detailed-info .action-container[data-v-4262832a] {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
