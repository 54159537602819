
    import { mapActions } from 'vuex'
    import SelectChat from "./SelectChat.vue"
    import {ACT_SEND_MESSAGE} from "../../store/actionsTypes"
    import {CHATS} from "../../store/modulesNames"

    export default {
        name: "SelectChatToSendMsg",
        extends: SelectChat,
        computed: {
            multiple: () => true,
            msg() { return this.props.msg },
        },
        methods: {
            async onOk (chats) {
                await Promise.all(chats.map(async ({cid, cidType}) => {
                        let msg = (!this.msg) ? this.buildMsgData({cid, cidType}) : {...this.msg, ...{cid, cidType}}
                        return msg ? this[ACT_SEND_MESSAGE](msg) : undefined
                    })
                )
                this.modalClose()
                this.props.cbOk && this.props.cbOk()
                this.$nextTick(() => {
                    this.btnOkCb && this.btnOkCb()
                    this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
                })                
            },
            buildMsgData(chat) {
                const type = this.props.type
                const params = this.props.params
                let data = {}
                switch (type) {
                    case 'text':
                        data = params
                        break
                    case 'channel':
                        Object.assign(data, {type, chId: params.chId})
                        break
                    case 'publication':
                        let {chId, pubId} = params
                        Object.assign(data, {type, chId, pubId})
                        break
                }
                if (type === 'data' && params.data.type === 'text') data = params.data
                return this.buildMsg(data, chat)
            },
            buildMsg(data, {cidType, cid}) {
                let dataType
                switch (this.props.type) {
                    case 'text':
                        dataType = declarations.msgDataTypes.MSG_DATA_TYPE_TEXT
                        break
                    case 'channel':
                    case 'publication':
                    case 'data':
                        dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
                        break
                    default:
                        break
                }
                if (data && data.constructor === Object) data = JSON.stringify(data)
                return {cidType, cid, data, dataType}
            },
            ...mapActions(CHATS, [ACT_SEND_MESSAGE])
        }
    }
