var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-entrance" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c("div", { staticClass: "component-section" }, [
      _c(
        "form",
        {
          staticClass: "input-block",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.doLogin.apply(null, arguments)
            },
          },
        },
        [
          _c("custom-input-text", {
            directives: [{ name: "focus", rawName: "v-focus" }],
            ref: "input_mail",
            staticClass: "input",
            attrs: {
              default_text: _vm.login,
              input_text: _vm.login,
              label: _vm.$t("loginPage.login-word"),
              tabindex: "1",
            },
            on: {
              "update:input_text": function ($event) {
                _vm.login = $event
              },
            },
          }),
          _c(
            "div",
            { staticClass: "password-section" },
            [
              _c("custom-input-text", {
                ref: "input_pass",
                staticClass: "input",
                attrs: {
                  default_text: _vm.password,
                  input_text: _vm.password,
                  type: _vm.isPasswordRevealed ? "text" : "password",
                  label: _vm.$t("loginPage.password"),
                  tabindex: "2",
                },
                on: {
                  "update:input_text": function ($event) {
                    _vm.password = $event
                  },
                },
              }),
              !_vm.isPasswordRevealed
                ? _c("i", {
                    staticClass: "fal fa-eye-slash",
                    on: { click: _vm.togglePasswordReveal },
                  })
                : _vm._e(),
              _vm.isPasswordRevealed
                ? _c("i", {
                    staticClass: "fal fa-eye",
                    on: { click: _vm.togglePasswordReveal },
                  })
                : _vm._e(),
            ],
            1
          ),
          false
            ? _c("custom-checkbox", {
                staticClass: "input",
                attrs: {
                  default: _vm.remember_me,
                  checkbox: _vm.remember_me,
                  label: _vm.$t("loginPage.logged-in"),
                  tabindex: 3,
                },
                on: {
                  "update:checkbox": function ($event) {
                    _vm.remember_me = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "login-btn",
                tabindex: "4",
                disabled: _vm.disableLogin,
              },
            },
            [_vm._v(_vm._s(_vm.$t("loginPage.to-log-in")))]
          ),
        ],
        1
      ),
    ]),
    _vm.avanpostEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn avanpost-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("avanpost")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/avanpost_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-avanpost"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.adEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn ad-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("ad")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/microsoft_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-ad"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.freeIPAEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn ad-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("freeIPA")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/freeipa_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-free-ipa"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.sambaEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn ad-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("samba")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/samba_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-samba"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "component-footer" }, [
      _vm.registrationEnabled
        ? _c("div", { staticClass: "registration-block text-center" }, [
            _c(
              "a",
              {
                attrs: { id: "register", tabindex: "5" },
                on: {
                  click: function ($event) {
                    return _vm.doOpenRegisterPage()
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.doOpenRegisterPage()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("loginPage.registration")))]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "pass-restore-block text-center" }, [
        _c(
          "a",
          {
            attrs: { id: "pass-restore", tabindex: "6" },
            on: {
              click: function ($event) {
                return _vm.doOpenEntrance("pass-restore")
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.doOpenEntrance("pass-restore")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("loginPage.forgot-pass")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }