// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog[data-v-90c0bd96] .body {
  height: 65vh;
}
.contacts-list-modal[data-v-90c0bd96] {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ContactsListModal.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".dialog[data-v-90c0bd96] .body {\n  height: 65vh;\n}\n.contacts-list-modal[data-v-90c0bd96] {\n  width: 100%;\n  padding: 0px;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
