import { render, staticRenderFns } from "./Reactions.vue?vue&type=template&id=07b8c668&scoped=true&lang=pug"
import script from "./Reactions.vue?vue&type=script&lang=js"
export * from "./Reactions.vue?vue&type=script&lang=js"
import style0 from "./Reactions.vue?vue&type=style&index=0&id=07b8c668&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b8c668",
  null
  
)

export default component.exports