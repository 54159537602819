// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conf-info[data-v-fa7ed8cc] {
  width: 100%;
  user-select: text;
}
.conf-info .item[data-v-fa7ed8cc] {
  user-select: text;
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item]:hover {
  background-color: initial;
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue {
  color: #37c6ff;
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue:hover {
  background-color: var(--bg-color-hover);
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue .item-title {
  color: #37c6ff;
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-media {
  margin-left: 0;
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-header {
  color: var(--title-color);
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-title {
  color: var(--text-color);
}
.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-content::after {
  border-bottom-color: var(--divider-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNew/videoConfInfo.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,yCAAyC;AAC3C","sourcesContent":[".conf-info[data-v-fa7ed8cc] {\n  width: 100%;\n  user-select: text;\n}\n.conf-info .item[data-v-fa7ed8cc] {\n  user-select: text;\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item]:hover {\n  background-color: initial;\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue {\n  color: #37c6ff;\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue:hover {\n  background-color: var(--bg-color-hover);\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item].color-blue .item-title {\n  color: #37c6ff;\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-media {\n  margin-left: 0;\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-header {\n  color: var(--title-color);\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-title {\n  color: var(--text-color);\n}\n.conf-info[data-v-fa7ed8cc] .list-item[class~=list-item] .item-content::after {\n  border-bottom-color: var(--divider-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
