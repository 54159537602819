var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "publication-audio-message" },
    [
      _vm.isPending
        ? _c(
            "div",
            {
              staticClass: "upload-abort-btn message-btn",
              on: { click: _vm.abortAjaxCall },
            },
            [
              _c("progress-circle", {
                attrs: {
                  load_progress: _vm.loadProgress,
                  radius: 16,
                  width: _vm.progressWidth,
                  height: _vm.progressHeight,
                },
              }),
              _c("i", { staticClass: "fa fa-ban" }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "audio-toggle-btn message-btn",
              on: { click: _vm.doPlayToggle },
            },
            [
              _c("i", {
                staticClass: "fa",
                class: _vm.played ? "fa-pause" : "fa-play",
              }),
            ]
          ),
      _c("div", { staticClass: "audio-middle-sections" }, [
        _c(
          "div",
          { staticClass: "top-audio-section" },
          [
            [
              _c("publication-editable-item-text", {
                staticClass: "audio-name name-text",
                attrs: {
                  "default-text": _vm.name,
                  edit: _vm.edit,
                  text: _vm.name,
                  "unix-last-time": _vm.unixLastTime,
                },
                on: {
                  "update:text": function ($event) {
                    _vm.name = $event
                  },
                },
              }),
            ],
            _c("div", { staticClass: "audio-time" }, [
              _vm._v(_vm._s(_vm.current_time)),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "bottom-audio-section" }, [
          _c(
            "div",
            { staticClass: "audio-progress" },
            [
              _c("slider", {
                attrs: {
                  active: _vm.link,
                  outher_progress:
                    _vm.current_second / (_vm.data.duration / 100) || 0,
                },
                on: { progress_change: _vm.doChangeCurrentSecond },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "audio-volume" },
            [
              _c("slider", {
                attrs: { active: _vm.link, outher_progress: _vm.volume * 100 },
                on: { progress_change: _vm.doChangeVolume },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("custom-audio", {
        ref: "audio",
        attrs: { src: _vm.link, volume: _vm.volume },
        on: { ended: _vm.clearAudioInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }