// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-list[data-v-07eabf3a] {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  flex: 1;
}
.contact-list .scroller[data-v-07eabf3a] {
  position: relative;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 100%;
}
.contact-list .scroller[data-v-07eabf3a] .contact-item {
  height: 0;
  width: 100%;
}
.contact-list .scroller[data-v-07eabf3a] .contact-item .hor-line {
  height: 1px;
  background: var(--hor-line-color);
  width: calc(100% - 68px);
  margin-left: 68px;
}
.contact-list .scroller[data-v-07eabf3a] .resize-observer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/RecycleContactsList.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,OAAO;AACT;AACA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,SAAS;EACT,WAAW;AACb;AACA;EACE,WAAW;EACX,iCAAiC;EACjC,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,6BAA6B;EAC7B,oBAAoB;EACpB,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".contact-list[data-v-07eabf3a] {\n  display: flex;\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n  flex: 1;\n}\n.contact-list .scroller[data-v-07eabf3a] {\n  position: relative;\n  overflow-y: auto !important;\n  overflow-x: hidden;\n  width: 100%;\n}\n.contact-list .scroller[data-v-07eabf3a] .contact-item {\n  height: 0;\n  width: 100%;\n}\n.contact-list .scroller[data-v-07eabf3a] .contact-item .hor-line {\n  height: 1px;\n  background: var(--hor-line-color);\n  width: calc(100% - 68px);\n  margin-left: 68px;\n}\n.contact-list .scroller[data-v-07eabf3a] .resize-observer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  border: none;\n  background-color: transparent;\n  pointer-events: none;\n  display: block;\n  overflow: hidden;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
