(function(name, definition) {
    if (typeof module != 'undefined') module.exports = definition();
    else if (typeof define == 'function' && typeof define.amd == 'object') define(definition);
    else this[name] = definition();
}('mod', function() {
    //This is the code you would normally have inside define() or add to module.exports
    const buildEnv = {
       APP_NAME: "RosChat",
       APP_NAME_RU: "РосЧат",
       APP_NAME_LOW: "roschat",
       APP_DESCRIPTION: "Corporate Messenger",
       APP_DESCRIPTION_RU: "Корпоративный мессенджер",
       VERSION: "6.0.2127",
       DEFAULT_VM_SERVER: "vmotsp.ros.chat",
       DEFAULT_CLIENT_UPDATE_SERVER: "https://firelink.me"
    }
    if (typeof window !== 'undefined') window.buildEnv = buildEnv
    return buildEnv
}));