var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "action-btn",
      class: _vm.type === "terminate" ? "color-red" : "color-green",
      style: { opacity: _vm.disable ? "0.3" : "1" },
      on: { click: _vm.click },
    },
    [
      _vm.type === "voice-call"
        ? _c("i", { staticClass: "fa fa-phone fa-rotate-90" })
        : _vm.type === "tetra-call"
        ? _c("i", { staticClass: "fa fa-phone fa-rotate-90" })
        : _vm.type === "answer-conf"
        ? _c("i", { staticClass: "fas fa-users" })
        : _vm.type === "video-call"
        ? _c("i", { staticClass: "fas fa-video" })
        : _vm.type === "terminate"
        ? _c("i", { staticClass: "fa fa-phone fa-rotate" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }