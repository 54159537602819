// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-red {
  color: var(--elem-red-color);
}
.color-green {
  color: green;
}
.color-blue {
  color: var(--toolbtn-active-color);
}
.v--modal {
  color: var(--font-color);
  background-color: var(--bg-color);
}
.v--modal .header {
  display: flex;
  padding: 15px 20px 25px 15px;
  background-color: var(--bg-color);
}
.v--modal .header .title {
  font-size: 18px;
}
.v--modal .header .close-btn {
  margin: auto 0 auto auto;
  opacity: .2;
  cursor: pointer;
}
.v--modal .header .close-btn:hover {
  opacity: .1;
}
.v--modal .body {
  display: flex;
  padding: 0 20px;
  background-color: var(--bg-color);
}
.v--modal .footer {
  display: flex;
  padding: 15px 20px;
  background-color: var(--bg-color);
}
.v--modal .footer .btn {
  border-radius: 3px;
  background-color: initial;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 18px;
  border: none;
  color: var(--modal-elem-color);
  outline: 0;
}
.v--modal .footer .btn:first-child {
  margin-left: auto;
}
.v--modal .footer .btn:not(:last-child) {
  margin-right: 10px;
}
.v--modal .footer .btn:hover,
.v--modal .footer .btn:active,
.v--modal .footer .btn:focus {
  background: var(--btn-bghover);
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ModalContainer.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,YAAY;AACd;AACA;EACE,kCAAkC;AACpC;AACA;EACE,wBAAwB;EACxB,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,iCAAiC;AACnC;AACA;EACE,eAAe;AACjB;AACA;EACE,wBAAwB;EACxB,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,eAAe;EACf,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iCAAiC;AACnC;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,8BAA8B;EAC9B,UAAU;AACZ;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;;;EAGE,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".color-red {\n  color: var(--elem-red-color);\n}\n.color-green {\n  color: green;\n}\n.color-blue {\n  color: var(--toolbtn-active-color);\n}\n.v--modal {\n  color: var(--font-color);\n  background-color: var(--bg-color);\n}\n.v--modal .header {\n  display: flex;\n  padding: 15px 20px 25px 15px;\n  background-color: var(--bg-color);\n}\n.v--modal .header .title {\n  font-size: 18px;\n}\n.v--modal .header .close-btn {\n  margin: auto 0 auto auto;\n  opacity: .2;\n  cursor: pointer;\n}\n.v--modal .header .close-btn:hover {\n  opacity: .1;\n}\n.v--modal .body {\n  display: flex;\n  padding: 0 20px;\n  background-color: var(--bg-color);\n}\n.v--modal .footer {\n  display: flex;\n  padding: 15px 20px;\n  background-color: var(--bg-color);\n}\n.v--modal .footer .btn {\n  border-radius: 3px;\n  background-color: initial;\n  font-size: 15px;\n  text-transform: uppercase;\n  font-weight: bold;\n  padding: 10px 18px;\n  border: none;\n  color: var(--modal-elem-color);\n  outline: 0;\n}\n.v--modal .footer .btn:first-child {\n  margin-left: auto;\n}\n.v--modal .footer .btn:not(:last-child) {\n  margin-right: 10px;\n}\n.v--modal .footer .btn:hover,\n.v--modal .footer .btn:active,\n.v--modal .footer .btn:focus {\n  background: var(--btn-bghover);\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
