// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-participants[data-v-64ca9b6a] {
  -webkit-app-region: no-drag;
  overflow: auto;
  overflow-x: hidden;
  height: 375px;
  width: 100%;
  margin: 0 10px;
  border-bottom: 1px solid var(--vm-border-color);
  color: var(--phone-text-color);
}
.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper:last-child .hor-line {
  display: none;
}
.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper .side-list-item {
  flex-wrap: wrap;
  padding: 0 10px;
}
.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper .side-list-item .hor-line {
  height: 1px;
  background: var(--hor-line-color);
  width: calc(100% - 60px);
  margin-left: 60px !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/PhoneParticipants.vue"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,cAAc;EACd,+CAA+C;EAC/C,8BAA8B;AAChC;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,eAAe;AACjB;AACA;EACE,WAAW;EACX,iCAAiC;EACjC,wBAAwB;EACxB,4BAA4B;AAC9B","sourcesContent":[".phone-participants[data-v-64ca9b6a] {\n  -webkit-app-region: no-drag;\n  overflow: auto;\n  overflow-x: hidden;\n  height: 375px;\n  width: 100%;\n  margin: 0 10px;\n  border-bottom: 1px solid var(--vm-border-color);\n  color: var(--phone-text-color);\n}\n.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper:last-child .hor-line {\n  display: none;\n}\n.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper .side-list-item {\n  flex-wrap: wrap;\n  padding: 0 10px;\n}\n.phone-participants[data-v-64ca9b6a] .contacts-list-item-wrapper .side-list-item .hor-line {\n  height: 1px;\n  background: var(--hor-line-color);\n  width: calc(100% - 60px);\n  margin-left: 60px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
