var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "inner-wrapper",
      class: {
        "select-mode": _vm.isSelectMode && _vm.message.sub_type !== "time",
        "selected-message": _vm.isMessageSelected(this.message.id)
      },
      style: "position: relative; z-index: 0",
      attrs: { id: _vm.message.id ? _vm.message.id : false },
      on: {
        click: function($event) {
          _vm.isSelectMode && _vm.message.sub_type !== "time"
            ? _vm.onSelectMessage(_vm.message, $event, true)
            : ""
        }
      }
    },
    [
      _vm.isSelectMode && !this.readOnly && _vm.message.type !== "system"
        ? _c("check-box", {
            key: _vm.message.id,
            staticClass: "select-message",
            attrs: {
              value: _vm.getSelected(_vm.message.id),
              default: _vm.getSelected(_vm.message.id)
            },
            on: {
              "update:checkbox": function($event) {
                return _vm.onSelectMessage(_vm.message, $event)
              }
            }
          })
        : _vm._e(),
      _vm.message.type !== "system" && _vm.isOverMsgFooter
        ? _c("reactions-picker", {
            key: "rp" + _vm.message.id,
            attrs: {
              msgId: _vm.message.id,
              pickerStyle: _vm.reactionPickerStyle,
              isLeftSide: _vm.side === "left",
              isThreads: _vm.isThreadsComment
            },
            on: { mouseOverReactonPicker: _vm.mouseOverReactonPicker }
          })
        : _vm._e(),
      _vm.message.type !== "data" || _vm.message.msg
        ? _c(
            "li",
            {
              staticClass: "item",
              class: _vm.classes,
              style: _vm.style,
              attrs: { id: _vm.message.id ? "msg" + _vm.message.id : false }
            },
            [
              _c(
                "div",
                {
                  staticClass: "message-wrapper",
                  class: _vm.messageWrapperClasses,
                  on: {
                    click: function($event) {
                      return _vm.doOnClick($event, _vm.message)
                    },
                    contextmenu: function($event) {
                      $event.preventDefault()
                      return _vm.doContextMenu($event, _vm.message)
                    },
                    emailcontextmenu: function($event) {
                      return _vm.openEmailContextMenu($event)
                    },
                    vccontextmenu: function($event) {
                      return _vm.openVCContextMenu($event)
                    },
                    linkcontextmenu: function($event) {
                      return _vm.openLinkContextMenu($event)
                    },
                    botcommand: function($event) {
                      return _vm.runBotCommand($event)
                    },
                    botcommandclick: function($event) {
                      return _vm.runBotCommand($event)
                    },
                    phonenumbercontextmenu: function($event) {
                      return _vm.openPhoneNumberContextMenu($event)
                    },
                    phonenumberlink: function($event) {
                      return _vm.callPhoneNumberByClick($event)
                    }
                  }
                },
                [
                  _vm.photo
                    ? _c(
                        "div",
                        {
                          staticClass: "message-icon message-mouth-left",
                          on: { click: _vm.openSenderInfo }
                        },
                        [
                          _c("icon-image", {
                            staticClass: "small-medium",
                            attrs: { src: _vm.photo, text: _vm.name }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "message-list-item",
                      style: {
                        display: _vm.displayStyle,
                        "margin-left": _vm.marginLeftMsg
                      },
                      on: {
                        mouseover: _vm.mouseOverMsg,
                        mouseleave: _vm.mouseLeaveMsg
                      }
                    },
                    [
                      _vm.nameSignature
                        ? _c("div", { staticClass: "message-header" }, [
                            _c("div", { staticClass: "name-signature" }, [
                              _c(
                                "span",
                                {
                                  class: _vm.getColorClassByMessage(
                                    _vm.message
                                  ),
                                  on: { click: _vm.openSenderInfo }
                                },
                                [_vm._v(_vm._s(_vm.nameSignature))]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "message-body" }, [
                        _vm.message.replyId
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-reply-message", {
                                  attrs: {
                                    message: _vm.message,
                                    cid: _vm.cid,
                                    isThreads: _vm.isThreadsComment
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.message.sub_type === "contact"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-contact-message", {
                                  attrs: { cid: _vm.cid, message: _vm.message },
                                  on: {
                                    secondcontextmenu: _vm.doSecondContextMenu
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "location"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-geo-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.fileGroupId &&
                            _vm.message.startFileGroup
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-grouped-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "image"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-image-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "video"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-video-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "audio"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-audio-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "file"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-regular-file-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "channel"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-channel-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "poll"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-poll-message", {
                                  ref: "chatPollMessage",
                                  attrs: {
                                    message: _vm.message,
                                    nocontextmenu: true
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "survey"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-survey-message", {
                                  attrs: {
                                    message: _vm.message,
                                    surveyOpened: _vm.isSurveyOpened
                                  },
                                  on: { surveyopened: _vm.openSurvey }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "schedule"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-schedule-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "publication"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-publication-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.message.sub_type === "call-availability"
                          ? _c(
                              "div",
                              { staticClass: "message" },
                              [
                                _c("chat-call-availability-message", {
                                  attrs: { message: _vm.message }
                                })
                              ],
                              1
                            )
                          : _vm.checkReference(
                              _vm.message.msg,
                              _vm.message.entities
                            )
                          ? _c(
                              "div",
                              {
                                staticClass: "message text",
                                attrs: {
                                  "data-datestamp": _vm.getDateStamp(
                                    _vm.message
                                  )
                                }
                              },
                              [
                                _c("reference-msg", {
                                  attrs: {
                                    message: _vm.message,
                                    textMsg: _vm.message.msg,
                                    textEntities: _vm.message.entities
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function() {
                                          return [
                                            _c("div", {
                                              class: _vm.textIndentClasses
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    490247007
                                  )
                                })
                              ],
                              1
                            )
                          : _vm.message.author
                          ? _c(
                              "div",
                              {
                                staticClass: "message text",
                                class: { "with-url-preview": _vm.isUrlPreview }
                              },
                              [
                                _c("div", { staticClass: "forward" }, [
                                  _c("span", { staticClass: "fwd title" }, [
                                    _c("i", { staticClass: "fas fa-share" }),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.$t("chat.fwd-message")))
                                    ])
                                  ]),
                                  _vm.fioById(_vm.message.author)
                                    ? _c(
                                        "span",
                                        { staticClass: "fwd author" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("chat.author")) +
                                              ": " +
                                              _vm._s(
                                                _vm.fioById(_vm.message.author)
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.msgTextHtml(
                                        _vm.message.msg,
                                        _vm.message.entities
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          : _vm.checkBotMsg(
                              _vm.message.msg,
                              _vm.message.entities
                            )
                          ? _c(
                              "div",
                              { staticClass: "message text bot-message" },
                              [
                                _c("reference-msg", {
                                  attrs: {
                                    textMsg: _vm.message.msg,
                                    textEntities: _vm.message.entities,
                                    message: _vm.message
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function() {
                                          return [
                                            _c("div", {
                                              class: _vm.textIndentClasses
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    490247007
                                  )
                                })
                              ],
                              1
                            )
                          : _c("div", {
                              staticClass: "message text",
                              class: _vm.txtMsgClasses,
                              attrs: {
                                id: "message-text",
                                "data-datestamp": _vm.getDateStamp(_vm.message)
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.msgTextHtml(
                                    _vm.message.msg,
                                    _vm.message.entities
                                  )
                                )
                              }
                            }),
                        _vm.isUrlPreview
                          ? _c(
                              "div",
                              {
                                staticClass: "message url-preview",
                                style: _vm.isThreads
                                  ? "max-width: 100%"
                                  : "max-width: 350px",
                                on: {
                                  click: function($event) {
                                    return _vm.openUrlLink(_vm.urlPreview.url)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(_vm.urlPreview.title))
                                ]),
                                _c("div", { staticClass: "description" }, [
                                  _vm._v(_vm._s(_vm.urlPreview.description))
                                ]),
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: _vm.urlPreview.image }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.captionHtml(_vm.message)
                          ? _c(
                              "div",
                              {
                                staticClass: "message text",
                                class:
                                  _vm.message.sub_type === "audio"
                                    ? "audio-caption"
                                    : "caption"
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.captionHtml(_vm.message)
                                    )
                                  }
                                }),
                                _c("span", { staticClass: "aux-gap" })
                              ]
                            )
                          : _vm._e(),
                        _vm.textContinue()
                          ? _c(
                              "div",
                              {
                                staticClass: "next",
                                on: {
                                  click: function($event) {
                                    _vm.shortMsg = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("continue")))]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "message-footer",
                          class: _vm.msgFooterClasses,
                          style:
                            _vm.message.sub_type === "image" &&
                            _vm.message.edited &&
                            !_vm.message.msg.text
                              ? "float: none;"
                              : ""
                        },
                        [
                          _c("div", { staticClass: "responsive-gap-left" }),
                          _vm.message.type !== "system" && _vm.message.reactions
                            ? _c("reactions", {
                                key: "r" + _vm.message.id,
                                attrs: {
                                  reactions: _vm.message.reactions,
                                  isThreads: _vm.isThreads,
                                  isRightSide: _vm.side !== "left"
                                }
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "aux" }, [
                            _vm.message.edited
                              ? _c("div", { staticClass: "edited" }, [
                                  _vm._v(_vm._s(_vm.$t("edited")))
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.message.sentTime))
                            ]),
                            _vm.message.ownMsg && !_vm.message.isNote
                              ? _c("div", { staticClass: "status" }, [
                                  _vm.message.status === "received"
                                    ? _c("i", {
                                        staticClass:
                                          "far fa-check-double greyFont"
                                      })
                                    : _vm.message.status === "watched"
                                    ? _c("i", {
                                        staticClass: "far fa-check-double"
                                      })
                                    : _vm.message.status === ""
                                    ? _c("i", {
                                        staticClass: "far fa-check greyFont"
                                      })
                                    : _vm.message.status === "sending"
                                    ? _c("i", {
                                        staticClass: "fa fa-clock greyFont"
                                      })
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "responsive-gap-right" })
                        ],
                        1
                      ),
                      _vm.message.comments
                        ? _c(
                            "div",
                            {
                              staticClass: "message-footer-threads",
                              on: {
                                mouseover: _vm.mouseOverMsgComments,
                                mouseleave: _vm.mouseLeaveMsgComments
                              }
                            },
                            [
                              _c("chat-threads", {
                                ref: "comments" + _vm.message.id,
                                attrs: {
                                  message: _vm.message,
                                  isFirstComment: _vm.isFirstComment,
                                  isActive: _vm.isOpenedComments,
                                  isLeftSide: _vm.side === "left"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "slot-after" }, [_vm._t("after")], 2),
              _vm.message.keyboard && _vm.message.keyboard.length
                ? _c("bot-keyboard-msg", {
                    attrs: { keyboard: _vm.message.keyboard, chat: _vm.message }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }