// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notification[data-v-9d6da4a2] {
  --notify-width: 426px;
  --notify-height: 88px;
  height: 0px;
  width: 0px;
  position: absolute;
  z-index: 100;
  left: calc(50% - 213px);
}
#notification[data-v-9d6da4a2] .notify {
  margin: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/notification/CustomNotificationWrapper.vue"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,aAAa;AACf","sourcesContent":["#notification[data-v-9d6da4a2] {\n  --notify-width: 426px;\n  --notify-height: 88px;\n  height: 0px;\n  width: 0px;\n  position: absolute;\n  z-index: 100;\n  left: calc(50% - 213px);\n}\n#notification[data-v-9d6da4a2] .notify {\n  margin: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
