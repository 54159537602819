// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remote {
  display: flex;
  flex-direction: column;
}
.remote .qr-code {
  position: relative;
  align-self: center;
  width: 216px;
  height: 216px;
  background-color: white;
}
.remote .qr-code::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.remote .qr-code > div {
  width: 80%;
  margin: auto;
  margin-top: 22px;
}
.remote .qr-code > div > img {
  width: 100%;
  object-fit: scale-down;
}
.remote .input {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 38%;
}
.remote .description > p {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/login/Remote.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;AACR;AACA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".remote {\n  display: flex;\n  flex-direction: column;\n}\n.remote .qr-code {\n  position: relative;\n  align-self: center;\n  width: 216px;\n  height: 216px;\n  background-color: white;\n}\n.remote .qr-code::after {\n  content: '';\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n}\n.remote .qr-code > div {\n  width: 80%;\n  margin: auto;\n  margin-top: 22px;\n}\n.remote .qr-code > div > img {\n  width: 100%;\n  object-fit: scale-down;\n}\n.remote .input {\n  text-align: center;\n  margin-top: 10%;\n  margin-bottom: 38%;\n}\n.remote .description > p {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
