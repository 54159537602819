// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-custom[data-v-627f9e70] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-custom i.fa-window-maximize[data-v-627f9e70] {
  font-size: inherit;
  margin-left: unset;
}
.btn-custom i.fa-phone[data-v-627f9e70] {
  font-size: 0.96em;
  margin-left: unset;
}
.btn-custom[data-v-627f9e70]:hover {
  color: var(--toolbtn-color-hover) !important;
}
.btn-custom.menu[data-v-627f9e70] {
  width: 1.14em;
}
.header-chat-search[data-v-627f9e70] {
  color: var(--toolbtn-color);
}
.header-chat-search[data-v-627f9e70]:before {
  content: "\\F002";
  font-family: "Font Awesome 5 Pro";
  margin-top: 2px;
  margin-left: -2px;
}
.header-chat-search[data-v-627f9e70]:hover {
  color: var(--toolbtn-color-hover) !important;
}
svg[data-v-627f9e70]:hover {
  fill: var(--toolbtn-color-hover);
}
.header-back[data-v-627f9e70] {
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/header/MainHeaderGroup.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,gCAAgC;AAClC;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".btn-custom[data-v-627f9e70] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.btn-custom i.fa-window-maximize[data-v-627f9e70] {\n  font-size: inherit;\n  margin-left: unset;\n}\n.btn-custom i.fa-phone[data-v-627f9e70] {\n  font-size: 0.96em;\n  margin-left: unset;\n}\n.btn-custom[data-v-627f9e70]:hover {\n  color: var(--toolbtn-color-hover) !important;\n}\n.btn-custom.menu[data-v-627f9e70] {\n  width: 1.14em;\n}\n.header-chat-search[data-v-627f9e70] {\n  color: var(--toolbtn-color);\n}\n.header-chat-search[data-v-627f9e70]:before {\n  content: \"\\F002\";\n  font-family: \"Font Awesome 5 Pro\";\n  margin-top: 2px;\n  margin-left: -2px;\n}\n.header-chat-search[data-v-627f9e70]:hover {\n  color: var(--toolbtn-color-hover) !important;\n}\nsvg[data-v-627f9e70]:hover {\n  fill: var(--toolbtn-color-hover);\n}\n.header-back[data-v-627f9e70] {\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
