var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "item-list" }, [
        _c("div", { staticClass: "item-name" }, [
          _c(
            "span",
            {
              attrs: { title: _vm.showTitle ? _vm.contact : "" },
              on: {
                mouseover: function ($event) {
                  return _vm.mouseOver($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.contact))]
          ),
        ]),
        _vm.marked
          ? _c("div", { staticClass: "item-icon" }, [
              _c("i", {
                staticClass: "fa fa-check",
                attrs: { "aria-hidden": "true" },
              }),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }