var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c(
        "transition",
        { attrs: { name: _vm.getTransitionName } },
        [
          _vm.currentInfo
            ? _c("info-wrapper", {
                attrs: { id: "info-wrapper", currentInfo: _vm.currentInfo },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }