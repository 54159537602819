var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.loader
        ? _c("content-loader")
        : _c("main-content-container", {
            staticClass: "custom",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("div", { staticClass: "calendar" }, [
                      _c("div", { staticClass: "calendar-header" }, [
                        _c("div", { staticClass: "left-block" }, [
                          _c("div", { staticClass: "arrows" }, [
                            _c(
                              "div",
                              {
                                staticClass: "arrow",
                                on: { click: _vm.monthDown },
                              },
                              [_c("i", { staticClass: "far fa-long-arrow-up" })]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "arrow",
                                on: { click: _vm.monthUp },
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-long-arrow-down",
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "month" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getCurrentMonthYear())),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "right-block" }, [
                          _vm.isAuthSuccessful
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-create-event",
                                  on: { click: _vm.syncAppointments },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("integrations.sync")) +
                                        "                    "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.isAuthSuccessful
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-create-event",
                                  on: { click: _vm.createEvent },
                                },
                                [
                                  _c("span", { staticClass: "plus" }, [
                                    _vm._v("+ "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("integrations.event"))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "calendar-subheader" }, [
                        _c(
                          "div",
                          { staticClass: "weekdays" },
                          _vm._l(_vm.getWeekDays(), function (day, i) {
                            return _c("div", { staticClass: "day" }, [
                              _c("span", [_vm._v(_vm._s(day))]),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "calendar-body" },
                        [
                          _vm.refresh
                            ? _c("content-loader", { staticClass: "refresh" })
                            : _vm._e(),
                          _vm._l(_vm.getDays, function (day, i) {
                            return _c(
                              "div",
                              {
                                staticClass: "day",
                                class: {
                                  today: day.isToday,
                                  notCurrentMonth: day.notCurrentMonth,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dayClicked(day)
                                  },
                                  dblclick: function ($event) {
                                    return _vm.dayDblClicked(day)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "dayNumber" }, [
                                  _vm._v(_vm._s(day.dayNumber)),
                                ]),
                                day.monthName
                                  ? _c("div", { staticClass: "monthName" }, [
                                      _vm._v(_vm._s(day.monthName)),
                                    ])
                                  : _vm._e(),
                                day.isToday
                                  ? _c("div", { staticClass: "today-line" })
                                  : _vm._e(),
                                day.events.length
                                  ? _c(
                                      "div",
                                      { staticClass: "events" },
                                      _vm._l(day.events, function (event, i) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass: "event",
                                            attrs: {
                                              title: _vm.getEventTitle(event),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editEvent(day, event)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "event-subject" },
                                              [_vm._v(_vm._s(event.subject))]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.clickedDay.dayNumber ===
                                  _vm.selectedDay.dayNumber && day.clicked
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "eventsPanel",
                                        class: _vm.getEditPanelClass(i),
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "eventsPanelHeader" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getClickedDate(day))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "eventsPanelContent" },
                                          [
                                            day.events.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "events-edit",
                                                  },
                                                  _vm._l(
                                                    day.events,
                                                    function (event, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "event-edit",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editEvent(
                                                                day,
                                                                event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "event-time",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "startTime",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getStartTimeFormatted(
                                                                        event
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "endTime",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getEndTimeFormatted(
                                                                        event
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "event-subject",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  event.subject
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "eventsPanelFooter" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn-edit-event",
                                                on: { click: _vm.createEvent },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "plus" },
                                                  [_vm._v("+ ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "integrations.event"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }