var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "intergrations" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c("list-item", {
                    attrs: {
                      title: _vm.$t("settings.integrations-list"),
                      link: true,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.setType.apply(null, arguments)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("settings.integrations"))),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }