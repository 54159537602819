// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-6f2ba800] .v--modal-box.v--modal .header {
  padding: 15px 20px 26px 15px;
}
.radioCheckBoxes[data-v-6f2ba800] {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] {
  width: 230px;
  height: 36px;
  transition: 0.2s;
  opacity: 0.7;
}
.radioCheckBoxes .checkBoxItem.checked[data-v-6f2ba800] {
  transition: 0.2s;
  opacity: 0.9;
}
.radioCheckBoxes .checkBoxItem[data-v-6f2ba800]:hover {
  opacity: 0.9;
}
.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] label.custom-checkbox {
  color: var(--font-color);
}
.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] .custom-checkbox i {
  transition: 0.2s ease;
  transition-property: opacity;
  font-size: 20px;
  margin-left: -12px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/integrations/DeleteEvent.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,qBAAqB;EACrB,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".custom-modal[data-v-6f2ba800] .v--modal-box.v--modal .header {\n  padding: 15px 20px 26px 15px;\n}\n.radioCheckBoxes[data-v-6f2ba800] {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n}\n.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] {\n  width: 230px;\n  height: 36px;\n  transition: 0.2s;\n  opacity: 0.7;\n}\n.radioCheckBoxes .checkBoxItem.checked[data-v-6f2ba800] {\n  transition: 0.2s;\n  opacity: 0.9;\n}\n.radioCheckBoxes .checkBoxItem[data-v-6f2ba800]:hover {\n  opacity: 0.9;\n}\n.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] label.custom-checkbox {\n  color: var(--font-color);\n}\n.radioCheckBoxes .checkBoxItem[data-v-6f2ba800] .custom-checkbox i {\n  transition: 0.2s ease;\n  transition-property: opacity;\n  font-size: 20px;\n  margin-left: -12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
