var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-audio" }, [
    _vm.isRc
      ? _c("div", { staticClass: "rc" }, [_c("rc-signal"), _c("rc-speech")], 1)
      : _vm.isTetra
      ? _c(
          "div",
          { staticClass: "tetra" },
          [_c("tetra-signal"), _c("tetra-speech")],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }