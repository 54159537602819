
    import {mapGetters, mapActions} from 'vuex'

    import {
        BOTS,
        CHAT,
        CONTACTS,
    } from "../../store/modulesNames"
    import {
        GET_BOT_BY_ID,
        GET_MERGED_CONTACT_BY_ID,
        GET_CHAT_REPLY_QUOTE,
    } from "../../store/gettersTypes"

    import {
        ACT_CHAT_UPDATE_REPLY,
    } from "../../store/actionsTypes"

    import ChatSelectedMsg from "./ChatSelectedMsg.vue"
    import {CONTACT_FIELD_TYPES} from '../../constants'

    export default {
        name: 'reply-message',
        extends: ChatSelectedMsg,
        computed: {
            infoTitle() {
                let bot = this[GET_BOT_BY_ID](this.message.senderId)
                if (bot) return bot[CONTACT_FIELD_TYPES.BOTTITLE]
                else {
                    const contact = this[GET_MERGED_CONTACT_BY_ID](this.message.senderId)
                    return contact.fio
                }
            },
            quote() {
                return this[GET_CHAT_REPLY_QUOTE]
            },
            bgColorClass() {
                return this.getBGClassByMessage(this.message)
            },
            colorClass() {
                return this.getColorClassByMessage(this.message)
            },
            ...mapGetters(BOTS, [GET_BOT_BY_ID]),
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
            ...mapGetters(CHAT, [GET_CHAT_REPLY_QUOTE]),
        },
        methods: {
            onClose() {
                this[ACT_CHAT_UPDATE_REPLY]({})
            },
            ...mapActions(CHAT, [ACT_CHAT_UPDATE_REPLY])
        }
    }
