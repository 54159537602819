var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.side_notif_msg,
          expression: "side_notif_msg",
        },
      ],
      staticClass: "side-notification-bar",
    },
    [_c("span", [_vm._v(_vm._s(_vm.side_notif_msg))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }