//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex"
import { CHAT } from "../../../store/modulesNames"
import { ACT_CHAT_SHOW_REACTION_PANEL } from "../../../store/actionsTypes"

export default {
    name: "Reactions",
    props: {
        reactions: {
            type: Array,
            required: false,
            default: []
        },
        isThreads: {
            type: Boolean,
            default: false,
        },        
        isRightSide: {
            type: Boolean,
            default: false,
        },        
    },
    data() {
        return {
            isMouseOver: false,
            isShowReactionsPanel: false,
            clickX: -1,
            clickY: -1,  
            panelX: -1,
            panelY: -1,
            deltaY: 0,
            panelHeight: 0,
        }
    },
    computed: {
        reactionsWrapperStyle() {
            if (this.reactions.length) return {}
            return {'width': 0}
        },
        panelStyle() {
            const style = {}
            Object.assign(style, {'padding-top': '0.45em'})
            Object.assign(style, {'background-color': 'var(--bg-color3)'})
            Object.assign(style, {'max-height': '368px', 'max-width': '300px'})
            if (this.deltaY > 0 && this.deltaY < this.panelHeight) Object.assign(style, {'bottom': '52px'})
            else Object.assign(style, {'top': this.panelY + 'px'})
            Object.assign(style, {'left': this.panelX + 'px'})
            return style
        },
        preparedAggrReactions() {
            if (!this.reactions.length) return []
            const _aggReactions = this._groupArrayByField(this.reactions, 'reaction')
            const aggReactionsSorted = Object.fromEntries(Object.entries(_aggReactions).sort(([,a],[,b]) => b.length-a.length))
            const result = []
            Object.keys(aggReactionsSorted).map(key => {
                if (key && key.length) result.push({ reaction: key, count: _aggReactions[key].length })
            })
            return result
        },
    },
    methods: {
        showReactionsPanel(e) {
            e.preventDefault()
            e.stopPropagation()
            let screenX = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            this.panelX = (e.clientX >= screenX/2) ? e.clientX - 210 : e.clientX - 125
            const panelHeight = 40 + 58*this.reactions.length > (370 + 40) ? 370 + 40 : 40 + 58*this.reactions.length
            this.panelHeight = panelHeight
            let screenY = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            const deltaY = e.clientY + panelHeight - 52 - screenY
            this.deltaY = deltaY
            if (deltaY > -58 && deltaY < 0) this.panelY = screenY - panelHeight - 52
            else this.panelY = e.clientY
            const reactions = { reactions: this.reactions, aggReactions: this.preparedAggrReactions }
            this[ACT_CHAT_SHOW_REACTION_PANEL]({ isShow: true, reactions, style: this.panelStyle})
        },
        _groupArrayByField(arr, key) {
            if (!Array.isArray(arr) || !key) return [] 
            return arr.reduce((rv, x) => {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv
            }, {})
        },
        ...mapActions(CHAT, [ACT_CHAT_SHOW_REACTION_PANEL]),
    },

}
