const mixin = {
    methods: {
        async scrollToPub(pubId) {
            try {                
                const pubsList = document.getElementById('publications-list')
                const element = pubsList.querySelector('#publication' + pubId)
                if (!element) return
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })

                setTimeout(function() {
                    element.classList.add('scroll-pub');
                    element.style.opacity = 0.3;
                }, 500);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 800);
                setTimeout(function() {
                    element.style.opacity = 0.3;
                }, 1100);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 1400);
                setTimeout(function() {
                    element.classList.remove('scroll-pub');
                }, 1700);
            } catch (error) {
                console.log("🚀 ~ file: ScrollToPubsMixin.js:29 ~ scrollToPub ~ error:", error)
            }
        },
    }
}

export default mixin