var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c(
        "div",
        { staticClass: "main-content" },
        [
          _vm.need_loader && !_vm.loaded ? _c("main-content-loader") : _vm._e(),
          _c(
            _vm.component,
            _vm._b(
              {
                ref: "component",
                tag: "component",
                staticClass: "main-body",
                attrs: { need_loader: _vm.need_loader, loaded: _vm.loaded },
                on: {
                  "update:need_loader": function ($event) {
                    _vm.need_loader = $event
                  },
                  "update:loaded": function ($event) {
                    _vm.loaded = $event
                  },
                },
              },
              "component",
              _vm.compProps,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }