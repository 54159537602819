
    import { mapActions } from "vuex"
    import { ACT_SET_CHANNEL_MUTED } from "../../store/actionsTypes"
    import { CHANNELS } from "../../store/modulesNames"

    import MuteDialog from "./MuteDialog.vue"

    export default {
        name: "ChannelMute",
        extends: MuteDialog,
        props: {
            chId: {
                type: Number,
                required: true
            },
        },
        methods: {
            setMute(mute) {
                this[ACT_SET_CHANNEL_MUTED]({
                    chId: this.chId,
                    mute,
                })
            },
            ...mapActions(CHANNELS, [ACT_SET_CHANNEL_MUTED])
        }
    }
