var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "footer-section" }, [
        _c(
          "div",
          { staticClass: "camera-toggle sec-btn" },
          [
            _c("conf-btn", {
              attrs: {
                imageClasses: _vm.cameraClasses,
                subTitle: _vm.$t("videomost.room-btns.camera"),
                disabled: _vm.cameraDisabled,
                clickable: _vm.btsShow,
                chevron: true,
              },
              on: { chevron: _vm.toggleCamList, click: _vm.cameraToggle },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "microphone-toggle sec-btn" },
          [
            _c("conf-btn", {
              attrs: {
                imageClasses: _vm.microphoneClasses,
                subTitle: _vm.$t("videomost.room-btns.microphone"),
                disabled: _vm.microphoneDisabled,
                clickable: _vm.btsShow,
                chevron: true,
              },
              on: { chevron: _vm.showMicList, click: _vm.microphoneToggle },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "members sec-btn" },
          [
            _c("conf-btn", {
              attrs: {
                imageClasses: "fal fa-users",
                subTitle: _vm.$t("videomost.room-btns.participants"),
                disabled: !_vm.btsShow,
                clickable: _vm.btsShow,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("members-open")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "share sec-btn" },
          [
            _c("conf-btn", {
              attrs: {
                imageClasses: "fal fa-desktop",
                subTitle: _vm.ownShare
                  ? _vm.$t("videomost.room-btns.share-end")
                  : _vm.$t("videomost.room-btns.share-start"),
                disabled: _vm.shareDisabled,
                color: _vm.ownShare ? "red" : undefined,
                clickable: _vm.btsShow,
              },
              on: { click: _vm.onShare },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "more sec-btn" },
          [
            _c("conf-btn", {
              attrs: {
                imageClasses: "fal fa-ellipsis-h",
                subTitle: _vm.$t("videomost.room-btns.more"),
                disabled: !_vm.btsShow,
              },
              on: { click: _vm.toggleReactionSelect },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }