// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title[data-v-a7954d68] {
  padding: 0px 15px;
  font-size: 17px;
  color: var(--base-blue-color);
}
.found-bots .bot[data-v-a7954d68] {
  margin: 0px 10px;
  height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.found-bots .bot.selected[data-v-a7954d68] {
  background-color: var(--list-item-activebgcolor);
}
.found-bots .bot[data-v-a7954d68]:hover {
  background-color: var(--item-bgcolor-hover);
}
.found-bots .bot.focused[data-v-a7954d68] {
  background-color: var(--list-item-activebgcolor);
}
.found-bots .bot .bot-info[data-v-a7954d68] {
  padding-left: 12px;
}
.found-bots .bot .bot-info span.bot-name[data-v-a7954d68] {
  font-size: 16px;
}
.found-bots .bot .bot-info span.bot-description[data-v-a7954d68] {
  display: block;
  width: 262px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #BDBDBD;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/search/myBots.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gDAAgD;AAClD;AACA;EACE,2CAA2C;AAC7C;AACA;EACE,gDAAgD;AAClD;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".title[data-v-a7954d68] {\n  padding: 0px 15px;\n  font-size: 17px;\n  color: var(--base-blue-color);\n}\n.found-bots .bot[data-v-a7954d68] {\n  margin: 0px 10px;\n  height: 70px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.found-bots .bot.selected[data-v-a7954d68] {\n  background-color: var(--list-item-activebgcolor);\n}\n.found-bots .bot[data-v-a7954d68]:hover {\n  background-color: var(--item-bgcolor-hover);\n}\n.found-bots .bot.focused[data-v-a7954d68] {\n  background-color: var(--list-item-activebgcolor);\n}\n.found-bots .bot .bot-info[data-v-a7954d68] {\n  padding-left: 12px;\n}\n.found-bots .bot .bot-info span.bot-name[data-v-a7954d68] {\n  font-size: 16px;\n}\n.found-bots .bot .bot-info span.bot-description[data-v-a7954d68] {\n  display: block;\n  width: 262px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #BDBDBD;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
