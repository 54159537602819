
    import {mapGetters} from 'vuex'
    import {RC_RADIO} from '../../store/modulesNames'
    import {
        RC_RADIO_IS_TURN_ON,
        GET_RC_RADIO_GRANT_STATUS,
    } from '../../store/gettersTypes'

    import RadioSignal, { SIGNALS } from "./RadioSignal.vue"

    import { GRANT_STATUSES } from '../../store/modules/rc-radio'

    export default {
        name: "RcSignal",
        extends: RadioSignal,
        data() {
          return {
              signal: -1
          }
        },
        computed: {
            audioRepeat() {
                return this[GET_RC_RADIO_GRANT_STATUS] === GRANT_STATUSES.REQUEST_QUEUED
            },
            ...mapGetters(RC_RADIO, [RC_RADIO_IS_TURN_ON, GET_RC_RADIO_GRANT_STATUS])
        },
        watch: {
            [RC_RADIO_IS_TURN_ON](val) {
                if (!val) this.signal = -1
            },
            [GET_RC_RADIO_GRANT_STATUS](status, oldStatus) {
                let signal = -1
                if (this[RC_RADIO_IS_TURN_ON]) {
                    switch (status) {
                        case GRANT_STATUSES.GRANTED:
                            signal = SIGNALS.GRANTED
                            break
                        case GRANT_STATUSES.NOT_GRANTED:
                            signal = SIGNALS.NOT_GRANTED
                            break
                        case GRANT_STATUSES.REQUEST_QUEUED:
                            signal = SIGNALS.REQUEST_QUEUED
                            break
                        case GRANT_STATUSES.TO_ANOTHER_USER:
                            signal = SIGNALS.TO_ANOTHER_USER
                            break
                        case GRANT_STATUSES.ANOTHER_USER_INTERRUPTED:
                            signal = SIGNALS.NOT_GRANTED
                            break
                        case GRANT_STATUSES.REQUEST:
                            if (oldStatus === GRANT_STATUSES.GRANTED) signal = SIGNALS.RELEASE //timeout
                            break
                        case GRANT_STATUSES.NONE:
                            if ([GRANT_STATUSES.GRANTED, GRANT_STATUSES.TO_ANOTHER_USER].includes(oldStatus)) signal = SIGNALS.RELEASE
                            break
                    }
                }
                if (signal > -1) this.signal = signal
            }
        }
    }
