var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-server" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c("div", { staticClass: "component-section" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("select-server-input", {
            attrs: {
              id: "select-servery",
              name: "server",
              tabindex: "1",
              label: _vm.$t("ip"),
              default_text: _vm.server,
              items: _vm.servers,
              "set-server": _vm.server,
            },
            on: {
              "update:setServer": function ($event) {
                _vm.server = $event
              },
              "update:set-server": function ($event) {
                _vm.server = $event
              },
              done: _vm.doSetServer,
            },
          }),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: !_vm.server || _vm.connecting, tabindex: "2" },
          on: {
            click: _vm.doSetServer,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doSetServer.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("continue")))]
      ),
    ]),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "description text-center" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("select-server")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }