// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader[data-v-29d4b630] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: var(--bg-color2);
  z-index: 10;
}
.loader .spinner-cont[data-v-29d4b630] {
  margin: auto;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/MainContentLoader.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,4BAA4B;EAC5B,WAAW;AACb;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".loader[data-v-29d4b630] {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  background: var(--bg-color2);\n  z-index: 10;\n}\n.loader .spinner-cont[data-v-29d4b630] {\n  margin: auto;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
