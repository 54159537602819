// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-wrapper[data-v-6d549568] {
  pointer-events: none;
  padding: 48vh 10px;
  width: 100%;
  height: 100vh;
  margin: auto;
  /* transform: translate(0, 283px); */
  background-color: rgba(255, 255, 255, 0.95);
}
.notice-wrapper .notice[data-v-6d549568] {
  display: flex;
  flex-direction: row;
  margin: auto 10px 10px 10px;
  padding: 10px;
  height: 40px;
  line-height: 20px;
  border-radius: 5px;
  border: solid 1px var(--base-blue-color);
  color: var(--base-blue-color);
  background-color: rgba(26, 88, 211, 0.3);
}
.notice-wrapper .notice span[data-v-6d549568] {
  flex: 1 1;
  text-align: center;
}
.notice-wrapper .notice div[data-v-6d549568] {
  pointer-events: initial;
  align-self: flex-end;
  margin: auto 0px auto auto;
  cursor: pointer;
}
.fade-enter-active[data-v-6d549568],
.fade-leave-active[data-v-6d549568] {
  transition: opacity .5s;
}
.fade-enter[data-v-6d549568],
.fade-leave-to[data-v-6d549568] {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/Notice.vue"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,YAAY;EACZ,oCAAoC;EACpC,2CAA2C;AAC7C;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,wCAAwC;EACxC,6BAA6B;EAC7B,wCAAwC;AAC1C;AACA;EACE,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,0BAA0B;EAC1B,eAAe;AACjB;AACA;;EAEE,uBAAuB;AACzB;AACA;;EAEE,UAAU;AACZ","sourcesContent":[".notice-wrapper[data-v-6d549568] {\n  pointer-events: none;\n  padding: 48vh 10px;\n  width: 100%;\n  height: 100vh;\n  margin: auto;\n  /* transform: translate(0, 283px); */\n  background-color: rgba(255, 255, 255, 0.95);\n}\n.notice-wrapper .notice[data-v-6d549568] {\n  display: flex;\n  flex-direction: row;\n  margin: auto 10px 10px 10px;\n  padding: 10px;\n  height: 40px;\n  line-height: 20px;\n  border-radius: 5px;\n  border: solid 1px var(--base-blue-color);\n  color: var(--base-blue-color);\n  background-color: rgba(26, 88, 211, 0.3);\n}\n.notice-wrapper .notice span[data-v-6d549568] {\n  flex: 1 1;\n  text-align: center;\n}\n.notice-wrapper .notice div[data-v-6d549568] {\n  pointer-events: initial;\n  align-self: flex-end;\n  margin: auto 0px auto auto;\n  cursor: pointer;\n}\n.fade-enter-active[data-v-6d549568],\n.fade-leave-active[data-v-6d549568] {\n  transition: opacity .5s;\n}\n.fade-enter[data-v-6d549568],\n.fade-leave-to[data-v-6d549568] {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
