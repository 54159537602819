// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activeWrap[data-v-4873250f] {
  width: 70vh;
  flex-direction: inherit;
  height: 70vh;
}
.wrapper[data-v-4873250f] {
  position: absolute;
  right: 10px;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/IncommingCallWrapper.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,aAAa;EACb,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".activeWrap[data-v-4873250f] {\n  width: 70vh;\n  flex-direction: inherit;\n  height: 70vh;\n}\n.wrapper[data-v-4873250f] {\n  position: absolute;\n  right: 10px;\n  bottom: 0;\n  margin: auto;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
