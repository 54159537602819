import IOClient from './IOClient'

import meetingsProto from './protocols/meetings'
import encryptionProto from './protocols/encryption'
import {encryptTextByPublicKey, generateHexString, importRsaKey} from '../../common/Encrypter'

const protoList = [
    meetingsProto,
]

class MeetingIOClient extends IOClient {
    constructor (encryption) {
        super({logName: 'MeetingIOClient', protoList, encryption, roschatServer: true})
        this.quiet = false //@todo
    }
}

export default MeetingIOClient
