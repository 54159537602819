import IOClient from './IOClient'

import sessionsProto from './protocols/sessions'
import userProfileProto from './protocols/userProfile'
import messagesProto from './protocols/messages'
import chatsProto from './protocols/chats'
import callsProto from './protocols/calls'
import favoriteProto from './protocols/favorite'
import acsProto from './protocols/acs'
import channelsProto from './protocols/channels'
import assistantProto from './protocols/assistant'
import radioProto from './protocols/radio'
import pollProto from './protocols/poll'
import locationProto from './protocols/location'
import botsProto from './protocols/bots'
import webrtcProto from './protocols/webrtc'
import conferenceProto from './protocols/conference'
import contactsProto from './protocols/contacts'
// import encryptionProto from './protocols/encryption'
import dlpProto from './protocols/dlp'
import rolesProto from './protocols/roles'
import scheduleProto from './protocols/schedule'

import meetingsProto from './protocols/meetings'

// import axios from 'axios'

const protoList = [
    sessionsProto,
    userProfileProto,
    messagesProto,
    chatsProto,
    callsProto,
    favoriteProto,
    acsProto,
    channelsProto,
    assistantProto,
    radioProto,
    pollProto,
    locationProto,
    botsProto,
    webrtcProto,
    meetingsProto,
    conferenceProto,
    contactsProto,
    dlpProto,
    rolesProto,
    scheduleProto
]

class CommonIOClient extends IOClient {
    constructor () {
        super({logName: 'CommonIOClient', protoList, roschatServer: true })
    }

    async getCompanies() {
        try {
            return []
        } catch (e) {
            console.log(e)
            return []
        }
    }

    /*_onSocketDisconnected() {

    }*/
}

export default CommonIOClient