// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-block .main-body[data-v-959def0a] {
  border-left: none;
}
.main-header .menu-icons[data-v-959def0a] {
  display: flex;
  margin: 0 16px;
  align-items: center;
}
.main-header .menu-icons i[data-v-959def0a] {
  cursor: pointer;
  font-size: 24px;
  color: var(--toolbtn-color);
}
.main-header .menu-icons i[data-v-959def0a]:hover {
  color: var(--toolbtn-color-hover);
}
.videoconferences[data-v-959def0a] {
  padding: 0 16px 18px 16px;
  overflow-y: auto;
}
.videoconferences[data-v-959def0a] .main-content-container {
  margin: 20px auto 0 auto;
  flex-basis: auto !important;
  background: var(--content-container-bgcolor);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;
}
.videoconferences[data-v-959def0a] .main-content-container .main-content-container-header {
  margin-bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 600;
}
.videoconferences[data-v-959def0a] .main-content-container .main-content-container-main {
  background: inherit;
  box-shadow: none;
  margin-bottom: 0px;
}
.videoconferences[data-v-959def0a] .main-content-container .main-content-container-main .no-confs {
  cursor: initial;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNewManagment/mainContentVideoConfs.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,iCAAiC;AACnC;AACA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,4CAA4C;EAC5C,kBAAkB;EAClB,6EAA6E;AAC/E;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".main-block .main-body[data-v-959def0a] {\n  border-left: none;\n}\n.main-header .menu-icons[data-v-959def0a] {\n  display: flex;\n  margin: 0 16px;\n  align-items: center;\n}\n.main-header .menu-icons i[data-v-959def0a] {\n  cursor: pointer;\n  font-size: 24px;\n  color: var(--toolbtn-color);\n}\n.main-header .menu-icons i[data-v-959def0a]:hover {\n  color: var(--toolbtn-color-hover);\n}\n.videoconferences[data-v-959def0a] {\n  padding: 0 16px 18px 16px;\n  overflow-y: auto;\n}\n.videoconferences[data-v-959def0a] .main-content-container {\n  margin: 20px auto 0 auto;\n  flex-basis: auto !important;\n  background: var(--content-container-bgcolor);\n  border-radius: 4px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;\n}\n.videoconferences[data-v-959def0a] .main-content-container .main-content-container-header {\n  margin-bottom: 0px;\n  padding-top: 12px;\n  padding-left: 12px;\n  font-size: 16px;\n  font-weight: 600;\n}\n.videoconferences[data-v-959def0a] .main-content-container .main-content-container-main {\n  background: inherit;\n  box-shadow: none;\n  margin-bottom: 0px;\n}\n.videoconferences[data-v-959def0a] .main-content-container .main-content-container-main .no-confs {\n  cursor: initial;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
