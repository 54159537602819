import io from 'socket.io-client'

import Logger from '../../common/Logger'
import EventEmitter from '../../common/EventEmitter'
import encryptionProto from './protocols/encryption'
import {
    decryptText,
    encryptText,
    encryptTextByPublicKey,
    generateHexString,
    importRsaKey,
} from '../../common/Encrypter'
import axios from 'axios'
import {ACT_GET_SERVER_CONFIG} from '../../store/actionsTypes'

export const STATUS = {
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    RECONNECTING: 'reconnecting',
    KEY_AWAITING: 'key-awaiting',
}

class IOClient extends EventEmitter {
    constructor ({
                     logName,
                     transports = null,
                     withCredentials = true,
                     encryption = false,
                     roschatServer = false,
                     protoList = [],
    }) {
        super()
        this.socket = null
        this.server = null
        this.logger = new Logger(logName)
        this.sessionKey = null
        this.reconnect = true // переподключаться на disconnect?
        this.retrySeconds = 0 // время повторения запроса конфига и ключа шиврования без выброса ошибки в connect
        this.serverApi = 0
        this.withCredentials = withCredentials
        this.transports = transports
        this.encryption = encryption
        this.try_count = 0 // число попыток переподклбчения, используем для расета интервала переподключений
        this.reconnectTimeoutId = 0
        this.state = STATUS.DISCONNECTED
        this.protoList = protoList
        this.roschatServer = roschatServer // для РосЧат сервера дополнительно тянем конфиг
        this.log = (str) => this.logger.info(str)
        this._subscribeOnSocket(encryption)
    }

    _subscribeOnSocket(encryption) {
        if (encryption) this.addSocketEmitters(encryptionProto.emitters)
        this.on('state-changed', ({state, ...payload}) => {
            switch (state) {
                case STATUS.CONNECTED: {
                    this.protoList.forEach((proto) => {
                        this.addSocketEmitters(proto.emitters)
                        this.addSocketListeners(proto.listeners)
                    })
                    break
                }
                case STATUS.DISCONNECTED: {
                    console.log('~~~~~~~~ case STATUS.DISCONNECTED', this.reconnect)
                    if (this.reconnect) this._reconnect()
                    break
                }
                case STATUS.RECONNECTING: {
                    console.log('~~~~~~~~ case STATUS.RECONNECTING')
                    break
                }
                case STATUS.KEY_AWAITING: {
                    console.log('~~~~~~~~ case STATUS.KEY_AWAITING')
                    break
                }
            }
        })
        //this.on('connect', this._onSocketConnected.bind(this))
        //this.on('disconnect', this._onSocketDisconnected.bind(this))
        //this.on('connect_error', this._onSocketDisconnected.bind(this))
    }

    _setState (state, payload = {}) {
        if (this.state === state || (state === STATUS.RECONNECTING && this.state === STATUS.CONNECTING)) return
        this.state = state
        this.emit('state-changed', { state, ...payload })
    }

    async getServerConfig ({roschatServer}) {
        try {
            const url = new URL(roschatServer)
            const response = await axios.get(url.origin + '/ajax/config.json')
            const respOrigin = (new URL(response.request.responseURL)).origin
            const config = (response || {}).data
            config.redirectedServer = respOrigin
            return config
        } catch (e) {
            this.log('getServerConfig: error while getting server config')
            this.log(e)
            throw e
        }
    }

    async encKeyExchange () {
        try {
            let publicKey = await this.getPublicKey()
            publicKey = await importRsaKey(publicKey)
            let sessionKey = await generateHexString()
            await this.setSessionKey(await encryptTextByPublicKey(publicKey, sessionKey))
            this.setProtoSessionKey(sessionKey)
        } catch (e) {
            this.log('encKeyExchange: error while transferring encryption keys')
            this.log(e)
            throw e
        }
    }

    executeWithRetrySeconds(fn) {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(await fn())
            } catch (e) {
                this.retrySeconds -= 5
                if (this.retrySeconds > 0) {
                    setTimeout(async () => {
                        try {
                            resolve(await this.executeWithRetrySeconds(fn))
                        } catch (e) {
                            reject(e)
                        }
                    }, 5000)
                } else {
                    reject(e)
                }
            }
        })
    }

    connect({server = this.server, repeat = false}) {
        console.log(`IOClient: args ${JSON.stringify(arguments)}`)
        return new Promise(async (resolve, reject) => {
            console.log(`IOClient: connect to ${server}`)
            if (!server) return reject('Server not specified')
            this.serverApi = 0
            this.server = server
            let wsServer = server
            this.retrySeconds = repeat ? 30 : 0
            if (this.roschatServer) {
                let config
                try {
                    this.log('connect: request server config')
                    config = await this.executeWithRetrySeconds( () => this.getServerConfig({roschatServer: server}))
                } catch (e) {
                    return reject(e)
                }
                this.emit('config-received', config)
                this.encryption = config.APILevel >= declarations.serverAPILevels.LEVEL_11
                const respOrigin = config.redirectedServer
                // формируем websocket адрес
                let wsUrl = new URL(respOrigin)
                wsUrl.port = config.webSocketsPortVer4
                // всегда https
                wsUrl.protocol = 'https'
                wsServer = wsUrl.origin
            }
            await this.disconnect(this.reconnect)
            //if (reconnect) this.reconnect = true
            let options = {
                reconnection: false,
                ...(this.withCredentials && {withCredentials: this.withCredentials}),
                ...(this.transports && {transports: this.transports}),
                // transports: ['websocket']
                // нельзя websocket, т.к. нужны cookie
            }
            this.log(`connect: to ${wsServer} with options ${JSON.stringify(options)}`)
            this._setState(STATUS.CONNECTING)
            this.socket = io.connect(wsServer, options)
            this.socket.on('connect', async () => {
                this.reconnect = true
                this.try_count = 0
                this.log('connect: connected to ' + wsServer)
                this.bindSocket()
                if (this.encryption) {
                    try {
                        this.log('connect: start transferring encryption keys')
                        this.addSocketEmitters(encryptionProto.emitters)
                        //this._setState(STATUS.KEY_AWAITING)
                        await this.executeWithRetrySeconds( () => this.encKeyExchange())
                        this.log('connect: encryption keys set')
                    } catch (e) {
                        this.log('connect: error while transferring encryption keys')
                        await this.disconnect(this.reconnect)
                        return reject(e)
                    }
                }
                resolve(this.socket.io.engine.id)
                this._setState(STATUS.CONNECTED, {socket: this.socket})
                //this.emit('connect', this.socket)
            })

            this.socket.on('disconnect', (e) => {
                this.log('connect: disconnect from server ' + wsServer)
                //this.emit('disconnect', this.reconnect)
                this._setState(STATUS.DISCONNECTED, {reconnect: this.reconnect})
                reject(e)
            })
            this.socket.on('connect_error', (e) => {
                this.log('connect: error ' + e)
                this._setState(STATUS.DISCONNECTED, {reconnect: this.reconnect})
                //this.emit('connect_error', this.reconnect)
                reject(e)
            })
        })
    }

    _reconnect () {
        if (this.reconnectTimeoutId) return
        this._setState(STATUS.RECONNECTING)
        let timeout = 5000
        if (this.try_count >= 6) timeout = 30000
        else if (this.try_count >= 3) timeout = 10000
        //else if (this.try_count === 0) timeout = 0

        this.try_count++

        this.reconnectTimeoutId = setTimeout(async () => {
            clearTimeout(this.reconnectTimeoutId)
            this.reconnectTimeoutId = 0
            try {
                this._setState(STATUS.RECONNECTING)
                await this.connect({server: this.server})
            } catch (e) {
                this._reconnect()
            }
        }, timeout)
    }

    disconnect (reconnect = false) {
        return new Promise((resolve, reject) => {
            this.reconnect = reconnect
            if (this.reconnectTimeoutId) {
                clearTimeout(this.reconnectTimeoutId)
                this.reconnectTimeoutId = 0
            }

            if (this.socket && this.socket.close) {
                this.socket.off('connect')
                this.socket.off('disconnect')
                this.socket.off('connect_error')
                this.sessionKey = null
                this.socket.close()
                this.log('Socket disconnect')
            }
            if (!reconnect) this._setState(STATUS.DISCONNECTED)
            setTimeout(resolve, 100)
        })
    }

    isConnected () {
        return this.state === STATUS.CONNECTED
    }

    setProtoSessionKey (key) {
        this.sessionKey = key
    }
    isEncryptedEvent(event) {
        return !['get-public-key', 'set-session-key'].includes(event)
    }
    /*isEncryptedEvent (event) {
     return !['get-public-key', 'set-session-key'].includes(event)
     }*/
    async decryptProtoData (data) {
        if (!data) return data
        if (!this.sessionKey) throw Error('session key not set')
        if (!data.encryptedData) throw Error('data not encrypted')
        const decryptedString = await decryptText(this.sessionKey, data.encryptedData)
        return JSON.parse(decryptedString)
    }
    async encryptProtoData (data) {
        if (!data) return data
        if (!this.sessionKey) throw Error('session key not set')
        const dataString = JSON.stringify(data)
        const encryptedData = await encryptText(this.sessionKey, dataString)
        return { encryptedData }
    }
    bindSocket () {
        let socketOnOrigin = this.socket.on
        let socketEmitOrigin = this.socket.emit

        this.socket.emit = async (event, data, cb) => {
            let overloadingCb = cb
            if (this.sessionKey && this.isEncryptedEvent(event)) {
                data = data && await this.encryptProtoData(data)
                if (overloadingCb) overloadingCb = async (data) => {
                    if (data && !data.error) data = await this.decryptProtoData(data)
                    cb(data)
                }
            }
            socketEmitOrigin.apply(this.socket, [event, data, overloadingCb])
        }

        this.socket.on = (event, cb) => {
            socketOnOrigin.apply(this.socket, [event, async (data, cbSocket) => {
                let overloadingCb = cbSocket
                if (this.sessionKey && this.isEncryptedEvent(event)) {
                    data = data && await this.decryptProtoData(data)
                    if (overloadingCb) overloadingCb = async (data) => cb(await this.encryptProtoData(data))
                }
                cb && cb(data, overloadingCb)
            }])
        }
    }

    addSocketEmitters(emitters = {}) {
        Object.entries(emitters).forEach(([methodName, method]) => this[methodName] = method)
        //emitters.log = this.log
        //emitters.socket = this.socket
    }

    addSocketListeners(listeners = {}) {
        Object.entries(listeners).forEach(([event, listener]) => this.socket.on(event, listener.bind(this)))
    }

    _emitWithTimeOut(eventName, payload, timeOut = 3000) {
        return new Promise((resolve, reject) => {
            let timeOutId = setTimeout(() => {
                reject(new Error('emitTimeOut'))
            }, timeOut)
            this.socket.emit(eventName, payload, (...args) => {
                clearTimeout(timeOutId)
                resolve(...args)
            })
        })
    }
}

export default IOClient