import {equals} from 'ramda'

import {
    BOTS,
    CHANNEL,
    CHANNELS,
    CHAT,
    CHATS,
    CLIENTDATA,
    CONTACTS,
    CONTENT_MANAGER,
    INFO,
    TETRA,
} from '../modulesNames'

import {
    GET_ALL_NOTIFY,
    GET_BOT_BY_ID,
    GET_CHANNEL,
    GET_CHAT,
    GET_DOCUMENT_FOCUS,
    GET_DOCUMENT_HIDDEN,
    GET_INFO_IS_THREADS_OPENED,
    GET_INFO_OPEN,
    GET_IS_ELECTRON,
    GET_MAX_NOTIFY_COUNT,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_NEW_NOTIFY_TYPE,
    GET_NOTIFIES_BY_TYPE,
    GET_NOTIFY_BADGE_COUNT,
    GET_NOTIFY_BY_DATA,
    GET_NOTIFY_BY_ID,
    GET_NOTIFY_BY_PAYLOAD,
    GET_SELECTED_CHAT,
    GET_TETRA_USER_INFO,
} from '../gettersTypes'

import {
    ACT_INFO_PUSH,
    ACT_NOTIFY_NEW_ADD,
    ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE,
    ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE_TEXT,
    ACT_NOTIFY_NEW_BUILD_INCOMING_CALL,
    ACT_NOTIFY_NEW_BUILD_MESSAGE,
    ACT_NOTIFY_NEW_BUILD_MISSED_CALL,
    ACT_NOTIFY_NEW_BUILD_PUBLICATION,
    ACT_NOTIFY_NEW_BUILD_TETRA_MESSAGE,
    ACT_NOTIFY_NEW_BUILD_THREADS_MESSAGE,
    ACT_NOTIFY_NEW_HANDLE_CLICK,
    ACT_NOTIFY_NEW_REMOVE,
    ACT_NOTIFY_NEW_REMOVE_ALL,
    ACT_NOTIFY_NEW_REMOVE_BY_DATA,
    ACT_NOTIFY_NEW_SHOW_BROWSER_NOTIFY,
    ACT_NOTIFY_NEW_SHOW_ELECTRON_NOTIFY,
    ACT_NOTIFY_NEW_SHOW_IN_APP_NOTIFY,
    ACT_NOTIFY_NEW_SHOW_NOTIFY,
    ACT_OPEN_CHANNEL,
    ACT_SET_CURRENT_COMMENTS,
    ACT_SHOW_SYSTEM_NOTIFICATION,
    ACT_HIDE_SYSTEM_NOTIFICATION,
} from '../actionsTypes'

import {
    MUT_SCROLL_TO_PUBLICATION,
    MUT_SET_SIDE_TYPE,
    ADD_SYSTEM_NOTIFICATION,
    DELETE_SYSTEM_NOTIFICATION,
} from '../mutationsTypes'

import {
    CHAT_TYPES,
    CONTACT_FIELD_TYPES,
} from '../../constants'

import { i18n } from '../../../ext/i18n'
import lookForRefs from '../../mixins/lookForRefs'

import ipc from '../../../electron/ipc'
import event_bus from '../../eventBus'
import {INFO_TYPES} from './info'
let locale = i18n.messages[i18n.locale]

export const NOTIFY_TYPES = {
    IN_APP: 'inApp',
    BROWSER: 'browser',
    ELECTRON: 'electron',
}

export const NOTIFY_DATA_TYPES = {
    ALERT: 'alert',
    CHAT_MESSAGE: 'chatMessage',
    PUBLICATION: 'publication',
    MISSED_CALL: 'missedCall',
    INCOMING_CALL: 'incomingCall',
    TETRA_MESSAGE: 'tetraMessage',
    THREADS_MESSAGE: 'threadsMessage',
}

export const NOTIFY_PROPERTIES = {
    ID: 'id',
    TYPE: 'type',
    PAYLOAD_TYPE: 'payloadType',
    PAYLOAD: 'payload',
    NOTIFY_PAYLOAD: 'notifyPayload',
    BROWSER_NOTIFY: 'browserNotify',
}

export const NOTIFY_PAYLOAD_PROPERTIES = {
    IMAGE: 'image',
    THEME: 'theme',
    HEADER: 'header',
    TITLE: 'title',
    SUBTITLE: 'subtitle',
    ALERT: 'alert'
}

export default {
    namespaced: true,
    state: {
        lastNotifyId: 0,
        // список отображаемых
        notifications: [],
        // очередь на добавление в список отображаемых
        delayNotifications: [],
        systemNotification: null,
        badgeCounter: {
            chat: 0,
            channels: 0,
            calls: 0,
        },
    },
    getters: {
        [GET_NOTIFY_BY_ID]: (state) => (id) => {
            return state.notifications.find(({id: notifyID}) => notifyID === id)
        },
        [GET_NOTIFY_BY_PAYLOAD]: (state) => (type, payload) => {
            return state.notifications.find(({type: notifyType, payload: notifyPayload}) => notifyType === type && equals(notifyPayload, payload))
        },
        [GET_MAX_NOTIFY_COUNT]: () => {
            return 3
        },
        [GET_NOTIFY_BADGE_COUNT]: () => {

        },
        [GET_NOTIFIES_BY_TYPE]: (state, getters) => (type) => {
            let count = getters[GET_MAX_NOTIFY_COUNT]
            let notifies = []
            //for (let i = state.notifications.length - 1; i >= 0; i--) {
            for (let i = 0, j = state.notifications.length; i < j; i++) {
                let notify = state.notifications[i]
                if (notify[NOTIFY_PROPERTIES.TYPE] === type) {
                    if (notifies.push(notify) >= count) break
                }
            }
            return notifies
        },
        [GET_NOTIFY_BY_DATA]: (state) => (type, payload) => {
            for (let i = 0, count = state.notifications.length; i < count; i++) {
                let notify = state.notifications[i]
                if (notify[NOTIFY_PROPERTIES.PAYLOAD_TYPE] === type) {
                    const notifyPayload = notify[NOTIFY_PROPERTIES.PAYLOAD]
                    switch (type) {
                        case NOTIFY_DATA_TYPES.CHAT_MESSAGE:
                            if (notifyPayload.id === payload.id)  return notify
                            break
                        case NOTIFY_DATA_TYPES.PUBLICATION:
                            if (notifyPayload.id === payload.id) return notify
                            break
                        case NOTIFY_DATA_TYPES.MISSED_CALL:
                            if (notifyPayload.id === payload.id) return notify
                            break
                        case NOTIFY_DATA_TYPES.INCOMING_CALL:
                            if (notifyPayload.type === payload.type && notifyPayload.id === payload.id) return notify
                            break
                        case NOTIFY_DATA_TYPES.THREADS_MESSAGE:
                            if (notifyPayload.id === payload.id) return notify
                            break
                        case NOTIFY_DATA_TYPES.TETRA_MESSAGE:
                            if (notifyPayload.id === payload.id) return notify
                            break
                    }
                }
            }
        },
        [GET_NEW_NOTIFY_TYPE]: (state, getters, rootState, rootGetters) => (payloadType) => {
            let type
            let hidden = rootGetters[`${CONTENT_MANAGER}/${GET_DOCUMENT_HIDDEN}`]
            let focus = rootGetters[`${CONTENT_MANAGER}/${GET_DOCUMENT_FOCUS}`]
            let isElectron = rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]
            if (!hidden && focus) {
                type = NOTIFY_TYPES.IN_APP
            } else if (isElectron) {
                type = NOTIFY_TYPES.ELECTRON
            } else {
                type = NOTIFY_TYPES.BROWSER
            }
            return type
        },
        [GET_ALL_NOTIFY]: (state) => {
            return state.notifications
        },
    },
    actions: {
        [ACT_NOTIFY_NEW_ADD]: async ({state, dispatch, getters, commit}, {type, payload}) => {
            let notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_MESSAGE, {type, payload})
            if (notifyPayload) {

                let notify = {
                    [NOTIFY_PROPERTIES.ID]: ++state.lastNotifyId,
                    [NOTIFY_PROPERTIES.PAYLOAD_TYPE]: type,
                    [NOTIFY_PROPERTIES.PAYLOAD]: { ...payload },
                    [NOTIFY_PROPERTIES.NOTIFY_PAYLOAD]: notifyPayload,
                }
                console.log('~~~~~~~~~notify', notify)
                state.delayNotifications.push(notify)
                setTimeout(async () => {
                    let index = state.delayNotifications.indexOf(notify)
                    if (index >= 0 ) {
                        state.delayNotifications.splice(index, 1)
                        notify[NOTIFY_PROPERTIES.TYPE] = getters[GET_NEW_NOTIFY_TYPE](type)
                        const notifyCopy = JSON.parse(JSON.stringify(notify))
                        if (notify[NOTIFY_PROPERTIES.TYPE] === NOTIFY_TYPES.BROWSER) {
                            const browserNotify = await dispatch(ACT_NOTIFY_NEW_SHOW_BROWSER_NOTIFY, notifyCopy)
                            if (browserNotify) {
                                notifyCopy[NOTIFY_PROPERTIES.BROWSER_NOTIFY] = browserNotify
                                commit('addNotification', notifyCopy)
                            }
                        }
                        else if (notify[NOTIFY_PROPERTIES.TYPE] === NOTIFY_TYPES.ELECTRON) {
                            commit('addNotification', notifyCopy)
                            ipc.send('openNotification')
                        }
                        else commit('addNotification', notifyCopy)
                    }
                }, 1000)
                // dispatch(ACT_NOTIFY_NEW_SHOW_BROWSER_NOTIFY, notify)
            }
        },
        [ACT_NOTIFY_NEW_REMOVE_BY_DATA]: ({state, dispatch, getters, commit}, {type, payload}) => {
            const notify = getters[GET_NOTIFY_BY_DATA](type, payload)
            if (notify) dispatch(ACT_NOTIFY_NEW_REMOVE, notify[NOTIFY_PROPERTIES.ID])
        },
        [ACT_NOTIFY_NEW_REMOVE_ALL]: ({dispatch, getters}) => {
            [...getters[GET_ALL_NOTIFY]].forEach(({id}) => dispatch(ACT_NOTIFY_NEW_REMOVE, id))
        },
        [ACT_NOTIFY_NEW_REMOVE]: ({state, dispatch, getters, commit}, id) => {
            const notify = getters[GET_NOTIFY_BY_ID](id)
            if (notify && notify[NOTIFY_PROPERTIES.TYPE] === NOTIFY_TYPES.BROWSER) notify[NOTIFY_PROPERTIES.BROWSER_NOTIFY].close()
            commit('removeNotification', id)
            if (!getters[GET_NOTIFIES_BY_TYPE](NOTIFY_TYPES.ELECTRON).length) ipc.send('closeNotification')
        },
        [ACT_SHOW_SYSTEM_NOTIFICATION]: ({ commit, state, rootGetters }, data) => {
            let theme = rootGetters['clientdata/getTheme']
            data.theme = theme
            if (state.systemNotification) ipc.send('system-notification-update', data)
            else ipc.send('system-notification-show', { data })
            commit(ADD_SYSTEM_NOTIFICATION, data)
        },
        [ACT_HIDE_SYSTEM_NOTIFICATION]: ({ state, commit }) => {
            if (state.systemNotification) {
                ipc.send('system-notification-hide')
                commit(DELETE_SYSTEM_NOTIFICATION)
            }
        },
        [ACT_NOTIFY_NEW_HANDLE_CLICK]: ({state, dispatch, getters, rootGetters, commit}, id) => {
            window.focus()

            let notify = getters[GET_NOTIFY_BY_ID](id)
            if (notify) {
                let notifyPayload = notify[NOTIFY_PROPERTIES.PAYLOAD]
                const currentChat = rootGetters[`${CHATS}/${GET_SELECTED_CHAT}`]

                dispatch(ACT_NOTIFY_NEW_REMOVE_ALL)

                switch (notify[NOTIFY_PROPERTIES.PAYLOAD_TYPE]) {
                    case NOTIFY_DATA_TYPES.CHAT_MESSAGE:
                        const { cid } = notifyPayload
                        if (cid && cid !== currentChat.cid) {
                            dispatch('chats/update', null, {root: true})
                            dispatch('chats/open', notifyPayload, {root: true})
                            commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'chats', {root: true})
                        }
                        break
                    case NOTIFY_DATA_TYPES.PUBLICATION:
                        dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, notifyPayload.chId, {root: true})
                        commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, {
                            chId: notifyPayload.chId,
                            pubId: notifyPayload.pubId,
                        }, {root: true})
                        break
                    case NOTIFY_DATA_TYPES.MISSED_CALL:
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'calls-list', {root: true})
                        break
                    case NOTIFY_DATA_TYPES.THREADS_MESSAGE:
                        const infoIsThreadsOpened = rootGetters[`${INFO}/${GET_INFO_IS_THREADS_OPENED}`]
                        const infoOpen = rootGetters[`${INFO}/${GET_INFO_OPEN}`]
                        if (!infoOpen || !infoIsThreadsOpened) {
                            const { cid, cidType, commentId } = notifyPayload
                            if (infoOpen && infoOpen.params && infoOpen.params.commentId !== commentId) dispatch(`${CHAT}/${ACT_SET_CURRENT_COMMENTS}`, {commentId}, {root: true})
                            if (cid && cid !== currentChat.cid || cidType && cidType !== currentChat.cidType) {
                                dispatch('chats/open', notifyPayload, {root: true})
                            } else {
                                let infoParams = { cid, cidType, commentId }
                                dispatch(`${INFO}/${ACT_INFO_PUSH}`, { type: INFO_TYPES.THREADS, params: infoParams }, {root: true})
                            }
                        } else {
                            const { cid, cidType, commentId } = infoOpen && infoOpen.params
                            const params = notifyPayload
                            if (cid && cid !== params.cid || cidType && cidType !== params.cidType || commentId && commentId !== params.commentId)
                                dispatch('chats/open', notifyPayload, {root: true})
                        }
                        break
                    case NOTIFY_DATA_TYPES.TETRA_MESSAGE:
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'radio', {root: true})
                        setTimeout(() => event_bus.$emit('open-tetra-chat', notifyPayload), 300)
                        break
                }
            }
        },
        [ACT_NOTIFY_NEW_BUILD_MESSAGE]: async ({state, dispatch, getters, rootGetters, commit}, {type, payload}) => {
            let notifyPayload
            switch (type) {
                case NOTIFY_DATA_TYPES.ALERT:
                    notifyPayload = {
                        [NOTIFY_PAYLOAD_PROPERTIES.ALERT]: true,
                        [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: payload.title,
                        [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: payload.subtitle
                    }
                    break
                case NOTIFY_DATA_TYPES.CHAT_MESSAGE:
                    notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE, payload)
                    break
                case NOTIFY_DATA_TYPES.PUBLICATION:
                    notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_PUBLICATION, payload)
                    break
                case NOTIFY_DATA_TYPES.MISSED_CALL:
                    notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_MISSED_CALL, payload)
                    break
                case NOTIFY_DATA_TYPES.INCOMING_CALL:
                    if (getters[GET_NEW_NOTIFY_TYPE](type) === NOTIFY_TYPES.BROWSER) {
                        notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_INCOMING_CALL, payload)
                    }
                    break
                case NOTIFY_DATA_TYPES.TETRA_MESSAGE:
                    notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_TETRA_MESSAGE, payload)
                    break
                case NOTIFY_DATA_TYPES.THREADS_MESSAGE:
                    notifyPayload = await dispatch(ACT_NOTIFY_NEW_BUILD_THREADS_MESSAGE, payload)
                    break
            }
            if (notifyPayload) {
                notifyPayload[NOTIFY_PAYLOAD_PROPERTIES.THEME] = rootGetters['clientdata/getTheme']
            }
            return notifyPayload
        },
        [ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE]: async ({state, dispatch, getters, rootGetters, commit}, payload) => {
            let user

            let image
            let title
            let subtitle

            let bot = rootGetters[`${BOTS}/${GET_BOT_BY_ID}`](payload.cid)
            if (bot) user = {fio: bot[CONTACT_FIELD_TYPES.BOTTITLE], photo: bot.photo}
            else if (payload.cid === 0) {
                let result = rootGetters[`${CHATS}/${GET_CHAT}`]({
                    cid: 0,
                    cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
                })
                user = {photo: result.photo, fio: result.name}
            } else user = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.cid)

            if (payload.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                let chat = rootGetters[`${CHATS}/${GET_CHAT}`]({cid: payload.cid, cidType: payload.cidType})
                let sender = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.senderId)
                title = chat.name
                image = chat.photo
                subtitle = (sender.cid ? sender.fio + ': ' : '') + await dispatch(ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE_TEXT, payload)
            } else {
                subtitle = await dispatch(ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE_TEXT, payload)
                image = user.photo
                title = user.fio
            }
            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: image,
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: title,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: subtitle
            }
        },
        [ACT_NOTIFY_NEW_BUILD_TETRA_MESSAGE]:  async ({state, dispatch, getters, rootGetters, commit}, payload) => {
            const { ssi, text } = payload
            const tetraUser = rootGetters[`${TETRA}/${GET_TETRA_USER_INFO}`](ssi, 'msg')
            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: '',
                [NOTIFY_PAYLOAD_PROPERTIES.HEADER]: i18n.t('tetra-new-sds-message'),
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: tetraUser && tetraUser.name || ssi,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: text
            }
        },
        [ACT_NOTIFY_NEW_BUILD_THREADS_MESSAGE]:  async ({state, dispatch, getters, rootGetters, commit}, payload) => {
            const { cid, cidType, id, commentId, senderId, data } = payload
            const text = await dispatch(ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE_TEXT, payload)
            const chat = rootGetters[`${CHATS}/${GET_CHAT}`]({cid, cidType})
            let user = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](senderId)
            let fio = cidType === CHAT_TYPES.GROUP ? (chat.fio || '') + ' ' + user.fio : user.fio
            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: user.photo,
                [NOTIFY_PAYLOAD_PROPERTIES.HEADER]: i18n.t('chat.new-comment'),
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: fio,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: text
            }
        },
        [ACT_NOTIFY_NEW_BUILD_CHAT_MESSAGE_TEXT]: ({state, dispatch, getters, rootGetters, commit}, payload) => {
            if (payload.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_TEXT) {
                return lookForRefs(payload.data)
            } else if (payload.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_DATA) {
                switch (payload.data.type) {
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                        return i18n.t('contact')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                        return i18n.t('channel')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                        return i18n.t('publication')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                        return i18n.t('mainPage.location')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                        return i18n.t('image')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                        return i18n.t('video')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                        return i18n.t('audio')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                        return i18n.t('file')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                        return i18n.t('poll')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
                        return i18n.t('chat.survey')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
                        return i18n.t('modal.sent-call-availability')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
                        return i18n.t('chat.schedule')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
                        return payload.data.text
                    default:
                        break
                }
            } else if (payload.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM) {
                switch (payload.data.type) {
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CREATED:
                        return i18n.t('created-chat')
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_ADDED:
                        return i18n.t('added') + rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.data.cid).fio
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CHANGED:
                        return i18n.t('changed') + rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.data.cid).fio
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_DELETED: {
                        let editor = payload.data.editorId && rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.data.editorId).cid
                        let target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.data.cid)
                        return (editor ? i18n.t('removed') + target.fio : target.fio + i18n.t('left'))
                    }
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_UPDATED:
                        return i18n.t('default-change-msg', {name: ''})
                }
            }
        },
        [ACT_NOTIFY_NEW_BUILD_PUBLICATION]: ({state, dispatch, getters, rootGetters, commit}, payload) => {
            let channel = rootGetters[`${CHANNELS}/${GET_CHANNEL}`]({chId: payload.chId})
            let image = ''

            if ('photo' in channel) image = channel.photo
            else if ('icon' in channel) image = app.getChannelPhotoUrl(channel.icon)

            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: image,
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: channel.name,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: payload.title
            }
        },
        [ACT_NOTIFY_NEW_BUILD_MISSED_CALL]: ({state, dispatch, getters, rootGetters, commit}, payload) => {
            let image
            let title

            if ('cid' in payload && payload.cid) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.cid)
                title = contact.fio
                image = contact.photo
            } else if ('number' in payload) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_PHONE}`](payload.number)
                if (contact && contact.fio) {
                    title = contact.fio
                    image = contact.photo
                } else if (payload.number === '') {
                    title = i18n.t('modal.not-defined-number')
                    image = ''
                } else {
                    title = payload.number
                    image = ''
                }
            }

            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: image,
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: title,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: i18n.t('missed-call')
            }
        },
        [ACT_NOTIFY_NEW_BUILD_INCOMING_CALL]: ({state, dispatch, getters, rootGetters, commit}, payload) => {
            let image = payload.icon
            let title = payload.name
            let subtitle = i18n.t('phone.incoming-call')

            /*id: this.id,
                icon: this.icon,
                name: this.name,
                type: this.callTypeNames.type,
                title: this.callTypeNames.title,*/

            /*const { contact } = payload

            if (contact && contact.cid) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](contact.cid)
                title = contact.fio
                image = contact.photo
            }

            if ('cid' in payload && payload.cid) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](payload.cid)
                title = contact.fio
                image = contact.photo
            } else if ('number' in payload) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_PHONE}`](payload.number)
                if (contact && contact.fio) {
                    title = contact.fio
                    image = contact.photo
                } else if (payload.number === '') {
                    title = i18n.t('modal.not-defined-number')
                    image = ''
                } else {
                    title = payload.number
                    image = ''
                }
            }

            subtitle = (payload.class && payload.class === declarations.CALL_CLASSES.VIDEO) ? i18n.t('mainPage.video-call') : i18n.t('mainPage.voice-call')*/

            return {
                [NOTIFY_PAYLOAD_PROPERTIES.IMAGE]: image,
                [NOTIFY_PAYLOAD_PROPERTIES.TITLE]: title,
                [NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE]: subtitle
            }
        },
        [ACT_NOTIFY_NEW_SHOW_NOTIFY]: ({state, dispatch, getters, rootGetters, commit}, payload) => {

        },
        [ACT_NOTIFY_NEW_SHOW_IN_APP_NOTIFY]: ({state, dispatch, getters, rootGetters, commit}, payload) => {

        },
        [ACT_NOTIFY_NEW_SHOW_BROWSER_NOTIFY]: async ({state, dispatch, getters, rootGetters, commit}, notify) => new Promise(async (resolve, reject) => {
            if (!('Notification' in window)) console.log('This browser does not support desktop notification')
            else if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
                try {
                    await Notification.requestPermission()
                } catch (e) {
                    return resolve()
                }
            }

            if (Notification && Notification.permission === 'granted') {
                let payload = notify[NOTIFY_PROPERTIES.NOTIFY_PAYLOAD]
                setTimeout(async () => {
                    let maxCount = getters[GET_MAX_NOTIFY_COUNT]
                    //maxCount = Math.min(maxCount, 1)
                    const browserNotifications = getters[GET_NOTIFIES_BY_TYPE](NOTIFY_TYPES.BROWSER)
                    if (browserNotifications.length + 1 > maxCount) {
                        //return
                        const lastNotify = browserNotifications[0]
                        if (lastNotify) await dispatch(ACT_NOTIFY_NEW_REMOVE, lastNotify[NOTIFY_PROPERTIES.ID])
                    }
                    let title = payload[NOTIFY_PAYLOAD_PROPERTIES.TITLE]
                    if (payload[NOTIFY_PAYLOAD_PROPERTIES.HEADER]) title = payload[NOTIFY_PAYLOAD_PROPERTIES.HEADER] + '\n' + title

                    let icon = payload[NOTIFY_PAYLOAD_PROPERTIES.IMAGE]
                    let srcGlobal = ''

                    if (icon && icon.search(/photos/) !== -1) {
                        let startPoint = icon.indexOf('/photos/') + 8
                        let endPoint = icon.indexOf('/', startPoint)
                        srcGlobal = icon.replace(icon.slice(startPoint, endPoint), '0')
                    }

                    if (srcGlobal && srcGlobal !== icon) {
                        try {
                            await axios.head(icon, {timeout: 100})
                        } catch (e) {
                            icon = srcGlobal
                        }
                    }
                    let notification = new Notification(title, {
                        body: payload[NOTIFY_PAYLOAD_PROPERTIES.SUBTITLE],
                        icon,
                        requireInteraction: true,
                        dir: 'auto',
                    })
                    notification.addEventListener('click', () => dispatch(ACT_NOTIFY_NEW_HANDLE_CLICK, notify[NOTIFY_PROPERTIES.ID]))
                    notification.addEventListener('close', () => dispatch(ACT_NOTIFY_NEW_REMOVE, notify[NOTIFY_PROPERTIES.ID]))
                    notification.addEventListener('show', () => resolve(notification))
                }, 0)
            } else {
                resolve()
            }
        }),
        [ACT_NOTIFY_NEW_SHOW_ELECTRON_NOTIFY]: ({state, dispatch, getters, rootGetters, commit}, payload) => {

        },
    },
    mutations: {
        addNotification: (state, notify) => {
            state.notifications.push(notify)
        },
        removeNotification: (state, id) => {
            let index = state.notifications.findIndex(({id: notifyID}) => notifyID === id)
            if (index >= 0) state.notifications.splice(index, 1)
        },
        clearNotifications: (state) => {
            state.notifications.splice(0, state.notifications.length)
        },
        [ADD_SYSTEM_NOTIFICATION](state, data) {
            state.systemNotification = data
        },
        [DELETE_SYSTEM_NOTIFICATION](state) {
            state.systemNotification = null
        }
    },
}

function getCleanNotificationPayload (payload) {

}

function buildNotificationMessage (payload) {

}