// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body[data-v-70624835] {
  display: flex;
  min-height: 180px;
}
.custom-dropdown[data-v-70624835] {
  height: 44px;
  width: 250px;
}
[data-v-70624835] .custom-dropdown .items div {
  height: 44px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/integrations/AddIntegration.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".modal-body[data-v-70624835] {\n  display: flex;\n  min-height: 180px;\n}\n.custom-dropdown[data-v-70624835] {\n  height: 44px;\n  width: 250px;\n}\n[data-v-70624835] .custom-dropdown .items div {\n  height: 44px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
