var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("acs.new-note"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "acsedit" }, [
          _c(
            "div",
            { staticClass: "time edit-item" },
            [
              _c("date-range-picker", {
                ref: "daterange",
                attrs: {
                  from: _vm.from,
                  default_from: _vm.defaultFrom,
                  to: _vm.to,
                  default_to: _vm.defaultTo,
                  clearable: false,
                  forceUpdate: true,
                },
                on: {
                  "update:from": function ($event) {
                    _vm.from = $event
                  },
                  "update:to": function ($event) {
                    _vm.to = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "status edit-item" },
            [
              _c("custom-drop-down", {
                ref: "select_status",
                attrs: {
                  options: _vm.statuses,
                  placeholder: _vm.$t("acs.reason"),
                },
                on: { "dd-selected-value": _vm.setStatus },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "short-desc edit-item" },
            [
              _c("custom-input-text", {
                ref: "input_text",
                attrs: {
                  input_text: _vm.text,
                  label: _vm.$t("acs.short-desc"),
                },
                on: {
                  "update:input_text": function ($event) {
                    _vm.text = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "full-desc edit-item" },
            [
              _c("custom-input-text", {
                ref: "input_comment",
                attrs: {
                  input_text: _vm.comment,
                  label: _vm.$t("acs.full-desc"),
                },
                on: {
                  "update:input_text": function ($event) {
                    _vm.comment = $event
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: {
              click: function ($event) {
                return _vm.modalClose()
              },
            },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("save") },
            on: { click: _vm.save },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }