var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-audio-wrapper" }, [
    _vm.message.author
      ? _c("div", { staticClass: "forward" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "chat-audio-message" },
      [
        !_vm.link && !_vm.isPending
          ? _c(
              "div",
              {
                staticClass: "audio-load-btn message-btn child-1",
                on: { click: _vm.doLoad },
              },
              [_c("i", { staticClass: "fa fa-long-arrow-down" })]
            )
          : _vm.isPending
          ? _c(
              "div",
              {
                staticClass: "upload-abort-btn message-btn child-1",
                on: { click: _vm.abortAjaxCall },
              },
              [
                _c("progress-circle", {
                  attrs: {
                    load_progress: _vm.loadProgress,
                    radius: 16,
                    width: 40,
                    height: 40,
                  },
                }),
                _c("i", { staticClass: "fa fa-ban" }),
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass: "audio-toggle-btn message-btn child-1",
                on: { click: _vm.doPlayToggle },
              },
              [
                _c("i", {
                  staticClass: "fa",
                  class: _vm.played ? "fa-pause" : "fa-play",
                }),
              ]
            ),
        _c("div", { staticClass: "audio-name child-2" }, [
          _c("span", [_vm._v(_vm._s(_vm.fileName))]),
        ]),
        _c(
          "div",
          { staticClass: "audio-progress child-3" },
          [
            _c("slider", {
              class: _vm.fileName ? "extra-margin-top" : "",
              attrs: {
                active: _vm.link,
                outher_progress:
                  _vm.current_second / (_vm.msg.duration / 100) || 0,
              },
              on: { progress_change: _vm.doChangeCurrentSecond },
            }),
            _vm.showSize
              ? _c("div", { staticClass: "file-size" }, [
                  _vm._v(" " + _vm._s(_vm.prettySize)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "audio-volume child-4" },
          [
            _c("slider", {
              class: _vm.fileName ? "extra-margin-top" : "",
              attrs: { active: _vm.link, outher_progress: _vm.volume * 100 },
              on: { progress_change: _vm.doChangeVolume },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "audio-time child-5" }, [
          _vm._v(_vm._s(_vm.current_time)),
        ]),
        _vm.link && _vm.withDownload
          ? _c(
              "div",
              {
                staticClass: "aux-download-btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.downloadAudioFile.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-arrow-down" })]
            )
          : _vm._e(),
        _c("custom-audio", {
          ref: "audio",
          attrs: { src: _vm.link, volume: _vm.volume },
          on: { ended: _vm.clearAudioInfo },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }