
    import { mapGetters, mapActions } from 'vuex'
    import { CHANNELS } from '../../store/modulesNames'
    import { IS_CHANNEL_PINNED } from '../../store/gettersTypes'
    import { ACT_SET_CHANNEL_PINED } from '../../store/actionsTypes'

    import PinSwitch from './PinSwitch.vue'

    export default {
        name: 'ChannelPinSwitch',
        extends: PinSwitch,
        props: {
            chId: {
                type: Number,
                required: true,
            },
        },
        computed: {
            switchValue() { return this[IS_CHANNEL_PINNED]({ chId: this.chId }) },
            ...mapGetters(CHANNELS, [IS_CHANNEL_PINNED])
        },
        methods: {
            pin() { this[ACT_SET_CHANNEL_PINED]({ chId: this.chId, pinned: true }) },
            unpin() { this[ACT_SET_CHANNEL_PINED]({ chId: this.chId, pinned: false }) },
            ...mapActions(CHANNELS, [ACT_SET_CHANNEL_PINED])
        },
    }
