// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu-base[data-v-023eb603] {
  top: var(--top);
  left: var(--left);
  background: var(--bg-color);
  backdrop-filter: var(--bd-filter);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.14), -1px -1px 0px 0 rgba(57, 57, 57, 0.1);
  display: block;
  margin: 0;
  border-radius: 3px;
  outline: none;
  position: fixed;
  min-width: 70px;
  z-index: 99999;
  overflow: hidden;
  max-width: 500px;
  -webkit-app-region: no-drag;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/contextMenu/ContextMenuBase.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,2BAA2B;EAC3B,iCAAiC;EACjC,sFAAsF;EACtF,cAAc;EACd,SAAS;EACT,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".context-menu-base[data-v-023eb603] {\n  top: var(--top);\n  left: var(--left);\n  background: var(--bg-color);\n  backdrop-filter: var(--bd-filter);\n  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.14), -1px -1px 0px 0 rgba(57, 57, 57, 0.1);\n  display: block;\n  margin: 0;\n  border-radius: 3px;\n  outline: none;\n  position: fixed;\n  min-width: 70px;\n  z-index: 99999;\n  overflow: hidden;\n  max-width: 500px;\n  -webkit-app-region: no-drag;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
