// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-div span a[data-v-014aacb1] {
  color: #a12a52 !important;
}
.edit-div span a[data-v-014aacb1]:hover {
  color: #59a5e7 !important;
  text-decoration: none !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomDiv.vue"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,gCAAgC;AAClC","sourcesContent":[".edit-div span a[data-v-014aacb1] {\n  color: #a12a52 !important;\n}\n.edit-div span a[data-v-014aacb1]:hover {\n  color: #59a5e7 !important;\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
