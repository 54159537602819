// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.official[data-v-3cd2e3f8] {
  height: 14px;
  width: 14px;
  border-radius: 8px;
  background-color: var(--toolbtn-active-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-left: 5px !important;
  flex-basis: 14px;
  min-width: 14px;
  min-height: 14px;
  overflow: hidden;
  color: white;
}
.draft[class='draft'][data-v-3cd2e3f8] {
  white-space: pre;
  color: var(--elem-red-color);
  overflow: visible;
}
.chat-list-item[data-v-3cd2e3f8] {
  position: relative;
  z-index: 1000;
}
.chat-list-item *[data-v-3cd2e3f8] {
  pointer-events: none;
}
.chat-list-item .fio-block[data-v-3cd2e3f8] {
  z-index: 100;
}
.systemMessage[data-v-3cd2e3f8] {
  color: var(--message-system-color) !important;
}
.status[data-v-3cd2e3f8] {
  margin-right: 4px !important;
  font-size: var(--subTitleFontSize);
  color: #83c1ef;
}
.status i.greyFont[data-v-3cd2e3f8] {
  color: var(--placeholder-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/ChatItem.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,6CAA6C;EAC7C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,oBAAoB;AACtB;AACA;EACE,YAAY;AACd;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,4BAA4B;EAC5B,kCAAkC;EAClC,cAAc;AAChB;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".official[data-v-3cd2e3f8] {\n  height: 14px;\n  width: 14px;\n  border-radius: 8px;\n  background-color: var(--toolbtn-active-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 10px;\n  margin-left: 5px !important;\n  flex-basis: 14px;\n  min-width: 14px;\n  min-height: 14px;\n  overflow: hidden;\n  color: white;\n}\n.draft[class='draft'][data-v-3cd2e3f8] {\n  white-space: pre;\n  color: var(--elem-red-color);\n  overflow: visible;\n}\n.chat-list-item[data-v-3cd2e3f8] {\n  position: relative;\n  z-index: 1000;\n}\n.chat-list-item *[data-v-3cd2e3f8] {\n  pointer-events: none;\n}\n.chat-list-item .fio-block[data-v-3cd2e3f8] {\n  z-index: 100;\n}\n.systemMessage[data-v-3cd2e3f8] {\n  color: var(--message-system-color) !important;\n}\n.status[data-v-3cd2e3f8] {\n  margin-right: 4px !important;\n  font-size: var(--subTitleFontSize);\n  color: #83c1ef;\n}\n.status i.greyFont[data-v-3cd2e3f8] {\n  color: var(--placeholder-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
