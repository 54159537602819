// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-switch[data-v-2b254ff6] {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/settings/GeneralSettings.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".item-switch[data-v-2b254ff6] {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
