/**
 * Created by Aleksey on 25.07.2018.
 */

'use strict'
import Vue from 'vue'
import proto from '../../protocol'
import {
    GET_SELECTED_CHANNEL,
    GET_CHANNEL,
    GET_HAVE_ADMIN_RIGHTS,
    GET_IS_CHANNEL_OPENED,
    GET_CHANNEL_MUTE_TIME,
    GET_MAIN_TYPE,
    GET_UID,
    GET_SERVER_API,
    GET_CHANNELS,
    IS_CHANNEL_PINNED,
} from '../gettersTypes'
import {
    ACT_SELECTED_CHANNEL,
    ACT_CHANNELS_OPEN,
    ACT_GET_CHANNEL_DETAILS,
    ACT_INFO_REPLACE,
    ACT_CREATE_CHANNEL,
    ACT_CHANGE_CHANNEL,
    ACT_DEL_CHANNEL,
    ACT_REMOVE_FROM_CHANNELS_LIST,
    ACT_SUBSCRIBE_ON_CHANNEL,
    ACT_GET_CHANNEL_USERS,
    ACT_SEARCH_CHANNEL_USERS,
    ACT_DELETE_CHANNEL_USERS,
    ACT_LEAVE_FROM_CHANNEL,
    ACT_HANDLE_CHANNEL_CHANGE_EVENT,
    ACT_SET_CHANNEL_VISIBLE,
    ACT_CHANGE_CHANNEL_USERS,
    ACT_CHANGE_CHANNEL_SETTINGS,
    ACT_SET_CHANNEL_MUTED,
    ACT_SET_CHANNEL_MARKED,
    ACT_UPDATE_CHANNEL,
    ACT_ADD_CONTACT_SHORT_INFO,
    ACT_SET_CHANNEL_PINED,
    ACT_MAIN_TYPE_CLEAR,
    ACT_REPLACE_MAIN_TYPE,
} from '../actionsTypes'
import { MUT_SET_SELECTED_CHANNEL } from '../mutationsTypes'
import { CHANNEL, CONTENT_MANAGER, INFO, USERDATA, LOGIN, CONTACTS } from '../modulesNames'

import { MAIN_TYPES } from './content-manager'

export const USER_PRIVILEGES = {
    OWNER: 'owner',
    ADMIN: 'admin',
    USER: 'user',
}

const ChannelsStore = class {
    constructor () {
        const self = this
        this.state = {
            channels: [],
            found_channels: [],
            used_found_channels: [],
            selected: null,
            rev: 0
        }
        function getChannel (channels, chId) {
            return channels.find(channel => {
                return channel && channel.chId === chId
            })
        }
        this.getters = {
            joinedP: (state, getters, rootState, rootGetters) => {
                const chId = rootGetters['channel/chId']
                const channel = getChannel(state.channels, chId)
                return state.channels.indexOf(channel) !== -1
            },
            [GET_SELECTED_CHANNEL] (state) {
                return state.selected
            },
            [GET_IS_CHANNEL_OPENED]: (state, getters, rootState, rootGetters) => ({ chId }) => {
                return Boolean(rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`] === MAIN_TYPES.CHANNEL && state.selected === chId)
            },
            /**
             *
             * @param {Array} keys
             * @param {String} search
             */
            remote: state => async search => {
                let channels = []
                try {
                    channels = await proto.searchChannels({ filter: search, count: 0 }) || []
                } catch (e) {
                    throw new Error(e)
                }
                return channels
            },
            filtered: state => (keys, search) => {
                search = search && search.toLowerCase()
                return state.channels.filter(channel => {
                    for (let i in keys) {
                        let key = keys[i]
                        if (!channel[key]) return false
                        switch (channel[key].constructor) {
                            case String:
                                if (channel[key].toLowerCase().indexOf(search) !== -1) return true
                                break
                            case Array:
                                for (let i in channel[key]) {
                                    if (channel[key][i].toLowerCase().indexOf(search) !== -1) return true
                                }
                                break
                            default:
                                return false
                        }
                    }
                })
            },
            [GET_CHANNELS] (state) {
                return state.channels.filter(channel => Boolean(channel && !channel.deleted) )
            },
            getFoundChannels (state) {
                return state.found_channels
            },
            [GET_CHANNEL] (state) {
                return function (params) {
                    let channel = state.channels.find(channel => channel.chId === params.chId) // getChannel(state.channels, params.chId)
                    if (!channel) {
                        channel = getChannel(state.found_channels, params.chId)
                        if (channel) {
                            channel = Vue.util.extend({}, channel)
                            self.mutations.addUsedFoundChannel(state, { chId: params.chId, channel: channel })
                        } else {
                            channel = getChannel(state.used_found_channels, params.chId)
                        }
                    }
                    // console.log("🚀 ~ channels.js:138 ~ channel:", channel)
                    return channel
                }
            },
            inList (state) {
                return function (params) {
                    return Boolean(getChannel(state.channels, params.chId))
                }
            },
            getTotalUnwatchedMessages (state) {
                return state.channels.filter(function (channel) {
                    return channel && channel.unwatched
                })
            },
            getTotalUnwatched: state => {
                return state.channels.reduce((prev = 0, channel) => {
                    // if (!self.getters.isMuted(state)(channel)) prev += channel.unwatched || 0;
                    // if(!this.getters.isMuted(channel)) prev += channel.unwatched;
                    const isMuted = channel && channel.settings && channel.settings.mute > date_helper.getCurrentUnixTime()
                    prev += !isMuted && channel.unwatched || 0
                    return prev
                }, 0)
            },
            getChannelUnwatched (state) {
                return function (params) {
                    const channel = getChannel(state.channels, params.chId)
                    return channel && channel.unwatched || 0 // @todo в прото
                }
            },
            isMuted: state => params => {
                var channel = getChannel(state.channels, params.chId)
                return channel && channel.settings && channel.settings.mute > date_helper.getCurrentUnixTime()
            },
            [IS_CHANNEL_PINNED]: state => params => {
                const channel = getChannel(state.channels, params.chId)
                return Boolean(channel && channel.settings && channel.settings.pinned)
            },
            [GET_CHANNEL_MUTE_TIME]: state => params => {
                const channel = getChannel(state.channels, params.chId);
                return channel && channel.settings && channel.settings.mute || 0;
            },
            isMarked (state) {
                return function (params) {
                    var channel = getChannel(state.channels, params.chId)
                    return channel && channel.settings && channel.settings.marked
                }
            },
            [GET_HAVE_ADMIN_RIGHTS]: (state, getters) => (params) => [USER_PRIVILEGES.ADMIN, USER_PRIVILEGES.OWNER].includes((getters.getChannel(params) || {}).privilege),
            [GET_SERVER_API](state, getters, rootState, rootGetters) {
                return rootGetters[`${LOGIN}/${GET_SERVER_API}`]
            },
        }

        this.actions = {
            async [ACT_CHANNELS_OPEN]({ commit, dispatch, getters }, chId) {
                const root = true
                dispatch(ACT_SELECTED_CHANNEL, chId)
                commit('channel/chId', chId, { root })
                dispatch('channel/update', { chId, count: 20 }, { root })
                dispatch('watchChannelPublications', { chId })
                dispatch(`${INFO}/${ACT_INFO_REPLACE}`, { type: 'channel-info', params: { chId } }, { root: true })
                if (getters['isMarked']({ chId })) dispatch(ACT_SET_CHANNEL_MARKED, { chId })
            },
            [ACT_SELECTED_CHANNEL] ({commit, dispatch}, chId) {
                commit(MUT_SET_SELECTED_CHANNEL, chId)
                dispatch(`${CONTENT_MANAGER}/${ACT_REPLACE_MAIN_TYPE}`, { type: MAIN_TYPES.CHANNEL, params: {chId} }, { root: true })
            },
            
            async [ACT_GET_CHANNEL_DETAILS] ({state, commit}, chId) {
                let containsChannels = getChannel(state.channels, chId)
                let containsFoundChannels = getChannel(state.found_channels, chId)

                if (!containsChannels || !containsFoundChannels) {
                    try {
                        const channel = await proto.getChannelDetails({ chId })
                        commit('clear_found')
                        commit('addFoundChannel', { channel: channel })
                        return true
                    } catch (e) { return false }
                }
                return true
            },

            async update ({ state, commit }, byRev) {
                const { channels, rev } = await proto.getChannels(byRev ? { minRev: state.rev } : {})
                const existChannels = []
                channels.forEach(channel => {
                    if (channel && !channel.deleted) {
                        commit('updateChannel', channel)
                        existChannels.push(channel.chId)
                    } else {
                        commit('removeChannel', { chId: channel.chId })
                    }
                })
                if (!byRev) {
                    state.channels.forEach(current => {
                        if (existChannels.indexOf(current.chId) === -1) commit('removeChannel', { chId: current.chId })
                    })
                }
                commit('updateRev', rev)
                log(`update channels: total ${channels.length} rev ${rev}`)
            },
            setFound ({commit}, found) {
                commit('setFound', found)
            },
            async search ({state, commit}, search) {
                let found = await proto.searchChannels({ filter: search, count: 0 }) || []
                
                commit('clear_found')
                let foundIds = new Set()
                found.forEach(channel => {
                    let _channel = getChannel(self.state.found_channels, channel.chId)
                    foundIds.add(channel.chId)
                    channel.found = true
                    if (!_channel) {
                        commit('addFoundChannel', { channel: channel })
                    } else {
                        commit('updateFoundChannel', { channel: channel })
                    }
                })

                let localFound = state.channels.filter(({chId, chType, name = '', info = ''}) => {
                    if (chType === declarations.channel_types.PUBLIC) return false
                    if (foundIds.has(chId)) return false
                    return [name, info].some((text) => (typeof text === 'string') && text.toLowerCase().search(search) !== -1)
                })

                found = localFound.concat(found).sort(({name: a},{name: b}) => utils.textSortFn(a,b))

                log('found channels: total ' + found.length)
                return found
            },
            watchChannelPublications (obj, params) {
                return new Promise((resolve, reject) => {
                    var channel = getChannel(self.state.channels, params.chId)
                    if (channel && channel.status === 'joined') {
                        proto.watchChannelPublications(params, () => {
                            Vue.set(channel, 'unwatched', 0)
                            resolve()
                        })
                    } else {
                        resolve()
                    }
                })
            },
            async [ACT_CREATE_CHANNEL] ({commit}, params) {
                let chId
                try {
                    chId = await proto.addChannel(params)
                } catch (e) {}
                let ch = await proto.getChannelDetails({ chId })
                commit('addChannel', ch)
            },
            async [ACT_CHANGE_CHANNEL] ({commit}, params) {
                const channel = getChannel(self.state.channels, params.chId)
                for (let i in params) if (i in channel && i !== 'chId' && i !== 'chType' && params[i] === channel[i]) delete params[i]
                try {
                    await proto.changeChannel(params)
                } catch (e) {}
                let ch = await proto.getChannelDetails({ chId: params.chId })
                if (ch.icon) Vue.set(ch, 'icon', ch.icon.split('?').shift() + '?t=' + new Date().getTime())
                commit('changeChannel', ch)
            },
            [ACT_DEL_CHANNEL] ({ dispatch }, payload) {
                proto.delChannel(payload).then(dispatch(ACT_REMOVE_FROM_CHANNELS_LIST, payload))
            },
            async [ACT_REMOVE_FROM_CHANNELS_LIST] ({ getters, rootGetters, dispatch, commit }, payload) {
                if (getters[GET_IS_CHANNEL_OPENED](payload)) {
                    dispatch(`${CHANNEL}/${ACT_SET_CHANNEL_VISIBLE}`, false, { root: true })
                    dispatch(`${CONTENT_MANAGER}/${ACT_MAIN_TYPE_CLEAR}`, null, {root: true})
                }
                commit('removeChannel', { chId: payload.chId })
            },
            delChannelSettingsParams (obj, params) {
                var channel = getChannel(self.state.channels, params.chId)
                var settings = channel.settings && channel.settings.constructor === Object ? JSON.parse(JSON.stringify(channel.settings)) : {}
                var data = { chId: params.chId, settings };
                (params.settings || []).forEach((settingName) => delete settings[settingName])
                proto.changeChannel(data).then(obj.commit('changeChannel', data))
            },
            async [ACT_CHANGE_CHANNEL_SETTINGS] ({commit}, {chId, ...newSettings}) {
                var channel = getChannel(self.state.channels, chId)
                var settings = channel && channel.settings && channel.settings.constructor === Object ? JSON.parse(JSON.stringify(channel.settings)) : {}
                for (let i in newSettings) {
                    let newVal = newSettings[i]
                    if (newVal === null) delete settings[i]
                    else settings[i] = newVal
                }
                const data = { chId, settings }
                try {
                    await proto.changeChannel(data)
                    commit('changeChannel', data)
                } catch (e) {}
            },
            [ACT_SET_CHANNEL_MUTED]({dispatch}, {chId, mute = null}) {
                dispatch(ACT_CHANGE_CHANNEL_SETTINGS, {chId, mute})
            },
            [ACT_SET_CHANNEL_MARKED]({dispatch}, {chId, marked = null}) {
                dispatch(ACT_CHANGE_CHANNEL_SETTINGS, {chId, marked})
            },
            [ACT_SET_CHANNEL_PINED]({dispatch}, {chId, pinned = null}) {
                dispatch(ACT_CHANGE_CHANNEL_SETTINGS, {chId, pinned})
            },
            async [ACT_GET_CHANNEL_USERS] ({ dispatch, getters }, params) {
                let users = []
                try {
                    users = await proto.getChannelUsers(params)
                    const serverAPI = getters[GET_SERVER_API]
                    if (serverAPI >= declarations.serverAPILevels.LEVEL_12) {
                        for (let ind = 0; ind < users.length; ind++){
                            const user = users[ind]
                            await dispatch(`${CONTACTS}/${ACT_ADD_CONTACT_SHORT_INFO}`, user, { root: true })
                        }
                    }
                } catch (e) {
                    console.log("!! -> file: channels.js proto.getChannelUsers -> line 355 -> getChannelUsers -> e", e)
                }
                return users
            },
            async [ACT_SEARCH_CHANNEL_USERS] ({ dispatch, getters }, params) {
                let users = []
                try {
                    users = await proto.searchChannelUsers(params)
                    const serverAPI = getters[GET_SERVER_API]
                    if (serverAPI >= declarations.serverAPILevels.LEVEL_12) {
                        for (let ind = 0; ind < users.length; ind++) {
                            const user = users[ind]
                            await dispatch(`${CONTACTS}/${ACT_ADD_CONTACT_SHORT_INFO}`, user, { root: true })
                        }
                    }
                } catch (e) {
                    console.log("!! -> file: channels.js proto.searchChannelUsers -> line 369 -> searchChannelUsers -> e", e)
                }
                return users
            },
            async getChannelDetails (obj, params) {
                try {
                    const channel = await proto.getChannelDetails({ chId: params.chId })
                    obj.commit('changeChannel', channel)
                } catch (e) {
                    console.error(e)
                }
            },
            [ACT_CHANGE_CHANNEL_USERS]: async ({ dispatch, commit }, params) => {
                try {
                    await proto.addChannelUser({ chId: params.chId, contacts: params.contacts })
                    let channel = getChannel(this.state.channels, params.chId)
                    if (!channel) channel = getChannel(this.state.found_channels, params.chId)
                    if (!channel) channel = getChannel(this.state.used_found_channels, params.chId)
                    commit('addChannel', channel)
                    await dispatch('getChannelDetails', { chId: params.chId })
                } catch (e) {
                    console.error(e)
                }
            },
            [ACT_SUBSCRIBE_ON_CHANNEL]: async ({ dispatch, commit, rootGetters }, {chId}) => {
                let privilege
                const cid = rootGetters[`${USERDATA}/${GET_UID}`]
                const channel = getChannel(this.state.channels, chId)
                if (channel.privilege === 'admin') privilege = declarations.channel_user_privilege.ADMIN;
                else privilege = declarations.channel_user_privilege.USER;
                await dispatch('changeChannelUsers', {
                    chId,
                    contacts: [{
                        cid,
                        privilege: privilege,
                        status: declarations.channel_user_statuses.JOINED
                    }]
                })
                dispatch('channel/update', { chId, count: 20 }, { root: true })
            },
            [ACT_DELETE_CHANNEL_USERS]: async ({ dispatch, commit, rootGetters }, payload) => {
                try {
                    const chId = payload.chId
                    const contacts = payload.contacts
                    await proto.deleteChannelUser({ chId, contacts })
                    await dispatch('getChannelDetails', { chId })
                } catch (e) {
                    throw new Error(e)
                }
            },
            [ACT_LEAVE_FROM_CHANNEL]: async ({ dispatch, commit, rootGetters }, payload) => {
                try {
                    const chId = payload.chId
                    const cid = rootGetters[`${USERDATA}/${GET_UID}`]
                    await proto.deleteChannelUser({ chId, contacts: [{ cid }] })
                    await dispatch(ACT_REMOVE_FROM_CHANNELS_LIST, payload)
                } catch (e) {
                    throw new Error(e)
                }
            },
            [ACT_HANDLE_CHANNEL_CHANGE_EVENT]: async ({ dispatch, commit, rootGetters }, channel) => {
                const chId = channel.chId
                if (channel.deleted || channel.status === declarations.channel_user_statuses.ABSENT) {
                    dispatch(ACT_REMOVE_FROM_CHANNELS_LIST, { chId })
                } else {
                    dispatch(ACT_UPDATE_CHANNEL, channel)
                }
            },
            [ACT_UPDATE_CHANNEL]: ({commit}, channel) => {
                commit('updateChannel', channel)
            },
        }
        this.mutations = {
            [MUT_SET_SELECTED_CHANNEL] (state, chId) {
                state.selected = chId
            },
            addChannel (state, channel) {
                if ('lastTime' in channel) channel.unixLastTime = date_helper.secondsLeftToUnix(channel.lastTime)
                if ('createTime' in channel) channel.unixCreateTime = date_helper.secondsLeftToUnix(channel.createTime)
                state.used_found_channels = []
                let newChannel = true
                for (let i = 0; i < state.channels.length; i++) {
                    if (state.channels[i].chId === channel.chId) newChannel = false
                }
                if (newChannel) state.channels.push(channel)
            },
            setFound (state, found) { state.found_channels = found },
            changeChannel (state, params) {
                let channel = getChannel(state.channels, params.chId)
                if (!channel) channel = getChannel(state.found_channels, params.chId)
                if (!channel) channel = getChannel(state.used_found_channels, params.chId)
                let index = state.channels.indexOf(channel)
                if (index < 0) return
                for (let param in params) {
                    Vue.set(state.channels[index], param, params[param])
                }
            },
            removeChannel (state, params) {
                var channel = getChannel(state.channels, params.chId)
                var index = state.channels.indexOf(channel)
                state.used_found_channels.push(channel)
                if (index !== -1) state.channels.splice(index, 1)
            },
            updateChannel (state, channel) {
                let oldChannel = getChannel(state.channels, channel.chId)
                let index

                if ('lastTime' in channel) channel.unixLastTime = date_helper.secondsLeftToUnix(channel.lastTime)
                if ('createTime' in channel) channel.unixCreateTime = date_helper.secondsLeftToUnix(channel.createTime)
                if (oldChannel) {
                    index = state.channels.indexOf(oldChannel)
                    if (channel.icon) Vue.set(channel, 'icon', channel.icon.split('?').shift() + '?t=' + new Date().getTime())
                    Vue.set(state.channels, index, { ...oldChannel, ...channel })
                } else {
                    state.channels.push(channel)
                }
            },
            addFoundChannel (state, params) {
                if (!getChannel(state.found_channels, params.chId)) state.found_channels.push(params.channel)
            },
            removeFoundChannel (state, params) {
                var channel = getChannel(state.found_channels, params.chId)
                var index = state.found_channels.indexOf(channel)
                if (index !== -1) state.found_channels.splice(index, 1)
            },
            updateFoundChannel (state, params) {
                var channel = getChannel(state.found_channels, params.channel.chId)
                var index = state.found_channels.indexOf(channel)
                channel && Vue.set(state.channel, index, params.channel)
            },
            updateChannelLastTitle (state, params) {
                var channel = getChannel(state.channels, params.chId)
                if (!channel) return
                Vue.set(channel, 'lastTitle', params.text)
                Vue.set(channel, 'unixLastTime', date_helper.getCurrentUnixTime())
                if (!channel.unwatched) Vue.set(channel, 'unwatched', 1)
                else Vue.set(channel, 'unwatched', ++channel.unwatched)
            },
            addUsedFoundChannel (state, params) {
                if (!getChannel(state.used_found_channels, params.chId)) state.used_found_channels.push(params.channel)
            },
            removeUsedFoundChannel (state, params) {
                var channel = getChannel(state.used_found_channels, params.chId)
                var index = state.used_found_channels.indexOf(channel)
                if (index !== -1) state.used_found_channels.splice(index, 1)
            },
            clear (state) {
                state.channels.splice(0, state.channels.length)
            },
            clear_found (state) {
                state.found_channels.splice(0, state.found_channels.length)
            },
            updateRev (state, rev) {
                state.rev = rev
            },
        }
    }

    get store () {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations,
        }
    }
}

const channelsStore = (new ChannelsStore()).store
export default channelsStore
