
    import { mapGetters, mapActions } from 'vuex'
    import { VIDEO_CONF_NEW } from "../../../store/modulesNames"
    import { GET_VC_CONF_TALK_TIME } from "../../../store/gettersTypes"
    import {
        ACT_VC_CONF_LEAVE,
        ACT_VC_TOGGLE_MINIMIZE,
    } from "../../../store/actionsTypes"

    import videoConfMixin from "../../videoConfNew/videoConfMixin"
    import ActivityLine from "./ActivityLine.vue"

    export default {
        name: "ConfBase",
        extends: ActivityLine,
        mixins: [videoConfMixin],
        props: {
            type: {
                type: String,
                required: true
            },
            id: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                bgColor: 'var(--conf-green-color)'
            }
        },
        computed: {
            leftText() {
                return this[GET_VC_CONF_TALK_TIME](...this.confIdGetterData)
            },
            title() {
                return this.$t('videomost.menu-title')
            },
            ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF_TALK_TIME])
        },
        methods: {
            toggleCall() {
                this[ACT_VC_TOGGLE_MINIMIZE]({minimize: false})
            },
            endCall() {
                this[ACT_VC_CONF_LEAVE](this.confIdActionData)
            },
            ...mapActions(VIDEO_CONF_NEW, [ACT_VC_TOGGLE_MINIMIZE, ACT_VC_CONF_LEAVE])
        },
    }
