// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label.custom-checkbox[data-v-b0b9ab0c] {
  display: inherit;
  cursor: pointer;
  position: relative;
  text-indent: 0;
  padding-left: 22px;
  font-weight: normal;
  line-height: 24px;
  color: var(--message-grey-color);
  min-height: 18px;
  min-width: 18px;
}
label.custom-checkbox i.fal.fa-circle[data-v-b0b9ab0c] {
  color: var(--color);
}
label.custom-checkbox input[type='checkbox'][data-v-b0b9ab0c] {
  position: absolute;
  left: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  line-height: inherit;
  margin: auto;
  opacity: 0;
}
label.custom-checkbox i[data-v-b0b9ab0c] {
  color: var(--special-blue-color2);
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  text-indent: 0;
  font-size: 16px;
  font-style: normal;
  line-height: inherit;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomCheckboxRound.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,gCAAgC;EAChC,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,eAAe;EACf,SAAS;EACT,QAAQ;EACR,oBAAoB;EACpB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":["label.custom-checkbox[data-v-b0b9ab0c] {\n  display: inherit;\n  cursor: pointer;\n  position: relative;\n  text-indent: 0;\n  padding-left: 22px;\n  font-weight: normal;\n  line-height: 24px;\n  color: var(--message-grey-color);\n  min-height: 18px;\n  min-width: 18px;\n}\nlabel.custom-checkbox i.fal.fa-circle[data-v-b0b9ab0c] {\n  color: var(--color);\n}\nlabel.custom-checkbox input[type='checkbox'][data-v-b0b9ab0c] {\n  position: absolute;\n  left: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n  line-height: inherit;\n  margin: auto;\n  opacity: 0;\n}\nlabel.custom-checkbox i[data-v-b0b9ab0c] {\n  color: var(--special-blue-color2);\n  position: absolute;\n  left: 0;\n  top: 0;\n  text-align: center;\n  text-indent: 0;\n  font-size: 16px;\n  font-style: normal;\n  line-height: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
