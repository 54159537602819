// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-keyboard-msg[data-v-badcb676] {
  margin-left: 40px;
}
.bot-keyboard-msg .bot-line-btns[data-v-badcb676] {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  margin: 2px;
}
.bot-keyboard-msg .bot-line-btns .bot-btn[data-v-badcb676] {
  background-color: var(--message-left-bgcolor);
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  color: var(--login-color);
  flex: 1;
  margin: 3px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  border: 1px solid #cccccc38;
}
.bot-keyboard-msg .bot-line-btns .bot-btn[data-v-badcb676]:active {
  background-color: var(--item-bgcolor-hover);
  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/bots/BotKeyboardMsg.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,2BAA2B;EAC3B,WAAW;AACb;AACA;EACE,6CAA6C;EAC7C,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,OAAO;EACP,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,2CAA2C;EAC3C,cAAc;AAChB","sourcesContent":[".bot-keyboard-msg[data-v-badcb676] {\n  margin-left: 40px;\n}\n.bot-keyboard-msg .bot-line-btns[data-v-badcb676] {\n  display: flex;\n  justify-content: space-around;\n  align-content: space-around;\n  margin: 2px;\n}\n.bot-keyboard-msg .bot-line-btns .bot-btn[data-v-badcb676] {\n  background-color: var(--message-left-bgcolor);\n  padding-top: 7px;\n  padding-bottom: 7px;\n  border-radius: 5px;\n  color: var(--login-color);\n  flex: 1;\n  margin: 3px;\n  text-align: center;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n  border: 1px solid #cccccc38;\n}\n.bot-keyboard-msg .bot-line-btns .bot-btn[data-v-badcb676]:active {\n  background-color: var(--item-bgcolor-hover);\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
