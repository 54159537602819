var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "typing-status" },
    [
      _c("typing-dots", { staticClass: "dots" }),
      _c("div", { staticClass: "typing-text" }, [
        _vm._v(_vm._s(_vm.typingText)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }