var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        id: _vm.modalName,
        name: _vm.modalName,
        "modal-props": _vm.compModalProps,
        title: _vm.$t("integrations.add-integration"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("custom-drop-down", {
              attrs: { options: _vm.integrationsList, default: _vm.selected },
              on: { "dd-selected-value": _vm.selectIntegration },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: { click: _vm.cancelBtn },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("add"), disabled: _vm.isAddBtnDisabled },
            on: { click: _vm.addBtn },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }