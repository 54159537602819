
    import Confirm from "./Confirm.vue"

    export default {
        name: "OpenUrlConfirm",
        extends: Confirm,
        props: {
            linkUrl: {
                type: String,
                required: true
            }
        },
        computed: {
            text() { return this.$t('bot.open-link') + this.linkUrl + " ?" },
        },
        methods: {
            async btnOkCb() {
                window.openLink(this.linkUrl)
            },
        }
    }
