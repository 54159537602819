// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-btn[data-v-6d6d6380] {
  cursor: pointer;
  display: flex;
  background-color: var(--elem-red-color);
  width: 35%;
  margin: auto;
  height: 40px;
  border-radius: 40px;
  color: white;
  align-items: center;
  justify-content: space-evenly !important;
}
.cancel-btn[data-v-6d6d6380]:hover {
  opacity: 0.7;
}
.cancel-btn .cancel-icon[data-v-6d6d6380] {
  transform: rotate(225deg);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/radio/CancelBtn.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,uCAAuC;EACvC,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;AAC1C;AACA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".cancel-btn[data-v-6d6d6380] {\n  cursor: pointer;\n  display: flex;\n  background-color: var(--elem-red-color);\n  width: 35%;\n  margin: auto;\n  height: 40px;\n  border-radius: 40px;\n  color: white;\n  align-items: center;\n  justify-content: space-evenly !important;\n}\n.cancel-btn[data-v-6d6d6380]:hover {\n  opacity: 0.7;\n}\n.cancel-btn .cancel-icon[data-v-6d6d6380] {\n  transform: rotate(225deg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
