var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "surveyMsg",
      staticClass: "survey-msg",
      on: { click: _vm.openSurvey },
    },
    [
      _c("div", { staticClass: "survey-msg-header" }, [
        _vm._m(0),
        _c("div", { staticClass: "survey-msg-title" }, [
          _c("span", { style: `width: ${_vm.maxWidth}px` }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("span", { staticClass: "subtitle" }, [
            _vm._v(_vm._s(_vm.$t("chat.survey"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "survey-header-img" }, [
      _c("img", { attrs: { src: "img/survey.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }