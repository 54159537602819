// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-text .input[data-v-5544a038] {
  padding-left: 5px;
  color: rgba(255, 255, 255, 0);
  caret-color: var(--placeholder-color);
  transition-property: all;
  transition-duration: 1s;
  font-size: 16px;
}
.custom-input-text .input.up[data-v-5544a038] {
  color: var(--custom-textarea-color);
  transition-property: all;
  transition-duration: .5s;
}
.custom-input-text .input[data-v-5544a038]:read-only {
  color: var(--placeholder-color);
}
.custom-input-text .input:read-only.non-border-input[data-v-5544a038]:focus {
  border-bottom: 1px solid var(--border-bottom-selecteditems);
}
.custom-input-text .input.required[data-v-5544a038] {
  color: var(--elem-red-color);
  border-bottom: 1px solid var(--elem-red-color);
}
.custom-input-text .custom-input-text-placeholder[data-v-5544a038] {
  pointer-events: none;
  margin: auto auto 5px 5px;
  font-size: 16px;
  position: absolute;
  color: var(--placeholder-color);
  transform: translateY(5px);
  transition-property: all;
  transition-duration: .25s;
}
.custom-input-text .custom-input-text-placeholder.up[data-v-5544a038] {
  margin: auto 5px;
  font-size: 0.8em;
  transform: translateY(-13px);
  transition-property: all;
  transition-duration: .25s;
}
.custom-input-text .custom-input-text-placeholder.warning-label[data-v-5544a038] {
  color: var(--elem-red-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomInputText.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,qCAAqC;EACrC,wBAAwB;EACxB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,mCAAmC;EACnC,wBAAwB;EACxB,wBAAwB;AAC1B;AACA;EACE,+BAA+B;AACjC;AACA;EACE,2DAA2D;AAC7D;AACA;EACE,4BAA4B;EAC5B,8CAA8C;AAChD;AACA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,+BAA+B;EAC/B,0BAA0B;EAC1B,wBAAwB;EACxB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,4BAA4B;EAC5B,wBAAwB;EACxB,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".custom-input-text .input[data-v-5544a038] {\n  padding-left: 5px;\n  color: rgba(255, 255, 255, 0);\n  caret-color: var(--placeholder-color);\n  transition-property: all;\n  transition-duration: 1s;\n  font-size: 16px;\n}\n.custom-input-text .input.up[data-v-5544a038] {\n  color: var(--custom-textarea-color);\n  transition-property: all;\n  transition-duration: .5s;\n}\n.custom-input-text .input[data-v-5544a038]:read-only {\n  color: var(--placeholder-color);\n}\n.custom-input-text .input:read-only.non-border-input[data-v-5544a038]:focus {\n  border-bottom: 1px solid var(--border-bottom-selecteditems);\n}\n.custom-input-text .input.required[data-v-5544a038] {\n  color: var(--elem-red-color);\n  border-bottom: 1px solid var(--elem-red-color);\n}\n.custom-input-text .custom-input-text-placeholder[data-v-5544a038] {\n  pointer-events: none;\n  margin: auto auto 5px 5px;\n  font-size: 16px;\n  position: absolute;\n  color: var(--placeholder-color);\n  transform: translateY(5px);\n  transition-property: all;\n  transition-duration: .25s;\n}\n.custom-input-text .custom-input-text-placeholder.up[data-v-5544a038] {\n  margin: auto 5px;\n  font-size: 0.8em;\n  transform: translateY(-13px);\n  transition-property: all;\n  transition-duration: .25s;\n}\n.custom-input-text .custom-input-text-placeholder.warning-label[data-v-5544a038] {\n  color: var(--elem-red-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
