// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-search-links[data-v-053e8f83] {
  height: 100%;
  overflow-y: auto;
}
.media-search-links .months-name[data-v-053e8f83] {
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin: 8px auto;
}
.media-search-links .links-wrapper[data-v-053e8f83] {
  display: flex;
  position: relative;
  flex-direction: column;
}
.media-search-links .links-wrapper[data-v-053e8f83] .item-header {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.media-search-links .links-wrapper[data-v-053e8f83] .item-footer[data-v-5273da3d] {
  padding-bottom: 2px;
  font-size: small;
}
.media-search-links .list-item-custom[data-v-053e8f83] {
  padding-top: 4px;
}
.media-search-links .list-item-custom[data-v-053e8f83] .item-content .item-text {
  max-height: fit-content;
}
.media-search-links .btnwrapper[data-v-053e8f83] {
  display: flex;
  border-top: 1px solid var(--border-bottom-section);
  height: 56px;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}
.media-search-links .btnwrapper .btn[data-v-053e8f83] {
  width: 98%;
  height: 45px;
  color: var(--font-color);
  background-color: var(--list-item-bghover);
  border: none;
  outline: 0;
  margin: 0 auto;
}
.media-search-links .btnwrapper .btn[data-v-053e8f83]:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/mediaViewer/MediaSearchLinks.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kDAAkD;EAClD,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;EACZ,wBAAwB;EACxB,0CAA0C;EAC1C,YAAY;EACZ,UAAU;EACV,cAAc;AAChB;AACA;EACE,YAAY;AACd","sourcesContent":[".media-search-links[data-v-053e8f83] {\n  height: 100%;\n  overflow-y: auto;\n}\n.media-search-links .months-name[data-v-053e8f83] {\n  display: flex;\n  justify-content: center;\n  font-weight: 500;\n  margin: 8px auto;\n}\n.media-search-links .links-wrapper[data-v-053e8f83] {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n}\n.media-search-links .links-wrapper[data-v-053e8f83] .item-header {\n  max-width: 250px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.media-search-links .links-wrapper[data-v-053e8f83] .item-footer[data-v-5273da3d] {\n  padding-bottom: 2px;\n  font-size: small;\n}\n.media-search-links .list-item-custom[data-v-053e8f83] {\n  padding-top: 4px;\n}\n.media-search-links .list-item-custom[data-v-053e8f83] .item-content .item-text {\n  max-height: fit-content;\n}\n.media-search-links .btnwrapper[data-v-053e8f83] {\n  display: flex;\n  border-top: 1px solid var(--border-bottom-section);\n  height: 56px;\n  align-items: center;\n  justify-content: center;\n  padding-right: 1px;\n}\n.media-search-links .btnwrapper .btn[data-v-053e8f83] {\n  width: 98%;\n  height: 45px;\n  color: var(--font-color);\n  background-color: var(--list-item-bghover);\n  border: none;\n  outline: 0;\n  margin: 0 auto;\n}\n.media-search-links .btnwrapper .btn[data-v-053e8f83]:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
