// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper[data-v-5bbc5859] {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 440px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/MediaSettings.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".wrapper[data-v-5bbc5859] {\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  width: 100%;\n  min-width: 440px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
