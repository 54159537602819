// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-list[data-v-35bdf514] {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
  padding-bottom: 10px;
}
.group-list .group[data-v-35bdf514]:not(.disabled) {
  cursor: pointer;
}
.group-list .group.disabled[data-v-35bdf514] .item-title {
  color: var(--listitem-headerfooter-color);
}
.group-list .selected[data-v-35bdf514] {
  color: var(--modal-elem-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/TetraRadioGroupSelect.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,oBAAoB;AACtB;AACA;EACE,eAAe;AACjB;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".group-list[data-v-35bdf514] {\n  width: 100%;\n  max-height: 60vh;\n  overflow: auto;\n  padding-bottom: 10px;\n}\n.group-list .group[data-v-35bdf514]:not(.disabled) {\n  cursor: pointer;\n}\n.group-list .group.disabled[data-v-35bdf514] .item-title {\n  color: var(--listitem-headerfooter-color);\n}\n.group-list .selected[data-v-35bdf514] {\n  color: var(--modal-elem-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
