// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-content[data-v-5e141cca] > * {
  box-shadow: none !important;
}
.threads-wrapper[data-v-5e141cca] {
  position: relative;
}
.threads-wrapper .threads-preload[data-v-5e141cca] {
  position: absolute;
}
.threads-wrapper .threads-content[data-v-5e141cca] {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.threads-wrapper .threads-content #disabled-area-info[data-v-5e141cca] {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--disabled-overlay-bg);
  opacity: .5;
  z-index: 9;
  top: 0;
}
.threads-wrapper .threads-content #comments[data-v-5e141cca] {
  display: flex;
  flex-direction: column;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/InfoThreads.vue"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,WAAW;EACX,UAAU;EACV,MAAM;AACR;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".info-content[data-v-5e141cca] > * {\n  box-shadow: none !important;\n}\n.threads-wrapper[data-v-5e141cca] {\n  position: relative;\n}\n.threads-wrapper .threads-preload[data-v-5e141cca] {\n  position: absolute;\n}\n.threads-wrapper .threads-content[data-v-5e141cca] {\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n}\n.threads-wrapper .threads-content #disabled-area-info[data-v-5e141cca] {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  background: var(--disabled-overlay-bg);\n  opacity: .5;\n  z-index: 9;\n  top: 0;\n}\n.threads-wrapper .threads-content #comments[data-v-5e141cca] {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
