// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recycle-contacts-list[data-v-1a39d353] {
  position: initial;
}
.title-cont[data-v-1a39d353] {
  display: flex;
  justify-content: space-between;
}
.title-cont .title[data-v-1a39d353] {
  padding: 0px 15px;
  font-size: 17px;
  color: var(--base-blue-color);
}
.title-cont .filter-btn[data-v-1a39d353] {
  color: #767777;
  font-size: 16px;
  cursor: pointer;
}
.title-cont .filter-btn i[data-v-1a39d353] {
  display: inline-block;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/search/Contacts.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;AACA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".recycle-contacts-list[data-v-1a39d353] {\n  position: initial;\n}\n.title-cont[data-v-1a39d353] {\n  display: flex;\n  justify-content: space-between;\n}\n.title-cont .title[data-v-1a39d353] {\n  padding: 0px 15px;\n  font-size: 17px;\n  color: var(--base-blue-color);\n}\n.title-cont .filter-btn[data-v-1a39d353] {\n  color: #767777;\n  font-size: 16px;\n  cursor: pointer;\n}\n.title-cont .filter-btn i[data-v-1a39d353] {\n  display: inline-block;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
