// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-search-audio[data-v-6bec6e64] {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 270px;
}
.media-search-audio .months-name[data-v-6bec6e64] {
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin: 8px auto;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] {
  display: flex;
  position: relative;
  flex-direction: column;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message .audio-progress .slider {
  width: 100%;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message .audio-volume .slider {
  width: auto;
  min-width: 60px;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message {
  margin-bottom: -10px;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] .audio-progress {
  flex-direction: column;
}
.media-search-audio .audio-wrapper[data-v-6bec6e64] .audio-progress .file-size {
  position: absolute;
  margin-left: 22%;
  font-size: smaller;
  color: var(--chat-text-color);
  margin-top: -8px;
}
.media-search-audio .audio-wrapper .media-audio-search[data-v-6bec6e64] {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/mediaViewer/MediaSearchAudio.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".media-search-audio[data-v-6bec6e64] {\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  min-width: 270px;\n}\n.media-search-audio .months-name[data-v-6bec6e64] {\n  display: flex;\n  justify-content: center;\n  font-weight: 500;\n  margin: 8px auto;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message .audio-progress .slider {\n  width: 100%;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message .audio-volume .slider {\n  width: auto;\n  min-width: 60px;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] .chat-audio-message {\n  margin-bottom: -10px;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] .audio-progress {\n  flex-direction: column;\n}\n.media-search-audio .audio-wrapper[data-v-6bec6e64] .audio-progress .file-size {\n  position: absolute;\n  margin-left: 22%;\n  font-size: smaller;\n  color: var(--chat-text-color);\n  margin-top: -8px;\n}\n.media-search-audio .audio-wrapper .media-audio-search[data-v-6bec6e64] {\n  margin-left: 4px;\n  margin-right: 4px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
