var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "channel-info" },
    [
      _c(
        "info-main-block",
        {
          attrs: {
            "icon-photo": _vm.photo,
            "icon-text": _vm.channel.name,
            "common-text": _vm.channel.name,
            "additional-text": _vm.channel.info,
            edit: _vm.hasAdminRights && !_vm.isCanBack,
            official: _vm.verifed,
            "icon-viewer": !!_vm.channel.icon,
            "icon-type": "channel",
            "icon-id": _vm.chId,
            isChannel: true,
          },
          on: { edit: _vm.doEditChannel },
        },
        [
          _vm.channel.chType === "private"
            ? _c(
                "div",
                {
                  staticClass: "channel-type",
                  attrs: { slot: "after-common-text" },
                  slot: "after-common-text",
                },
                [
                  _c("span", { staticClass: "text color-red" }, [
                    _vm._v(_vm._s(_vm.$t("information.private"))),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("share-link"),
                link: true,
                chevron: false,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.doShare.apply(null, arguments)
                },
              },
              slot: "action",
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true,
                  },
                  slot: "media",
                },
                [
                  _c("i", {
                    staticClass: "fal fa-share",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("copy-link"),
                link: true,
                chevron: false,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.doCopyReference.apply(null, arguments)
                },
              },
              slot: "action",
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true,
                  },
                  slot: "media",
                },
                [
                  _c("i", {
                    staticClass: "fal fa-external-link",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isCanBack
        ? _c(
            "actions-container",
            [
              _c("channel-mute-switch", {
                attrs: { slot: "action", chId: _vm.chId },
                slot: "action",
              }),
              _c("channel-pin-switch", {
                attrs: { slot: "action", chId: _vm.chId },
                slot: "action",
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              attrs: { slot: "action", title: _vm.$t("chat.subs"), link: true },
              nativeOn: {
                click: function ($event) {
                  return _vm.openChannelMembersInfo.apply(null, arguments)
                },
              },
              slot: "action",
            },
            [
              _c("span", { attrs: { slot: "after" }, slot: "after" }, [
                _vm._v(_vm._s(_vm.membersCountText)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("media-search-info-items", {
        attrs: { isChannel: true },
        on: { opensearchmedia: _vm.openSearchMedia },
      }),
      !_vm.isCanBack && _vm.isOwner
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.delete-channel"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doDelChannel.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-sign-out",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isCanBack && _vm.canJoin
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  attrs: {
                    slot: "action",
                    title: _vm.$t("information.subscribe"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doJoinChannel.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-sign-out",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isCanBack && !_vm.canJoin
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.leave-channel"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doLeaveChannel.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-sign-out",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.isBanned
        ? _c(
            "actions-container",
            [
              _c("list-item", {
                staticClass: "color-red",
                attrs: {
                  slot: "action",
                  title: _vm.$t("information.u-banned"),
                },
                slot: "action",
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }