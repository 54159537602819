var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "some" }, [
    _c("div", { staticClass: "video-quality" }, [
      _c(
        "div",
        { staticClass: "video-quality-item" },
        [
          _c("span", { staticClass: "subheader" }, [
            _vm._v(_vm._s(_vm.$t("videomost.video-quality"))),
          ]),
          _c("custom-select", {
            attrs: {
              selected_option: _vm.chosenVideoQuality,
              options: _vm.videoQuality,
            },
            on: {
              "update:selected_option": function ($event) {
                _vm.chosenVideoQuality = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "video-quality-item" },
        [
          _c("span", { staticClass: "subheader" }, [
            _vm._v(_vm._s(_vm.$t("videomost.quality-client"))),
          ]),
          _c("custom-select", {
            attrs: {
              selected_option: _vm.chosenVideoQualityClient,
              options: _vm.videoQualityClient,
            },
            on: {
              "update:selected_option": function ($event) {
                _vm.chosenVideoQualityClient = $event
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "conf-modes conf-item" },
      [
        _c("span", { staticClass: "subheader" }, [
          _vm._v(_vm._s(_vm.$t("videomost.conf-modes"))),
        ]),
        _c("custom-select", {
          attrs: {
            selected_option: _vm.show_only_owner,
            options: _vm.confModes,
          },
          on: {
            "update:selected_option": function ($event) {
              _vm.show_only_owner = $event
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "conf-modes-options conf-item subheader" },
      [
        _c("add-to-calendar", {
          key: 2,
          attrs: {
            confId: _vm.conf.id,
            label: _vm.$t("videoconf.add-to-calendar"),
          },
          on: {
            isAddCalendar: (val) => (_vm.isAddCalendar = val),
            attendees: _vm.attendeesChange,
          },
        }),
        _c("custom-checkbox", {
          attrs: {
            default: _vm.isAddTerminals,
            checkbox: _vm.isAddTerminals,
            label: this.$t("videomost.settings-addterminals"),
          },
          on: {
            "update:checkbox": function ($event) {
              _vm.isAddTerminals = $event
            },
          },
        }),
        _vm.isAddTerminals
          ? _c("div", { staticClass: "terminals subheader" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("custom-radio", {
                    attrs: {
                      inputs: _vm.SIPServerItems,
                      default_data: _vm.SIPServerValue,
                      value_change: _vm.SIPServerValue,
                      isHorizontal: "",
                    },
                    on: {
                      "update:value_change": function ($event) {
                        _vm.SIPServerValue = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.SIPServerValue.toString() === "1"
                ? _c("div", { staticClass: "sipserver item" }, [
                    _c(
                      "div",
                      { staticClass: "sipitem" },
                      [
                        _c("custom-input-text", {
                          key: "1",
                          attrs: {
                            input_text: _vm.SIPServer,
                            default_text: _vm.SIPServer,
                            label: "SIP Account (123@domain)",
                          },
                          on: {
                            "update:input_text": function ($event) {
                              _vm.SIPServer = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "sipitem" },
                      [
                        _c("custom-input-text", {
                          key: "2",
                          attrs: {
                            input_text: _vm.SIPProxy,
                            default_text: _vm.SIPProxy,
                            label: "SIP Proxy IP (1.2.3.4)",
                          },
                          on: {
                            "update:input_text": function ($event) {
                              _vm.SIPProxy = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "items" }, [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.SIPPartiesMixerLabel))]),
                    _c("custom-select", {
                      attrs: {
                        selected_option: _vm.SIPPartiesMixerValue,
                        options: _vm.SIPPartiesMixerItems,
                      },
                      on: {
                        "update:selected_option": function ($event) {
                          _vm.SIPPartiesMixerValue = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item", staticStyle: { height: "38px" } },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.SIPPartiesVideoQualityLabel)),
                    ]),
                    _c("custom-select", {
                      attrs: {
                        selected_option: _vm.SIPPartiesVideoQualityValue,
                        options: _vm.SIPPartiesVideoQualityItems,
                      },
                      on: {
                        "update:selected_option": function ($event) {
                          _vm.SIPPartiesVideoQualityValue = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }