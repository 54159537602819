var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "tabsWrapper",
      staticClass: "wrapper",
      attrs: { id: "tabsWrapper" },
    },
    [
      _c(
        "div",
        { staticClass: "tabs", style: _vm.tabsCss },
        _vm._l(_vm.tabItems, function (tab, index) {
          return _c(
            "div",
            {
              staticClass: "option",
              class: _vm.chosenTab === tab.key ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.tabClick(tab)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.tabName(index)))])]
          )
        }),
        0
      ),
      _vm.railBottomBorder
        ? _c("div", { staticClass: "rail", style: _vm.railCss }, [
            _c(
              "div",
              {
                staticClass: "line-wrapper",
                class: "chosenTab" + _vm.chosenTab,
              },
              [_c("div", { staticClass: "line" })]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }