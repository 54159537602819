// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-red {
  color: var(--elem-red-color);
}
.color-green {
  color: green;
}
.color-blue {
  color: var(--toolbtn-active-color);
}
.profile-info {
  height: 120px !important;
}
.profile-info.dnd .item-footer {
  color: var(--elem-red-color) !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/side/More.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,YAAY;AACd;AACA;EACE,kCAAkC;AACpC;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,uCAAuC;AACzC","sourcesContent":[".color-red {\n  color: var(--elem-red-color);\n}\n.color-green {\n  color: green;\n}\n.color-blue {\n  color: var(--toolbtn-active-color);\n}\n.profile-info {\n  height: 120px !important;\n}\n.profile-info.dnd .item-footer {\n  color: var(--elem-red-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
