var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", [
    _vm.isReactionPanelShow
      ? _c(
          "div",
          {
            staticClass: "reactions-panel-wrapper",
            attrs: { id: "reactionsPanel" },
            on: {
              mouseover: _vm.mousePanelOver,
              mouseleave: _vm.mousePanelLeave,
            },
          },
          [
            _c(
              "div",
              { staticClass: "reactions-panel", style: _vm.panelStyle },
              [
                _c(
                  "div",
                  { staticClass: "reactions-panel-header" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "total-reactions",
                        class: { active: !_vm.topReactionFilter },
                        on: {
                          click: function ($event) {
                            return _vm.topReactionClick($event)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("all")) +
                            " " +
                            _vm._s(_vm.reactions.reactions.length)
                        ),
                      ]
                    ),
                    _vm._l(_vm.reactions.aggReactions, function (r, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "header-reaction",
                          class: {
                            active: _vm.topReactionFilter === r.reaction,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.topReactionClick($event, r.reaction)
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "reaction-item",
                            domProps: {
                              innerHTML: _vm._s(_vm.wrapEmoji(r.reaction)),
                            },
                          }),
                          _c("div", { staticClass: "reaction-count" }, [
                            _vm._v(_vm._s(r.count)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "reactions-panel-body" },
                  _vm._l(_vm.contacts, function (c, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "contact-wrapper" },
                      [
                        _c("contact-list-item", {
                          attrs: {
                            contact: c.contact,
                            height: 48,
                            noswitch: true,
                            noSelection: true,
                            noHover: true,
                            isCompact: true,
                            isSmall: true,
                            isLineSeparator: true,
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "reaction-item",
                            domProps: {
                              innerHTML: _vm._s(_vm.wrapEmoji(c.reaction)),
                            },
                          },
                          [_vm._v("1")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }