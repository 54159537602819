var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-input-text" }, [
    !!_vm.label
      ? _c(
          "div",
          {
            staticClass: "custom-input-text-placeholder",
            class: {
              up: _vm.warning || !!_vm.input_text,
              "warning-label": _vm.warning,
            },
          },
          [_vm._v(_vm._s(_vm.lbl))]
        )
      : _vm._e(),
    _vm.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.applyChanges,
              expression: "applyChanges",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text, required: _vm.warning },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            readonly: _vm.readonly,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.input_text)
              ? _vm._i(_vm.input_text, null) > -1
              : _vm.input_text,
          },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.applyChanges.apply(null, arguments)
            },
            blur: _vm.applyChanges,
            change: function ($event) {
              var $$a = _vm.input_text,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.input_text = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.input_text = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.input_text = $$c
              }
            },
          },
        })
      : _vm.type === "radio"
      ? _c("input", {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.applyChanges,
              expression: "applyChanges",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text, required: _vm.warning },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            readonly: _vm.readonly,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.input_text, null) },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.applyChanges.apply(null, arguments)
            },
            blur: _vm.applyChanges,
            change: function ($event) {
              _vm.input_text = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.applyChanges,
              expression: "applyChanges",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text, required: _vm.warning },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            readonly: _vm.readonly,
            type: _vm.type,
          },
          domProps: { value: _vm.input_text },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.applyChanges.apply(null, arguments)
            },
            blur: _vm.applyChanges,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.input_text = $event.target.value
            },
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }