var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "date-range-picker" }, [
    _c("div", { staticClass: "date-range-content" }, [
      _c(
        "div",
        {
          staticClass: "from",
          class: { required: _vm.required_from || _vm.input_required.from },
          on: { click: _vm.fromClick },
        },
        [
          _c(
            "div",
            { staticClass: "date-picker" },
            [
              _c(
                "div",
                {
                  staticClass: "datepicker-from-placeholder",
                  class: [
                    {
                      "datepicker-from-placeholder-up": !!_vm.from,
                      required: _vm.required_from || _vm.input_required.from,
                    },
                  ],
                  on: { click: _vm.doClickDatepickerFrom },
                },
                [_vm._v(_vm._s(_vm.$t("settings.starts")))]
              ),
              _c("datepicker", {
                attrs: {
                  type: _vm.custom_type,
                  "input-class": [
                    "date-picker-input-from",
                    { visible: !!_vm.from },
                  ],
                  "first-day-of-week": 1,
                  lang: _vm.lang,
                  clearable: _vm.clearable,
                  placeholder: "",
                  "minute-step": _vm.minute_step,
                  "date-format": _vm.date_format,
                  format: _vm.date_format,
                  editable: _vm.editable,
                  disabled: _vm.disabled,
                  confirm: "",
                  "confirm-text": _vm.$t("done"),
                  "popup-style": _vm.popUpStyleFrom ? _vm.popUpStyleFrom : null,
                },
                on: { confirm: _vm.setFrom, clear: _vm.clearFrom },
                model: {
                  value: _vm.from,
                  callback: function ($$v) {
                    _vm.from = $$v
                  },
                  expression: "from",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "to",
          class: { required: _vm.required_to || _vm.input_required.to },
          on: { click: _vm.toClick },
        },
        [
          _c(
            "div",
            { staticClass: "date-picker" },
            [
              _c(
                "div",
                {
                  staticClass: "datepicker-from-placeholder",
                  class: [
                    {
                      "datepicker-to-placeholder-up": !!_vm.to,
                      required: _vm.required_to || _vm.input_required.to,
                    },
                  ],
                  on: { click: _vm.doClickDatepickerTo },
                },
                [_vm._v(_vm._s(_vm.$t("settings.ends")))]
              ),
              _c("datepicker", {
                attrs: {
                  type: _vm.custom_type,
                  "input-class": [
                    "date-picker-input-to",
                    { visible: !!_vm.to },
                  ],
                  "first-day-of-week": 1,
                  lang: _vm.lang,
                  clearable: _vm.clearable,
                  placeholder: "",
                  "minute-step": _vm.minute_step,
                  "date-format": _vm.date_format,
                  format: _vm.date_format,
                  editable: _vm.editable,
                  disabled: _vm.disabled,
                  confirm: "",
                  "confirm-text": _vm.$t("done"),
                  "popup-style": _vm.popUpStyleTo ? _vm.popUpStyleTo : null,
                },
                on: { confirm: _vm.setTo, clear: _vm.clearTo },
                model: {
                  value: _vm.to,
                  callback: function ($$v) {
                    _vm.to = $$v
                  },
                  expression: "to",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }