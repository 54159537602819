//
//
//
//
//
//
//
//

import Threads from "../chat/Threads.vue"
import { getIsFullCompactMode, getIsCompactMode } from '../../../ext/trackResizes'
import ContentLoader from "../main/body/MainContentLoader.vue"

export default {
    name: "InfoThreads",
    components: {
        Threads,
        ContentLoader,
    },
    props: ['params'],
    created() {
        this.$bus.$on('compact-mode', (val) => {
            this.isCompactMode = val
            this.isFullCompactMode = false
        })
        this.$bus.$on('full-compact-mode', (val) => {
            this.isFullCompactMode = val
            this.isCompactMode = false
        })
    },
    mounted() {
    },
    data() {
        return {
            isFullCompactMode: getIsFullCompactMode(),
            isCompactMode: getIsCompactMode(),
            isDisabledArea: false,
            isLoader: true
        }
    },
    computed: {      
        _isCompactMode() {
            return this.isFullCompactMode || this.isCompactMode
        },              
    },
    watch: {
    },
    methods: {
        setDisabledArea(val) {
            this.isDisabledArea = val
        }
    }
}
