var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conference-settings" },
    [
      _vm.isFieldVisible(_vm.TYPE)
        ? _c(
            "div",
            { staticClass: "conf-type conf-item" },
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v(_vm._s(_vm.$t("videomost.conferences-type"))),
              ]),
              _c("custom-select", {
                attrs: { options: _vm.confTypes, readonly: !_vm.isNew },
                on: { "update:selected_option": _vm.setConfTypeValue },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isFieldVisible(_vm.TOPIC)
        ? _c(
            "div",
            { staticClass: "subject conf-item" },
            [
              _c("custom-input-text", {
                attrs: {
                  default_text: _vm.getFieldDefault(_vm.TOPIC),
                  input_text: _vm.confProps[_vm.TOPIC],
                  label: _vm.$t("videomost.conf-subject"),
                  warning: _vm.isFieldMissed(_vm.TOPIC),
                  readonly: _vm.readonly,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.confProps, _vm.TOPIC, $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.dropFieldMissed(_vm.TOPIC)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isFieldVisible(_vm.START_TIME) && _vm.isFieldVisible(_vm.END_TIME)
        ? _c(
            "div",
            { staticClass: "schedule conf-item" },
            [
              _c("date-range-picker", {
                key: "2",
                attrs: {
                  default_from: _vm.getStartTime(_vm.conf),
                  from: _vm.confProps[_vm.START_TIME],
                  default_to: _vm.getFinishTime(_vm.conf),
                  to: _vm.confProps[_vm.END_TIME],
                  required_from: _vm.isFieldMissed(_vm.START_TIME),
                  required_to: _vm.isFieldMissed(_vm.END_TIME),
                  minute_step: 5,
                  disabled: _vm.readonly,
                  clearable: !_vm.readonly,
                  popUpStyleFrom: { left: 0, top: "36px" },
                  popUpStyleTo: { left: "-22px", top: "36px" },
                },
                on: {
                  "update:from": function ($event) {
                    return _vm.$set(_vm.confProps, _vm.START_TIME, $event)
                  },
                  fromClick: function ($event) {
                    return _vm.dropFieldMissed(_vm.START_TIME)
                  },
                  "update:to": function ($event) {
                    return _vm.$set(_vm.confProps, _vm.END_TIME, $event)
                  },
                  toClick: function ($event) {
                    return _vm.dropFieldMissed(_vm.END_TIME)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isFieldVisible(_vm.ID) && _vm.isFieldVisible(_vm.PASSWORD)
        ? _c(
            "div",
            { staticClass: "conf-auth conf-item" },
            [
              _c("custom-input-text", {
                key: "1",
                staticStyle: { "margin-right": "4px" },
                attrs: {
                  default_text: _vm.getFieldDefault(_vm.ID),
                  input_text: _vm.confProps[_vm.ID],
                  label: _vm.$t("videomost.conf-id"),
                  warning: _vm.isFieldMissed(_vm.ID),
                  readonly: _vm.readonly,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.confProps, _vm.ID, $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.dropFieldMissed(_vm.ID)
                  },
                },
              }),
              _c("custom-input-text", {
                key: "2",
                staticStyle: { "margin-left": "22px" },
                attrs: {
                  default_text: _vm.getFieldDefault(_vm.PASSWORD),
                  input_text: _vm.confProps[_vm.PASSWORD],
                  label: _vm.$t("videomost.conf-password"),
                  warning: _vm.isFieldMissed(_vm.PASSWORD),
                  readonly: _vm.readonly,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.confProps, _vm.PASSWORD, $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.dropFieldMissed(_vm.PASSWORD)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.getType === "roschat" && _vm.extended
        ? _c("add-to-calendar", {
            key: 1,
            attrs: {
              confId: _vm.conf.id,
              label: _vm.$t("videoconf.add-to-calendar"),
            },
            on: {
              isAddCalendar: (val) => (_vm.isAddCalendar = val),
              attendees: _vm.attendeesChange,
            },
          })
        : _vm._e(),
      _vm.getType === "vm" && _vm.extended
        ? _c("videomost-settings", {
            ref: "additionalProps",
            attrs: { conf: _vm.conf, isNew: _vm.isNew },
          })
        : _vm._e(),
      _vm.isFieldVisible(_vm.PERIODICITY)
        ? _c("div", { staticClass: "periodicity subheader" }, [
            _c(
              "div",
              {
                staticClass: "cbtitle",
                class: { "rc-conf": _vm.getType === "roschat" },
              },
              [
                _c("custom-checkbox", {
                  attrs: {
                    default: _vm.periodicity,
                    checkbox: _vm.periodicity,
                    label: this.$t("videomost.settings-period"),
                  },
                  on: {
                    "update:checkbox": function ($event) {
                      _vm.periodicity = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm.periodicity
              ? _c(
                  "div",
                  { staticClass: "options" },
                  [
                    _c("custom-select", {
                      attrs: {
                        selected_option: _vm.periodItemValue,
                        options: _vm.periodItems,
                      },
                      on: {
                        "update:selected_option": function ($event) {
                          _vm.periodItemValue = $event
                        },
                      },
                    }),
                    _c("div", { staticClass: "counterwrapper" }, [
                      _c("span", { staticClass: "words-left" }, [
                        _vm._v(_vm._s(_vm.$t("videomost.periods.repeat"))),
                      ]),
                      _c("span", { staticClass: "words-left" }, [
                        _vm._v(_vm._s(_vm.repeatPhrase.eachWord)),
                      ]),
                      _c("span", { staticClass: "words-right" }, [
                        _vm._v(_vm._s(_vm.repeatPhrase.periodWord)),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.periodicity &&
            _vm.periodItemValue === _vm.CONF_SCHEDULE_PERIODICITY_TYPES.WEEK
              ? _c(
                  "div",
                  { staticClass: "weekdays" },
                  [
                    _c("span", { staticClass: "words" }, [
                      _vm._v(
                        _vm._s(_vm.$t("videomost.periods.days")) +
                          " " +
                          _vm._s(
                            _vm.$t("videomost.periods.weeks").toLowerCase()
                          )
                      ),
                    ]),
                    _vm._l(_vm.periodDays, function (day) {
                      return [
                        _c("custom-checkbox", {
                          staticClass: "ccb",
                          attrs: {
                            label: day.label,
                            default: _vm.periodDayIsSet(day.value),
                          },
                          on: {
                            "update:checkbox": (val) =>
                              _vm.setPeriodDayValue(day.value, val),
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }