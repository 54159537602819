// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body[data-v-3a59e67e] {
  display: flex;
  padding: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/DndDialog.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".body[data-v-3a59e67e] {\n  display: flex;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
