// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn[data-v-658a121f] {
  border-radius: 3px;
  background-color: initial;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 18px;
  border: none;
  cursor: pointer;
  user-select: none;
  -webkit-appearance: button;
  outline: none;
}
.btn[class~='btn'][data-v-658a121f] {
  color: var(--modal-elem-color);
  border: none;
  outline: 0;
}
.btn.disabled[data-v-658a121f] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}
.btn[data-v-658a121f]:first-child {
  margin-left: auto;
}
.btn[data-v-658a121f]:not(:last-child) {
  margin-right: 10px;
}
.btn[data-v-658a121f]:hover,
.btn[data-v-658a121f]:active,
.btn[data-v-658a121f]:focus {
  background: var(--btn-bghover);
  box-shadow: none;
  /*&:active{
            color: var(--modal-elem-color);
            border: none;
        }*/
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ModalBtn.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,aAAa;AACf;AACA;EACE,8BAA8B;EAC9B,YAAY;EACZ,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;EACxB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;;;EAGE,8BAA8B;EAC9B,gBAAgB;EAChB;;;UAGQ;AACV","sourcesContent":[".btn[data-v-658a121f] {\n  border-radius: 3px;\n  background-color: initial;\n  font-size: 15px;\n  text-transform: uppercase;\n  font-weight: bold;\n  padding: 10px 18px;\n  border: none;\n  cursor: pointer;\n  user-select: none;\n  -webkit-appearance: button;\n  outline: none;\n}\n.btn[class~='btn'][data-v-658a121f] {\n  color: var(--modal-elem-color);\n  border: none;\n  outline: 0;\n}\n.btn.disabled[data-v-658a121f] {\n  cursor: not-allowed;\n  filter: alpha(opacity=65);\n  -webkit-box-shadow: none;\n  box-shadow: none;\n  opacity: .65;\n}\n.btn[data-v-658a121f]:first-child {\n  margin-left: auto;\n}\n.btn[data-v-658a121f]:not(:last-child) {\n  margin-right: 10px;\n}\n.btn[data-v-658a121f]:hover,\n.btn[data-v-658a121f]:active,\n.btn[data-v-658a121f]:focus {\n  background: var(--btn-bghover);\n  box-shadow: none;\n  /*&:active{\n            color: var(--modal-elem-color);\n            border: none;\n        }*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
