import { render, staticRenderFns } from "./MainContentDecoration.vue?vue&type=template&id=2a1b5f29&lang=pug"
import script from "./MainContentDecoration.vue?vue&type=script&lang=js"
export * from "./MainContentDecoration.vue?vue&type=script&lang=js"
import style0 from "./MainContentDecoration.vue?vue&type=style&index=0&id=2a1b5f29&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports