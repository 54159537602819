var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qrcode-entrance" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c(
      "div",
      { staticClass: "component-section" },
      [
        _c(
          "qrcode-drop-zone",
          {
            on: {
              detect: _vm.onDetect,
              decode: _vm.onDecode,
              dragover: _vm.onDragOver,
              init: _vm.logErrors,
            },
          },
          [
            _c(
              "div",
              { staticClass: "drop-area", class: { dragover: _vm.dragover } },
              [
                _c(
                  "div",
                  { staticClass: "area-content" },
                  [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("loginPage.qr-area"))),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary qr-file-select",
                        attrs: { tabindex: "1" },
                        on: { click: _vm.doQrFileSelect },
                      },
                      [_vm._v(_vm._s(_vm.$t("loginPage.select")))]
                    ),
                    _c("qrcode-capture", {
                      staticStyle: { display: "none" },
                      on: { detect: _vm.onDetect, decode: _vm.onDecode },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        !_vm.isElectron
          ? _c("custom-checkbox", {
              staticClass: "input",
              attrs: {
                checkbox: _vm.remember_me,
                label: _vm.$t("loginPage.logged-in"),
                tabindex: 2,
              },
              on: {
                "update:checkbox": function ($event) {
                  _vm.remember_me = $event
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "description text-center" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.qr-description")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }