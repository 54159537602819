// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-entrance .component-footer[data-v-9da66952] {
  display: flex;
  height: 100% !important;
}
.login-entrance .component-footer[data-v-9da66952] .alt-entrace-btns {
  align-items: center;
  justify-content: space-around;
}
.login-entrance .component-footer .alt-entrace-btns[data-v-9da66952] {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  color: var(--login-color);
}
.login-entrance .component-footer .alt-entrace-btns .footer-section[data-v-9da66952] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-entrance .component-footer .alt-entrace-btns span[data-v-9da66952] {
  font-size: .9em;
  color: var(--login-color);
  text-align: center;
  margin-top: 1em;
}
.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn[data-v-9da66952] {
  display: flex;
  flex-direction: column;
  width: 64px;
  height: 64px;
  font-size: 28px;
  background-color: var(--login-btns-bgcolor);
  border-radius: 50%;
  cursor: pointer;
}
.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn > *[data-v-9da66952] {
  margin: auto;
}
.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn.qr-entrace svg[data-v-9da66952] {
  stroke: var(--login-color);
  width: 30px;
  stroke-width: 2;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/login/ChooseMethod.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,2CAA2C;EAC3C,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,0BAA0B;EAC1B,WAAW;EACX,eAAe;AACjB","sourcesContent":[".login-entrance .component-footer[data-v-9da66952] {\n  display: flex;\n  height: 100% !important;\n}\n.login-entrance .component-footer[data-v-9da66952] .alt-entrace-btns {\n  align-items: center;\n  justify-content: space-around;\n}\n.login-entrance .component-footer .alt-entrace-btns[data-v-9da66952] {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  height: 100%;\n  color: var(--login-color);\n}\n.login-entrance .component-footer .alt-entrace-btns .footer-section[data-v-9da66952] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.login-entrance .component-footer .alt-entrace-btns span[data-v-9da66952] {\n  font-size: .9em;\n  color: var(--login-color);\n  text-align: center;\n  margin-top: 1em;\n}\n.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn[data-v-9da66952] {\n  display: flex;\n  flex-direction: column;\n  width: 64px;\n  height: 64px;\n  font-size: 28px;\n  background-color: var(--login-btns-bgcolor);\n  border-radius: 50%;\n  cursor: pointer;\n}\n.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn > *[data-v-9da66952] {\n  margin: auto;\n}\n.login-entrance .component-footer .alt-entrace-btns .alt-entrace-btn.qr-entrace svg[data-v-9da66952] {\n  stroke: var(--login-color);\n  width: 30px;\n  stroke-width: 2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
