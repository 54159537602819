var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "global-search-wrapper", class: { fullsize: !!_vm.open } },
    [
      _c(
        "div",
        { staticClass: "search-wrapper" },
        [
          _c("custom-search", {
            ref: "search",
            staticClass: "search",
            class: { shrink: _vm.open && _vm.isSearchAllowed },
            style: { paddingRight: "34px" },
            attrs: {
              search: _vm.search,
              placeholder: _vm.placeholder,
              isReadOnly: !_vm.isSearchAllowed,
              id: "global-search",
            },
            on: {
              filter: _vm.doShowFilter,
              keyDown: function ($event) {
                return _vm.gotoItem(1)
              },
              keyUp: function ($event) {
                return _vm.gotoItem(-1)
              },
              enter: _vm.selectItem,
              "update:search": function ($event) {
                _vm.search = $event
              },
            },
            nativeOn: {
              click: function ($event) {
                return _vm.makeSearchOpened.apply(null, arguments)
              },
            },
          }),
          _vm.isSearchAllowed
            ? _c(
                "a",
                {
                  staticClass: "search-close",
                  class: { h: !_vm.open },
                  style: _vm.calcAuxStyles,
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isSearchAllowed
        ? _c("div", { staticClass: "search-menu-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.open && _vm.categories.length > 1,
                    expression: "open && categories.length > 1",
                  },
                ],
                staticClass: "search-menu",
              },
              _vm._l(_vm.categories, function (category, i) {
                return _c(
                  "div",
                  {
                    staticClass: "filter",
                    class: { active: _vm.pane === category, [category]: true },
                    on: {
                      click: function ($event) {
                        return _vm.changePane(category)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm._f("rus")(category)))])]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.open && _vm.categories.length > 1,
                    expression: "open && categories.length > 1",
                  },
                ],
                staticClass: "rail",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.open,
                        expression: "open",
                      },
                    ],
                    staticClass: "line-wrapper",
                    class: _vm.linePosition,
                  },
                  [_c("div", { staticClass: "line" })]
                ),
              ]
            ),
            _vm.open && _vm.categories.length > 1 && _vm.isSearchFilterSupported
              ? _c("div", { staticClass: "search-more" }, [
                  (_vm.open && _vm.pane === "contacts") ||
                  _vm.pane === "searchContacts"
                    ? _c("div", { staticClass: "filter-more" }, [
                        _c("div", { staticClass: "filter-btn" }, [
                          _c(
                            "div",
                            {
                              staticClass: "filter-action",
                              on: { click: _vm.createSearch },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sliders",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                          _vm.contactsExtFilter
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "filter-subtitle",
                                    on: { click: _vm.createSearch },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataSearch(_vm.contactsExtFilter)
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.contactsExtFilter
                            ? _c(
                                "div",
                                {
                                  staticClass: "filter-close",
                                  on: { click: _vm.closeInfoSearch },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-times",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "title",
                                  on: { click: _vm.createSearch },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("search-comp.search-more"))
                                  ),
                                ]
                              ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "found" },
        [
          _vm.open
            ? _c(_vm.current_pane, {
                tag: "component",
                attrs: {
                  id: "found-search-area",
                  items: _vm.found,
                  search: _vm.search,
                  noswitch: _vm.noswitch,
                  focus: _vm.selectedNode,
                },
                on: {
                  "update:contact-selected": _vm.doContactSelected,
                  "update:focus-on": _vm.doFocusOn,
                  closeSearch: _vm.closeSearch,
                  scrolledToBottom: _vm.onScrolledToBottom,
                },
              })
            : _vm._e(),
          _vm.pane !== "global-search" &&
          _vm.isSearchAllowed &&
          _vm.open &&
          !_vm.found.length
            ? _c("div", { staticClass: "search-placeholder-wrapper" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.search.length || _vm.contactsExtFilter,
                        expression: "search.length || contactsExtFilter",
                      },
                    ],
                    staticClass: "search-placeholder",
                  },
                  [
                    _vm.loading &&
                    (_vm.search.length >= 2 || _vm.contactsExtFilter)
                      ? _c("content-loader")
                      : !_vm.loading &&
                        (_vm.search.length >= 2 || _vm.contactsExtFilter)
                      ? _c("span", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(_vm.$t("search-comp.no-matches")) +
                              ' "' +
                              _vm._s(_vm.search) +
                              '"'
                          ),
                        ])
                      : _vm.search.length < 2 && !_vm.contactsExtFilter
                      ? _c("span", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(_vm.$t("search-comp.min-chars-search-start"))
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }