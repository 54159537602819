// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialer .dialer-input[data-v-16c6c4c3] {
  height: 90px;
  width: 300px;
}
.dialer .dialer-input input[data-v-16c6c4c3] {
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  caret-color: transparent;
  background-color: var(--dialer-bgcolor);
  color: var(--dialer-color);
}
.dialer .dialer-keys .dialer-keys-row[data-v-16c6c4c3] {
  display: flex;
  justify-content: space-around;
  font-size: 34px;
  margin-bottom: 24px;
}
.dialer .dialer-keys .dialer-keys-row span[data-v-16c6c4c3] {
  color: var(--dialer-color);
  width: 30px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-weight: 100;
  -webkit-app-region: no-drag;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/phone-dialer.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,wBAAwB;EACxB,uCAAuC;EACvC,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".dialer .dialer-input[data-v-16c6c4c3] {\n  height: 90px;\n  width: 300px;\n}\n.dialer .dialer-input input[data-v-16c6c4c3] {\n  width: 100%;\n  text-align: center;\n  border: none;\n  outline: none;\n  user-select: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  caret-color: transparent;\n  background-color: var(--dialer-bgcolor);\n  color: var(--dialer-color);\n}\n.dialer .dialer-keys .dialer-keys-row[data-v-16c6c4c3] {\n  display: flex;\n  justify-content: space-around;\n  font-size: 34px;\n  margin-bottom: 24px;\n}\n.dialer .dialer-keys .dialer-keys-row span[data-v-16c6c4c3] {\n  color: var(--dialer-color);\n  width: 30px;\n  text-align: center;\n  cursor: pointer;\n  user-select: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  font-weight: 100;\n  -webkit-app-region: no-drag;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
