// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-geo-message[data-v-467155c9] {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
.chat-geo-message img[data-v-467155c9] {
  border-radius: 10px;
}
.forward[data-v-467155c9] {
  margin-bottom: 0.33em;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatGeoMessage.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".chat-geo-message[data-v-467155c9] {\n  width: 300px;\n  height: 200px;\n  cursor: pointer;\n}\n.chat-geo-message img[data-v-467155c9] {\n  border-radius: 10px;\n}\n.forward[data-v-467155c9] {\n  margin-bottom: 0.33em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
