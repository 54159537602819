// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-6d9ffb47] .header {
  padding: 20px 20px 10px 24px;
  color: var(--custom-textarea-color);
}
.custom-modal[data-v-6d9ffb47] .body {
  flex-direction: column;
}
.custom-modal[data-v-6d9ffb47] .body .mail {
  width: 100%;
}
.custom-modal[data-v-6d9ffb47] .body .mail input {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/AddGuestContact.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;AACA;EACE,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".custom-modal[data-v-6d9ffb47] .header {\n  padding: 20px 20px 10px 24px;\n  color: var(--custom-textarea-color);\n}\n.custom-modal[data-v-6d9ffb47] .body {\n  flex-direction: column;\n}\n.custom-modal[data-v-6d9ffb47] .body .mail {\n  width: 100%;\n}\n.custom-modal[data-v-6d9ffb47] .body .mail input {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
