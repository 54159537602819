// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i.badge[data-v-0cfed216] {
  color: white;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  vertical-align: middle;
  margin: auto;
  padding: 1px 6px 0px 5px;
  border-radius: 13px;
  background: var(--elem-red-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/side/More.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,wBAAwB;EACxB,mBAAmB;EACnB,iCAAiC;EACjC,2DAA2D;EAC3D,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["i.badge[data-v-0cfed216] {\n  color: white;\n  font-size: 13px;\n  line-height: 18px;\n  height: 18px;\n  vertical-align: middle;\n  margin: auto;\n  padding: 1px 6px 0px 5px;\n  border-radius: 13px;\n  background: var(--elem-red-color);\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
