var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c(
        "div",
        {
          staticClass: "attach-message",
          on: {
            click: function ($event) {
              return _vm.scrollingToElement(_vm.id)
            },
          },
        },
        [
          _c("div", { staticClass: "color-line" }),
          _c("div", { staticClass: "attach-block" }, [
            _c(
              "div",
              { staticClass: "attach-info" },
              [
                _c("div", { staticClass: "attach-info-name" }, [
                  _vm._v(_vm._s(_vm.$t("pinned-msg"))),
                ]),
                _c("message-text", { attrs: { message: _vm.message } }),
              ],
              1
            ),
          ]),
          _vm.isAdmin
            ? _c(
                "div",
                {
                  staticClass: "attach-message-close",
                  on: { click: _vm.close },
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }