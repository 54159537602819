// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-keyboard-chat[data-v-421bdf9e] {
  background-color: var(--bg-color);
  max-height: 275px;
  overflow-y: auto;
  max-width: 100%;
  flex: 0 0 auto;
}
.bot-keyboard-chat .bot-line-btns[data-v-421bdf9e] {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  margin: 2px;
}
.bot-keyboard-chat .bot-line-btns .bot-btn[data-v-421bdf9e] {
  background-color: var(--message-left-bgcolor);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: var(--login-color);
  flex: 1;
  border: 1px solid #cccccc38;
  margin: 5px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/bots/BotKeyboardChat.vue"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,2BAA2B;EAC3B,WAAW;AACb;AACA;EACE,6CAA6C;EAC7C,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;EACzB,OAAO;EACP,2BAA2B;EAC3B,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".bot-keyboard-chat[data-v-421bdf9e] {\n  background-color: var(--bg-color);\n  max-height: 275px;\n  overflow-y: auto;\n  max-width: 100%;\n  flex: 0 0 auto;\n}\n.bot-keyboard-chat .bot-line-btns[data-v-421bdf9e] {\n  display: flex;\n  justify-content: space-around;\n  align-content: space-around;\n  margin: 2px;\n}\n.bot-keyboard-chat .bot-line-btns .bot-btn[data-v-421bdf9e] {\n  background-color: var(--message-left-bgcolor);\n  padding-top: 10px;\n  padding-bottom: 10px;\n  border-radius: 5px;\n  color: var(--login-color);\n  flex: 1;\n  border: 1px solid #cccccc38;\n  margin: 5px;\n  text-align: center;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
