var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "note-info" },
    [
      _c("info-main-block", {
        attrs: {
          "icon-photo": _vm.iconPhoto,
          "icon-text": _vm.iconText,
          "common-text": _vm.iconText,
        },
      }),
      !_vm.canBack
        ? _c("chat-pin-switch", {
            attrs: { cid: _vm.cid, cidType: _vm.cidType },
          })
        : _vm._e(),
      _c("media-search-info-items", {
        on: { opensearchmedia: _vm.openSearchMedia },
      }),
      !_vm.canBack || _vm.isCompactMode
        ? _c(
            "actions-container",
            { staticClass: "delete-chat" },
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.delete-chat"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doDeleteChat.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-trash-alt",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }