//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex"
import { CONTACTS, INFO, CHAT, CHANNEL } from "../../store/modulesNames"
import { 
    ACT_INFO_BACK, 
    ACT_INFO_CLEAR, 
    ACT_CHAT_SET_SEARCH, 
    ACT_CHANNEL_SET_SEARCH, 
    ACT_CHAT_SET_MEDIA_SEARCH,
    ACT_INFO_PUSH,
    ACT_SET_MSG_COMMENT_SUBSCRIBED,
} from "../../store/actionsTypes"
import { GET_MERGED_CONTACT_BY_ID, GET_MESSAGE_BY_ID } from "../../store/gettersTypes"

import CustomSearch from "../custom/CustomSearch.vue"
import { INFO_TYPES } from "../../store/modules/info"
import { CONTACT_FIELD_TYPES } from "../../constants"

import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import ChatMessageMixin from "../chat/chat-message-mixin"
import { getIsFullCompactMode, getIsCompactMode, setMainVisible } from "../../../ext/trackResizes"
const inputSearchDelay = 500 // Delay (ms) in input before search starts

export default {
    name: "InfoHeader",
    mixins: [ScrollToMessageMixin, ChatMessageMixin],
    components: { CustomSearch },
    props: {
        currentInfo: {
            type: Object,
            required: true
        },
        canBack: {
            type: Boolean,
            required: true
        },
        isCompactMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isSearch: false,
            searchActive: false,
            searchContacts: "",
            chatsSearch: '',
            channelSearch: '',
            mediaSearch: '',
            isCommentsSubscribed: false,
        };
    },
    created() {
        this[ACT_CHAT_SET_SEARCH] = debounce(this[ACT_CHAT_SET_SEARCH], inputSearchDelay)
        this[ACT_CHANNEL_SET_SEARCH] = debounce(this[ACT_CHANNEL_SET_SEARCH], inputSearchDelay)
        this[ACT_CHAT_SET_MEDIA_SEARCH] = debounce(this[ACT_CHAT_SET_MEDIA_SEARCH], inputSearchDelay)
    },
    beforeMount() {
        this.isCommentsSubscribed = this.infoParams && this.infoParams.isCommentsSubscribed || this.infoParams.commentsSubscribed || false
    },    
    mounted() {
        this[ACT_CHAT_SET_SEARCH]('')
    },
    computed: {
        isShowSearch() {
            return this.currentInfo && (this.currentInfo.type === INFO_TYPES.CHAT_MEMBERS || this.currentInfo.type === INFO_TYPES.CHANNEL_MEMBERS)
        },
        isSearchInChat() {
            const isOn = this.currentInfo && this.currentInfo.type === INFO_TYPES.SEARCH_CHAT
            if (isOn) {
                if (this.searchActive) this.hideSearch()
                this.focusOnSearchInput()
            }
            return isOn
        },
        isSearchInChannel() {
            const isOn = this.currentInfo && this.currentInfo.type === INFO_TYPES.SEARCH_CHANNEL
            if (isOn) {
                if (this.searchActive) this.hideSearch()
                this.focusOnSearchInput()
            }
            return isOn
        },
        isSearchInMedia() {
            const isOn = this.currentInfo && this.currentInfo.type === INFO_TYPES.MEDIA_SEARCH
            if (isOn) {
                if (this.searchActive) this.hideSearch()
                this.focusOnSearchInput()
            }
            return isOn
        },
        isSearchInComments() {
            return this.currentInfo && this.currentInfo.params && this.currentInfo.params.commentId
        },
        isSearchModeInCompactMode() {
            return this.isCompactMode && (this.isShowSearch || this.isSearchInChat || this.isSearchInMedia || this.isSearchInChannel)
        },
        placeHolderStr() {
            if (this.isSearchInChat) {
                if (this.isSearchInComments) return this.$t('search-in-comments')
                return this.$t('search-in-chat')
            }
            if (this.isSearchInChannel) return this.$t('search-in-channel')
            if (this.isSearchInMedia) return this.$t('search-in-media')
        },
        searchClass() {
            const isShowSearch = this.$store.getters["info/getShowSearch"];
            return isShowSearch ? "show showMembersSearch" : "hide";
        },
        searchChatsClass() {
            return this.isSearchInChat || this.isSearchInChannel || this.isSearchInMedia ? "show" : "hide";
        },
        isThreads() {
            return this.currentInfo && this.currentInfo.type === INFO_TYPES.THREADS
        },
        infoToTitle() {
            const info = this.currentInfo
            let title = "";
            this.isSearch = false;
            switch (info && info.type) {
                case INFO_TYPES.CHAT:
                    title = this.$t("information.chat-info");
                    break;
                case INFO_TYPES.THREADS:
                    title = this.$t("information.comments");
                    break;
                case INFO_TYPES.CONTACT:
                    title = this.$t("information.contact-info");
                    break;
                case INFO_TYPES.BOT:
                    title = this.$t("bot-info");
                    break;
                case INFO_TYPES.SYSTEM_CHAT:
                    title = this.$t("sys-chat-info");
                    break;
                case INFO_TYPES.CONTACT_DETAILED:
                    const cid = info.params.cid
                    if (cid) {
                        const contact = this.$store.getters["contacts/getMergedContactById"](cid)
                        title = contact.fio || ""
                    } else {
                        const fields = info.params && info.params.fields || []
                        title = this.makeFio(fields)
                    }
                    break;
                case INFO_TYPES.CHANNEL:
                    title = this.$t("information.channel-info");
                    break;
                case INFO_TYPES.CHAT_MEMBERS:
                    title = this.$t("information.members");
                    this.isSearch = true;
                    break;
                case INFO_TYPES.CHANNEL_MEMBERS:
                    title = this.$t("chat.subs");
                    break;
                case INFO_TYPES.DETAILS_MSG:
                    title = this.$t("information.msg-details");
                    break;
                case INFO_TYPES.ACS_JOURNAL: {
                    let contact = this[GET_MERGED_CONTACT_BY_ID](
                        this.currentInfo.params && this.currentInfo.params.cid
                    );
                    title = contact.fio;
                    break;
                }
                case INFO_TYPES.NOTE:
                    title = this.$t("information.chat-info");
                    break;
                case INFO_TYPES.COMMON_CHATS:
                    title = this.$t("mainPage.common-chats");
                    break;
                case INFO_TYPES.TETRA_CONTACT:
                    title = this.$t("information.contact-info");
                    break;
            }
            return title;
        },
        isThreads() {
            const _isThreads = this.currentInfo && this.currentInfo.type === INFO_TYPES.THREADS
            return _isThreads
        },
        infoParams() {
            return this.currentInfo.params || {}
        },
        msgItem() {
            if (this.infoParams && this.infoParams.commentId) {
                let _msg = this[GET_MESSAGE_BY_ID](this.infoParams.commentId)
                return  this.getMessageItem(_msg)
            }
            return null
        },
        msg() {
            let _msg = this.infoParams.message || ''
            if (!_msg) {
                let _message = this.msgItem
                _msg = this.getMsgNameByType(_message)
            }
            return _msg
        },
        getThreadsMsgTitle() {
            let _text = this.infoParams.text || ''
            if (!_text) {
                let _message = this.msgItem
                _text = typeof _message.msg === 'object' ? _message.msg.text || '' : _message.msg
            }
            return _text
        },
        commentsSubscribed() {
            const isCommentsSubscribed = this.currentInfo && this.infoParams.isCommentsSubscribed
            return isCommentsSubscribed
        },
        getIsSubscribedTitle() {
            return this.isCommentsSubscribed ? this.$t("chat.unsubscribe-comments") : this.$t("chat.subscribe-comments")
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CHAT, [GET_MESSAGE_BY_ID]),
    },
    watch: {
        searchContacts(val) {
            if (this.searchActive) this.$bus.$emit("info-contacts-search", val)
        },
        chatsSearch(val) {
            if (val && val.length >= 2) this[ACT_CHAT_SET_SEARCH](val)
            else this[ACT_CHAT_SET_SEARCH]('')
        },
        channelSearch(val) {
            if (val && val.length >= 2) this[ACT_CHANNEL_SET_SEARCH](val)
            else this[ACT_CHANNEL_SET_SEARCH]('')
        },
        mediaSearch(val) {
            this[ACT_CHAT_SET_MEDIA_SEARCH](val)
        },
        commentsSubscribed(val) {
            this.isCommentsSubscribed = val
        }
    },
    methods: {
        showSearch() {
            this.searchActive = true;
            this.$store.dispatch(`info/actSetShowSearch`, true);
            const input = this.$refs.searchGroupContacts.$el.getElementsByTagName(
                "input"
            )[0];
            this.$nextTick(() => input && input.focus());
        },
        hideSearch() {
            this.searchActive = false;
            this.$store.dispatch(`info/actSetShowSearch`, false);
        },
        focusOnSearchInput() {
            setTimeout(() => {
                const input = this.$refs.searchInput && this.$refs.searchInput.$el.getElementsByTagName("input")[0]
                input && input.focus()
            }, 100)
        },
        doHide() {
            if (this.searchActive) {
                this.searchContacts = ""
                this.hideSearch()
            }
            if (this.isCompactMode) {
                setMainVisible()
                if (!this.isThreads) this.goBack()
            }
            this[ACT_INFO_CLEAR]()
        },
        goBack() {
            this.hideSearch()
            if (!this.isThreads) this.doGoBack()
            else this[ACT_INFO_CLEAR]()
        },
        openSearchChat() {
            let { cid, cidType, commentId } = this.infoParams
            this[ACT_INFO_PUSH]({type: 'search-in-chat', params: {cid, cidType, commentId}})
        },
        subscribeToComments(isSubscribed) {
            let { commentId } = this.infoParams
            this[ACT_SET_MSG_COMMENT_SUBSCRIBED]({id: commentId, status: isSubscribed})
            // this.isCommentsSubscribed = isSubscribed
        },
        scrollToChatMessage() {
            const id = this.infoParams && this.infoParams.commentId
            const isCompactMode = getIsFullCompactMode() || getIsCompactMode()
            if (isCompactMode) {
                this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
                setMainVisible()
                if (id) setTimeout(() => this.scrollingToElement(id), 300)
            }
            else if (id) this.scrollingToElement(id)
        },
        makeFio(fields) {
            let fio = this.fioBySurnameName(fields)
            if (!fio || !fio.trim().length) fio = this.fioByAuxFields(fields)
            return fio
        },
        fioBySurnameName(fields) {
            let fioArr = fields && fields.filter(f => [
                    CONTACT_FIELD_TYPES.SURNAME,
                    CONTACT_FIELD_TYPES.NAME,
                    CONTACT_FIELD_TYPES.NIK,
                ].includes(f.type),
            )
            let fioValsArr = []
            if (fioArr) fioValsArr = fioArr.map(f => f.value.trim())
            return fioValsArr.join(" ")
        },
        fioByAuxFields(fields) {
            const phones = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.PHONE || f.type === CONTACT_FIELD_TYPES.WORKPHONE)
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
            const mail = mails && mails.length && mails[0].value
            return phone || mail
        },        
        ...mapActions(INFO, { doGoBack: ACT_INFO_BACK }),
        ...mapActions(INFO, [ACT_INFO_CLEAR, ACT_INFO_PUSH]),
        ...mapActions(CHAT, [ACT_CHAT_SET_SEARCH, ACT_CHAT_SET_MEDIA_SEARCH, ACT_SET_MSG_COMMENT_SUBSCRIBED]),
        ...mapActions(CHANNEL, [ACT_CHANNEL_SET_SEARCH]),
    }
}

const debounce = (func, delay) => {
    let debounceTimer
    return function() {
        const context = this
        const args = arguments
            clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => { func.apply(context, args) }, delay)
    }
}

