
    import { mapGetters, mapActions } from "vuex"
    import { CHATS } from "../../store/modulesNames"
    import { GET_CHAT_MUTE_TIME } from "../../store/gettersTypes"
    import { ACT_SET_CHAT_MUTED } from "../../store/actionsTypes"

    import MuteSwitch from "./MuteSwitch.vue"

    import { CHAT_TYPES } from '../../constants'

    export default {
        name: "ChatMuteSwitch",
        extends: MuteSwitch,
        props: {
            cid: {
                type: Number,
                required: true,
            },
            cidType: {
                type: String,
                default: CHAT_TYPES.USER
            }
        },
        computed: {
            muteTime() {
                return this[GET_CHAT_MUTE_TIME]({cid: this.cid, cidType: this.cidType})
            },
            ...mapGetters(CHATS, [GET_CHAT_MUTE_TIME])
        },
        methods: {
            mute() {
                this.modalOpen({
                    name: 'chat-mute',
                    props: {
                        cid: this.cid,
                        cidType: this.cidType
                    }
                })
            },
            unMute() {
                this[ACT_SET_CHAT_MUTED]({cid: this.cid, cidType: this.cidType, mute: null})
            },
            ...mapActions(CHATS, [ACT_SET_CHAT_MUTED])
        },
    }
