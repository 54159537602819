import { render, staticRenderFns } from "./chatSurveyMessage.vue?vue&type=template&id=368afa4c&scoped=true&lang=pug"
import script from "./chatSurveyMessage.vue?vue&type=script&lang=js"
export * from "./chatSurveyMessage.vue?vue&type=script&lang=js"
import style0 from "./chatSurveyMessage.vue?vue&type=style&index=0&id=368afa4c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368afa4c",
  null
  
)

export default component.exports