// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-content[data-v-286af342] {
  background-color: var(--bg-color2);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.info-content[data-v-286af342] > * {
  background-color: var(--content-container-bgcolor);
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;
}
.info-content[data-v-286af342] > *:not(:last-child) {
  margin-bottom: 20px;
}
.info-content[data-v-286af342] .item-cont > * {
  padding: 0 20px;
}
.info-content[data-v-286af342] .single-action:hover,
.info-content[data-v-286af342] .actions > div:hover,
.info-content[data-v-286af342] .switch-item .switch:hover,
.info-content[data-v-286af342] .btns-list .list-item:hover,
.info-content[data-v-286af342] .members-list .list-item:hover {
  background-color: var(--asc-status-hover) !important;
}
.info-content[data-v-286af342] .loader {
  height: calc(100%);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/InfoBody.vue"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,kDAAkD;EAClD,6EAA6E;AAC/E;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;;;;;EAKE,oDAAoD;AACtD;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".info-content[data-v-286af342] {\n  background-color: var(--bg-color2);\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  overflow-y: auto;\n}\n.info-content[data-v-286af342] > * {\n  background-color: var(--content-container-bgcolor);\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;\n}\n.info-content[data-v-286af342] > *:not(:last-child) {\n  margin-bottom: 20px;\n}\n.info-content[data-v-286af342] .item-cont > * {\n  padding: 0 20px;\n}\n.info-content[data-v-286af342] .single-action:hover,\n.info-content[data-v-286af342] .actions > div:hover,\n.info-content[data-v-286af342] .switch-item .switch:hover,\n.info-content[data-v-286af342] .btns-list .list-item:hover,\n.info-content[data-v-286af342] .members-list .list-item:hover {\n  background-color: var(--asc-status-hover) !important;\n}\n.info-content[data-v-286af342] .loader {\n  height: calc(100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
