// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content-container {
  min-width: 250px;
  max-width: 800px;
  margin: 20px auto 0 auto;
  flex-basis: 800px;
}
.main-content-container .main-content-container-header {
  font-size: 18px;
  margin-bottom: 20px;
}
.main-content-container .main-content-container-main {
  background: var(--bg-color);
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  margin-bottom: 40px;
}
.main-content-container .main-content-container-footer {
  margin-top: 10px;
  color: gray;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/MainContentContainer.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,mFAAmF;EACnF,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".main-content-container {\n  min-width: 250px;\n  max-width: 800px;\n  margin: 20px auto 0 auto;\n  flex-basis: 800px;\n}\n.main-content-container .main-content-container-header {\n  font-size: 18px;\n  margin-bottom: 20px;\n}\n.main-content-container .main-content-container-main {\n  background: var(--bg-color);\n  border-radius: 4px;\n  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;\n  margin-bottom: 40px;\n}\n.main-content-container .main-content-container-footer {\n  margin-top: 10px;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
