var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.searchOpened
    ? _c("div", { staticClass: "toolbar" }, [
        _c(
          "div",
          { staticClass: "toolbar-wrapper" },
          _vm._l(_vm.nav_btns, function (btn) {
            return _c(
              "div",
              {
                staticClass: "toolbutton",
                class: _vm.active_button === btn.name ? "active" : "",
                attrs: { title: btn.title },
                on: {
                  click: function ($event) {
                    return _vm.doChangeActive(btn.name)
                  },
                },
              },
              [
                _c("i", {
                  class: btn.icon_class,
                  attrs: {
                    "aria-hidden": "true",
                    "data-count": _vm._f("maxEventCount")(_vm.events[btn.name]),
                  },
                }),
                _c("span", [_vm._v(_vm._s(btn.title))]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }