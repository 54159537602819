// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-fa .component-header .description[data-v-52fb2826] {
  color: var(--message-grey-color);
  font-size: 15px;
  line-height: 25px;
  height: 15%;
  margin-top: 45%;
}
.two-fa .component-header .description .mail[data-v-52fb2826] {
  text-decoration: underline;
}
.two-fa .component-section[data-v-52fb2826] {
  margin: 97px 0px 0px 0px !important;
}
.two-fa .component-section .input-section[data-v-52fb2826] {
  display: flex;
  justify-content: center;
  margin-bottom: 70%;
  margin-top: -20%;
}
.two-fa .component-section .input-section input[data-v-52fb2826] {
  border: 1px solid var(--elem-grey-color);
  margin: 10px;
  height: 37px;
  width: 37px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/login/2FA.vue"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,mCAAmC;AACrC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".two-fa .component-header .description[data-v-52fb2826] {\n  color: var(--message-grey-color);\n  font-size: 15px;\n  line-height: 25px;\n  height: 15%;\n  margin-top: 45%;\n}\n.two-fa .component-header .description .mail[data-v-52fb2826] {\n  text-decoration: underline;\n}\n.two-fa .component-section[data-v-52fb2826] {\n  margin: 97px 0px 0px 0px !important;\n}\n.two-fa .component-section .input-section[data-v-52fb2826] {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 70%;\n  margin-top: -20%;\n}\n.two-fa .component-section .input-section input[data-v-52fb2826] {\n  border: 1px solid var(--elem-grey-color);\n  margin: 10px;\n  height: 37px;\n  width: 37px;\n  text-align: center;\n  outline: none;\n  border-radius: 5px;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
