// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-document-file-message[data-v-63b2c432] {
  display: grid;
  grid-template-columns: 50px minmax(50px, auto) 32px;
  padding-top: 0.2em;
}
.chat-document-file-message .encrypted-data-load-btn i[data-v-63b2c432] {
  font-size: 16px;
}
.chat-document-file-message .document-file-name[data-v-63b2c432] {
  word-break: break-word;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: contrast(var(--fontContrast));
}
.chat-document-file-message .file-properties[data-v-63b2c432] {
  font-size: small;
  display: flex;
  flex-direction: row;
}
.chat-document-file-message .file-properties .file-size[data-v-63b2c432] {
  margin-right: 1em;
}
.forward[data-v-63b2c432] {
  margin-bottom: 0.33em;
  filter: contrast(var(--fontContrast));
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatRegularFileMessage.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mDAAmD;EACnD,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qCAAqC;AACvC;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,qCAAqC;AACvC","sourcesContent":[".chat-document-file-message[data-v-63b2c432] {\n  display: grid;\n  grid-template-columns: 50px minmax(50px, auto) 32px;\n  padding-top: 0.2em;\n}\n.chat-document-file-message .encrypted-data-load-btn i[data-v-63b2c432] {\n  font-size: 16px;\n}\n.chat-document-file-message .document-file-name[data-v-63b2c432] {\n  word-break: break-word;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  filter: contrast(var(--fontContrast));\n}\n.chat-document-file-message .file-properties[data-v-63b2c432] {\n  font-size: small;\n  display: flex;\n  flex-direction: row;\n}\n.chat-document-file-message .file-properties .file-size[data-v-63b2c432] {\n  margin-right: 1em;\n}\n.forward[data-v-63b2c432] {\n  margin-bottom: 0.33em;\n  filter: contrast(var(--fontContrast));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
