// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-file[data-v-dadbc2d4] {
  position: absolute;
  height: 90vh;
  width: 97%;
  background-color: var(--content-container-bgcolor);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-blue-color2);
  font-size: 35px;
  box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.25);
}
.drop-file span[data-v-dadbc2d4] {
  text-align: center;
}
.drop-file *[data-v-dadbc2d4] {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/DropFile.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,kDAAkD;EAClD,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,eAAe;EACf,mDAAmD;AACrD;AACA;EACE,kBAAkB;AACpB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".drop-file[data-v-dadbc2d4] {\n  position: absolute;\n  height: 90vh;\n  width: 97%;\n  background-color: var(--content-container-bgcolor);\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto;\n  z-index: 110;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--base-blue-color2);\n  font-size: 35px;\n  box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.25);\n}\n.drop-file span[data-v-dadbc2d4] {\n  text-align: center;\n}\n.drop-file *[data-v-dadbc2d4] {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
