var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "channel-create" }, [
          _c("div", { staticClass: "first-line" }, [
            _c("div", { staticClass: "icon-wrapper" }, [
              _c(
                "div",
                { on: { click: _vm.doFileSelect } },
                [
                  _c("icon-image", {
                    staticClass: "extra-big editable",
                    attrs: {
                      src: _vm.image,
                      remove: _vm.iconRemove,
                      rollBack: _vm.iconRollback,
                    },
                    on: {
                      rollBack: _vm.doRollbackImage,
                      remove: _vm.doRemoveImage,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "icon-label" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("modal.channel-img")))]),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              staticClass: "form-control non-border-input",
              class: { "warning-input": _vm.warning },
              attrs: {
                type: "text",
                placeholder: _vm.warning
                  ? _vm.$t("modal.enter-channel-name")
                  : _vm.$t("modal.channel-name"),
              },
              domProps: { value: _vm.name },
              on: {
                click: _vm.checkInput,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group description" }, [
            !_vm.info
              ? _c("div", { staticClass: "div-placeholder" }, [
                  _vm._v(_vm._s(_vm.$t("modal.channel-desc"))),
                ])
              : _vm._e(),
            _vm._m(0),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("span", { staticClass: "type-label-text" }, [
                _vm._v(_vm._s(_vm.$t("channel-type"))),
              ]),
              _c("custom-radio", {
                staticClass: "custom-radio",
                attrs: {
                  value_change: _vm.public,
                  inputs: _vm.publicInputs,
                  default_data: _vm.public,
                },
                on: {
                  "update:value_change": function ($event) {
                    _vm.public = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("input", {
            ref: "fileLoader",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.doLoadImage },
          }),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.modalClose } },
          [_vm._v(_vm._s(_vm.$t("close")))]
        ),
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.onOkClicked } },
          [_vm._v(_vm._s(_vm.okText))]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "info non-border-input div-input",
        attrs: { contenteditable: "true" },
        on: {
          input: function ($event) {
            _vm.info = $event.target.innerText
          },
        },
      },
      [_vm._v(_vm._s(_vm.info))]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }