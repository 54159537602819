// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btns .btns-list[data-v-99998bfa] {
  margin-bottom: 0;
}
.info-section[data-v-99998bfa] {
  padding: 0;
  margin-bottom: 0 !important;
}
.info-section .item-cont[data-v-99998bfa] {
  overflow: hidden;
  margin: 0;
}
.info-section .item-cont .list-item[data-v-99998bfa] {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 100%;
  overflow: hidden;
}
.info-section .item-cont .list-item[data-v-99998bfa]:not(:last-child)::after {
  content: " ";
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid var(--list-item-bordercolor);
  padding-top: 5px;
}
.info-section .item-cont .list-item .btn-text[data-v-99998bfa] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.info-section .item-cont .btn-list-item > *[data-v-99998bfa] {
  margin: auto 0;
}
.info-section .item-cont .btn-list-item .btn-icon[data-v-99998bfa] {
  font-size: 18px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--icon-bgcolor2);
  margin: auto 5px;
  color: white;
}
.info-section .item-cont .btn-list-item .btn-text[data-v-99998bfa] {
  margin-left: 15px;
  color: var(--icon-bgcolor2);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/ActionsButton.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,qBAAqB;EACrB,WAAW;EACX,qDAAqD;EACrD,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,sCAAsC;EACtC,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".btns .btns-list[data-v-99998bfa] {\n  margin-bottom: 0;\n}\n.info-section[data-v-99998bfa] {\n  padding: 0;\n  margin-bottom: 0 !important;\n}\n.info-section .item-cont[data-v-99998bfa] {\n  overflow: hidden;\n  margin: 0;\n}\n.info-section .item-cont .list-item[data-v-99998bfa] {\n  cursor: pointer;\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  height: 68px;\n  width: 100%;\n  overflow: hidden;\n}\n.info-section .item-cont .list-item[data-v-99998bfa]:not(:last-child)::after {\n  content: \" \";\n  position: absolute;\n  bottom: 0;\n  display: inline-block;\n  width: 100%;\n  border-bottom: 1px solid var(--list-item-bordercolor);\n  padding-top: 5px;\n}\n.info-section .item-cont .list-item .btn-text[data-v-99998bfa] {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.info-section .item-cont .btn-list-item > *[data-v-99998bfa] {\n  margin: auto 0;\n}\n.info-section .item-cont .btn-list-item .btn-icon[data-v-99998bfa] {\n  font-size: 18px;\n  width: 48px;\n  height: 48px;\n  line-height: 48px;\n  text-align: center;\n  border-radius: 50%;\n  background-color: var(--icon-bgcolor2);\n  margin: auto 5px;\n  color: white;\n}\n.info-section .item-cont .btn-list-item .btn-text[data-v-99998bfa] {\n  margin-left: 15px;\n  color: var(--icon-bgcolor2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
