var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assistant-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c(
                        "list-item",
                        {
                          attrs: { title: _vm.$t("сall-control"), link: true },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openAssistantModal.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "after" }, slot: "after" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `assistants.name.${
                                      _vm.assistant || "none"
                                    }`,
                                    _vm.getNumber()
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "list-item",
                        {
                          attrs: {
                            title: _vm.$t("settings.audio-video-title"),
                            link: true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openMediaDevicesModal.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "after" }, slot: "after" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("settings.audio-video-settings"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.$t("calls")))])],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }