// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title[data-v-62062a96] {
  padding: 0px 15px;
  font-size: 17px;
  color: var(--base-blue-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/search/Channels.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".title[data-v-62062a96] {\n  padding: 0px 15px;\n  font-size: 17px;\n  color: var(--base-blue-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
