var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.type, {
    tag: "component",
    staticClass: "info-content",
    attrs: { canBack: _vm.canBack, params: _vm.params },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }