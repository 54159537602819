var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loaded,
          expression: "!loaded",
        },
      ],
      attrs: { id: "login-screen" },
    },
    [
      _c("section", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.autoLoginInProgress,
                expression: "!autoLoginInProgress",
              },
            ],
            staticClass: "login-block",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLeftBlock,
                    expression: "isLeftBlock",
                  },
                ],
                staticClass: "left-block side-block",
              },
              [
                _c("div", { staticClass: "block-wrapper" }, [
                  _c("div", { staticClass: "project-logo" }, [
                    _c("div", { staticClass: "logo-wrapper" }, [
                      _c("img", { attrs: { src: _vm.logoImg } }),
                    ]),
                  ]),
                  _c("div", { staticClass: "greeting-block" }, [
                    _c("p", { staticClass: "greeting" }, [
                      _vm._v(_vm._s(_vm.$t("loginPage.greeting"))),
                    ]),
                    _c("p", { staticClass: "slogan" }, [
                      _vm._v(_vm._s(_vm.$t("loginPage.tagLine"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "entrance-text" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("loginPage.entrance-text"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "client-info" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("mainPage.client-version")) +
                        ": " +
                        _vm._s(_vm.clientVersion)
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "right-block side-block" }, [
              _c(
                "div",
                { staticClass: "block-wrapper" },
                [
                  _c("entrance", {
                    ref: "entrance",
                    attrs: { logging: _vm.changed },
                    on: {
                      "update:logging": function ($event) {
                        _vm.changed = $event
                      },
                    },
                  }),
                  _vm.logined && !_vm.loaded
                    ? _c("div", { staticClass: "entrance-status" }, [
                        _c("div", { staticClass: "status-wrapper" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "status-text" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("loginPage.loading-profile"))
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.autoLoginInProgress,
                expression: "autoLoginInProgress",
              },
            ],
            staticClass: "autologin-status",
          },
          [_vm._m(1)]
        ),
      ]),
      !_vm.autoLoginInProgress
        ? _c("footer", [
            _c("div", { staticClass: "copyright" }, [
              _vm._v(
                "© " +
                  _vm._s(_vm.$t("loginPage.developer")) +
                  " " +
                  _vm._s(_vm.year) +
                  " " +
                  _vm._s(_vm.$t("year"))
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-icon" }, [
      _c("i", { staticClass: "fal fa-spinner fa-spin" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-wrapper" }, [
      _c("div", { staticClass: "status-icon" }, [
        _c("i", { staticClass: "fal fa-spinner fa-spin" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }