
    import SelectChatToSendMsg from "./SelectChatToSendMsg.vue"

    export default {
        name: "SelectChatToForward",
        extends: SelectChatToSendMsg,
        computed: {
            okText() { return this.$t('chat.forward') }
        }
    }
