// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul[data-v-798a5c7b] {
  margin-bottom: 0px;
}
.listItemStyle[data-v-798a5c7b] {
  margin-bottom: 0px !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--list-item-horline-color);
}
.itemNoChats[data-v-798a5c7b] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/CommonChats.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,uDAAuD;AACzD;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":["ul[data-v-798a5c7b] {\n  margin-bottom: 0px;\n}\n.listItemStyle[data-v-798a5c7b] {\n  margin-bottom: 0px !important;\n  box-shadow: none !important;\n  border-bottom: 1px solid var(--list-item-horline-color);\n}\n.itemNoChats[data-v-798a5c7b] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 72px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
