// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-enter[data-v-2ecfc33f],
.warning-leave-to[data-v-2ecfc33f] {
  opacity: 0;
}
.warning-leave-to[data-v-2ecfc33f] {
  opacity: 1;
}
.warning-enter-active[data-v-2ecfc33f] {
  transition: all 0.6s linear;
}
.warning-leave-active[data-v-2ecfc33f] {
  transition: all 0.4s linear;
}
.wrapper[data-v-2ecfc33f] {
  align-items: flex-start;
  flex-direction: column;
}
.wrapper .custom-radio[data-v-2ecfc33f] {
  margin-top: 20px;
}
.wrapper .warning[data-v-2ecfc33f] {
  display: block;
  padding-top: 12px;
  color: var(--elem-red-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SelectAssistant.vue"],"names":[],"mappings":"AAAA;;EAEE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,4BAA4B;AAC9B","sourcesContent":[".warning-enter[data-v-2ecfc33f],\n.warning-leave-to[data-v-2ecfc33f] {\n  opacity: 0;\n}\n.warning-leave-to[data-v-2ecfc33f] {\n  opacity: 1;\n}\n.warning-enter-active[data-v-2ecfc33f] {\n  transition: all 0.6s linear;\n}\n.warning-leave-active[data-v-2ecfc33f] {\n  transition: all 0.4s linear;\n}\n.wrapper[data-v-2ecfc33f] {\n  align-items: flex-start;\n  flex-direction: column;\n}\n.wrapper .custom-radio[data-v-2ecfc33f] {\n  margin-top: 20px;\n}\n.wrapper .warning[data-v-2ecfc33f] {\n  display: block;\n  padding-top: 12px;\n  color: var(--elem-red-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
