var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "global-search" }, [
    _c(
      "div",
      { staticClass: "options" },
      _vm._l(_vm.options, function (option, i) {
        return _c(
          "div",
          {
            staticClass: "option",
            class: { active: _vm.selectedIndex === i },
            on: {
              click: function ($event) {
                return _vm.setSearchOption(i)
              },
            },
          },
          [
            _c("div", { staticClass: "option-item" }, [
              _vm._v(_vm._s(option.name)),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm.isSearching && _vm.search.length > 1
          ? _c("content-loader")
          : _vm._e(),
        _vm.preparedItems.length
          ? _c("RecycleScroller", {
              ref: "global-search-scroller",
              staticClass: "scroller",
              style: _vm.scrollerStyle,
              attrs: {
                itemClass: "listItem",
                items: _vm.preparedItems,
                buffer: _vm.bufferSize,
                "key-field": "id",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ item, index }) {
                      return [
                        item.isTitle
                          ? _c("div", { staticClass: "section-title" }, [
                              _c("span", { staticClass: "title-text" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ])
                          : !item.isLoadMore
                          ? _c("list-item", {
                              key: item.id,
                              class: { isTitle: item.isTitle },
                              attrs: { item: item, search: _vm.search },
                            })
                          : _c(
                              "div",
                              {
                                staticClass: "load-more",
                                on: {
                                  click: function ($event) {
                                    return _vm.searchMore(index)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "load-more-btn" }, [
                                  _vm._v(_vm._s(item.text)),
                                ]),
                              ]
                            ),
                        !item.isTitle && !item.isLoadMore
                          ? _c("div", { staticClass: "hor-line light-line" })
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                2459146328
              ),
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }