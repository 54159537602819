var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("my-bots")))]),
    _c(
      "div",
      { staticClass: "found-bots" },
      _vm._l(_vm.items, function (bot, i) {
        return _c(
          "div",
          {
            staticClass: "bot",
            class: { focused: _vm.focusOnItem === i },
            on: {
              click: function ($event) {
                return _vm.openBot(bot)
              },
            },
          },
          [
            _c("icon-image", {
              attrs: {
                src: bot.photo,
                text: bot[_vm.CONTACT_FIELD_TYPES.BOTTITLE],
              },
            }),
            _c("div", { staticClass: "bot-info" }, [
              _c("span", { staticClass: "bot-name" }, [
                _vm._v(_vm._s(bot[_vm.CONTACT_FIELD_TYPES.BOTTITLE])),
              ]),
              _c("span", { staticClass: "bot-description" }, [
                _vm._v(_vm._s(bot[_vm.CONTACT_FIELD_TYPES.BOTDESCRIPTION])),
              ]),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }