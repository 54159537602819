// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section-default[data-v-8e2561e0] {
  display: flex;
  height: 52px;
}
.header-section-default .sec-btn[data-v-8e2561e0] {
  margin: auto;
  padding: 6px 12px;
  -webkit-app-region: no-drag;
}
.header-section-default .exit-btn[data-v-8e2561e0] {
  margin-left: auto;
}
.header-section-default .left-section[data-v-8e2561e0],
.header-section-default .right-section[data-v-8e2561e0] {
  display: flex;
}
.header-section-default .central-section[data-v-8e2561e0] {
  flex: 1;
}
.header-section-default .right-section[data-v-8e2561e0] {
  margin: 0 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNew/videoConfSectionHeaderDefault.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,iBAAiB;AACnB;AACA;;EAEE,aAAa;AACf;AACA;EACE,OAAO;AACT;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".header-section-default[data-v-8e2561e0] {\n  display: flex;\n  height: 52px;\n}\n.header-section-default .sec-btn[data-v-8e2561e0] {\n  margin: auto;\n  padding: 6px 12px;\n  -webkit-app-region: no-drag;\n}\n.header-section-default .exit-btn[data-v-8e2561e0] {\n  margin-left: auto;\n}\n.header-section-default .left-section[data-v-8e2561e0],\n.header-section-default .right-section[data-v-8e2561e0] {\n  display: flex;\n}\n.header-section-default .central-section[data-v-8e2561e0] {\n  flex: 1;\n}\n.header-section-default .right-section[data-v-8e2561e0] {\n  margin: 0 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
