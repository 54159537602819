var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio-component", class: { active: _vm._connected } },
    [
      _c("div", { staticClass: "notification" }, [
        _c("div", { staticClass: "height-ident" }),
        _c(
          "div",
          {
            ref: "notify",
            staticClass: "notification-wrapper",
            class: _vm.notifyClass,
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.notifyText))]),
            _c("audio", {
              ref: "audioNotify",
              staticClass: "audio-notify",
              attrs: { src: _vm.soundSrc },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "middle-section" },
        [
          _c("radio-status", {
            staticClass: "middle-item status",
            attrs: { title: _vm._title, "sub-title": _vm._subTitle },
          }),
          _c("ptt-button", {
            staticClass: "middle-item ptt show-when-active",
            attrs: { status: _vm.pttStatus, pttPushed: _vm.pttPushed },
            nativeOn: {
              click: function ($event) {
                return _vm.onClickPTT.apply(null, arguments)
              },
              mousedown: function ($event) {
                return _vm.onPTTPush.apply(null, arguments)
              },
              mouseup: function ($event) {
                return _vm.onPTTPop.apply(null, arguments)
              },
              mouseleave: function ($event) {
                return _vm.onPTTPop.apply(null, arguments)
              },
            },
          }),
          _c("radio-btns", {
            staticClass: "middle-item show-when-active",
            attrs: {
              groupSelect: true,
              isTetra: _vm.isTetra,
              repeatLast: !_vm.isTetra,
              info: true,
              settings: true,
              missedMessagesCount: _vm.missedMsgsCount,
            },
            on: {
              groupSelect: _vm.onGroupSelect,
              repeatLast: _vm.onRepeatLast,
              startChat: _vm.onStartChat,
              info: (e) => _vm.onInfo(e, "bottom-right"),
              settings: _vm.onSettings,
            },
          }),
          _c("cancel-btn", {
            staticClass: "middle-item show-when-active",
            nativeOn: {
              click: function ($event) {
                return _vm.onExit.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("info-section", {
        staticClass: "show-when-active",
        attrs: { text: _vm.infoText },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }