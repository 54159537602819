// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-6f1a44f5] .body {
  overflow-y: hidden !important;
}
.survey-msg-content[data-v-6f1a44f5] {
  width: 100%;
  height: 100%;
}
.survey-msg-content iframe[data-v-6f1a44f5] {
  border: none;
  width: 100vw;
  height: 70vh;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ModalSurvey.vue"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;AACd","sourcesContent":["[data-v-6f1a44f5] .body {\n  overflow-y: hidden !important;\n}\n.survey-msg-content[data-v-6f1a44f5] {\n  width: 100%;\n  height: 100%;\n}\n.survey-msg-content iframe[data-v-6f1a44f5] {\n  border: none;\n  width: 100vw;\n  height: 70vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
