var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider", on: { click: _vm.onProgressClick } },
    [
      _c("div", { staticClass: "_slider-cont" }, [
        _vm.is_active
          ? _c("div", { staticClass: "_slider-wrapper" }, [
              _c("div", {
                staticClass: "_progress",
                style: { width: 100 - _vm.progress + "%" },
              }),
              _c("div", {
                staticClass: "_thumb",
                style: {
                  display: _vm.thump_active ? "block" : "",
                  marginLeft: _vm.progress + "%",
                },
                on: { mousedown: _vm.onThumbMouseDown },
              }),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }