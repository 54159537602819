
    import { mapActions } from "vuex"
    import {TETRA} from '../../../store/modulesNames'
    import {ACT_TETRA_END_DUPLEX_CALL} from '../../../store/actionsTypes'

    import CallBase from "./CallBase.vue"

    export default {
        name: "CallTetra",
        extends: CallBase,
        methods: {
            endCall(){
                this[ACT_TETRA_END_DUPLEX_CALL]({ ssi: this.id })
            },
            ...mapActions(TETRA, [ACT_TETRA_END_DUPLEX_CALL])
        }
    }
