import { render, staticRenderFns } from "./MainHeaderContact.vue?vue&type=template&id=68f27ea3&scoped=true&lang=pug"
import script from "./MainHeaderContact.vue?vue&type=script&lang=js"
export * from "./MainHeaderContact.vue?vue&type=script&lang=js"
import style0 from "./MainHeaderContact.vue?vue&type=style&index=0&id=68f27ea3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f27ea3",
  null
  
)

export default component.exports