// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu-group[data-v-382484b2] {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}
.context-menu-group .header[data-v-382484b2] {
  color: var(--text-color);
  font-weight: bold;
  padding: 5px 10px;
}
.context-menu-group + .context-menu-group[data-v-382484b2] {
  border-top: 1px solid var(--divider-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/contextMenu/ContextMenuGroup.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;AAC1B;AACA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,0CAA0C;AAC5C","sourcesContent":[".context-menu-group[data-v-382484b2] {\n  display: flex;\n  flex-direction: column;\n  color: var(--text-color);\n}\n.context-menu-group .header[data-v-382484b2] {\n  color: var(--text-color);\n  font-weight: bold;\n  padding: 5px 10px;\n}\n.context-menu-group + .context-menu-group[data-v-382484b2] {\n  border-top: 1px solid var(--divider-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
