
    import Alert from './Alert.vue'
    import { i18n } from '../../../ext/i18n'

    export default {
        name: "ChannelUnavailable",
        extends: Alert,
        computed: {
            title() { return i18n.messages[i18n.locale].errors.error },
            text() { return i18n.messages[i18n.locale].modal['channel-not-exist'] },
            okText() { return i18n.messages[i18n.locale].loginPage.ok },
        }
    }
