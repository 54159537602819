var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button-update", on: { click: _vm.openUpdatePage } },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("notification"))),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          _vm._s(_vm.$t("new-version-found")) + " " + _vm._s(_vm.version) + "."
        ),
        _c("span", [_vm._v(_vm._s(_vm.$t("detail")))]),
      ]),
      _c("div", { staticClass: "close-btn", on: { click: _vm.doClose } }, [
        _c("i", { staticClass: "fal fa-times" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }