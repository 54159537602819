import { ENTITIES, ENTITY_TAGS } from '../constants'
import { TLDS_LIST } from '../../ext/tlds'

import { CONF_TYPES } from '../constants'

const R = require('ramda')

const URL_REGEXP_PARTS = {
    PROTO: '(?:https?|ftp)://',
    PORT: '(:(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[1-9][0-9]{0,3}))',
    IP: '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)',
    SUB_CHARS: '[\u0400-\u04FFa-zA-Z0-9\-\_]',
    PATH: '(?:[\\]\\[\u0400-\u04FFa-zA-Z0-9_\\-./?%#:;&=()+~@\',]*)',
}

const LIST_TEST_REG = new RegExp(`^${URL_REGEXP_PARTS.SUB_CHARS}+$`)

// (com|ru...)
URL_REGEXP_PARTS.TLDS = '(' + TLDS_LIST.reduce((prev,cur) => {
    if (LIST_TEST_REG.test(cur)) {
        if (prev) prev += '|'
        prev += cur
    }
    return prev
}, '') + ')'

const URLREGEXP = `(` +
    `(` +
        `(?:${URL_REGEXP_PARTS.PROTO}` +
            // user:pass authentication
            `((?:${URL_REGEXP_PARTS.SUB_CHARS}{1,64}(?::${URL_REGEXP_PARTS.SUB_CHARS}{0,64})?@)?` +
            `(?:` +
                //ip part
                `(?:${URL_REGEXP_PARTS.IP})` +
                //common domain
                `|(?:(?:${URL_REGEXP_PARTS.SUB_CHARS}{1,64}\\.){1,10}${URL_REGEXP_PARTS.SUB_CHARS}{2,}))` +
            `)`+
        `)` +
        `|(?:(?:${URL_REGEXP_PARTS.SUB_CHARS}{1,64}\\.){1,10}${URL_REGEXP_PARTS.TLDS})` +
    `)` +
    `(?!${URL_REGEXP_PARTS.SUB_CHARS}|\\.)` +
    `(?:${URL_REGEXP_PARTS.PORT})?` +
    `${URL_REGEXP_PARTS.PATH}` +
`)`

const ENTITIES_REGEXP = {
    [ENTITIES.MAIL]: /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+/g,
    [ENTITIES.PHONE]: /(^|\s)(?<matchBody>[\+]?[\d][ -\.]?[(]?(\d{3})?[)]?[ -\.]?\d{2,3}[ -\.]?((\d{4})|(\d{2}[ -\.]?\d{2}))(?!\S))/g,
    [ENTITIES.CONTACT]: /@\[(?<title>[^@]+?)\]\[contact\:(?<contactId>\d+)\]/g,
    [ENTITIES.BOT]: /@\[(?<title>[^@]+?)\]\[bot\:(?<botId>\d+)\]/g,
    [ENTITIES.CHANNEL]: /@\[(?<title>[^@]+?)\]\[channel\:(?<channelId>\d+)\]/g,
    [ENTITIES.PUBLICATION]: /@\[(?<title>[^@]+?)\]\[publication\:(?<channelId>\d+)\:(?<postId>\d+)\]/g,
    [ENTITIES.URL]: new RegExp(URLREGEXP, 'g'),
    [ENTITIES.TEXTURL]: /@\[(?<title>[^@]+?)\]\[url\:(?<url>[^\s\]]+)\]/g,
    [ENTITIES.BOTCOMMAND]: /(^|[^\S\/])(?<matchBody>(?<command>\/(?!\w+\/)(?:\w+)))/g,
    [ENTITIES.TEXTBOTCOMMAND]: /@\[(?<title>[^@]+?)\]\[command\:(?<command>\/[^\s\]]+)]/g,
    [ENTITIES.HASHTAG]: /(?:^|\s)(?<matchBody>(?:#)([a-zA-Z\u0400-\u04FF\d\-_]+))(?=\s|$)/gm
}

const FONT_STYLE_RE = {
    [ENTITIES.BOLD]: /(^|[^*])(?<matchBody>\*{2}[ \t]*(?=[^*])(?<text>([^*]|(.+?[^*])))[ \t]*\*{2}(?!\*))/g,
    [ENTITIES.ITALIC]: /(^|[^_])(?<matchBody>_{2}[ \t]*(?=[^_])(?<text>([^_]|(.+?[^_])))[ \t]*_{2}(?!_))/g,
    [ENTITIES.UNDERLINE]: /(^|[^+])(?<matchBody>\+{2}[ \t]*(?=[^+])(?<text>([^+]|(.+?[^+])))[ \t]*\+{2}(?!\+))/g,
    [ENTITIES.STRIKE]: /(^|[^~])(?<matchBody>~{2}[ \t]*(?=[^~])(?<text>([^~]|(.+?[^~])))[ \t]*~{2}(?!~))/g,
    [ENTITIES.MONOSPACE]: /(?<matchBody>\`{2}(?<text>([\s\S]*?))\`{2})/g,
    [ENTITIES.CODEBLOCK]: /(?<matchBody>\#{2}(?<text>([\s\S]*?))\#{2})/g,
}

const OLDREFS_RE = {
    [ENTITIES.CONTACT]: /@(?![\"\`(\<\/)]\S*)\[(?<contactId>\d+)\:(?<title>.+?[^(\]\[)].+?)\]/g,
    [ENTITIES.BOT]: /@\[bot\\(?<botId>\d+)\:(?<title>.+?[^(\]\[)].+?)\]/g,
    [ENTITIES.BOTCOMMAND]: /(^|[^\S\/])(?<matchBody>(?<command>\/(?!\w+\/)(?:\w+)))/g,
    [ENTITIES.CHANNEL]: /@(?![\"\`(\<\/)]\S*)\[channel\\(?<channelId>\d+)\:(?<title>.+?[^(\]\[)].+?)\]/g,
    [ENTITIES.PUBLICATION]: /@(?![\"\`(\<\/)]\S*)\[publication\\(?<channelId>\d+)\\(?<postId>\d+)\:(?<title>.+?[^(\]\[)].+?)\]/g,
}

const RE_VM_CONF_LINK = /\S*?\/service\/join\/\?confid=\S*/g
const RE_ROSCHAT_CONF_LINK = /\S*?\/join\/\?meetingid=\S*/g

const LINK_ENTITIES_TYPES = [
    ENTITIES.MAIL,
    ENTITIES.PHONE,
    ENTITIES.URL,
    ENTITIES.TEXTURL,
    ENTITIES.CONTACT,
    ENTITIES.BOT,
    ENTITIES.BOTCOMMAND,
    ENTITIES.CHANNEL,
    ENTITIES.PUBLICATION,
    ENTITIES.HASHTAG
]

const isLinkEntType = (type) => {
    return LINK_ENTITIES_TYPES.includes(type)
}

const getOpenTag = ({type, ...payload}, title) => {
    let text = ''
    switch (type) {
        case ENTITIES.BOLD:
        case ENTITIES.ITALIC:
        case ENTITIES.UNDERLINE:
        case ENTITIES.STRIKE: 
        case ENTITIES.MONOSPACE: 
        case ENTITIES.CODEBLOCK: {
            const enTag = ENTITY_TAGS.find(enTag => enTag.name === type)
            if (enTag) text = `${enTag.open}`
            break
        }
        case ENTITIES.HASHTAG: {
            text = `<a draggable="false" href="#" oncontextmenu="window.onHashtagContextMenu(event,'${title}')" onclick="window.onHashtagClick(event, '${title}')">`
            break
        }
        case ENTITIES.MAIL:
            text = `<a draggable="false" href="mailto:${title}" oncontextmenu="window.openEmailContext(event,'${title}')">`
            break
        case ENTITIES.PHONE:
            text = `<a draggable="false" href="#" oncontextmenu="window.openPhoneNumberContext(event,'${title}')" onclick="window.openPhoneNumberByLink(event, '${title}')">`
            break
        case ENTITIES.URL: {
            let modUrl0 = title
            const isProto0 = modUrl0.search(/^(?:https?:\/\/|ftps?:\/\/)/i)
            if (isProto0 === -1) modUrl0 = `https://${modUrl0}`
            const checkVmConfLink = modUrl0.match(RE_VM_CONF_LINK)
            const checkRoschatConfLink = modUrl0.match(RE_ROSCHAT_CONF_LINK)
            let type = ''
            let vcLink = ''
            if (checkVmConfLink) {
                type = CONF_TYPES.VM
                vcLink = checkVmConfLink[0]
                text = '<a draggable="false" href="#" oncontextmenu="window.openVcConfContext(event,\''+ type + '\', \''+ vcLink + '\')" onclick="window.openVcConfByLink(\''+ type + '\', \''+ vcLink + '\')">'
            } else if (checkRoschatConfLink) {
                type = CONF_TYPES.ROSCHAT
                vcLink = checkRoschatConfLink[0]
                text = '<a draggable="false" href="#" oncontextmenu="window.openVcConfContext(event,\''+ type + '\', \''+ vcLink + '\')" onclick="window.openVcConfByLink(\''+ type + '\', \''+ vcLink + '\')">'
            } else {
                text = `<a draggable="false" href="${modUrl0}" target="_blank" oncontextmenu="window.openLinkContext(event,'${modUrl0}')">`
            }
            break
        }
        case ENTITIES.TEXTURL:
            let modUrl = payload.url
            const isProto = modUrl.search(/^(?:https?:\/\/|ftps?:\/\/)/i)
            if (isProto === -1) modUrl = `https://${modUrl}`
            text = `<a draggable="false" href="${modUrl}" target="_blank" oncontextmenu="window.openLinkContext(event,'${modUrl}')">`
            break
        case ENTITIES.CONTACT: {
            const { contactId } = payload
            text = `<a draggable="false" href="#" onclick="window.onContactClick(event, 'title:${title}-contactId:${contactId}')" oncontextmenu="window.onContactContextMenu(event, 'title:${title}-contactId:${contactId}')">`
            break
        }
        case ENTITIES.BOT: {
            const {botId} = payload
            text = `<a draggable="false" href="#" onclick="window.openBot(event, 'title:${title}-botId:${botId}')">`
            break
        }
        case ENTITIES.BOTCOMMAND: {
            const {botCommand: command} = payload
            text = `<a class="botCommand" draggable="false" href="#" onclick="window.openBotCommand(event, '${command}')">`
            break
        }
        case ENTITIES.CHANNEL: {
            const {channelId} = payload
            text = `<a draggable="false" href="#" oncontextmenu="window.onChannelContextMenu(event, 'title:${title}-channelId:${channelId}')" onclick="window.openChannel(event, 'title:${title}-channelId:${channelId}')">`
            break
        }
        case ENTITIES.PUBLICATION: {
            const {channelId, postId} = payload
            text = `<a draggable="false" href="#" oncontextmenu="window.onPublicationContextMenu(event, 'title:${title}-channelId::PostId:${channelId}::${postId}')" onclick="window.openPublication(event, 'title:${title}-channelId::PostId:${channelId}::${postId}')">`
            break
        }
        default:
            break
    }
    return text
}

let getCloseTag = ({type}) => {
    let text = ''
    switch (type) {
        case ENTITIES.BOLD:
        case ENTITIES.ITALIC:
        case ENTITIES.UNDERLINE:
        case ENTITIES.STRIKE: 
        case ENTITIES.MONOSPACE: 
        case ENTITIES.CODEBLOCK: {
            const enTag = ENTITY_TAGS.find(enTag => enTag.name === type)
            if (enTag) text = `${enTag.close}`
            break
        }
        case ENTITIES.HASHTAG:
        case ENTITIES.MAIL:
        case ENTITIES.PHONE:
        case ENTITIES.URL:
        case ENTITIES.TEXTURL:
        case ENTITIES.CONTACT:
        case ENTITIES.BOT:
        case ENTITIES.BOTCOMMAND:
        case ENTITIES.CHANNEL:
        case ENTITIES.PUBLICATION:
            text = '</a>'
            break
    }
    return text
}

const getSortedEntitiesObj = (entities = []) => {
    return entities.reduce((prev, cur) => {
        const start = cur.offset
        const end = start + cur.length
        // убираем entities без длины
        if (!cur.length) return prev
        if (!prev[start]) prev[start] = {}
        if (!prev[end]) prev[end] = {}
        if (!prev[start]['start']) prev[start]['start'] = []
        if (!prev[end]['end']) prev[end]['end'] = []
        const isLink = LINK_ENTITIES_TYPES.includes(cur.type)
        if (isLink) {
            prev[start]['start'].unshift(cur)
            prev[end]['end'].push(cur)
        } else {
            prev[start]['start'].push(cur)
            prev[end]['end'].unshift(cur)
        }
        return prev
    }, {})
}

export const messageTextFormat = (entities, text) => {

    if (!(entities && Array.isArray(entities) && entities.length)) return text

    entities = normalizeEntities(entities)
    entities = R.clone(entities)

    const sortedEntities = getSortedEntitiesObj(entities)

    let newText = ''

    const currentEntities = []
    const indexes = Object.keys(sortedEntities).map((index) => +index)
    const firstIndex = indexes[0]
    const lastIndex = indexes[indexes.length - 1]

    if (firstIndex !== 0) newText += text.slice(0, firstIndex)

    for (let j = 0, i = indexes[j]; i <= lastIndex; i = indexes[++j]) {
        const startedEntities = sortedEntities[i]['start']
        const endedEntities = sortedEntities[i]['end']
        if (endedEntities) {
            let curStyleEntities = currentEntities.filter((cur) => !LINK_ENTITIES_TYPES.includes(cur.type))
            if (curStyleEntities.length) curStyleEntities.forEach((cur) => newText += getCloseTag(cur))
            endedEntities.forEach((cur) => {
                newText += getCloseTag(cur)
                currentEntities.splice(currentEntities.indexOf(cur), 1)
                const styleIndex = curStyleEntities.indexOf(cur)
                if (styleIndex >= 0) curStyleEntities.splice(styleIndex, 1)
            })
            if (curStyleEntities.length) curStyleEntities.forEach((cur) => newText += getOpenTag(cur))
            if (!startedEntities) newText += text.slice(i, (indexes[j + 1]))
        }

        if (startedEntities) {
            let curStyleEntities = currentEntities.filter((cur) => !LINK_ENTITIES_TYPES.includes(cur.type))
            if (curStyleEntities.length) curStyleEntities.forEach((cur) => newText += getCloseTag(cur))
            startedEntities.forEach((cur) => {
                newText += getOpenTag(cur, text.slice(cur.offset, cur.offset + cur.length))
                currentEntities.push(cur)
            })
            if (curStyleEntities.length) curStyleEntities.forEach((cur) => newText += getOpenTag(cur))
            newText += text.slice(i, (indexes[j + 1]))
        }
    }

    return newText
}

const getEntitieTextPart = (entity, startPart, rawText = '') => {
    let text = ''
    let entityText = rawText.slice(entity.offset, entity.offset + entity.length)
    switch (entity.type) {
        case ENTITIES.BOLD:
        case ENTITIES.ITALIC:
        case ENTITIES.UNDERLINE:
        case ENTITIES.STRIKE: 
        case ENTITIES.MONOSPACE: 
        case ENTITIES.CODEBLOCK:
        case ENTITIES.HASHTAG: {
            const enTag = ENTITY_TAGS.find(enTag => enTag.name === entity.type)
            if (enTag) text = `${enTag.rcs}`
            break
        }
        case ENTITIES.CONTACT: {
            if (startPart) {
                text = `@[`
            } else {
                text = `][contact:${entity.contactId}]`
            }
            break
        }
        case ENTITIES.TEXTURL: {
            if (entityText !== entity.url) {
                if (startPart) {
                    text = `@[`
                } else {
                    text = `][url:${entity.url}]`
                }
            }
            break
        }
        case ENTITIES.BOT: {
            if (startPart) {
                text = `@[`
            } else {
                text = `][bot:${entity.botId}]`
            }
            break
        }
        case ENTITIES.BOTCOMMAND: {
            if (entityText !== entity.botCommand) {
                if (startPart) {
                    text = `@[`
                } else {
                    text = `][command:${entity.botCommand}]`
                }
            }
            break
        }
        case ENTITIES.CHANNEL: {
            if (startPart) {
                text = `@[`
            } else {
                text = `][channel:${entity.channelId}]`
            }
            break
        }
        case ENTITIES.PUBLICATION: {
            if (startPart) {
                text = `@[`
            } else {
                text = `][publication:${entity.channelId}:${entity.postId}]`
            }
            break
        }
    }

    return text
}

export const applyInputTextFormat = (text, entities) => {

    if (!(entities && Array.isArray(entities) && entities.length)) return text

    let newText = ''

    const sortedEntities = getSortedEntitiesObj(entities)

    const indexes = Object.keys(sortedEntities).map((index) => +index)
    const firstIndex = indexes[0]
    const lastIndex = indexes[indexes.length - 1]

    if (firstIndex !== 0) newText += text.slice(0, firstIndex)

    for (let j = 0, i = indexes[j]; i <= lastIndex; i = indexes[++j]) {
        const startedEntities = sortedEntities[i]['start']
        const endedEntities = sortedEntities[i]['end']

        if (endedEntities) {
            endedEntities.forEach((cur) => {
                newText += getEntitieTextPart(cur, false, text)
            })
            if (!startedEntities) newText += text.slice(i, (indexes[j + 1]))
        }

        if (startedEntities) {
            startedEntities.forEach((cur) => {
                newText += getEntitieTextPart(cur, true, text)
            })
            newText += text.slice(i, (indexes[j + 1]))
        }
    }

    return newText
}

const formEntity = (type, matchStartIndex, fullMatchText, visiblePart = '', payload = {}) => {
    let offset
    let length
    if (visiblePart) {
        offset = matchStartIndex + fullMatchText.indexOf(visiblePart)
        length = visiblePart.length
    } else {
        offset = matchStartIndex
        length = fullMatchText.length
    }
    return {
        type,
        offset,
        length,
        matchStartIndex,
        fullMatchText,
        ...payload,
    }
}

export const extractInputTextFormat = (inText) => {
    inText = inText.replace(/([^\n])?(##.*?##)([^\n])?/g, (match, before, content, after) => {
        let prefix = before && before !== '\n' ? `${before}\n` : ''
        let suffix = after && after !== '\n' ? `\n${after}` : ''
        return `${prefix}${content}${suffix}`
    })
    let formatOutput = _extractInputTextFormat(inText, true)
    let { outText, entities } = _extractInputTextFormat(formatOutput.outText, false, formatOutput.entities)
    entities.forEach((entity) => {
        delete entity.matchStartIndex
        delete entity.fullMatchText
        delete entity.symbolsDeleted
    } )
    return { outText, entities }
}

export const _extractInputTextFormat = (inText, style = false, entities = []) => {
    // console.log("!!🚀 ~ file: textFormat.js:398 ~ extractInputTextFormat ~ inText:", inText)
    let outText = '', entity = {}
    const modEntities = []
    if (style) modEntities.push(...[...Object.entries(FONT_STYLE_RE), ...Object.entries(OLDREFS_RE)])
    else modEntities.push(...Object.entries(ENTITIES_REGEXP))
    const charArray = new Array(inText.length).fill(true)

    modEntities.forEach(([key, re]) => {
        const matches = [...(inText && inText.matchAll && inText.matchAll(re) || [])]
        if (!matches || !matches.length) return

        matches.forEach((match) => {
            const { title, text, matchBody, ...payload } = match.groups || {}
            let fullMatchText = match[0]
            let matchStartIndex = match.index
            let matchEndIndex = match.index + fullMatchText.length

            //@todo костыль для регулярки без lookbehind
            if (matchBody) {
                matchStartIndex = matchStartIndex + fullMatchText.indexOf(matchBody)
                matchEndIndex = matchStartIndex + matchBody.length
                fullMatchText = matchBody
            }

            switch (key) {
                case ENTITIES.URL:
                case ENTITIES.MAIL:
                case ENTITIES.PHONE:
                    entity = formEntity(key, matchStartIndex, fullMatchText)
                    break
                case ENTITIES.TEXTURL:
                    entity = formEntity(key, matchStartIndex, fullMatchText, title, payload)
                    break
                case ENTITIES.CONTACT:
                    payload.contactId = +payload.contactId
                    entity = formEntity(key, matchStartIndex, fullMatchText, title, payload)
                    break
                case ENTITIES.BOT:
                    payload.botId = +payload.botId
                    entity = formEntity(key, matchStartIndex, fullMatchText, title, payload)
                    break
                case ENTITIES.CHANNEL:
                    payload.channelId = +payload.channelId
                case ENTITIES.PUBLICATION:
                    payload.postId = +payload.postId
                    entity = formEntity(key, matchStartIndex, fullMatchText, title, payload)
                    break
                case ENTITIES.BOTCOMMAND:
                case ENTITIES.TEXTBOTCOMMAND:
                    payload.botCommand = payload.command
                    delete payload.command
                    entity = formEntity(ENTITIES.BOTCOMMAND, matchStartIndex, fullMatchText, title, payload)
                    break
                case ENTITIES.BOLD:
                case ENTITIES.ITALIC:
                case ENTITIES.UNDERLINE:
                case ENTITIES.STRIKE:
                case ENTITIES.HASHTAG:
                case ENTITIES.MONOSPACE:
                case ENTITIES.CODEBLOCK:
                    entity = formEntity(key, matchStartIndex, fullMatchText, text, payload)
                    break
                default:
                    console.warn(`unknown entity type - ${key}`)
                    return
            }

            entities.push(entity)
        })
    })

    let singleEntities = entities.filter(({type}) => {
        return [ENTITIES.CODEBLOCK].includes(type)
    })
    entities = entities.filter((entity) => {
        const entityStart = entity.offset
        const entityEnd = entityStart + entity.length
        const insideSingle = singleEntities.find((singleEntity) => {
            const singleEntityStart = singleEntity.offset
            const singleEntityEnd = singleEntityStart + singleEntity.length

            return (singleEntity !== entity && ((entityStart <= singleEntityStart && entityEnd > singleEntityStart)
                || (entityStart > singleEntityStart && entityStart <= singleEntityEnd)))
        })

        if (insideSingle) {
            return false
        } else {
            const offsetEnd = (entity.offset + entity.length - 1)
            const matchStartIndex = entity.matchStartIndex
            const matchEndIndex = matchStartIndex + entity.fullMatchText.length

            if (!entity.symbolsDeleted) {
                if (entity.offset !== matchStartIndex || matchEndIndex !== offsetEnd) {
                    for (let i = matchStartIndex; i < matchEndIndex; i++) {
                        if (i < entity.offset || i > offsetEnd) charArray[i] = false
                    }
                }
            }
            return true
        }
    })
    entities = normalizeEntities(entities)
    // console.log("!!🚀 ~ file: textFormat.js:480 ~ extractInputTextFormat ~ entities:", entities)

    entities.forEach((ent) => {
        if (!ent.symbolsDeleted) {
            let offset = ent.offset
            ent.offset = charArray.slice(0, offset).filter((val) => val).length
            ent.length = charArray.slice(offset, offset + ent.length).filter((val) => val).length
            ent.symbolsDeleted = true
        }
    })

    charArray.forEach((val, index) => {
        if (!val) return
        outText += inText[index]
    })

    return { outText, entities }
}

const normalizeEntities = (entities) => {
    for (let i = entities.length - 1;i >= 0; i--) {
        const ent = entities[i]
        let del = false
        if (!(ent && ent.constructor === Object && Object.keys(ent).length !== 0 && ent.length)) del = true
        if (!del) {
            if (!isLinkEntType(ent.type)) continue
            const entStart = ent.offset
            const entEnd = ent.offset + ent.length
            del = entities.some(ee => {
                if (ee === ent || !isLinkEntType(ee.type)) return false
                const eeStart = ee.offset
                const eeEnd = ee.offset + ee.length
                return (eeStart <= entStart && eeEnd >= entEnd)
            })
        }
        if (del) entities.splice(i, 1)
    }
    return entities
}

export const convertOldRefsToNewFormat = (text, makeLinks = false) => {
    let outText = text, entities = [], entity = {}, substPart, offset, deltaLength = 0, channelId
    Object.keys(OLDREFS_RE).forEach(key => {
        const re = OLDREFS_RE[key]
        const matches = [...outText.matchAll(re)]
        if (matches) {
            matches.forEach(match => {
                const replacedPart = match[0]
                const title = match.groups.title
                switch (key) {
                    case ENTITIES.CONTACT:
                        const contactId = match.groups.contactId
                        let linkContactPart = makeCustomALink('onContactContextMenu', 'onContactClick', { title, id: contactId })
                        if (makeLinks) outText = outText.replace(replacedPart, linkContactPart)
                        else outText = outText.replace(replacedPart, title)
                        entity = { type: key, offset: match.index, length: title.length, contactId }
                        break;
                    case ENTITIES.BOT:
                        const botId = match.groups.botId
                        let linkBotPart = makeCustomALink('onContactContextMenu', 'onContactClick', { title, id: botId })
                        if (makeLinks) outText = outText.replace(replacedPart, linkBotPart)
                        else outText = outText.replace(replacedPart, title)
                        entity = { type: key, offset: match.index, length: title.length, botId }
                        break;
                    case ENTITIES.BOTCOMMAND:
                        let testBotCommand = match
                        let botCommand = match.groups.command
                        if (!botCommand) break
                        let linkBotCommandPart = `<a draggable="false" href="#" onclick="window.openBotCommand(event, '${botCommand}')">${botCommand}</a>`
                        if (makeLinks) outText = outText.replace(replacedPart, linkBotCommandPart)
                        else outText = outText.replace(replacedPart, botCommand)
                        entity = { type: key, offset: match.index, length: botCommand.length, botCommand }
                        break;
                    case ENTITIES.CHANNEL:
                        let test = match
                        channelId = match.groups.channelId
                        let linkChannelPart = `<a draggable="false" href="#" oncontextmenu="window.onChannelContextMenu(event, 'title:${title}-channelId:${channelId}')" onclick="window.openChannel(event, 'title:${title}-channelId:${channelId}')">${title}</a>`
                        if (makeLinks) outText = outText.replace(replacedPart, linkChannelPart)
                        else outText = outText.replace(replacedPart, title)
                        entity = { type: key, offset: match.index, length: title.length, channelId }
                        break;
                    case ENTITIES.PUBLICATION:
                        channelId = match.groups.channelId
                        const postId = match.groups.postId
                        let linkPublicationPart = `<a draggable="false" href="#" oncontextmenu="window.onPublicationContextMenu(event, 'title:${title}-channelId::PostId:${channelId}::${postId}')" onclick="window.openPublication(event, 'title:${title}-channelId::PostId:${channelId}::${postId}')">${title}</a>`
                        if (makeLinks) outText = outText.replace(replacedPart, linkPublicationPart)
                        else outText = outText.replace(replacedPart, title)
                        entity = { type: key, offset: match.index, length: title.length, channelId, postId }
                        break;
                    default:
                        break;
                }
                entities.push(entity)
            })
        }
    })
    return { resultedText: outText, addEntities: entities }
}

const makeCustomALink = (onContextMenuName = '', onClickName = '', params) => {
    let link = ''
    if (onContextMenuName && onClickName) {
        link = `<a draggable="false" href="#" oncontextmenu="window.${onContextMenuName}(event, 'title:${params.title}-contactId:${params.id}')" onclick="window.${onClickName}(event, 'title:${params.title}-contactId:${params.id}')">${params.title}</a>`
    }
    else if (onContextMenuName) {
        link = `<a draggable="false" href="#" oncontextmenu="window.${onContextMenuName}(event, 'title:${params.title}-contactId:${params.id}')">${params.title}</a>`
    }
    else if (onClickName) {
        link = `<a draggable="false" href="#" onclick="window.${onClickName}(event, 'title:${params.title}-contactId:${params.id}')">${params.title}</a>`
    }
    return link
}

window.onHashtagClick = (e, hashtag) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('hashtagclick', { bubbles: true, detail: { event: e, hashtag } })
    e.target.dispatchEvent(event)
}

window.onHashtagContextMenu = (e, hashtag) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('hashtagcontextmenu', { bubbles: true, detail: { event: e, hashtag } })
    e.target.dispatchEvent(event)
}

window.onContactContextMenu = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    let params = paramsStr.split('-')
    let title = params[0].split(':')[1]
    let cid = +params[1].split(':')[1]
    const event = new CustomEvent('contactcontextmenu', { bubbles: true,  detail: { event: e, title, cid } })
    e.target.dispatchEvent(event)
}

window.onContactClick = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    let params = paramsStr.split('-')
    let title = params[0].split(':')[1]
    let contactId = +params[1].split(':')[1]
    const event = new CustomEvent('contactclick', { bubbles: true, detail: { event: e, title, contactId } })
    e.target.dispatchEvent(event)
}

window.openEmailContext2 = (e, email) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('emailcontextmenu', { 'bubbles': true, detail: { event: e, email } })
    e.target.dispatchEvent(event)
}

window.openBot = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    let params = paramsStr.split('-')
    let title = params[0].split(':')[1]
    let botId = +params[1].split(':')[1]
    const event = new CustomEvent('botclick', { bubbles: true, detail: { event: e, title, botId } })
    e.target.dispatchEvent(event)
}

window.openBotCommand = (e, command) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('botcommandclick', { bubbles: true, detail: { event: e, command } })
    e.target.dispatchEvent(event)
}

const getChTitleId = (paramsStr) => {
    const titleStr = 'title:', channelIdStr = '-channelId:'
    const channelIdStrIndex  = paramsStr.indexOf(channelIdStr)
    const title = paramsStr.substring(titleStr.length, channelIdStrIndex)
    const chId = paramsStr.substring(channelIdStrIndex + channelIdStr.length)
    return { title, chId }
}

const getTitleChPostStr = (paramsStr) => {
    const titleStr = 'title:', channelPubIdStr = '-channelId::PostId:'
    const channelPostIdStrIndex  = paramsStr.indexOf(channelPubIdStr)
    const title = paramsStr.substring(titleStr.length, channelPostIdStrIndex)
    const chPostIdStr = paramsStr.substring(channelPostIdStrIndex + channelPubIdStr.length)
    return { title, chPostIdStr }
}

window.onChannelContextMenu = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    const { title, chId } = getChTitleId(paramsStr)
    const event = new CustomEvent('channelcontextmenu', { bubbles: true,  detail: { event: e, title, chId } })
    e.target.dispatchEvent(event)
}

window.openChannel = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    const { title, chId } = getChTitleId(paramsStr)
    const event = new CustomEvent('openchannelevent', { bubbles: true, detail: { event: e, title, chId } })
    e.target.dispatchEvent(event)
}

window.onPublicationContextMenu = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    const { title, chPostIdStr } = getTitleChPostStr(paramsStr)
    let chId = +chPostIdStr.split('::')[0]
    let postId = +chPostIdStr.split('::')[1]
    const event = new CustomEvent('publicationcontextmenu', { bubbles: true, detail: { event: e, title, chId, postId } })
    e.target.dispatchEvent(event)
}

window.openPublication = (e, paramsStr) => {
    e.stopPropagation()
    e.preventDefault()
    const { title, chPostIdStr } = getTitleChPostStr(paramsStr)
    let chId = +chPostIdStr.split('::')[0]
    let postId = +chPostIdStr.split('::')[1]
    const event = new CustomEvent('openpublicationevent', { bubbles: true, detail: { event: e, title, channelId: chId, postId } })
    e.target.dispatchEvent(event)
}

window.openVcConfContext = (e, type, vcLink) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('vccontextmenu', { 'bubbles': true, detail: { event: e, type, vcLink } })
    e.target.dispatchEvent(event)
}

window.openVcConfByLink = (type, vcLink) => {
    const infoEl = document.getElementById('app')
    const vueInstance = infoEl.__vue__
    vueInstance.$bus.$emit('enter-conference', { type, vcLink })
}

window.openPhoneNumberContext = (e, number) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('phonenumbercontextmenu', { 'bubbles': true, detail: { event: e, number } })
    e.target.dispatchEvent(event)
}

window.openPhoneNumberByLink = (e, number) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('phonenumberlink', { 'bubbles': true, detail: { event: e, number } })
    e.target.dispatchEvent(event)
}