var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "info-wrapper",
      on: {
        click: _vm.infoWrapperClick,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.escKey.apply(null, arguments)
        },
      },
    },
    [
      _c("transition", { attrs: { name: _vm.transitionName } }, [
        _c(
          "div",
          { key: _vm.key, staticClass: "transition-wrapper" },
          [
            _c("info-header", {
              staticClass: "sections",
              attrs: { currentInfo: _vm.currentInfo, canBack: _vm.canBack },
            }),
            _c("info-body", {
              staticClass: "sections",
              attrs: { currentInfo: _vm.currentInfo, canBack: _vm.canBack },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }