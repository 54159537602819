// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i[data-v-5e203342] {
  color: var(--toolbtn-color);
}
i[data-v-5e203342]:hover {
  color: var(--toolbtn-color-hover);
}
.header-back[data-v-5e203342] {
  font-size: 19px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/header/MainHeader.vue"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,iCAAiC;AACnC;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["i[data-v-5e203342] {\n  color: var(--toolbtn-color);\n}\ni[data-v-5e203342]:hover {\n  color: var(--toolbtn-color-hover);\n}\n.header-back[data-v-5e203342] {\n  font-size: 19px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
