
    import ActivityBase from "./ActivityBase.vue"

    import ConfVm from "./ConfVm.vue"
    import ConfRoschat from "./ConfRoschat.vue"

    import { CONF_TYPES } from "../../../constants"

    export default {
        name: "ConfActivity",
        extends: ActivityBase,
        props: {
            conf: {
                type: Object,
                required: true
            }
        },
        computed: {
            component() {
                switch (this.type) {
                    case CONF_TYPES.VM:
                        return ConfVm
                    case CONF_TYPES.ROSCHAT:
                        return ConfRoschat
                }
            },
            bindProps() {
                return {
                    type: this.type,
                    id: this.id,
                }
            },
            type() {
                return this.conf.type
            },
            id() {
                return this.conf.id
            },
        }
    }
