// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-update {
  color: #ffffff;
  padding: 10px;
  position: relative;
  cursor: pointer;
}
.button-update .title {
  font-size: 16px;
  font-weight: bold;
}
.button-update .text span {
  text-decoration: underline;
}
.button-update .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #ffffff;
}
.button-update .close-btn:hover {
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/infoNotification/UpdateButton.vue"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,cAAc;AAChB;AACA;EACE,YAAY;AACd","sourcesContent":[".button-update {\n  color: #ffffff;\n  padding: 10px;\n  position: relative;\n  cursor: pointer;\n}\n.button-update .title {\n  font-size: 16px;\n  font-weight: bold;\n}\n.button-update .text span {\n  text-decoration: underline;\n}\n.button-update .close-btn {\n  position: absolute;\n  top: 5px;\n  right: 10px;\n  color: #ffffff;\n}\n.button-update .close-btn:hover {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
