
    import { mapActions } from "vuex"
    import { ASISTANTS } from "../../../store/modulesNames"
    import { ACT_TERMINATE_ASISTANT } from "../../../store/actionsTypes"

    import CallBase from "./CallBase.vue"

    export default {
        name: "MinimizedAssistant",
        extends: CallBase,
        methods: {
            endCall(){
                this[ACT_TERMINATE_ASISTANT](this.id)
            },
            ...mapActions(ASISTANTS, [ACT_TERMINATE_ASISTANT])
        }
    }
