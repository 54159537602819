// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-notification-bar[data-v-e0032394] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
  font-size: small;
  background-color: var(--list-item-bghover);
  border-top: 1px solid var(--border-bottom-section);
  border-bottom: 1px solid var(--border-bottom-section);
}
.side-notification-bar span[data-v-e0032394] {
  white-space: break-spaces;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/side/SideNotificationBar.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,0CAA0C;EAC1C,kDAAkD;EAClD,qDAAqD;AACvD;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".side-notification-bar[data-v-e0032394] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 18px;\n  font-size: small;\n  background-color: var(--list-item-bghover);\n  border-top: 1px solid var(--border-bottom-section);\n  border-bottom: 1px solid var(--border-bottom-section);\n}\n.side-notification-bar span[data-v-e0032394] {\n  white-space: break-spaces;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
