import axios from 'axios'
import ipc from '../../../../electron/ipc'
import moment from "moment"
import Logger from "../../../common/Logger"
const logger = new Logger('clientdata.main')

import {
    GET_UPDATE_SERVER_URL,
    GET_LOGIN_DATA,
    SHOW_NEW_VERSION_NOTIFY
} from "../../gettersTypes"

import {
    ACT_CHANGE_LOCALE,
    ACT_GET_NEW_CLIENT_INFO,
    ACT_UPDATE_CLIENT,
    ACT_CHECK_NEW_VERSION,
    ACT_UPDATE_NOTIFICATION,
} from '../../actionsTypes'

import {
    MUT_SET_UPDATE_STATUS,
    MUT_SET_NEW_CLIENT_INFO,
    MUT_INFO_NOTIFICATION,
    MUT_SET_LOCALE,
    MUT_SET_NEXT_NEW_CLIENT_REQUEST,
    MUT_SET_NEW_CLIENT_INFO_VIEWED,
    MUT_SET_TIMER_CHECK_UPDATE,
    MUT_SET_CLIENT_CHANGES,
} from '../../mutationsTypes'

import { CONTENT_MANAGER, USERDATA, LOGIN } from "../../modulesNames"

import { UPDATE_STATUSES } from "../../modules/clientdata"

import module from '../../modules/clientdata'

import APP_BUILD_ENV from '../../../buildEnv.js'

const state = {
    clientVersionFull: APP_BUILD_ENV.VERSION,
}

const MIN_NEW_VERSION_CHECK_INT = 24 * 60 * 60
const MAX_NEW_VERSION_CHECK_INT = 72 * 60 * 60

const actions = {
    async [ACT_GET_NEW_CLIENT_INFO]({ state, dispatch, commit, rootGetters }, timeout = false) {
        logger.info(`ACT_GET_NEW_CLIENT_INFO: timeout - ${timeout}`)
        if (state.updateStatus >= UPDATE_STATUSES.PENDING_INFO) return
        
        let newClientInfo
        commit(MUT_SET_UPDATE_STATUS, UPDATE_STATUSES.PENDING_INFO)
        // if (timeout) await ((m) => new Promise((resolve) => setTimeout(resolve, m)))(2000)
        if (timeout) await new Promise(resolve => setTimeout(resolve, 2000))
        let UPDATE_SERVER_URL = ''
        
        try {
            const login = rootGetters[`${LOGIN}/${GET_LOGIN_DATA}`].login
            UPDATE_SERVER_URL = rootGetters[`${USERDATA}/${GET_UPDATE_SERVER_URL}`]
            if (!UPDATE_SERVER_URL) throw new Error('update server is not set') //@todo speclink
            //if (!UPDATE_SERVER_URL) UPDATE_SERVER_URL = DEFAULT_UPDATE_URL
            let os = state.isAppImage ? 'appimage' : state.os
            let url = `${UPDATE_SERVER_URL}/get-client-last-version?version=${state.clientVersion}&os=${os}&arch=${state.clientArch}&email=${login}`
            logger.info(`ACT_GET_NEW_CLIENT_INFO: > url - ${url}`)
            let response = await axios.get(url)
            logger.info(`ACT_GET_NEW_CLIENT_INFO: < ${JSON.stringify(response)}`)
            newClientInfo = response && response.data
            if (newClientInfo) commit(MUT_SET_UPDATE_STATUS, UPDATE_STATUSES.HAVE_NEW_VERSION)
            commit(MUT_SET_NEW_CLIENT_INFO, newClientInfo)
        } catch (e) {
            commit(MUT_SET_UPDATE_STATUS, UPDATE_STATUSES.UP_TO_DATA)
            commit(MUT_SET_NEW_CLIENT_INFO, null)
            logger.info(`ACT_GET_NEW_CLIENT_INFO: < error ${e.message}`)
        }
        
        try {
            const changesResponse = await axios.get(`${UPDATE_SERVER_URL}/get-client-changes`)
            logger.info(`ACT_GET_NEW_CLIENT_INFO get-client-changes: < ${JSON.stringify(changesResponse)}`)
            const clientChanges = changesResponse && changesResponse.data || []
            commit(MUT_SET_CLIENT_CHANGES, clientChanges)
        } catch(e) {
            commit(MUT_SET_CLIENT_CHANGES, [])
            logger.info(`ACT_GET_NEW_CLIENT_INFO: < error ${e.message}`)
        }

        dispatch(ACT_UPDATE_NOTIFICATION)
        return newClientInfo
    },
    async [ACT_CHECK_NEW_VERSION]({ state, commit, dispatch }, force = false) {
        logger.info(`ACT_CHECK_NEW_VERSION: force - ${force}`)
        let currentTime = moment().unix()
        if (currentTime < state.nextNewClientRequest) {
            logger.info(`ACT_CHECK_NEW_VERSION: up-to-date`)
            dispatch(ACT_UPDATE_NOTIFICATION)
            if (!state.timerCheckUpdate) {
                let secDiff = state.nextNewClientRequest - currentTime
                commit(MUT_SET_TIMER_CHECK_UPDATE, setTimeout(() => dispatch(ACT_CHECK_NEW_VERSION), secDiff * 1000))
            }
            return
        }
        commit(MUT_SET_NEW_CLIENT_INFO_VIEWED, false)
        clearTimeout(state.timerCheckUpdate)
        commit(MUT_SET_TIMER_CHECK_UPDATE, 0)
        await dispatch(ACT_GET_NEW_CLIENT_INFO)

        let nextCheck = Math.random() * (MAX_NEW_VERSION_CHECK_INT - MIN_NEW_VERSION_CHECK_INT) + MIN_NEW_VERSION_CHECK_INT
        nextCheck = Math.floor(nextCheck)
        let nextCheckUnix = moment().unix() + nextCheck
        logger.info(`ACT_CHECK_NEW_VERSION: set next request at ${moment.unix(nextCheckUnix).format('YYYY-MM-DD HH:mm:ss')}`)
        commit(MUT_SET_NEXT_NEW_CLIENT_REQUEST, nextCheckUnix)
        commit(MUT_SET_TIMER_CHECK_UPDATE, setTimeout(() => dispatch(ACT_CHECK_NEW_VERSION), nextCheck * 1000))
    },
    [ACT_UPDATE_NOTIFICATION]({ commit, getters }) {
        let notifyVal
        if (getters[SHOW_NEW_VERSION_NOTIFY]) {
            logger.info(`ACT_UPDATE_NOTIFICATION: show notification`)
            notifyVal = 'update-button'
        } else {
            logger.info(`ACT_UPDATE_NOTIFICATION: hide notification`)
            notifyVal = null
        }
        commit(`${CONTENT_MANAGER}/${MUT_INFO_NOTIFICATION}`, notifyVal, { root: true })
    },
    async [ACT_UPDATE_CLIENT]({state, commit}) {
        if (state.updateStatus >= UPDATE_STATUSES.PENDING_INFO) return;
        commit(MUT_SET_UPDATE_STATUS, UPDATE_STATUSES.DOWNLOADING);
        if (ipc) ipc.send("clientdata/updateClient", state.newClientInfo.link);
    },
    [ACT_CHANGE_LOCALE]({ state, commit }, locale) {
        commit(MUT_SET_LOCALE, locale);
        ipc && ipc.send("locale-changed", locale);
        if (!state.isElectron) window.location.reload();
    },
    actSetIsAppImage({commit}, value) {
        commit('mutSetIsAppImage', value)
    }
}

Object.assign(module.state, state)
Object.assign(module.actions, actions)

export default module
