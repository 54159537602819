// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-btn[data-v-a27ebad2] {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.action-btn i[data-v-a27ebad2] {
  color: white;
  font-size: 26px;
}
.action-btn .fa-rotate[data-v-a27ebad2] {
  transform: rotate(225deg);
}
.color-green[data-v-a27ebad2] {
  background-color: var(--elem-green-color);
}
.color-red[data-v-a27ebad2] {
  background-color: var(--elem-red-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/PhoneActionButton.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,uCAAuC;AACzC","sourcesContent":[".action-btn[data-v-a27ebad2] {\n  height: 70px;\n  width: 70px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.action-btn i[data-v-a27ebad2] {\n  color: white;\n  font-size: 26px;\n}\n.action-btn .fa-rotate[data-v-a27ebad2] {\n  transform: rotate(225deg);\n}\n.color-green[data-v-a27ebad2] {\n  background-color: var(--elem-green-color);\n}\n.color-red[data-v-a27ebad2] {\n  background-color: var(--elem-red-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
