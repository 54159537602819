var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-info" },
    [
      _c(
        "info-main-block",
        {
          attrs: {
            "icon-photo": _vm.icon,
            "icon-text": _vm.name,
            "common-text": _vm.name,
            edit: _vm.isEditable,
            "icon-viewer": !!_vm.icon,
            "icon-type": "chat",
            "icon-id": _vm.cid,
          },
          on: { edit: _vm.doEditGroupChat },
        },
        [
          _vm.serverDisplayName
            ? _c(
                "div",
                {
                  staticClass: "additional-text",
                  attrs: { slot: "after-common-text" },
                  slot: "after-common-text",
                },
                [_c("p", [_vm._v(_vm._s(_vm.serverDisplayName))])]
              )
            : _vm._e(),
        ]
      ),
      !_vm.isCanBack
        ? _c(
            "actions-container",
            [
              _c("chat-mute-switch", {
                attrs: { slot: "action", cid: _vm.cid, cidType: _vm.cidType },
                slot: "action",
              }),
              _c("chat-pin-switch", {
                attrs: { slot: "action", cid: _vm.cid, cidType: _vm.cidType },
                slot: "action",
              }),
              _vm.isAdmin
                ? _c(
                    "list-item",
                    {
                      attrs: {
                        slot: "action",
                        title: _vm.$t("chat-read-only"),
                        link: true,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.doChangeReadOnly.apply(null, arguments)
                        },
                      },
                      slot: "action",
                    },
                    [
                      _c("span", { attrs: { slot: "after" }, slot: "after" }, [
                        _vm._v("(" + _vm._s(_vm.readOnlyText) + ")"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              attrs: {
                slot: "action",
                title: _vm.$t("information.members"),
                link: true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.doOpenMembersInfo.apply(null, arguments)
                },
              },
              slot: "action",
            },
            [
              _c("span", { attrs: { slot: "after" }, slot: "after" }, [
                _vm._v("(" + _vm._s(_vm.all_members.length) + ")"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("media-search-info-items", {
        on: { opensearchmedia: _vm.openSearchMedia },
      }),
      !_vm.isCanBack && _vm.isChatMember
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.leave-chat"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doExitFromChat.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-sign-out",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isCanBack
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.delete-chat"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doDeleteChat.apply(null, arguments)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-trash-alt",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }