var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "central hotkeys" },
    [
      _c(
        "main-content-container",
        {
          staticClass: "global",
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    _vm._l(_vm.globalItems, function (item, i) {
                      return _c("list-item", {
                        key: i,
                        attrs: {
                          title: _vm.getTitle(item.id, true),
                          after: item.hotKeysStr,
                          link: true,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showModal(item)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(" " + _vm._s(_vm.$t("hotkeys.global"))),
          ]),
        ],
        2
      ),
      _c(
        "main-content-container",
        {
          staticClass: "local",
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    _vm._l(_vm.localItems, function (item, i) {
                      return _c("list-item", {
                        key: i,
                        attrs: {
                          title: _vm.getTitle(item.id, false),
                          after: item.hotKeysStr,
                          link: true,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showModal(item)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("hotkeys.local"))),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }