
    import { mapActions } from 'vuex'
    import SelectChat from "./SelectChat.vue"
    import {ACT_SEND_MESSAGE} from "../../store/actionsTypes"
    import {CHATS} from "../../store/modulesNames"

    export default {
        name: "SelectChatToForwardMessages",
        extends: SelectChat,
        props: {
            msgs: {
                type: Array,
                required: true,
            },           
        },
        data: () => {
            return {
                isSending: false,
            }
        },                
        computed: {
            multiple: () => true,
        },
        methods: {
            async onOk(chats) {
                if (this.isSending) return
                this.isSending = true
                this.modalClose()
                const msgs = this.props.msgs
                for (let index = 0; index < msgs.length; index++) {
                    const msg = msgs[index]
                    const data = msg.data
                    await Promise.all(chats.map(async ({cid, cidType}) => {
                            let msgToSent = (!this.msg) ? this.buildMsg(cid, cidType, data) : {...msg, ...{cid, cidType}}
                            msgToSent.author = msg.author ? msg.author : msg.senderId
                            return msgToSent ? this[ACT_SEND_MESSAGE](msgToSent) : undefined
                        })
                    )
                }
                this.$nextTick(() => {
                    this.btnOkCb && this.btnOkCb()
                    this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
                    this.isSending = false
                })
            },
            buildMsg(cid, cidType, data) {
                const dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
                if (data && data.constructor === Object) data = JSON.stringify(data)
                return {cidType, cid, data, dataType}
            },
            ...mapActions(CHATS, [ACT_SEND_MESSAGE])
        }
    }
