// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper[data-v-faf610b4] {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.search-wrapper .search-filter[data-v-faf610b4] {
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 20px;
  width: fit-content;
  margin-left: 2px;
  padding-left: 5px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.5em;
}
.search-wrapper .search-filter[data-v-faf610b4]:hover {
  background-color: var(--bg-color-hover);
}
.search-wrapper .search-filter .filter-btn[data-v-faf610b4] {
  padding-left: 0.5em;
  padding-right: 1em;
}
.search-wrapper .search-filter .title[data-v-faf610b4] {
  padding-right: 0.5em;
}
.search-wrapper .select-contact[data-v-faf610b4] {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SelectContactBody.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;AACtB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".search-wrapper[data-v-faf610b4] {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n.search-wrapper .search-filter[data-v-faf610b4] {\n  display: flex;\n  align-items: center;\n  padding: 0.5em;\n  border-radius: 20px;\n  width: fit-content;\n  margin-left: 2px;\n  padding-left: 5px;\n  padding-top: 0.5em;\n  padding-bottom: 0.5em;\n  padding-right: 0.5em;\n}\n.search-wrapper .search-filter[data-v-faf610b4]:hover {\n  background-color: var(--bg-color-hover);\n}\n.search-wrapper .search-filter .filter-btn[data-v-faf610b4] {\n  padding-left: 0.5em;\n  padding-right: 1em;\n}\n.search-wrapper .search-filter .title[data-v-faf610b4] {\n  padding-right: 0.5em;\n}\n.search-wrapper .select-contact[data-v-faf610b4] {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 0px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
