var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-select-wrapper",
      class: { up: _vm.up, down: !_vm.up, required: _vm.input_required },
      attrs: { "data-label": _vm.label },
    },
    [
      _vm.isPlaceHolderShown
        ? _c("label", { attrs: { for: "custom_select" } }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected_option,
              expression: "selected_option",
            },
          ],
          staticClass: "custom-select",
          class: { up: !!_vm.selected_option },
          attrs: { name: "custom_select", disabled: _vm.isDisabled },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selected_option = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.doSelect,
            ],
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c("option", { domProps: { value: option.value } }, [
            _vm._v(_vm._s(option.name)),
          ])
        }),
        0
      ),
      _c(
        "svg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.selected_option || !_vm.showClearSign,
              expression: "!selected_option || !showClearSign",
            },
          ],
          staticClass: "arrow",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "8",
            height: "8",
            viewBox: "0 0 8 8",
          },
        },
        [
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              fill: "#777",
              "fill-rule": "evenodd",
              d: "M8,0L4.141,6.993,0.012,0.156Z",
            },
          }),
        ]
      ),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.selected_option && _vm.showClearSign,
            expression: "!!selected_option && showClearSign",
          },
        ],
        staticClass: "clear fal fa-times",
        class: { up: !_vm.selected_option },
        on: { click: _vm.doClear },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }