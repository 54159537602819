// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  height: 100%;
  position: relative;
}
.main-body {
  overflow-x: auto;
}
.main-body.central {
  margin: auto;
  display: initial;
  padding: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/MainContent.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".main-content {\n  height: 100%;\n  position: relative;\n}\n.main-body {\n  overflow-x: auto;\n}\n.main-body.central {\n  margin: auto;\n  display: initial;\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
