// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-edition[data-v-f77ab938] {
  width: 100%;
}
.contact-edition .footer[data-v-f77ab938] {
  display: flex;
  justify-content: space-between;
}
.contact-edition .footer .footer-btns[data-v-f77ab938] {
  display: flex;
}
.contact-edition .footer .footer-btns .btn[data-v-f77ab938] {
  display: flex;
  justify-content: flex-end;
}
.contact-edition .footer i.fas[data-v-f77ab938] {
  font-size: 16px;
  font-style: normal;
  line-height: inherit;
  color: #2688c2;
  margin-right: auto;
  padding-top: 10px;
  cursor: pointer;
}
.active-element[data-v-f77ab938] {
  transition: 0.5s;
}
.inactive-element[data-v-f77ab938] {
  transition: 0.5s;
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/SelectContactInfoExport.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".contact-edition[data-v-f77ab938] {\n  width: 100%;\n}\n.contact-edition .footer[data-v-f77ab938] {\n  display: flex;\n  justify-content: space-between;\n}\n.contact-edition .footer .footer-btns[data-v-f77ab938] {\n  display: flex;\n}\n.contact-edition .footer .footer-btns .btn[data-v-f77ab938] {\n  display: flex;\n  justify-content: flex-end;\n}\n.contact-edition .footer i.fas[data-v-f77ab938] {\n  font-size: 16px;\n  font-style: normal;\n  line-height: inherit;\n  color: #2688c2;\n  margin-right: auto;\n  padding-top: 10px;\n  cursor: pointer;\n}\n.active-element[data-v-f77ab938] {\n  transition: 0.5s;\n}\n.inactive-element[data-v-f77ab938] {\n  transition: 0.5s;\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
