let originAddEventListener = HTMLElement.prototype.addEventListener;
let originRemoveEventListener = HTMLElement.prototype.removeEventListener;
const wrappedEvents = new Map()
const clickBlockMap = new WeakMap()

const addWrappedFunction = (event, element, cb, cbOrigin) => {
    if (!wrappedEvents.has(event)) wrappedEvents.set(event, new WeakMap())
    let eventMap = wrappedEvents.get(event)
    if (!eventMap.has(element)) eventMap.set(element, new WeakMap())
    let cbMap = eventMap.get(element)
    cbMap.set(cbOrigin, cb)
}

const getWrappedFunction = (event, element, cbOrigin) => {
    if (!wrappedEvents.has(event)) return
    let eventMap = wrappedEvents.get(event)
    if (!eventMap.has(element)) return
    let cbMap = eventMap.get(element)
    return cbMap.get(cbOrigin)
}

const addClickBlock = (element, cb) => {
    if (!clickBlockMap.has(element)) clickBlockMap.set(element, new WeakSet())
    let elementSet = clickBlockMap.get(element)
    if (!elementSet.has(cb)) elementSet.add(cb)
}

const isClickBlocked = (element, cb) => {
    if (!clickBlockMap.has(element)) return false
    return clickBlockMap.get(element).has(cb)
}

const removeClickBlock = (element, cb) => {
    if (!clickBlockMap.has(element)) return false
    clickBlockMap.get(element).delete(cb)
}

EventTarget.prototype.removeEventListener = function (event, cb, ...args) {
    let scope = this
    let wrappedFunction = getWrappedFunction(event, scope, cb)
    if (wrappedFunction) {
        originRemoveEventListener.apply(this, [event, wrappedFunction, ...args])
    } else {
        originRemoveEventListener.apply(this, arguments)
    }
}

EventTarget.prototype.addEventListener = function (event, cb, ...args) {
    let element = this
    if (event === 'click') {
        let clickCb = function (e) {
                let target = e.target
                if (target && target.classList && !target.classList.contains('counter')) {
                    if (isClickBlocked(target, clickCb)) {
                        e.stopPropagation()
                        e.preventDefault()
                        return
                    } else {
                        addClickBlock(target, clickCb)
                        setTimeout(() => {
                            //eventsTest.delete(e)
                            removeClickBlock(target, clickCb)
                        }, 500)
                    }
                }
            cb.apply(this, [e])
        }
        // if (e.target.className === 'fal fa-plus counter' || e.target.className === 'fal fa-minus counter') return
        addWrappedFunction('click', element, clickCb, cb)
        originAddEventListener.apply(this, [event, clickCb, ...args])
    } else {
        originAddEventListener.apply(element, [event, cb, ...args])
    }
}