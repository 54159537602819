// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.func-button[data-v-7249297b] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  margin: auto;
}
.func-button.long[data-v-7249297b] {
  width: 110px;
  margin: 0 auto auto auto;
}
.func-button .func-button-icon[data-v-7249297b] {
  height: 50px;
  width: 50px;
  background-color: var(--func-btn-bgcolor);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.func-button .func-button-icon.func-button-active[data-v-7249297b] {
  background-color: var(--func-btn-bgactive);
}
.func-button .func-button-icon.func-button-active i[data-v-7249297b] {
  color: var(--func-btn-bgcolor);
}
.func-button .func-button-icon i[data-v-7249297b] {
  color: var(--func-btn-bgactive);
  font-size: 17px;
  font-weight: 400;
}
.func-button .func-button-icon svg[data-v-7249297b] {
  fill: var(--func-btn-bgactive);
}
.func-button .func-button-name[data-v-7249297b] {
  color: var(--func-btn-bgactive);
  font-size: 11px;
  padding-top: 3px;
}
.func-button-disable[data-v-7249297b] {
  opacity: 0.35;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/PhoneFunctionButton.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,YAAY;EACZ,WAAW;EACX,yCAAyC;EACzC,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,8BAA8B;AAChC;AACA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;AACf","sourcesContent":[".func-button[data-v-7249297b] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 75px;\n  padding: 5px;\n  cursor: pointer;\n  text-align: center;\n  margin: auto;\n}\n.func-button.long[data-v-7249297b] {\n  width: 110px;\n  margin: 0 auto auto auto;\n}\n.func-button .func-button-icon[data-v-7249297b] {\n  height: 50px;\n  width: 50px;\n  background-color: var(--func-btn-bgcolor);\n  border-radius: 25px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.func-button .func-button-icon.func-button-active[data-v-7249297b] {\n  background-color: var(--func-btn-bgactive);\n}\n.func-button .func-button-icon.func-button-active i[data-v-7249297b] {\n  color: var(--func-btn-bgcolor);\n}\n.func-button .func-button-icon i[data-v-7249297b] {\n  color: var(--func-btn-bgactive);\n  font-size: 17px;\n  font-weight: 400;\n}\n.func-button .func-button-icon svg[data-v-7249297b] {\n  fill: var(--func-btn-bgactive);\n}\n.func-button .func-button-name[data-v-7249297b] {\n  color: var(--func-btn-bgactive);\n  font-size: 11px;\n  padding-top: 3px;\n}\n.func-button-disable[data-v-7249297b] {\n  opacity: 0.35;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
