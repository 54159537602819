// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper[data-v-93e77524] {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.wrapper > *[data-v-93e77524] {
  margin-bottom: 15px;
}
.wrapper .settings-group .group-header[data-v-93e77524] {
  color: gray;
  margin-bottom: 15px;
  font-size: 16px;
}
.wrapper .settings-group .group-properties[data-v-93e77524] {
  display: flex;
  flex-direction: column;
}
.wrapper .settings-group .group-properties .property.sound[data-v-93e77524] {
  display: flex;
  flex-direction: row;
}
.wrapper .settings-group .group-properties .property.sound > *[data-v-93e77524] {
  margin: auto 0;
}
.wrapper .settings-group .group-properties .property.sound > *[data-v-93e77524]:last-child {
  margin-left: auto;
  margin-right: 18px;
}
.wrapper .settings-group .group-properties .property.sound span[data-v-93e77524] {
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/RadioSettings.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".wrapper[data-v-93e77524] {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n.wrapper > *[data-v-93e77524] {\n  margin-bottom: 15px;\n}\n.wrapper .settings-group .group-header[data-v-93e77524] {\n  color: gray;\n  margin-bottom: 15px;\n  font-size: 16px;\n}\n.wrapper .settings-group .group-properties[data-v-93e77524] {\n  display: flex;\n  flex-direction: column;\n}\n.wrapper .settings-group .group-properties .property.sound[data-v-93e77524] {\n  display: flex;\n  flex-direction: row;\n}\n.wrapper .settings-group .group-properties .property.sound > *[data-v-93e77524] {\n  margin: auto 0;\n}\n.wrapper .settings-group .group-properties .property.sound > *[data-v-93e77524]:last-child {\n  margin-left: auto;\n  margin-right: 18px;\n}\n.wrapper .settings-group .group-properties .property.sound span[data-v-93e77524] {\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
