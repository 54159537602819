var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "side",
      class: { "side-show-info-notification": _vm.infoNotificationShow },
      style: _vm.extraStyles,
    },
    [
      _c("search-items"),
      _c("toolbar"),
      _c("side-content"),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.infoNotificationShow
            ? _c("info-notification", {
                attrs: { component: _vm.infoNotificationShow },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showDialer ? _c("dialer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }