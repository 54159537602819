// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info[data-v-943395ce] {
  height: 100%;
}
.info-content .info-transition[data-v-943395ce] {
  padding: 0;
}
.info-content .info-transition[data-v-943395ce] > :not(:first-child) {
  color: gray;
}
.extra-big[data-v-943395ce]:before {
  font-size: 53px;
  top: 48px;
}
.info-fade-enter-active[data-v-943395ce],
.info-fade-leave-active[data-v-943395ce] {
  transition: width 0.5s;
}
.info-fade-enter[data-v-943395ce],
.info-fade-leave-to[data-v-943395ce] {
  width: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/Info.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,SAAS;AACX;AACA;;EAEE,sBAAsB;AACxB;AACA;;EAEE,mBAAmB;AACrB","sourcesContent":[".info[data-v-943395ce] {\n  height: 100%;\n}\n.info-content .info-transition[data-v-943395ce] {\n  padding: 0;\n}\n.info-content .info-transition[data-v-943395ce] > :not(:first-child) {\n  color: gray;\n}\n.extra-big[data-v-943395ce]:before {\n  font-size: 53px;\n  top: 48px;\n}\n.info-fade-enter-active[data-v-943395ce],\n.info-fade-leave-active[data-v-943395ce] {\n  transition: width 0.5s;\n}\n.info-fade-enter[data-v-943395ce],\n.info-fade-leave-to[data-v-943395ce] {\n  width: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
