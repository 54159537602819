// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-create[data-v-3f493714] {
  padding: 0 15px;
  font-size: 16px;
  width: 100%;
}
.channel-create > *[data-v-3f493714] {
  display: flex;
  margin: 20px 10px;
}
.channel-create .icon-wrapper[data-v-3f493714] {
  display: flex;
  justify-content: center;
}
.channel-create .icon-label[data-v-3f493714] {
  margin: 20px auto auto auto;
}
.channel-create .first-line[data-v-3f493714] {
  flex-direction: column;
}
.channel-create input[data-v-3f493714] {
  font-size: 16px;
}
.channel-create label[data-v-3f493714] {
  font-weight: normal;
}
.channel-create .info[data-v-3f493714] {
  min-height: 3.6em;
  max-height: 6.6em;
  overflow-y: auto;
}
.channel-create .non-border-input[data-v-3f493714] {
  width: 100%;
}
.channel-create .div-input[data-v-3f493714] {
  height: 30px;
  display: flex;
  align-items: end;
}
.channel-create .form-group[data-v-3f493714] {
  display: flex;
  flex-direction: column;
}
.channel-create .icon-label-text[data-v-3f493714],
.channel-create .type-label-text[data-v-3f493714] {
  color: gray;
}
.channel-create .type-label-text[data-v-3f493714] {
  margin-bottom: 15px;
}
.channel-create .description[data-v-3f493714] {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ChannelEdit.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb;AACA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;;EAEE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".channel-create[data-v-3f493714] {\n  padding: 0 15px;\n  font-size: 16px;\n  width: 100%;\n}\n.channel-create > *[data-v-3f493714] {\n  display: flex;\n  margin: 20px 10px;\n}\n.channel-create .icon-wrapper[data-v-3f493714] {\n  display: flex;\n  justify-content: center;\n}\n.channel-create .icon-label[data-v-3f493714] {\n  margin: 20px auto auto auto;\n}\n.channel-create .first-line[data-v-3f493714] {\n  flex-direction: column;\n}\n.channel-create input[data-v-3f493714] {\n  font-size: 16px;\n}\n.channel-create label[data-v-3f493714] {\n  font-weight: normal;\n}\n.channel-create .info[data-v-3f493714] {\n  min-height: 3.6em;\n  max-height: 6.6em;\n  overflow-y: auto;\n}\n.channel-create .non-border-input[data-v-3f493714] {\n  width: 100%;\n}\n.channel-create .div-input[data-v-3f493714] {\n  height: 30px;\n  display: flex;\n  align-items: end;\n}\n.channel-create .form-group[data-v-3f493714] {\n  display: flex;\n  flex-direction: column;\n}\n.channel-create .icon-label-text[data-v-3f493714],\n.channel-create .type-label-text[data-v-3f493714] {\n  color: gray;\n}\n.channel-create .type-label-text[data-v-3f493714] {\n  margin-bottom: 15px;\n}\n.channel-create .description[data-v-3f493714] {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
