// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-notification {
  position: absolute;
  bottom: -100px;
  left: 10px;
  height: 90px;
  width: 330px;
  background-color: var(--toolbtn-active-color);
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.35);
  transition: 0.4s all;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/infoNotification/InfoNotification.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,6CAA6C;EAC7C,mBAAmB;EACnB,kDAAkD;EAClD,oBAAoB;AACtB","sourcesContent":[".info-notification {\n  position: absolute;\n  bottom: -100px;\n  left: 10px;\n  height: 90px;\n  width: 330px;\n  background-color: var(--toolbtn-active-color);\n  border-radius: 10px;\n  box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.35);\n  transition: 0.4s all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
