// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-btns[data-v-ee1d356c] {
  display: flex;
  width: 75%;
  margin: 0 auto;
}
.radio-btns .radio-btn[data-v-ee1d356c] {
  margin: 0 auto;
  display: flex;
  border-radius: 100%;
  background-color: var(--icon-btn-with-bg);
  cursor: pointer;
}
.radio-btns .radio-btn:hover i[data-v-ee1d356c] {
  opacity: 0.7;
}
.radio-btns .radio-btn[data-v-ee1d356c]:first-child {
  margin-left: 0;
}
.radio-btns .radio-btn[data-v-ee1d356c]:last-child {
  margin-right: 0;
}
.radio-btns .radio-btn i[data-v-ee1d356c] {
  color: gray;
  margin: auto;
}
.radio-btns .radio-btn i[data-count][data-v-ee1d356c]::after {
  content: attr(data-count);
  position: absolute;
  top: 8px;
  left: 50px;
  transform: translate(-108%, -65%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 12px;
  height: 20px;
  width: 20px;
  padding-right: 2px;
  border-radius: 13px;
  background: var(--badge-red-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/radio/RadioBtns.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,cAAc;AAChB;AACA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,yCAAyC;EACzC,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,kCAAkC;EAClC,2DAA2D;EAC3D,mBAAmB;AACrB","sourcesContent":[".radio-btns[data-v-ee1d356c] {\n  display: flex;\n  width: 75%;\n  margin: 0 auto;\n}\n.radio-btns .radio-btn[data-v-ee1d356c] {\n  margin: 0 auto;\n  display: flex;\n  border-radius: 100%;\n  background-color: var(--icon-btn-with-bg);\n  cursor: pointer;\n}\n.radio-btns .radio-btn:hover i[data-v-ee1d356c] {\n  opacity: 0.7;\n}\n.radio-btns .radio-btn[data-v-ee1d356c]:first-child {\n  margin-left: 0;\n}\n.radio-btns .radio-btn[data-v-ee1d356c]:last-child {\n  margin-right: 0;\n}\n.radio-btns .radio-btn i[data-v-ee1d356c] {\n  color: gray;\n  margin: auto;\n}\n.radio-btns .radio-btn i[data-count][data-v-ee1d356c]::after {\n  content: attr(data-count);\n  position: absolute;\n  top: 8px;\n  left: 50px;\n  transform: translate(-108%, -65%);\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  color: white;\n  font-size: 12px;\n  height: 20px;\n  width: 20px;\n  padding-right: 2px;\n  border-radius: 13px;\n  background: var(--badge-red-color);\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
