
    import PhoneAudio from "./PhoneAudio.vue"
    import {PHONE_TYPES, ASSISTANT_TYPES} from "../../constants"
    import {PHONE_BTN_TYPES} from "./PhoneFunctionButton.vue"
    import {
        GET_ACTIVE_CALL,
        GET_CALLS,
        GET_MICROPHONE,
        GET_SPEAKER,
        GET_TYPE_ASISTANT,
        GET_CALL_TALK_TIME,
    } from "../../store/gettersTypes"
    import {mapActions, mapGetters} from "vuex"
    import {ASISTANTS, PHONE_CNTL} from "../../store/modulesNames"
    import {
        ACT_CONFERENCE,
        ACT_PRESS_DIAL_KEY,
        ACT_SET_ACTIVE_CALL,
        ACT_SET_CALL_STATUS,
        ACT_TERMINATE_ASISTANT,
        ACT_TOGGLE_MICROPHONE,
        ACT_TOGGLE_SPEAKER,
        ACT_TRANSIT
    } from "../../store/actionsTypes"

    export default {
        name: "PhoneWebrtcAudio",
        extends: PhoneAudio,
        data() {
            let base = [PHONE_BTN_TYPES.BUTTONS, PHONE_BTN_TYPES.MSG, PHONE_BTN_TYPES.TRANSIT, PHONE_BTN_TYPES.PARTICIPANTS]
            let pult = [PHONE_BTN_TYPES.MICROPHONE, PHONE_BTN_TYPES.SPEAKER, PHONE_BTN_TYPES.CONFERENCE, ...base]
            let analog = [PHONE_BTN_TYPES.MICROPHONE, PHONE_BTN_TYPES.CONFERENCE, ...base]
            return {
                supportedFuncBtnsByType: {
                    [ASSISTANT_TYPES.SIP_BOOSTER]: base,
                    [ASSISTANT_TYPES.DX_BOOSTER_PULT]: pult,
                    [ASSISTANT_TYPES.DX_BOOSTER_ANALOG]: analog,
                    [ASSISTANT_TYPES.MX_BOOSTER_PULT]: pult,
                    [ASSISTANT_TYPES.MX_BOOSTER_ANALOG]: analog
                }
            }
        },
        computed: {
            // *** Переопределение start *** //
            type() {
                return PHONE_TYPES.ASSISTANT
            },
            id() {
                return this.activeCall && this.activeCall.id
            },
            number() {
                return this.activeCall && this.activeCall.digits
            },
            btnsDisabled() {
                return !this.activeCall
            },
            btns() {
                let btns = [PHONE_BTN_TYPES.SPEAKER, PHONE_BTN_TYPES.MICROPHONE, PHONE_BTN_TYPES.MSG]
                if (this.isConf) btns.unshift(PHONE_BTN_TYPES.PARTICIPANTS)
                else btns.unshift(PHONE_BTN_TYPES.BUTTONS)
                btns = [PHONE_BTN_TYPES.CONFERENCE, PHONE_BTN_TYPES.TRANSIT, ...btns]
                return btns
            },
            statusText() {
                if (this.activeCall && this.activeCall.state === 'talk') return this.time
                else return this.stateCallText
            },
            typeCallText() {
                return this.numberText
            },
            // *** Переопределение stop  *** //

            calls () {
                return this.$store.getters[`${ASISTANTS}/${GET_CALLS}`]
            },
            activeCall () {
                return this.$store.getters[`${ASISTANTS}/${GET_ACTIVE_CALL}`]
            },
            digits () {
                return this.activeCall && this.activeCall.dtmfDigits || ''
            },
            stateCall () {
                return this.activeCall && this.activeCall.state || ''
            },
            stateCallText() {
                return this.stateCall && this.$t(`assistants.${this.stateCall}`) || ''
            },
            microphoneState () {
                return this.$store.getters[`${ASISTANTS}/${GET_MICROPHONE}`]
            },
            speakerState () {
                return this.$store.getters[`${ASISTANTS}/${GET_SPEAKER}`]
            },
            time() {
                return this.activeCall && this[GET_CALL_TALK_TIME](PHONE_TYPES.ASSISTANT, this.activeCall.id)
            },
            micDisabled() {
                return !this.activeCall
            },
            speakerDisabled() {
                return !this.activeCall
            },
            msgDisabled() {
                return !this.contact || !this.contact.cid
            },
            inTalkState() {
                return this.activeCall && this.activeCall.state !== 'talk'
            },
            buttonsDisabled() {
                return this.inTalkState
            },
            transitDisabled() {
                return this.inTalkState
            },
            conferenceDisabled() {
                return this.inTalkState
            },

            terminateDisabled() {
                if (!this.activeCall) return true
                return !['talk', 'dialing', 'pick-up'].includes(this.activeCall.state)
            },
            ...mapGetters(ASISTANTS, [GET_TYPE_ASISTANT]),
            ...mapGetters(PHONE_CNTL, [GET_CALL_TALK_TIME]),
        },
        watch: {
            stateCallText(status) {
                this[ACT_SET_CALL_STATUS]({ type: PHONE_TYPES.ASSISTANT, id: this.activeCall.id, status })
            },
            stateCall(state, oldState) {
                if (state === 'ready') this.keysDialerShow(true, this.digits)
                else if (oldState === 'ready') this.keysDialerHide()
            },
            digits(val) {
                if (this.stateCall === 'ready') this.dialerDigits = val
            }
        },
        methods: {
            // *** Переопределение start *** //
            isFuncBtnActive(btn) {
                switch (btn) {
                    case PHONE_BTN_TYPES.MICROPHONE:
                        return !this.isFuncBtnDisabled(btn) && !this.microphoneState
                    case PHONE_BTN_TYPES.SPEAKER:
                        return this.speakerState
                }
            },
            isFuncBtnDisabled (btn) {
                if (this.funcBtnSupported(btn)) {
                    switch (btn) {
                        case PHONE_BTN_TYPES.MSG:
                            return !this.contact || !this.contact.cid
                        case PHONE_BTN_TYPES.MICROPHONE:
                        case PHONE_BTN_TYPES.SPEAKER:
                        case PHONE_BTN_TYPES.BUTTONS:
                        case PHONE_BTN_TYPES.TRANSIT:
                        case PHONE_BTN_TYPES.CONFERENCE:
                            return this.inTalkState
                    }
                }
                return true
            },
            toggleMicrophone () {
                this.$store.dispatch(`${ASISTANTS}/${ACT_TOGGLE_MICROPHONE}`)
            },
            toggleSpeaker () {
                this.$store.dispatch(`${ASISTANTS}/${ACT_TOGGLE_SPEAKER}`)
            },
            doTransit () {
                this.$store.dispatch(`${ASISTANTS}/${ACT_TRANSIT}`)
            },
            doConference () {
                this.$store.dispatch(`${ASISTANTS}/${ACT_CONFERENCE}`)
            },
            pressDialKey ({ digits }) {
                this.$store.dispatch(`${ASISTANTS}/${ACT_PRESS_DIAL_KEY}`, digits)
            },
            terminate() {
                if (this.terminateDisabled) return
                this.$store.dispatch(`${ASISTANTS}/${ACT_TERMINATE_ASISTANT}`, this.activeCall.id)
            },
            setActiveCall (id) {
                this.$store.dispatch(`${ASISTANTS}/${ACT_SET_ACTIVE_CALL}`, id)
            },
            // *** Переопределение stop *** //
            funcBtnSupported(buttonType) {
                let type = this[GET_TYPE_ASISTANT]
                return type && this.supportedFuncBtnsByType[type].includes(buttonType)
            },
            ...mapActions(PHONE_CNTL, [ACT_SET_CALL_STATUS])
        },
    }
