var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "test",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("custom-radio", {
              attrs: {
                header: _vm.$t("radio-settings-ptt-mode-text"),
                value_change: _vm.newSettings.pttBtnMode,
                inputs: _vm.pttBtnModes,
                default_data: _vm.settings.pttBtnMode,
                isDisabled: !_vm.isMicEnabled,
              },
              on: {
                "update:value_change": function ($event) {
                  return _vm.$set(_vm.newSettings, "pttBtnMode", $event)
                },
              },
            }),
            _c("div", { staticClass: "settings-group notify-settings" }, [
              _c("div", { staticClass: "group-header" }, [
                _vm._v(_vm._s(_vm.$t("radio-settings-notifications-text"))),
              ]),
              _c("div", { staticClass: "group-properties" }, [
                _c(
                  "div",
                  { staticClass: "property sound" },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("radio-settings-sound"))),
                    ]),
                    _c("custom-checkbox-slider", {
                      attrs: {
                        checkbox: _vm.newSettings.signalsSound,
                        default: _vm.settings.signalsSound,
                      },
                      on: {
                        "update:checkbox": function ($event) {
                          return _vm.$set(
                            _vm.newSettings,
                            "signalsSound",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.modalClose } },
          [_vm._v(_vm._s(_vm.$t("done")))]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }