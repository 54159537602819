// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.list li .hor-line[data-v-33efaf84] {
  width: calc(100% - 68px);
  margin-left: 68px;
}
ul.list li .hor-line[data-v-33efaf84]:last-child {
  display: none;
}
.title[data-v-33efaf84] {
  padding: 0px 15px;
  font-size: var(--titleFontSize);
  color: var(--base-blue-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/search/SearchContacts.vue"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,+BAA+B;EAC/B,6BAA6B;AAC/B","sourcesContent":["ul.list li .hor-line[data-v-33efaf84] {\n  width: calc(100% - 68px);\n  margin-left: 68px;\n}\nul.list li .hor-line[data-v-33efaf84]:last-child {\n  display: none;\n}\n.title[data-v-33efaf84] {\n  padding: 0px 15px;\n  font-size: var(--titleFontSize);\n  color: var(--base-blue-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
