// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter[data-v-333369ee] {
  display: block;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.filter-line[data-v-333369ee] {
  height: 1px;
  border-bottom: 1px solid var(--list-item-bordercolor);
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/FilterContactType.vue"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,qDAAqD;EACrD,gBAAgB;AAClB","sourcesContent":[".filter[data-v-333369ee] {\n  display: block;\n  width: 100%;\n  height: 65vh;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n.filter-line[data-v-333369ee] {\n  height: 1px;\n  border-bottom: 1px solid var(--list-item-bordercolor);\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
