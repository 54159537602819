// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.official[data-v-f3a4faec] {
  height: 14px;
  width: 14px;
  border-radius: 8px;
  background-color: var(--icon-bgcolor2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  margin-left: 5px !important;
  flex-basis: 14px;
  min-width: 14px;
  min-height: 14px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/ChannelItem.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".official[data-v-f3a4faec] {\n  height: 14px;\n  width: 14px;\n  border-radius: 8px;\n  background-color: var(--icon-bgcolor2);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #ffffff;\n  font-size: 10px;\n  margin-left: 5px !important;\n  flex-basis: 14px;\n  min-width: 14px;\n  min-height: 14px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
