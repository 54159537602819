import {
    ALARM,
    CLIENTDATA,
    USERDATA,
} from '../../modulesNames'
import {
    GET_IS_ELECTRON,
    GET_APP_SERVER,
} from '../../gettersTypes'
import {
    ACT_SOCKET_INIT,
    ACT_SOCKET_RECONNECT,
    ACT_SOCKET_CONNECT,
    ACT_SET_CONNECTION_STATUS,
    ACT_SOCKET_HANDLE_STATUS_CHANGED,
    ACT_ALARM_SET_PROPS,
    ACT_SOCKET_HANDLE_CONFIG_RECEIVED,
    ACT_USERDATA_SET_HOST,
    ACT_SET_SOCKET_ID,
    ACT_SET_APP_SERVER_CONFIG,
} from '../../actionsTypes'
import proto from '../../../protocol'

import module, {SOCKET_STATUSES} from '../../modules/socket'

const logger = new Logger('socket.main')

import { generateHexString, importRsaKey, encryptTextByPublicKey } from '../../../common/Encrypter'
import Logger from "../../../common/Logger"
import {console} from 'vuedraggable/src/util/helper'
import store from '../store'

import { i18n } from '../../../../ext/i18n'


const actions = {
    async [ACT_SOCKET_INIT] ({state, dispatch, commit, rootGetters}, repeat = true) {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(await dispatch(ACT_SOCKET_CONNECT, { repeat }))
            } catch (e) {
                logger.error(e)
                reject('connection-refused')
            }
        })
    },
    [ACT_SOCKET_RECONNECT] ({ commit, dispatch }, payload = {}) {
    },
    async [ACT_SOCKET_CONNECT] ({ commit, dispatch, state, rootGetters }, payload = {}) {
        const { repeat = false } = payload
        // let repeat = false
        // адрес сервера
        let hostPort
        if (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            let selectedServer = rootGetters[`${USERDATA}/${GET_APP_SERVER}`]
            hostPort = `http://${selectedServer}`
        } else {
            hostPort = utils.getPageProtoHostPort()
        }
        let serverUrl
        try {
            serverUrl = new URL(hostPort)
        } catch (e) {
            return console.log(`${ACT_SOCKET_INIT}: invalid hostPort: ${hostPort}`)
        }

        logger.info(`${ACT_SOCKET_CONNECT}: serverUrl: ${serverUrl}`)

        const socket_id = await proto.connect({server: serverUrl, repeat})

        return socket_id
    },
    async [ACT_SOCKET_HANDLE_CONFIG_RECEIVED]({dispatch}, config) {
        const respOrigin = config.redirectedServer
        const originURL = new URL(respOrigin)
        dispatch(`${USERDATA}/${ACT_USERDATA_SET_HOST}`, {host: originURL.origin}, {root: true})
        dispatch(`${USERDATA}/${ACT_SET_APP_SERVER_CONFIG}`, config, {root: true})
    },
    async [ACT_SOCKET_HANDLE_STATUS_CHANGED]({ commit, dispatch, state, rootGetters }, {state: status, ...payload}) {
        switch (status) {
            case 'connected': {
                const { socket } = payload || {}

                const name = 'io'
                const value = socket.io.engine.id

                const urlObj = new URL(socket.io.uri)
                const url = urlObj.protocol + '//' + urlObj.hostname
                if (window.rpc) window.rpc.setCookie({ url, name, value, expires: date_helper.getCurrentUnixTime() +  30 * 24 * 60 * 60 })

                dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, {}, { root: true })
                dispatch(`${CLIENTDATA}/${ACT_SET_SOCKET_ID}`, socket.io.engine.id, { root: true })
                dispatch(ACT_SET_CONNECTION_STATUS, SOCKET_STATUSES.CONNECTED)
                break
            }
            case 'disconnected': {
                const { reconnect } = payload || {}
                if (reconnect && (!rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] || rootGetters[`${USERDATA}/${GET_APP_SERVER}`])) {
                    dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, { msg: i18n.messages[i18n.locale]['no-serv-conn'] }, { root: true })
                    // dispatch(`${SOCKET}/${ACT_SOCKET_RECONNECT}`, { root: true })
                }
                dispatch(ACT_SET_CONNECTION_STATUS, SOCKET_STATUSES.DISCONNECTED)
                break
            }
            case 'reconnecting': {
                break
            }
        }
    },
}

Object.assign(module.actions, actions)

export default module
