// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-297ab158] .v--modal-box.v--modal {
  overflow: visible;
}
.custom-modal[data-v-297ab158] .v--modal-box.v--modal .header {
  padding: 15px 20px 0px 15px;
}
.custom-modal[data-v-297ab158] .v--modal-box.v--modal .body {
  height: 100%;
  overflow-y: initial;
}
.custom-modal[data-v-297ab158] .custom-input-text-placeholder.up {
  font-size: 0.8em;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNewManagment/scheduleConference.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".custom-modal[data-v-297ab158] .v--modal-box.v--modal {\n  overflow: visible;\n}\n.custom-modal[data-v-297ab158] .v--modal-box.v--modal .header {\n  padding: 15px 20px 0px 15px;\n}\n.custom-modal[data-v-297ab158] .v--modal-box.v--modal .body {\n  height: 100%;\n  overflow-y: initial;\n}\n.custom-modal[data-v-297ab158] .custom-input-text-placeholder.up {\n  font-size: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
