// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list[data-v-4806e0e7] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid var(--list-item-bordercolor);
  cursor: pointer;
}
.item-list[data-v-4806e0e7]:hover {
  background-color: var(--list-item-bghover);
}
.item-name[data-v-4806e0e7] {
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-icon[data-v-4806e0e7] {
  color: #337ab7;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/NameListItem.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,qDAAqD;EACrD,eAAe;AACjB;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".item-list[data-v-4806e0e7] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 72px;\n  border-bottom: 1px solid var(--list-item-bordercolor);\n  cursor: pointer;\n}\n.item-list[data-v-4806e0e7]:hover {\n  background-color: var(--list-item-bghover);\n}\n.item-name[data-v-4806e0e7] {\n  font-size: 16px;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n.item-icon[data-v-4806e0e7] {\n  color: #337ab7;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
