
    import {mapGetters} from "vuex"
    import {VIDEO_CONF_NEW} from "../../store/modulesNames"
    import {
        GET_VC_ACTIVE_CONF,
        IS_VC_IN_FULLSCREEN,
        IS_VC_MINIMIZED
    } from "../../store/gettersTypes"

    import BaseContainerWebWrapper from "./BaseContainerWebWrapper.vue"

    import ConfContainer from "./ConfContainer.vue"

    export default {
        name: "ConfContainerWebWrapper",
        extends: BaseContainerWebWrapper,
        data() {
            return {
                component: ConfContainer,
                notFullScreenHeight: '700px',
                notFullScreenWidth: '800px',
            }
        },
        computed: {
            call() {
                return this[GET_VC_ACTIVE_CONF]
            },
            minimized() {
                return this[IS_VC_MINIMIZED]
            },
            fullScreen () {
                return this[IS_VC_IN_FULLSCREEN]
            },
            ...mapGetters(VIDEO_CONF_NEW, [GET_VC_ACTIVE_CONF, IS_VC_MINIMIZED, IS_VC_IN_FULLSCREEN])
        },
    }
