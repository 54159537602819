// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-channel-message[data-v-76636880] {
  width: 300px;
  cursor: pointer;
  margin-bottom: 8px;
}
.chat-channel-message .chat-channel-info[data-v-76636880] {
  padding-left: 5px;
}
.chat-channel-message .chat-channel-info .channel-name[data-v-76636880],
.chat-channel-message .chat-channel-info .channel-subscribers[data-v-76636880] {
  white-space: nowrap;
}
.chat-channel-message .chat-channel-info .channel-name[data-v-76636880] {
  font-size: 16px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-channel-message .chat-channel-info .channel-subscribers[data-v-76636880] {
  font-size: 12px;
}
.chat-channel-message .alter[data-v-76636880] {
  font-size: 40px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatChannelMessage.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;;EAEE,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".chat-channel-message[data-v-76636880] {\n  width: 300px;\n  cursor: pointer;\n  margin-bottom: 8px;\n}\n.chat-channel-message .chat-channel-info[data-v-76636880] {\n  padding-left: 5px;\n}\n.chat-channel-message .chat-channel-info .channel-name[data-v-76636880],\n.chat-channel-message .chat-channel-info .channel-subscribers[data-v-76636880] {\n  white-space: nowrap;\n}\n.chat-channel-message .chat-channel-info .channel-name[data-v-76636880] {\n  font-size: 16px !important;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.chat-channel-message .chat-channel-info .channel-subscribers[data-v-76636880] {\n  font-size: 12px;\n}\n.chat-channel-message .alter[data-v-76636880] {\n  font-size: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
