// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog .search-contact[data-v-5c4499aa] {
  margin-bottom: 15px;
}
.dialog[data-v-5c4499aa] .body {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  height: 68vh;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SelectContact.vue"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":[".dialog .search-contact[data-v-5c4499aa] {\n  margin-bottom: 15px;\n}\n.dialog[data-v-5c4499aa] .body {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden !important;\n  height: 68vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
