
    import { PTT_STATUS } from "./PttButton.vue"
    import {mapGetters, mapActions} from 'vuex'
    import {RADIO, RC_RADIO, CHATS, CONTACTS} from '../../store/modulesNames'
    import {
        RC_RADIO_IS_TURN_ON,
        GET_RC_RADIO_PTT_PUSHED,
        GET_RC_RADIO_GRANT_STATUS,
        GET_RC_RADIO_ACTIVE_GROUP,
        GET_CHAT,
        GET_MERGED_CONTACT_BY_ID,
        GET_RC_RADIO_CONNECTION_STATUS,
        GET_RC_RADIO_GRANT_CID,
        GET_SPEECH_PACK_RATE,
        GET_RC_RADIO_CONTACTS,
        GET_RC_RADIO_ECHO,
        GET_MERGED_CONTACTS_BY_IDS,
    } from '../../store/gettersTypes'
    import {
        ACT_RC_RADIO_REQUEST_VOICE,
        ACT_RC_RADIO_STOP_VOICE,
        ACT_RC_RADIO_SET_ECHO, ACT_RC_RADIO_EXIT_BTN,
    } from '../../store/actionsTypes'

    import {
        CONNECTION_STATUS,
        GRANT_STATUSES,
        GRAND_REASONS,
        MESSAGE_TYPES,
        EVENTS,
    } from '../../store/modules/rc-radio'

    import { SUPPORT_GROUP_CID } from '../../constants'

    import RadioComponent from "./RadioComponent.vue"
    import SelectRadioGroup from '../modal/SelectRadioGroup.vue'
    import ContactsListModal from '../modal/ContactsListModal.vue'
    import event_bus from "../../eventBus"

    export default {
        name: "RcRadio",
        extends: RadioComponent,
        computed: {
            title() {
                let title = ''
                switch (this[GET_RC_RADIO_CONNECTION_STATUS]) {
                    case CONNECTION_STATUS.OFFLINE:
                    case CONNECTION_STATUS.GROUP_NOT_SET:
                        title = this.$t('radio-off')
                        break
                    case CONNECTION_STATUS.RECONNECTING:
                        title = this.$t('radio-reconnecting')
                        break
                    case CONNECTION_STATUS.ONLINE:
                        title = this.chatName
                        break
                    case CONNECTION_STATUS.CONNECTING:
                    case CONNECTION_STATUS.ERROR:
                        title = this.$t('turning-on-radio')
                        break
                }
                return title
            },
            subTitle() {
                let subTitle = ''
                if (!this.connected) return subTitle

                switch (this[GET_RC_RADIO_GRANT_STATUS]) {
                    case GRANT_STATUSES.TO_ANOTHER_USER:
                        let cid = this[GET_RC_RADIO_GRANT_CID]
                        let contact = this[GET_MERGED_CONTACT_BY_ID](cid)
                        subTitle = contact.fio
                        if (!contact.cid) subTitle+= ` (${cid})`
                        if (cid === SUPPORT_GROUP_CID) subTitle = this.$t('system-radio')
                        break
                    case GRANT_STATUSES.REQUEST:
                        subTitle = this.$t('word-query')
                        break
                    case GRANT_STATUSES.GRANTED:
                        subTitle = this.$t('speak')
                        break
                    case GRANT_STATUSES.REQUEST_QUEUED:
                        subTitle = this.$t('wait')
                        break
                    case GRANT_STATUSES.NOT_GRANTED:
                        subTitle = this.$t('not-grunted')
                        break
                    case GRANT_STATUSES.NONE:
                    default:
                }
                return subTitle
            },
            pttPushed() {
                return this[GET_RC_RADIO_PTT_PUSHED]
            },
            pttStatus() {
                let status = PTT_STATUS.IDLE
                switch (this[GET_RC_RADIO_GRANT_STATUS]) {
                    case GRANT_STATUSES.TO_ANOTHER_USER:
                        status = PTT_STATUS.PUSHED_OTHER
                        break
                    case GRANT_STATUSES.REQUEST:
                        status = PTT_STATUS.REQUEST
                        break
                    case GRANT_STATUSES.GRANTED:
                        status = PTT_STATUS.PUSHED
                        break
                    case GRANT_STATUSES.REQUEST_QUEUED:
                        status = PTT_STATUS.QUEUED
                        break
                    case GRANT_STATUSES.NOT_GRANTED:
                        status = PTT_STATUS.PUSHED_OTHER
                        break
                    case GRANT_STATUSES.NONE:
                    default:
                }
                return status
            },
            infoText() {
                let text=`${this.$t('active-members')}${(this[GET_RC_RADIO_CONTACTS]).filter(({status}) => status === 'online').length}`
                if (this[GET_SPEECH_PACK_RATE]) text+=`,${this.$t('pack/sec')}${this[GET_SPEECH_PACK_RATE]}`
                return text
            },
            chat() {
                let cid = this[GET_RC_RADIO_ACTIVE_GROUP]
                if (cid === SUPPORT_GROUP_CID) return { name: this.$t('system-radio') }
                if (cid) return this[GET_CHAT]({cid: this[GET_RC_RADIO_ACTIVE_GROUP], cidType: 'group'})
                else return {}
            },
            chatName() {
                return this.chat && this.chat.name || `${this.$t('unnamed-group')} (${this[GET_RC_RADIO_ACTIVE_GROUP]})`
            },
            activeContacts() {
                let cids = this[GET_RC_RADIO_CONTACTS].map(({cid}) => +cid)
                return this[GET_MERGED_CONTACTS_BY_IDS](cids)
            },
            ...mapGetters(RADIO, [GET_SPEECH_PACK_RATE]),
            ...mapGetters(RC_RADIO, {
                connected: RC_RADIO_IS_TURN_ON,
                [GET_RC_RADIO_CONNECTION_STATUS]: GET_RC_RADIO_CONNECTION_STATUS,
                [GET_RC_RADIO_PTT_PUSHED]: GET_RC_RADIO_PTT_PUSHED,
                [GET_RC_RADIO_CONTACTS]: GET_RC_RADIO_CONTACTS,
                [GET_RC_RADIO_GRANT_CID]: GET_RC_RADIO_GRANT_CID,
                [GET_RC_RADIO_GRANT_STATUS]: GET_RC_RADIO_GRANT_STATUS,
                [GET_RC_RADIO_ACTIVE_GROUP]: GET_RC_RADIO_ACTIVE_GROUP,
                [GET_RC_RADIO_ECHO]: GET_RC_RADIO_ECHO,
            }),
            ...mapGetters(CHATS, [GET_CHAT]),
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACTS_BY_IDS]),
        },
        methods: {
            pushPTT() { this[ACT_RC_RADIO_REQUEST_VOICE]() },
            popPTT() { this[ACT_RC_RADIO_STOP_VOICE]() },
            onGroupSelect() {
                this.modalOpen({
                    component: SelectRadioGroup,
                })
            },
            onRepeatLast() {
                this[ACT_RC_RADIO_SET_ECHO]()
            },
            onInfo(event, position) {
                let handlers = []
                let open = this.cmOpen
                handlers.push({
                    item_name: this.$t('radio-group-member'),
                    handler: () => {
                        this.modalOpen({
                            component: ContactsListModal,
                            props: {
                                title: this.chatName,
                                contactsList: this.activeContacts
                            }
                        })
                    }
                })
                open(event, handlers, position);
            },
            onExit() {
                if (this[GET_RC_RADIO_GRANT_CID] === SUPPORT_GROUP_CID) {
                    this[ACT_RC_RADIO_REQUEST_VOICE]()
                    this[ACT_RC_RADIO_STOP_VOICE]()
                }
                else this[ACT_RC_RADIO_EXIT_BTN]()
            },
            getFio(cid) {
                if (!cid) return this.$t('system-radio')
                let contact = this[GET_MERGED_CONTACT_BY_ID](+cid)
                return contact.fio
            },
            onContactsEvent({type, cid}) {
                switch (type) {
                    case 'new':
                        this.setNotify(`${this.$t('radio-member-connected')} ${this.getFio(cid)}`)
                        break
                    case 'del':
                        this.setNotify(`${this.$t('radio-member-disconnected')} ${this.getFio(cid)}`)
                        break
                }
            },
            onGrandEvent({grand, cid, reason}) {
                if (!grand) {
                    switch (reason) {
                        case GRAND_REASONS.GSM_INTERRUPT && cid !== SUPPORT_GROUP_CID:
                            this.setNotify(`${this.$t('gsm-interrupt')} ${this.getFio(cid)}`)
                            break
                    }
                }
            },
            onMessageEvent({type, data}) {
                switch (type) {
                    case MESSAGE_TYPES.REQUEST_VOICE:
                        this.setNotify(`${this.$t('request-voice')} ${this.getFio(data.cid)}`)
                        this.playAudioNotify()
                        break
                }
            },
            onMounted() {
                event_bus.$off(EVENTS.CONTACT_CHANGED)
                event_bus.$on(EVENTS.CONTACT_CHANGED, this.onContactsEvent)
                event_bus.$off(EVENTS.GRAND)
                event_bus.$on(EVENTS.GRAND, this.onGrandEvent)
                event_bus.$off(EVENTS.MESSAGE)
                event_bus.$on(EVENTS.MESSAGE, this.onMessageEvent)
            },
            ...mapActions(RC_RADIO, [
                ACT_RC_RADIO_REQUEST_VOICE,
                ACT_RC_RADIO_STOP_VOICE,
                ACT_RC_RADIO_SET_ECHO,
                ACT_RC_RADIO_EXIT_BTN,
            ])
        },
        watch: {
            [GET_RC_RADIO_CONNECTION_STATUS](status) {
                if (status === CONNECTION_STATUS.GROUP_NOT_SET) {
                    this.modalOpen({
                        component: SelectRadioGroup,
                    })
                }
            },
            [GET_RC_RADIO_GRANT_STATUS](status, oldStatus) {
                if (status === GRANT_STATUSES.REQUEST && oldStatus === GRANT_STATUSES.REQUEST_QUEUED) this.pushPTT()
            },
            [GET_RC_RADIO_ACTIVE_GROUP]() {
                this.setNotify('')
            },
        }
    }
