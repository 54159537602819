// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-wrapper[data-v-1f8d5570] {
  position: relative;
  width: 100%;
}
.selected-wrapper[data-v-1f8d5570]::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-bottom-selecteditems);
}
.selected[data-v-1f8d5570] {
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  display: flex;
}
.selected .selected-item[data-v-1f8d5570] {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 60px;
  margin: 5px;
  margin-top: 0;
}
.selected .selected-item .icon-block[data-v-1f8d5570] {
  margin: auto;
}
.selected .selected-item span[data-v-1f8d5570] {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SelectedItemsLine.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,2DAA2D;AAC7D;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".selected-wrapper[data-v-1f8d5570] {\n  position: relative;\n  width: 100%;\n}\n.selected-wrapper[data-v-1f8d5570]::after {\n  content: ' ';\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 1px;\n  border-bottom: 1px solid var(--border-bottom-selecteditems);\n}\n.selected[data-v-1f8d5570] {\n  overflow: hidden;\n  overflow-x: auto;\n  position: relative;\n  display: flex;\n}\n.selected .selected-item[data-v-1f8d5570] {\n  position: relative;\n  display: inline-block;\n  text-align: center;\n  width: 60px;\n  margin: 5px;\n  margin-top: 0;\n}\n.selected .selected-item .icon-block[data-v-1f8d5570] {\n  margin: auto;\n}\n.selected .selected-item span[data-v-1f8d5570] {\n  width: 100%;\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
