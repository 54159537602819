// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-icons[data-v-8dafcfd2] {
  display: flex;
  /*margin: 0 16px;
    align-items: center;
    i {
        cursor: pointer;
        font-size: 24px;
        color: var(--toolbtn-color);
        &:hover {
            color: var(--toolbtn-color-hover);
        }
    }*/
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/header/MainHeaderVideoConfs.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;;;;;;;MASI;AACN","sourcesContent":[".menu-icons[data-v-8dafcfd2] {\n  display: flex;\n  /*margin: 0 16px;\n    align-items: center;\n    i {\n        cursor: pointer;\n        font-size: 24px;\n        color: var(--toolbtn-color);\n        &:hover {\n            color: var(--toolbtn-color-hover);\n        }\n    }*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
