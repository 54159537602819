// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pass-restore[data-v-a844ba30] .non-border-input {
  width: 100%;
}
.pass-restore .component-section[data-v-a844ba30] {
  position: relative;
}
.pass-restore .component-section .input-block.is-hidden #pass-restore-btn[data-v-a844ba30],
.pass-restore .component-section .input-block.is-hidden .login[data-v-a844ba30] {
  visibility: hidden;
}
.pass-restore .component-section #pass-restore-btn[data-v-a844ba30] {
  margin-top: 14%;
}
.pass-restore .component-section .sended[data-v-a844ba30] {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
}
.pass-restore .component-section .sended p[data-v-a844ba30] {
  position: relative;
  top: -25px;
  font-size: 16px;
  margin-top: auto;
  text-align: center;
}
.pass-restore .component-section .sended button[data-v-a844ba30] {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 140px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/login/PassRestore.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".pass-restore[data-v-a844ba30] .non-border-input {\n  width: 100%;\n}\n.pass-restore .component-section[data-v-a844ba30] {\n  position: relative;\n}\n.pass-restore .component-section .input-block.is-hidden #pass-restore-btn[data-v-a844ba30],\n.pass-restore .component-section .input-block.is-hidden .login[data-v-a844ba30] {\n  visibility: hidden;\n}\n.pass-restore .component-section #pass-restore-btn[data-v-a844ba30] {\n  margin-top: 14%;\n}\n.pass-restore .component-section .sended[data-v-a844ba30] {\n  display: flex;\n  flex-direction: column;\n  background-color: var(--bg-color);\n}\n.pass-restore .component-section .sended p[data-v-a844ba30] {\n  position: relative;\n  top: -25px;\n  font-size: 16px;\n  margin-top: auto;\n  text-align: center;\n}\n.pass-restore .component-section .sended button[data-v-a844ba30] {\n  margin-top: auto;\n  margin-left: auto;\n  margin-right: auto;\n  width: 140px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
