// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheme-select[data-v-4585e0a5] {
  display: flex;
  border-radius: 5px;
  opacity: 0.9;
  overflow: hidden;
}
.scheme-select .select-wrapper[data-v-4585e0a5] {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.scheme-select .select-wrapper .btn-wrapper[data-v-4585e0a5] {
  width: 80px;
  -webkit-app-region: no-drag;
}
.scheme-select .select-wrapper .btn-wrapper[data-v-4585e0a5]:not(:last-child) {
  margin-right: 5px;
}
.scheme-select .select-wrapper .btn-wrapper > *[data-v-4585e0a5] {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNew/videoConfSchemeSelect.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,WAAW;EACX,2BAA2B;AAC7B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;AACd","sourcesContent":[".scheme-select[data-v-4585e0a5] {\n  display: flex;\n  border-radius: 5px;\n  opacity: 0.9;\n  overflow: hidden;\n}\n.scheme-select .select-wrapper[data-v-4585e0a5] {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n}\n.scheme-select .select-wrapper .btn-wrapper[data-v-4585e0a5] {\n  width: 80px;\n  -webkit-app-region: no-drag;\n}\n.scheme-select .select-wrapper .btn-wrapper[data-v-4585e0a5]:not(:last-child) {\n  margin-right: 5px;\n}\n.scheme-select .select-wrapper .btn-wrapper > *[data-v-4585e0a5] {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
