// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span[data-v-bda5e11e] {
  color: var(--toolbtn-active-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/settings/SelectAssistant.vue"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC","sourcesContent":["span[data-v-bda5e11e] {\n  color: var(--toolbtn-active-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
