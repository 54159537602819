// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-container[data-v-5c7eadf3] > .list-item {
  height: 45px;
  padding: 0 20px;
}
.action-container[data-v-5c7eadf3] > .list-item .item-media[class~=item-media] {
  margin-left: 0;
}
.action-container[data-v-5c7eadf3] > .list-item .item-content > div:first-child {
  margin-left: 0;
}
.action-container[data-v-5c7eadf3] > .list-item .item-content > div:last-child {
  margin-right: 0;
}
.action-container[data-v-5c7eadf3] > .list-item .item-content > div .item-title {
  font-size: inherit;
}
.action-container[data-v-5c7eadf3] > .list-item .item-link {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/ActionsContainer.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".action-container[data-v-5c7eadf3] > .list-item {\n  height: 45px;\n  padding: 0 20px;\n}\n.action-container[data-v-5c7eadf3] > .list-item .item-media[class~=item-media] {\n  margin-left: 0;\n}\n.action-container[data-v-5c7eadf3] > .list-item .item-content > div:first-child {\n  margin-left: 0;\n}\n.action-container[data-v-5c7eadf3] > .list-item .item-content > div:last-child {\n  margin-right: 0;\n}\n.action-container[data-v-5c7eadf3] > .list-item .item-content > div .item-title {\n  font-size: inherit;\n}\n.action-container[data-v-5c7eadf3] > .list-item .item-link {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
