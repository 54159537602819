// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper[data-v-095449a8] {
  align-items: flex-start;
  flex-direction: column;
}
.wrapper .custom-radio[data-v-095449a8] {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ChatReadOnlyDialog.vue"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".wrapper[data-v-095449a8] {\n  align-items: flex-start;\n  flex-direction: column;\n}\n.wrapper .custom-radio[data-v-095449a8] {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
