// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-info[data-v-bdc3f0a4] {
  position: absolute!important;
}
.phone-wrapper[data-v-bdc3f0a4] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.phone-contain[data-v-bdc3f0a4] {
  top: 30px;
  border-radius: 10px;
  background-color: inherit;
  z-index: 1000;
  cursor: pointer;
}
.phone-contain[data-v-bdc3f0a4].web .phone-contain {
  position: relative;
  top: initial;
}
.phone-contain[data-v-bdc3f0a4] .phone {
  position: relative;
}
.positionCenter[data-v-bdc3f0a4] {
  left: 50%!important;
  top: 50%!important;
  transform: translate(-50%, -50%);
  bottom: 0px;
}
.phone-contain-resize[data-v-bdc3f0a4] {
  resize: auto;
}
.video-conf-full-screen[data-v-bdc3f0a4] {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/BaseContainerWebWrapper.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;AACT;AACA;EACE,SAAS;EACT,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":[".phone-info[data-v-bdc3f0a4] {\n  position: absolute!important;\n}\n.phone-wrapper[data-v-bdc3f0a4] {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: -1;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n.phone-contain[data-v-bdc3f0a4] {\n  top: 30px;\n  border-radius: 10px;\n  background-color: inherit;\n  z-index: 1000;\n  cursor: pointer;\n}\n.phone-contain[data-v-bdc3f0a4].web .phone-contain {\n  position: relative;\n  top: initial;\n}\n.phone-contain[data-v-bdc3f0a4] .phone {\n  position: relative;\n}\n.positionCenter[data-v-bdc3f0a4] {\n  left: 50%!important;\n  top: 50%!important;\n  transform: translate(-50%, -50%);\n  bottom: 0px;\n}\n.phone-contain-resize[data-v-bdc3f0a4] {\n  resize: auto;\n}\n.video-conf-full-screen[data-v-bdc3f0a4] {\n  top: 0 !important;\n  right: 0 !important;\n  bottom: 0 !important;\n  left: 0 !important;\n  width: 100vw !important;\n  height: 100vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
