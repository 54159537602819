
    import { mapActions } from "vuex"
    import { PHONE } from "../../../store/modulesNames"
    import { ACT_PHONE_COMMANDS } from "../../../store/actionsTypes"

    import CallBase from "./CallBase.vue"

    export default {
        name: "CallWebrtc",
        extends: CallBase,
        methods: {
            endCall(){
                this[ACT_PHONE_COMMANDS]( { params: {callId: this.id}, command: 'terminate'} )
            },
            ...mapActions(PHONE, [ACT_PHONE_COMMANDS])
        }
    }
