var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pubaudio-search-wrapper" },
    _vm._l(_vm.audioItems, function (item, i) {
      return _c(
        "div",
        { key: i, staticClass: "div" },
        [
          _c("publication-audio-item", {
            attrs: {
              data: item,
              "unix-last-time": _vm.unixTime(),
              progressWidth: 40,
              progressHeight: 40,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }