// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-f22da5d0] .v--modal-box.v--modal {
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.custom-modal[data-v-f22da5d0] .v--modal-box.v--modal .body {
  overflow-y: auto;
}
.custom-modal[data-v-f22da5d0] ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ModalDialog.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".custom-modal[data-v-f22da5d0] .v--modal-box.v--modal {\n  max-height: 90vh;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n.custom-modal[data-v-f22da5d0] .v--modal-box.v--modal .body {\n  overflow-y: auto;\n}\n.custom-modal[data-v-f22da5d0] ::-webkit-scrollbar-corner {\n  background: rgba(0, 0, 0, 0);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
