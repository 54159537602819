var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "acs-journal-list" },
    [
      _vm.journal_sorted.length
        ? _vm._l(_vm.journal_sorted, function (record, i) {
            return _c("li", { key: i, staticClass: "item" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("div", { staticClass: "left-side" }, [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", { class: _vm._f("iconCalc")(record.status) }),
                  ]),
                ]),
                _c("div", { staticClass: "center-right" }, [
                  _c("div", { staticClass: "center" }, [
                    _c("div", { staticClass: "desc" }, [
                      _c("span", { staticClass: "status" }, [
                        _vm._v(_vm._s(_vm.translateStatus(record.status))),
                      ]),
                      _c("span", { staticClass: "text date" }, [
                        _vm._v(
                          _vm._s(_vm._f("dateCalc")(record.from)) +
                            " - " +
                            _vm._s(_vm._f("dateCalc")(record.to))
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "text",
                          attrs: { title: record.text + ". " + record.comment },
                        },
                        [_vm._v(_vm._s(_vm._f("cut")(record.description)))]
                      ),
                    ]),
                  ]),
                  _vm.hasRights
                    ? _c(
                        "div",
                        {
                          staticClass: "right circle-animation",
                          on: {
                            click: function ($event) {
                              return _vm.doContextMenu(
                                $event,
                                record.from,
                                record.to,
                                record.recId
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            { attrs: { width: "10px", height: "24px" } },
                            [
                              _c("circle", {
                                attrs: { cx: "5", cy: "5", r: "2" },
                              }),
                              _c("circle", {
                                attrs: { cx: "5", cy: "12", r: "2" },
                              }),
                              _c("circle", {
                                attrs: { cx: "5", cy: "19", r: "2" },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }