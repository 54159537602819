// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intergrations .main-content-container {
  background: var(--bg-color);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;
}
.intergrations .main-content-container .main-content-container-main {
  background: inherit;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/settings/Integrations.vue"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,6EAA6E;AAC/E;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".intergrations .main-content-container {\n  background: var(--bg-color);\n  border-radius: 4px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;\n}\n.intergrations .main-content-container .main-content-container-main {\n  background: inherit;\n  border-radius: 0;\n  box-shadow: none;\n  margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
