
    import {mapActions, mapGetters} from "vuex"
    import {
        USERDATA,
        VIDEO_CONF,
    } from "../../store/modulesNames"
    import {
        GET_CONTACTS,
        GET_UID, IS_CONF_ROOM_FULL,
    } from "../../store/gettersTypes"

    import SelectContact from "./SelectContact.vue"
    import {
        ACT_ADD_CONTACTS_TO_CONFERENCE,
        ACT_SET_ADD_DIALOG_OPENED,
    } from "../../store/actionsTypes"

    export default {
        name: "AddToConf",
        extends: SelectContact,
        mounted() {
            this[ACT_SET_ADD_DIALOG_OPENED](true)
        },
        beforeDestroy() {
            this[ACT_SET_ADD_DIALOG_OPENED](false)
        },
        computed: {
            excludeMembers() {
                let exc = [this[GET_UID]]
                exc = exc.concat(this[GET_CONTACTS].map(({cid}) => cid))
                return exc
            },
            ...mapGetters(USERDATA, [GET_UID]),
            ...mapGetters(VIDEO_CONF, [GET_CONTACTS, IS_CONF_ROOM_FULL]),
        },
        methods: {
            async onOk({cid}) {
                this[ACT_ADD_CONTACTS_TO_CONFERENCE]([cid])
                this.modalClose()
            },
            ...mapActions(VIDEO_CONF, [ACT_ADD_CONTACTS_TO_CONFERENCE, ACT_SET_ADD_DIALOG_OPENED])
        },
        watch: {
            [IS_CONF_ROOM_FULL](val) {
                if (val) this.modalClose()
            }
        },
    }
