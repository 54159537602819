// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acs-entrance[data-v-8c6c1d9c] {
  color: gray;
  font-size: 13px;
}
.acs-entrance > span[data-v-8c6c1d9c]:not(:last-child)::after {
  content: " ";
}
`, "",{"version":3,"sources":["webpack://./js/app/components/acs/AcsEntrances.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,YAAY;AACd","sourcesContent":[".acs-entrance[data-v-8c6c1d9c] {\n  color: gray;\n  font-size: 13px;\n}\n.acs-entrance > span[data-v-8c6c1d9c]:not(:last-child)::after {\n  content: \" \";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
