// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing-status[data-v-0ed8c3ec] {
  display: flex;
  flex-direction: row;
}
.typing-status .dots[data-v-0ed8c3ec] {
  margin-right: 5px;
}
.typing-status > *[data-v-0ed8c3ec] {
  margin-top: auto;
  margin-bottom: auto;
}
.typing-status .typing-text[data-v-0ed8c3ec] {
  color: var(--message-grey-color);
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/TypingStatus.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,eAAe;AACjB","sourcesContent":[".typing-status[data-v-0ed8c3ec] {\n  display: flex;\n  flex-direction: row;\n}\n.typing-status .dots[data-v-0ed8c3ec] {\n  margin-right: 5px;\n}\n.typing-status > *[data-v-0ed8c3ec] {\n  margin-top: auto;\n  margin-bottom: auto;\n}\n.typing-status .typing-text[data-v-0ed8c3ec] {\n  color: var(--message-grey-color);\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
