// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.text[data-v-6e720648] {
  display: block;
  margin-bottom: 15px;
}
.modal-body[data-v-6e720648] {
  display: flex;
  padding: 0 10px;
  margin: 0 auto !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/HotKeys.vue"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":["span.text[data-v-6e720648] {\n  display: block;\n  margin-bottom: 15px;\n}\n.modal-body[data-v-6e720648] {\n  display: flex;\n  padding: 0 10px;\n  margin: 0 auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
