var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.call
    ? _c(
        "div",
        [
          [
            _c("div", { ref: "wrapper", staticClass: "phone-wrapper" }),
            _c(
              "div",
              {
                ref: "phone",
                staticClass: "phone-contain phone-info",
                class: { positionCenter: _vm.activePosition },
                style: _vm.styleParams,
                on: {
                  mousedown: function ($event) {
                    return _vm.mousedown($event)
                  },
                  mousemove: function ($event) {
                    $event.preventDefault()
                    return _vm.mousemove($event)
                  },
                  mouseup: function ($event) {
                    return _vm.mouseup($event)
                  },
                },
              },
              [
                _c(_vm.component, {
                  ref: "phoneContainer",
                  tag: "component",
                  attrs: { call: _vm.call, showPhone: !_vm.minimized },
                }),
              ],
              1
            ),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }