
    import CustomInputText from './CustomInputText.vue'

    export default {
        name: 'CustomInputNumber',
        extends: CustomInputText,
        props: {
            default_text: {
                type: Number,
                required: false,
                default: 0
            },
        },
        methods: {
            getValue() {
                return +this.input_text
            },
        },
    }
