var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-contact-message-wrapper",
      attrs: { id: "chat-contact-message-template" },
      on: {
        click: _vm.makeActions,
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.secondContextMenu($event, _vm.message)
        },
      },
    },
    [
      _vm.message.author
        ? _c("div", { staticClass: "fwd" }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "chat-contact-message" },
        [
          _c("icon-image", {
            attrs: { src: _vm.photo, text: _vm.message.fio },
          }),
          _c("div", { staticClass: "contact-name" }, [
            _c("span", [_vm._v(_vm._s(_vm.fio))]),
            _c("span", { staticClass: "lbl" }, [
              _vm._v(_vm._s(_vm.$t("contact"))),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }