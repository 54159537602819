import { i18n } from '../../../ext/i18n'
const locale = i18n.messages[i18n.locale]

import moment from 'moment'

import {
    GET_CONF,
    GET_CONF_MIC_STATUS,
    GET_CONF_CAM_STATUS,
    GET_CONF_INFO,
    GET_CONF_SPEAKER_STATUS,
    GET_CONF_OWN_MEMBER,
    GET_CONF_MEMBERS,
    GET_USER_PARAMS,
    GET_VM_CONF_CONNECTION_PARAMS,
    GENERATE_CONF_PROPS,
    GET_UID,
    GET_ACTIVE_MEDIA_DEVICES,
    GET_CONF_INVITE_TEXT,
    GET_MERGED_CONTACT_BY_ID,
    GET_IS_ELECTRON,
    GET_VM_SERVER_MANAGEMENT_HOST,
    GENERATE_CONF_LINK,
    GET_APP_REDIRECTED_SERVER,
    GET_CONFSCALENDAR,
    GET_CONF_ERROR_TEXT,
} from '../gettersTypes'

import {
    ACT_VM_CONF_UPDATE_DEVICES,
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_FINISH,
    ACT_CONF_LEAVE,
    ACT_CONF_CAMERA_TOGGLE,
    ACT_CONF_SPEAKER_TOGGLE,
    ACT_CONF_MICROPHONE_TOGGLE,
    ACT_VM_CONF_FINISH,
    ACT_CONF_SHARE,
    ACT_CONF_WANT_SPEAK,
    ACT_VM_CONF_SEARCH_CONFERENCE,
    ACT_VM_CONF_GET_SID,
    ACT_CONF_SEND_CID,
    ACT_CONF_ANSWER_CID,
    ACT_CONF_HAND_UP,
    ACT_CONF_REACTION,
    ACT_VM_CONF_HANDLE_MEMBER_DATA,
    ACT_VM_GET_CONFERENCE_INFO,
    ACT_CONF_SET_DEVICE,
    ACT_CONF_SET_DEVICES,
    ACT_CONF_CREATE,
    ACT_GET_CONF_LIST,
    ACT_CONF_DELETE,
    ACT_CONF_CHANGE,
    ACT_CONF_CHANGE_MEMBER_PROPS,
    ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SET_CONFSCALENDAR,
    ACT_DELETE_CONFSCALENDAR,
} from '../actionsTypes'

import {
    MUT_CONF_ADD,
    MUT_UPDATE_CONF,
    MUT_CONF_ADD_MEMBER,
    MUT_CONF_REMOVE_MEMBER,
    MUT_CONF_UPDATE_MEMBER,
    MUT_SET_CONF_SHARE,
    MUT_CONF_REMOVE,
} from '../mutationsTypes'

import module, {
    MEMBER_PROPS as MEMBER_PROPS_BASE,
    CAM_STATUS,
    CONF_PROPS,
    MIC_STATUS,
    INFO_PROPS,
    STATUS,
    END_REASONS,
    CONF_SCHEDULE_PROPS,
    CONF_SCHEDULE_PERIODICITY_PROPS,
    CONF_SCHEDULE_PERIODICITY_TYPES,
    CONF_SCHEDULE_PERIODICITY_DAYS,
} from './video-conf-abstract?t=vm'

import VideomostClient, { CONF_SETTINGS_GLOBAL_PROPS } from "../../sdk/videomost/VideomostClient"

import VideoMostXml from "../../api/videomost/VideoMostXml"

const CORS_IGNORED_PORT = 8080

export const VM_CONF_SCHEDULE_PROPS = {
    CONF_ID: 'confid',
    START_U_TIME: 'startUTime',
    FINISH_U_TIME: 'finishUTime',
    TOPIC: 'topic',
    PASSWORD: 'password',
    CONF_MODE: 'show_only_owner',
    VIDEO_QUALITY: 'video_mix_profile',
    VIDEO_QUALITY_CLIENT: 'video_out_profile',
    SIP_ENABLED: 'sip_enabled',
    SIP_SERVER: 'sip',
    SIP_PROXY: 'sip_proxy',
    SIP_MIXED_TYPE: 'sip_mixed_type',
    SIP_MIXED_PROFILE: 'sip_mixed_profile',
    PERIODICITY: 'rhythm',
}

export const VM_CONF_SCHEDULE_PERIODICITY_PROPS = {
    ENABLED: 'rhythm_pattern_enabled',
    TYPE: 'rhythm_pattern_mode_option',
    DAYS_COUNT: 'rhythm_pattern_period_day',
    WEEKS_COUNT: 'rhythm_pattern_period_week',
    MONTHS_COUNT: 'rhythm_pattern_period_month',
    YEARS_COUNT: 'rhythm_pattern_period_year',
    MN: 'rhythm_pattern_weekday_check_monday',
    TU: 'rhythm_pattern_weekday_check_tuesday',
    WE: 'rhythm_pattern_weekday_check_wednesday',
    TH: 'rhythm_pattern_weekday_check_thursday',
    FR: 'rhythm_pattern_weekday_check_friday',
    SA: 'rhythm_pattern_weekday_check_saturday',
    SU: 'rhythm_pattern_weekday_check_sunday',
}

export const VM_CONF_SCHEDULE_PERIODICITY_DAYS_PROPS = {

}

export const VM_CONF_SCHEDULE_PERIODICITY_TYPES = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
}

const xmlRpc = {
    _instance: null,
    getInstance({getters}) {
        if (!this._instance) {
            const params = getters[GET_VM_CONF_CONNECTION_PARAMS]
            const managementHost = getters[GET_VM_SERVER_MANAGEMENT_HOST](params.server)
            window.videoMostXml = this._instance = new VideoMostXml(managementHost, params.login, params.password)
        }
        return this._instance
    }
}

import {
    EVENTS,
    MEMBER_PROPS,
    MEMBER_ATTRS_PROPS,
    MEMBER_STATES_PROPS,
    CONF_SETTINGS_PROPS,
    CONF_SETTINGS_INFO_PROPS,
} from "../../sdk/videomost/VideomostClient"

import {CLIENTDATA, USERDATA, INTEGRATIONS, USERPROFILE} from "../modulesNames"
import event_bus from "../../eventBus"
import {CONF_TYPES} from '../../constants'


const LINK_REG = /\w+:\/\/(?<server>[^\/]+)\/service\/join\/\?confid=(?<confId>[^\&]+)&confpass=(?<pass>[^($|&)]+)/gm

const clients = {}

const RCH_COMMANDS = {
    CONTACT: 'rch_contact',
    ANSWER_CONTACT: 'rch_answer_contact',
    HANDUP: 'rch_handup',
    REACTION: 'rch_reaction',
}

const XML_RPC_ERRORS = {
    SESSION_EXPIRED: 'Error_session_expired',
    ACCESS: 'Error_access',
    CONFERENCE_FORMAT_DATE: 'Error_conference_format_date',
    CONFERENCE_EXIST: 'Error_conference_exist',
    DB: 'Error_db',
    CONFERENCE_NOT_EXIST: 'Error_conference_not_exist',
    CONFERENCE_WRONG_PARAM: 'Error_conference_wrong_param',
    CONFERENCE_PARAM_NOTALLOW: 'Error_conference_param_notallow',
}

const state = {
    server: '',
    login: '',
    password: '',
    ice: [],
    confs: {},
    inited: false,
}

const getters = {
    [GENERATE_CONF_PROPS]: (state, getters) => ({confId, pass, server, link}) => {
        let generatedLink = link
        let defServer = (getters[GET_VM_CONF_CONNECTION_PARAMS]).server
        let isServerExternal = false
        if (link) {
            let temp
            let groups = null
            while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
            let { confId: _confId, server: _server, pass: _pass } = groups || {}
            server = _server
            confId = _confId
            pass = _pass
            isServerExternal = defServer !== _server
        } else {
            if (!server) server = defServer
            generatedLink = `https://${server}/service/join/?confid=${confId}&confpass=${pass}`
        }
        return { id: `${confId}(conf.vm)@${server}`, confId, server, pass, link, generatedLink, isServerExternal }
    },
    [GET_VM_CONF_CONNECTION_PARAMS]: (state, getters, rootState, rootGetters) => {
        let ud = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`]

        let params = { }
        if (ud.videomostUrl)  {
            let url = new URL(ud.videomostUrl)
            params.server = url.host
        }
        let vcService = (ud.services || []).find(({type}) => type === 'videomost')
        if (vcService) {
            params.login = vcService.login
            params.password = vcService.password
        }
        return params
    },
    [GET_VM_SERVER_MANAGEMENT_HOST]: (state, getters, rootState, rootGetters) => (server) => {
        const isElectron = rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]
        let port = isElectron ? `` : `:${CORS_IGNORED_PORT}`
        return `${server}${port}`
    },
    [GET_CONF_ERROR_TEXT]: () => (error) => {
        let errorText = locale.errors.unknown
        switch (error) {
            case XML_RPC_ERRORS.SESSION_EXPIRED:
                errorText = locale.videomost.errors['conf-session-expired']
                break
            case XML_RPC_ERRORS.ACCESS:
                errorText = locale.videomost.errors['conf-access']
                break
            case XML_RPC_ERRORS.CONFERENCE_FORMAT_DATE:
                errorText = locale.videomost.errors['conf-format-date']
                break
            case XML_RPC_ERRORS.CONFERENCE_EXIST:
                errorText = locale.videomost.errors['conf-exist']
                break
            case XML_RPC_ERRORS.DB:
                errorText = locale.videomost.errors['conf-db']
                break
            case XML_RPC_ERRORS.CONFERENCE_NOT_EXIST:
                errorText = locale.videomost.errors['conf-not-exist']
                break
            case XML_RPC_ERRORS.CONFERENCE_WRONG_PARAM:
                errorText = locale.videomost.errors['conf-wrong-param']
                break
            case XML_RPC_ERRORS.CONFERENCE_PARAM_NOTALLOW:
                errorText = locale.videomost.errors['conf-param-not-allow']
                break
        }
        return errorText
    },
}

const actions = {
    // ------------------ XML-RPC --------------------------
    async [ACT_CONF_CREATE](obj, payload) {
        const { getters, dispatch } = obj
        let isAddCalendar
        let clonedPayload
        try {
            clonedPayload = JSON.parse(JSON.stringify(payload))
            isAddCalendar = clonedPayload && clonedPayload.additionalProps && clonedPayload.additionalProps.isAddCalendar
            const { additionalProps } = payload
            if (isAddCalendar) {
                delete(additionalProps.isAddCalendar)
                if (additionalProps.hasOwnProperty('attendees')) delete(additionalProps.attendees)
                if (payload.hasOwnProperty('recurrence')) delete(payload.recurrence)
            }
        } catch (e) {
            console.log("🚀 ~ file: video-conf-videomost.js:237 ~ error:", e)
        }

        await xmlRpc.getInstance(obj).createConferenceFull(convertCommonConfToVmFormat(payload))

        try {
            if (isAddCalendar) {
                const server = 'vmotsp.ros.chat'
                const host = getters[GET_VM_SERVER_MANAGEMENT_HOST](server)
                const calendarPayload = getCalendarPayload(payload, clonedPayload, host)
                const scheduledAppointment = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_ADD_APPOINTMENT}`, calendarPayload, { root: true })
                const confsCalendar = { confId: id, appointmentId: scheduledAppointment.id }
                dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, { root: true })
            }

        } catch (error) {
            console.log("🚀 ~ file: video-conf-videomost.js:253 ~ error:", error)
        }
        return true
    },
    async [ACT_CONF_CHANGE](obj, payload) {
        const { dispatch, getters, rootGetters } = obj
        let isAddCalendar
        let copyPayload
        try {
            copyPayload = JSON.parse(JSON.stringify(payload))
            isAddCalendar = copyPayload && copyPayload.additionalProps && copyPayload.additionalProps.isAddCalendar
            const { additionalProps } = payload 
            if (isAddCalendar) {

                delete (additionalProps.isAddCalendar)
                if (additionalProps.hasOwnProperty('attendees')) delete (additionalProps.attendees)
                if (payload.hasOwnProperty('recurrence')) delete (payload.recurrence)
            }
        } catch (error) {
            console.log("🚀 ~ file: video-conf-videomost.js:272 ~ error:", error)
        }

        await xmlRpc.getInstance(obj).setConferenceParamsArray({confid: payload[CONF_SCHEDULE_PROPS.ID], params: convertCommonConfToVmFormat(payload)})

        try {
            if (isAddCalendar) {
                const { startTime, endTime, topic } = copyPayload
                const startDayTime = moment.unix(startTime).startOf('day').unix()
                const endDayTime = moment.unix(endTime).startOf('day').unix()
                const appointments = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS}`, { type: "exchange", startTime: startDayTime, endTime: endDayTime }, { root: true })
                const appointment = appointments && appointments.find(ap => ap.subject === topic)
                const server = 'vmotsp.ros.chat'
                const host = getters[GET_VM_SERVER_MANAGEMENT_HOST](server)
                if (appointment) {
                    const calendarPayload = getCalendarPayload(payload, copyPayload, host)
                    calendarPayload.id = appointment.id
                    await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_EDIT_APPOINTMENT}`, calendarPayload, { root: true })
                    const storedIds = rootGetters[`${USERPROFILE}/${GET_CONFSCALENDAR}`](payload.id)
                    if (!storedIds) {
                        const confsCalendar = { confId: payload.id, appointmentId: appointment.id }
                        dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, {root: true})                    
                    }
                } else {
                    const calendarPayload = getCalendarPayload(payload, copyPayload, host)
                    const scheduledAppointment = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_ADD_APPOINTMENT}`, calendarPayload, {root: true})
                    const confsCalendar = { confId: payload.id, appointmentId: scheduledAppointment.id }
                    dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, {root: true})
                }
            }
            await xmlRpc.getInstance(obj).setConferenceParamsArray({confid: payload[CONF_SCHEDULE_PROPS.ID], params: convertCommonConfToVmFormat(payload)})
        } catch (error) {
            console.log("🚀 ~ file: video-conf-videomost.js:304 ~ error:", error)
        }
        return true
    },
    async [ACT_CONF_DELETE](obj, payload) {
        const { id } = payload
        const { dispatch } = obj
        payload = getVmIdObj(payload)
        try {
            await xmlRpc.getInstance(obj).stopConference(payload)
            dispatch(`${USERPROFILE}/${ACT_DELETE_CONFSCALENDAR}`, id, {root: true})
        } catch (e) {
            console.log("🚀 ~ file: video-conf-videomost.js:275 ~ e:", e)
        }
        return xmlRpc.getInstance(obj).deleteConference(payload)
    },
    async [ACT_VM_CONF_FINISH](obj, {confid}) {
        await xmlRpc.getInstance(obj).setConferenceParamsArray({confid, params: {finishTime: moment().utc().format('YYYY-MM-DD HH:mm:ss')} })
        await xmlRpc.getInstance(obj).stopConference({confid})
        event_bus.$emit('reload-conf-lists')
    },

    async [ACT_VM_CONF_GET_SID](obj) {
        return xmlRpc.getInstance(obj).getSid()
    },
    async [ACT_VM_CONF_SEARCH_CONFERENCE](obj, payload) {
        return xmlRpc.getInstance(obj).searchConference(payload)
    },
    async [ACT_VM_GET_CONFERENCE_INFO](obj, {confid}) {
        const conf = await xmlRpc.getInstance(obj).getConferenceInfo({confid})
        return conf ? convertVmConfToCommonFormat(conf) : conf
    },

    async [ACT_GET_CONF_LIST](obj) {
        const rpc = xmlRpc.getInstance(obj)
        const currentUnixTime = Math.floor(Date.now() / 1000)
        const sorting = 'finishTime DESC'
        const pagesize = 20

        let planned = await rpc.searchConference({filter:{"cg-finishUTime": currentUnixTime}, pagesize}) || []
        let passed = await rpc.searchConference({filter:{"cl-finishUTime": currentUnixTime}, sorting, pagesize}) || []
        // console.log("!!  - file: mainContentVideoConfs.vue - loadConfLists - this.confPassed", this.confPassed)
        if (planned) planned = planned.sort((a, b) => b.startUTime - a.startUTime)
        // console.log("!!  - file: mainContentVideoConfs.vue - loadConfLists - this.confPlanned", this.confPlanned)
        return {
            planned: planned.map((item) => convertVmConfToCommonFormat(item)),
            passed: passed.map((item) => convertVmConfToCommonFormat(item)),
        }
    },
    // -----------------------------------------------------
    [ACT_VM_CONF_UPDATE_DEVICES](obj, {id}) {
        let client = getClient(id)
        if (client) client.updateDevices()
    },
    async [ACT_CONF_JOIN](obj, {username, confId, pass, server, ...payload }) {
        let { getters, commit, rootGetters, dispatch } = obj
        let sid = ''
        let id = `${confId}(conf.vm)@${server}`
        let defDevices = rootGetters[`${USERDATA}/${GET_ACTIVE_MEDIA_DEVICES}`]
        let managementHost = getters[GET_VM_SERVER_MANAGEMENT_HOST](server)
        let client = new VideomostClient({ id, server, username, defDevices, managementHost })
        window.testClient = client
        let joinPayload = { confId, confPass: pass, ...payload }
        let connectionParams = getters[GET_VM_CONF_CONNECTION_PARAMS]
        if (server === connectionParams.server && connectionParams.login && connectionParams.password) {
            Object.assign(joinPayload, { login: connectionParams.login, password: connectionParams.password })
            try {
                sid = await dispatch(ACT_VM_CONF_GET_SID)
            } catch (e) {
                console.log('getSid error', e)
            }
        }
        commit(MUT_CONF_ADD, { id })
        client.joinConference({sid, ...joinPayload}).catch(e => {
            commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.ERROR, [CONF_PROPS.ERROR_TEXT]: e.message } })
            client.leaveConference()
        })
        clients[id] = client
        subscribeOnClient(obj, client)
        return id
    },
    async [ACT_CONF_JOIN_BY_LINK]({dispatch, commit}, {link, ...payload}) {
        let temp
        let groups = null
        while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
        let { confId, pass, server } = groups || {}
        return dispatch(ACT_CONF_JOIN, { confId, pass, server, ...payload })
    },
    async [ACT_CONF_FINISH]({getters, commit, dispatch}, {id}) {
        let confInfo = getters[GET_CONF_INFO](id)
        let confid = confInfo[INFO_PROPS.CONF_ID]
        await dispatch(ACT_CONF_LEAVE, {id})
        if (confid) await dispatch(ACT_VM_CONF_FINISH, {confid})
    },
    async [ACT_CONF_LEAVE]({getters, commit}, {id}) {
        let client = getClient(id)
        if (client) {
            await client.leaveConference()
            commit(MUT_CONF_REMOVE, {id})
        }
    },
    [ACT_CONF_CAMERA_TOGGLE]({getters}, {id}) {
        let status = getters[GET_CONF_CAM_STATUS](id)
        let val
        if (status === CAM_STATUS.DISABLE) val = false
        else if (status === CAM_STATUS.ENABLE) val = true
        else return
        let client = getClient(id)
        if (client) client.disableConfCamera(val)
    },
    [ACT_CONF_MICROPHONE_TOGGLE]({getters}, {id}) {
        let status = getters[GET_CONF_MIC_STATUS](id)
        let val
        if (status === MIC_STATUS.DISABLE) val = false
        else if (status === MIC_STATUS.ENABLE) val = true
        else return
        let client = getClient(id)
        if (client) client.muteConfMicrophone(val)
    },
    [ACT_CONF_SPEAKER_TOGGLE]({getters, commit}, {id}) {
        let status = getters[GET_CONF_SPEAKER_STATUS](id)
        let newStatus = !status
        let client = getClient(id)
        if (client) {
            client.muteConfSpeaker(!newStatus)
            commit(MUT_UPDATE_CONF, {id, props: {[CONF_PROPS.SPEAKER_STATUS]: newStatus}})
        }
    },
    [ACT_CONF_CHANGE_MEMBER_PROPS]({getters}, {id, memberId, prop, value}) {
        let client = getClient(id)
        if (client) {
            let props = { }
            switch (prop) {
                case MEMBER_PROPS_BASE.MODERATOR:
                    props[MEMBER_ATTRS_PROPS.MODERATOR] = +value
                    break
                case MEMBER_PROPS_BASE.CAMERA_GLOBAL:
                    props[MEMBER_ATTRS_PROPS.VISIBLE] = +value
                    break
                case MEMBER_PROPS_BASE.MICROPHONE_GLOBAL:
                    props[MEMBER_ATTRS_PROPS.SPEAKER] = +value
                    if (value) props[MEMBER_ATTRS_PROPS.WANTSPEAK] = 0
                    break
                case MEMBER_PROPS_BASE.BANNED:
                    props[MEMBER_ATTRS_PROPS.BANNED] = +value
                    break
                case MEMBER_PROPS_BASE.WANT_SPEAK:
                    props[MEMBER_ATTRS_PROPS.WANTSPEAK] = +value
                    break
            }
            if (Object.keys(props).length) {
                props = { [memberId]: props }
                client.setMembersProps(props)
            }
        }
    },
    [ACT_CONF_SHARE](obj, {id}) {
        let client = getClient(id)
        if (client) client.shareToggle()
    },
    [ACT_CONF_SEND_CID](obj, { id }) {
        let { rootGetters } = obj
        let cid = rootGetters[`${USERDATA}/${GET_UID}`]
        sendData(obj, {id, name: RCH_COMMANDS.CONTACT, data: { cid }})
    },
    [ACT_CONF_ANSWER_CID](obj, { id, to }) {
        let { rootGetters } = obj
        let cid = rootGetters[`${USERDATA}/${GET_UID}`]
        sendData(obj, {id, name: RCH_COMMANDS.ANSWER_CONTACT, data: { cid }, to})
    },
    [ACT_CONF_HAND_UP](obj, { id, handup }) {
        sendData(obj, {id, name: RCH_COMMANDS.HANDUP, data: { handup }})
    },
    [ACT_CONF_REACTION](obj, { id, reaction }) {
        sendData(obj, {id, name: RCH_COMMANDS.REACTION, data: { reaction }})
    },
    [ACT_CONF_SET_DEVICE](obj, {id, kind, deviceId}) {
        let client = getClient(id)
        if (!client) return
        client.setDevice(kind, deviceId)
    },
    [ACT_VM_CONF_HANDLE_MEMBER_DATA]({ commit, dispatch }, { id, from, name, data }) {
        let member = { [MEMBER_PROPS_BASE.ID]: from }
        switch (name) {
            case RCH_COMMANDS.CONTACT:
                dispatch(ACT_CONF_ANSWER_CID, { id, to: from })
            case RCH_COMMANDS.ANSWER_CONTACT:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.CID]: data[MEMBER_PROPS_BASE.CID] }})
                break
            case RCH_COMMANDS.HANDUP:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.HANDUP]: data[MEMBER_PROPS_BASE.HANDUP] }})
                break
            case RCH_COMMANDS.REACTION:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.REACTION]: data[MEMBER_PROPS_BASE.REACTION] }})
                break
        }
    },
}

function subscribeOnClient({ commit, dispatch, getters }, client) {
    let id = client.getActiveConfId()
    client.on(EVENTS.CONF_JOINED, (id, confInfo) => {
        let info = prepareVmInfo(confInfo)
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.INFO]: info } })
    })
    client.on(EVENTS.CONF_TERMINATED, (reason) => {
        if (!(reason in END_REASONS)) reason = END_REASONS.UNKNOWN
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.END_REASON]: reason, [CONF_PROPS.STATUS]: STATUS.ENDED } })
    })
    client.on(EVENTS.REBUILD_LAYOUT, () => {
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.ACTIVE } })
    })
    client.on(EVENTS.USERS_INITED, (users) => {
        // console.log('EVENTS.USERS_INITED', users)
        users.forEach((user) => commit(MUT_CONF_ADD_MEMBER, { id, member: prepareVmMember(user) }))
        dispatch(ACT_CONF_SEND_CID, { id })
    })
    client.on(EVENTS.USER_ADDED, (user) => {
        let member = prepareVmMember(user)
        commit(MUT_CONF_ADD_MEMBER, { id, member})
        dispatch(ACT_CONF_SEND_CID, { id, to: member[MEMBER_PROPS_BASE.ID] })
    })
    client.on(EVENTS.USER_CHANGED, (user, attr, newVal) => {
        let member = prepareVmMember(user)
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(EVENTS.USER_STATES_CHANGED, (user, attr, newVal) => {
        let member = prepareVmMember(user)
        // если нам включили камеру или микрофон, то снимаем WANT_SPEAK
        if (member[MEMBER_PROPS_BASE.ME]) {
            const oldVal = getters[GET_CONF_OWN_MEMBER](id)
            if (oldVal[MEMBER_PROPS_BASE.WANT_SPEAK]) {
                if (member[MEMBER_PROPS_BASE.CAMERA_GLOBAL] || member[MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]) {
                    dispatch(ACT_CONF_WANT_SPEAK, {id, value: false})
                }
            }
        }
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(EVENTS.USER_REMOVED, (user) => {
        commit(MUT_CONF_REMOVE_MEMBER, {id, member: prepareVmMember(user)})
    })
    client.on(EVENTS.SHARE_STARTED, (user) => {
        let userId = ''
        if (user) {
            userId = user[MEMBER_PROPS.LOGIN]
        } else {
            let ownMember = getters[GET_CONF_OWN_MEMBER](id)
            userId = ownMember && ownMember[MEMBER_PROPS_BASE.ID]
        }
        commit(MUT_SET_CONF_SHARE, {id, userId})
    })
    client.on(EVENTS.SHARE_ENDED, () => {
        commit(MUT_SET_CONF_SHARE, {id})
    })
    client.on(EVENTS.SHARE_ENDED, () => {
        commit(MUT_SET_CONF_SHARE, {id})
    })
    client.on(EVENTS.ON_DEVICES_CHANGED, ( devices ) => {
        dispatch(ACT_CONF_SET_DEVICES, { id, devices })
    })
    client.on(EVENTS.ON_DATA, ( user, name, data ) => {
        if (user) dispatch(ACT_VM_CONF_HANDLE_MEMBER_DATA, { id, from: user[MEMBER_PROPS.LOGIN], name, data: JSON.parse(data) })
    })
}

const getCalendarPayload = (payload, clonedPayload, host) => {
    const { additionalProps: addProps } = clonedPayload
    const attendees = addProps && addProps.attendees
    const recurrence = clonedPayload && clonedPayload.recurrence
    const { id, password, startTime, endTime, topic } = payload

    const link = `https://${host}/service/join/?confid=${id}&password=${password}`
    const calendarPayload = {
        type: 'exchange',
        startTime,
        endTime,
        allDay: false,
        recurrence,
        isPrivate: false,
        subject: topic,
        location: locale.videoconf['location_VM'],
        body: `${locale.videoconf['link']}: 
${link}`
    }
    if (attendees && attendees.length) {
        const attendeesFormed = []
        if (attendees && attendees.length) attendees.map(email => {
            attendeesFormed.push({ address: email })
        })
        calendarPayload.attendees = attendeesFormed
    }
    return calendarPayload
}

function prepareVmInfo(confSettings) {
    let confInfo = confSettings[CONF_SETTINGS_PROPS.CONF_INFO]
    let global = confSettings[CONF_SETTINGS_PROPS.GLOBAL]
    let ownerName = [confInfo[CONF_SETTINGS_INFO_PROPS.LASTNAME], confInfo[CONF_SETTINGS_INFO_PROPS.FIRSTNAME]].filter((val) => val).join(' ')
    let id = confInfo[CONF_SETTINGS_INFO_PROPS.CONF_ID]
    let password = confInfo[CONF_SETTINGS_INFO_PROPS.PASSWORD]
    let server = global[CONF_SETTINGS_GLOBAL_PROPS.SERVER]
    let link = `https://${server}/service/join/?confid=${id}&confpass=${password}`
    let startTime = confInfo[CONF_SETTINGS_INFO_PROPS.START_TIME]
    let finishTime = confInfo[CONF_SETTINGS_INFO_PROPS.FINISH_TIME]
    let sipEnabled = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_ENABLED]
    let sipType = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_TYPE]
    let sip = confInfo[CONF_SETTINGS_INFO_PROPS.SIP]
    let sipProxy = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_PROXY]
    let sipMixedType = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_MIXED_TYPE]
    let sipMixedProfile = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_MIXED_PROFILE]
    return {
        [INFO_PROPS.TOPIC]: confInfo[CONF_SETTINGS_INFO_PROPS.TOPIC],
        [INFO_PROPS.OWNER_CID]: null,
        [INFO_PROPS.OWNER_NAME]: ownerName,
        [INFO_PROPS.CONF_ID]: id,
        [INFO_PROPS.PASSWORD]: password,
        [INFO_PROPS.LINK]: link,
        [INFO_PROPS.START_TIME]: startTime,
        [INFO_PROPS.FINISH_TIME]: finishTime,
        [INFO_PROPS.SIP_ENABLED]: sipEnabled,
        [INFO_PROPS.SIP_TYPE]: sipType,
        [INFO_PROPS.SIP]: sip,
        [INFO_PROPS.SIP_PROXY]: sipProxy,
        [INFO_PROPS.SIP_MIXED_TYPE]: sipMixedType,
        [INFO_PROPS.SIP_MIXED_PROFILE]: sipMixedProfile
    }
}

function prepareVmMember(user) {
    let cam = (MEMBER_STATES_PROPS.CAM in user[MEMBER_PROPS.STATES]) ? Boolean(user[MEMBER_PROPS.STATES][MEMBER_STATES_PROPS.CAM]) : true
    let mic = (MEMBER_STATES_PROPS.MIC in user[MEMBER_PROPS.STATES]) ? Boolean(user[MEMBER_PROPS.STATES][MEMBER_STATES_PROPS.MIC]) : true
    return {
        [MEMBER_PROPS_BASE.ID]: user[MEMBER_PROPS.LOGIN],
        [MEMBER_PROPS_BASE.NAME]: user[MEMBER_PROPS.NAME],
        [MEMBER_PROPS_BASE.ME]: user[MEMBER_PROPS.IM],
        [MEMBER_PROPS_BASE.BANNED]: user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.BANNED],
        [MEMBER_PROPS_BASE.MODERATOR]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.MODERATOR]),
        [MEMBER_PROPS_BASE.HIDDEN]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.HIDDEN]),
        [MEMBER_PROPS_BASE.OWNER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.OWNER]),
        [MEMBER_PROPS_BASE.PRESENTER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRESENTER]),
        [MEMBER_PROPS_BASE.PRIORITY]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRIORITY]),
        [MEMBER_PROPS_BASE.READER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.READER]),
        [MEMBER_PROPS_BASE.CAMERA_GLOBAL]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.VISIBLE]),
        [MEMBER_PROPS_BASE.CAMERA]: cam,
        [MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.SPEAKER]),
        [MEMBER_PROPS_BASE.MICROPHONE]: mic,
        [MEMBER_PROPS_BASE.WANT_SHARE]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSHARE]),
        [MEMBER_PROPS_BASE.WANT_SPEAK]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSPEAK]),
        [MEMBER_PROPS_BASE.WRITER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WRITER]),
    }
}

function sendData({getters}, {id, to, name, data}) {
    let client = getClient(id)
    if (!client) return
    data = JSON.stringify(data)
    if (to) {
        client.sendData(name, data, to)
    } else {
        let members = getters[GET_CONF_MEMBERS](id) || []
        members.forEach((member) => {
            if (member[MEMBER_PROPS_BASE.ME]) return
            client.sendData(name, data, member[MEMBER_PROPS_BASE.ID])
        })
    }
}

function getClient(id) {
    return clients[id]
}

const mutations = {
}

function convertVmConfToCommonFormat(conf) {
    let commonVmPropsKeys = [
        VM_CONF_SCHEDULE_PROPS.CONF_ID,
        VM_CONF_SCHEDULE_PROPS.START_U_TIME,
        VM_CONF_SCHEDULE_PROPS.FINISH_U_TIME,
        VM_CONF_SCHEDULE_PROPS.TOPIC,
        VM_CONF_SCHEDULE_PROPS.PASSWORD,
        VM_CONF_SCHEDULE_PROPS.PERIODICITY,
    ]
    let commonVmProps = commonVmPropsKeys.reduce((prev, cur) => {
        prev[cur] = conf[cur]
        delete conf[cur]
        return prev
    }, {})
    let commonConf = { [CONF_SCHEDULE_PROPS.TYPE]: CONF_TYPES.VM }
    commonConf[CONF_SCHEDULE_PROPS.ID] = commonVmProps[VM_CONF_SCHEDULE_PROPS.CONF_ID]
    commonConf[CONF_SCHEDULE_PROPS.START_TIME] = commonVmProps[VM_CONF_SCHEDULE_PROPS.START_U_TIME]
    commonConf[CONF_SCHEDULE_PROPS.END_TIME] = commonVmProps[VM_CONF_SCHEDULE_PROPS.FINISH_U_TIME]
    commonConf[CONF_SCHEDULE_PROPS.TOPIC] = commonVmProps[VM_CONF_SCHEDULE_PROPS.TOPIC]
    commonConf[CONF_SCHEDULE_PROPS.PASSWORD] = commonVmProps[VM_CONF_SCHEDULE_PROPS.PASSWORD]
    commonConf[CONF_SCHEDULE_PROPS.VIDEO_DISABLED] = false //@todo
    commonConf[CONF_SCHEDULE_PROPS.WAITING] = false //@todo
    commonConf[CONF_SCHEDULE_PROPS.AT_ONCE] = false //@todo
    const periodicity = commonVmProps[VM_CONF_SCHEDULE_PROPS.PERIODICITY] && JSON.parse(commonVmProps[VM_CONF_SCHEDULE_PROPS.PERIODICITY])
    if (periodicity && periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE] && periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.ENABLED] === '1') {
        let type
        let count
        let days = []
        switch (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]) {
            case VM_CONF_SCHEDULE_PERIODICITY_TYPES.DAILY:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.DAY
                count = periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.DAYS_COUNT]
                break
            case VM_CONF_SCHEDULE_PERIODICITY_TYPES.WEEKLY:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.WEEK
                count = periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.WEEKS_COUNT]
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.MN]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.MN)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TU]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.TU)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.WE]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.WE)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TH]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.TH)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.FR]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.FR)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.SA]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.SA)
                if (periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.SU]) days.push(CONF_SCHEDULE_PERIODICITY_DAYS.SU)
                break
            case VM_CONF_SCHEDULE_PERIODICITY_TYPES.MONTHLY:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.MONTH
                count = periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.MONTHS_COUNT]
                break
            case VM_CONF_SCHEDULE_PERIODICITY_TYPES.YEARLY:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.YEAR
                count = periodicity[VM_CONF_SCHEDULE_PERIODICITY_PROPS.YEARS_COUNT]
                break
        }
        if (type) {
            commonConf[CONF_SCHEDULE_PROPS.PERIODICITY] = {
                [CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]: type,
                [CONF_SCHEDULE_PERIODICITY_PROPS.COUNT]: +count,
            }
            if (days.length) commonConf[CONF_SCHEDULE_PROPS.PERIODICITY][CONF_SCHEDULE_PERIODICITY_PROPS.DAYS] = days
        }
    }

    return { additionalProps: conf, ...commonConf }
}

function convertCommonConfToVmFormat(conf) {
    let vmConf = { ...conf.additionalProps }
    // moment.unix(this.from).utc().format("YYYY-MM-DD HH:mm:ss")
    // moment.unix(this.to).utc().format("YYYY-MM-DD HH:mm:ss")
    vmConf[VM_CONF_SCHEDULE_PROPS.CONF_ID] = conf[CONF_SCHEDULE_PROPS.ID]
    vmConf[VM_CONF_SCHEDULE_PROPS.START_U_TIME] = conf[CONF_SCHEDULE_PROPS.START_TIME]
    vmConf[VM_CONF_SCHEDULE_PROPS.FINISH_U_TIME] = conf[CONF_SCHEDULE_PROPS.END_TIME]
    vmConf[VM_CONF_SCHEDULE_PROPS.TOPIC] = conf[CONF_SCHEDULE_PROPS.TOPIC]
    vmConf[VM_CONF_SCHEDULE_PROPS.PASSWORD] = conf[CONF_SCHEDULE_PROPS.PASSWORD]
    if (conf[CONF_SCHEDULE_PROPS.PERIODICITY]) {
        let rhythm = {
            [VM_CONF_SCHEDULE_PERIODICITY_PROPS.ENABLED]: '1'
        }
        let type
        let coutProp
        let periodicity = conf[CONF_SCHEDULE_PROPS.PERIODICITY]
        switch (periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]) {
            case CONF_SCHEDULE_PERIODICITY_TYPES.DAY:
                type = VM_CONF_SCHEDULE_PERIODICITY_TYPES.DAILY
                coutProp = VM_CONF_SCHEDULE_PERIODICITY_TYPES.DAYS_COUNT
                break
            case CONF_SCHEDULE_PERIODICITY_TYPES.WEEK:
                type = VM_CONF_SCHEDULE_PERIODICITY_TYPES.WEEKLY
                coutProp = VM_CONF_SCHEDULE_PERIODICITY_TYPES.WEEKS_COUNT
                if (periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS]) {
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.MN] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.MN)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TU] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.TU)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.WE] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.WE)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TH] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.TH)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.FR] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.FR)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.SA] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.SA)
                    rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.SU] = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].includes(CONF_SCHEDULE_PERIODICITY_DAYS.SU)
                }
                break
            case CONF_SCHEDULE_PERIODICITY_TYPES.MONTH:
                type = VM_CONF_SCHEDULE_PERIODICITY_TYPES.MONTHLY
                coutProp = VM_CONF_SCHEDULE_PERIODICITY_TYPES.MONTHS_COUNT
                break
            case CONF_SCHEDULE_PERIODICITY_TYPES.YEARLY:
                type = VM_CONF_SCHEDULE_PERIODICITY_TYPES.YEARLY
                coutProp = VM_CONF_SCHEDULE_PERIODICITY_TYPES.YEARS_COUNT
                break
        }
        rhythm[VM_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE] = type
        rhythm[coutProp] = `${periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.COUNT]}`
        Object.keys(rhythm).forEach((key) => { if (rhythm[key] === undefined) delete rhythm[key] })
        vmConf[VM_CONF_SCHEDULE_PROPS.PERIODICITY] = JSON.stringify(rhythm)
    }
    Object.keys(vmConf).forEach((key) => { if (vmConf[key] === undefined) delete vmConf[key] })
    return vmConf
}

function getVmIdObj(conf) {
    return {
        [VM_CONF_SCHEDULE_PROPS.CONF_ID]: conf[CONF_SCHEDULE_PROPS.ID]
    }
}

const testModule = { ...module }

Object.assign(testModule.state, state)
Object.assign(testModule.getters, getters)
Object.assign(testModule.actions, actions)
Object.assign(testModule.mutations, mutations)

export default testModule