// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.text[data-v-59146e4e] {
  display: block;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SetBirthday.vue"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":["span.text[data-v-59146e4e] {\n  display: block;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
