var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "tetra-chat-modal dialog",
      attrs: { name: _vm.modalName, "modal-props": _vm.sumModalProps },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "custom-header" },
          [
            _c("icon-image", {
              staticClass: "icon",
              attrs: { text: _vm.contactName },
              on: {
                contextmenu: function ($event) {
                  return _vm.showContextMenu($event, _vm.contact)
                },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.showContextMenu($event, _vm.contact)
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "contact-info",
                on: {
                  click: function ($event) {
                    return _vm.showContextMenu($event, _vm.contact)
                  },
                  contextmenu: function ($event) {
                    return _vm.showContextMenu($event, _vm.contact)
                  },
                },
              },
              [
                _c("div", { staticClass: "contact-name" }, [
                  _vm._v(_vm._s(_vm.contactName)),
                ]),
                _c("div", { staticClass: "contact-status" }, [
                  _vm._v(_vm._s(_vm.status)),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "call-btn circle-animation",
                attrs: { title: _vm.$t("tetra-call") },
                on: { click: _vm.makeCall },
              },
              [_c("i", { staticClass: "fa fa-phone" })]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "chat", attrs: { id: "tetra-chat" } },
          [
            !_vm.isChatMessages
              ? _c("div", { staticClass: "chat-space" })
              : _vm._e(),
            _vm._l(_vm.chatMessages, function (value, name, index) {
              return _c(
                "div",
                { staticClass: "chat-msg-wrapper" },
                [
                  _c("div", { staticClass: "chat-date" }, [
                    _vm._v(_vm._s(`${name}`)),
                  ]),
                  _vm._l(_vm.chatMessages[name], function (m, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "chat-msg",
                        class: {
                          in: m.direction === "in",
                          out: m.direction === "out",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "msg",
                            class: _vm.getMsgClass(index, m, name, i),
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(`${m.text}`)),
                            ]),
                            _c("div", { staticClass: "time" }, [
                              _vm._v(_vm._s(`${m.time}`)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
          ],
          2
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "message-overflow" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.alert,
                      expression: "alert",
                    },
                  ],
                  staticClass: "message-overflow-alert",
                },
                [_c("span", [_vm._v(_vm._s(_vm.alert_msg))])]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "enter-message-area" },
          [
            _c("custom-textarea", {
              key: "inputTetraMsg",
              ref: "inputMsg",
              staticClass: "custom-textarea",
              class: { focus: _vm.focus },
              attrs: {
                id: "message-input",
                disabled: !_vm.isTetraConnected,
                custom_placeholder: _vm.$t("chat.enter-msg"),
                text: _vm.inputMsg,
                focus: _vm.focus,
                caret_position: _vm.inputMsg.length,
                isFormatter: false,
              },
              on: {
                "update:text": function ($event) {
                  _vm.inputMsg = $event
                },
                "update:focus": function ($event) {
                  _vm.focus = $event
                },
                inputmounted: _vm.focusInput,
                send: _vm.sendSSI,
              },
            }),
            _c(
              "div",
              {
                staticClass: "buttons circle-animation right-btn send-message",
                class: { "disable-btn": _vm.disableBtn },
                attrs: { title: _vm.$t("chat.send-msg") },
                on: { click: _vm.sendSSI },
              },
              [_c("i", { staticClass: "fa fa-paper-plane" })]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }