// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content[data-v-3690e3fb] {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/side/SideContent.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".content[data-v-3690e3fb] {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  min-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
