// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera[data-v-500a11d2] {
  border-bottom: 1px solid var(--border-bottom-section);
}
.camera .camera-header[data-v-500a11d2] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.camera .camera-header span[data-v-500a11d2] {
  font-size: 16px;
  font-weight: 600;
}
.camera .camera-header .camera-select[data-v-500a11d2] {
  width: 65%;
}
.camera .camera-video[data-v-500a11d2] {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0px 15px 12px;
}
.camera .camera-video #videoDescription[data-v-500a11d2] {
  color: var(var(--placeholder-color));
  position: absolute;
  top: -6%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera .camera-video #videoDescription span[data-v-500a11d2] {
  font-size: 16px;
}
.camera .camera-video #localVideoWeb[data-v-500a11d2] {
  background-color: var(--bg-color);
  height: 270px;
  max-width: 480px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/settings/WebcamerasSettings.vue"],"names":[],"mappings":"AAAA;EACE,qDAAqD;AACvD;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,SAAS;EACT,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,iCAAiC;EACjC,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".camera[data-v-500a11d2] {\n  border-bottom: 1px solid var(--border-bottom-section);\n}\n.camera .camera-header[data-v-500a11d2] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px;\n}\n.camera .camera-header span[data-v-500a11d2] {\n  font-size: 16px;\n  font-weight: 600;\n}\n.camera .camera-header .camera-select[data-v-500a11d2] {\n  width: 65%;\n}\n.camera .camera-video[data-v-500a11d2] {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  padding: 0px 15px 12px;\n}\n.camera .camera-video #videoDescription[data-v-500a11d2] {\n  color: var(var(--placeholder-color));\n  position: absolute;\n  top: -6%;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.camera .camera-video #videoDescription span[data-v-500a11d2] {\n  font-size: 16px;\n}\n.camera .camera-video #localVideoWeb[data-v-500a11d2] {\n  background-color: var(--bg-color);\n  height: 270px;\n  max-width: 480px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
