var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.new-conference"),
      },
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("confSettingsBase", {
            ref: "settings",
            attrs: { fieldsToShow: _vm.fieldsToShow, isNew: true },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("div", { staticClass: "conf-settings conf-item" }, [
                      _c("a", { on: { click: _vm.openConfSettings } }, [
                        _vm._v(_vm._s(_vm.confSettingsText)),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: {
              click: function ($event) {
                return _vm.modalClose()
              },
            },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.save") },
            on: { click: _vm.createNewConf },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }