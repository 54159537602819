var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-groupmsg-wrapper" }, [
    _vm.message.author
      ? _c("div", { staticClass: "fwd" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "chat-group-messages",
        attrs: { "data-id-list": _vm.idList },
      },
      [
        _vm._l(_vm.message.groupMsgs.slice(0, 4), function (msg, i) {
          return _c("div", { key: i, staticClass: "chat-group-message" }, [
            _c(
              "div",
              { staticClass: "preview-block" },
              [
                _vm.isPending
                  ? _c("div", { staticClass: "pending" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upload-abort-btn message-btn",
                          on: { click: _vm.abortAjaxCall },
                        },
                        [
                          _c("progress-circle", {
                            attrs: {
                              load_progress: _vm.loadProgress,
                              radius: 16,
                              width: 40,
                              height: 40,
                            },
                          }),
                          _c("i", { staticClass: "fa fa-ban" }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.isPending && !_vm.groupSrcs[i]
                  ? _c("content-loader")
                  : _vm._e(),
                _c("div", { staticClass: "img-wrapper" }, [
                  !_vm.groupSrcs[i]
                    ? _c("img", {
                        attrs: {
                          src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          draggable: "false",
                          src: _vm.groupSrcs[i],
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doShowViewer(i)
                          },
                        },
                      }),
                ]),
              ],
              1
            ),
          ])
        }),
        _vm.message.groupMsgs.length - 4 > 0
          ? _c("div", { staticClass: "groupText" }, [
              _c("span", [
                _vm._v(
                  _vm._s("+" + (_vm.message.groupMsgs.length - 4) + " ...")
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }