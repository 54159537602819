
    import { mapGetters, mapActions } from 'vuex'
    import MediaViewer from './MediaViewer.vue'
    import ChatMessageMixin from '../chat/chat-message-mixin'

    import {BLOB, CHAT, CONTACTS, AJAX, DLP} from '../../store/modulesNames'
    import {ACT_AJAX_GET_FILE, ACT_SEND_FILE_SAVE_EVENT} from '../../store/actionsTypes'
    import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'
    import {
        GET_FILE_BLOB,
        GET_MERGED_CONTACT_BY_ID,
        GET_MESSAGE_BY_ID,
    } from '../../store/gettersTypes'

    export default {
        name: 'ChatMessageMediaViewer',
        props: {
            src: {
                type: Object,
                required: false
            },
            message: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            readOnly: {
                type: Boolean,
                required: false,
                default: false
            },
            index: {
                type: Number,
                required: false
            }
        },
        mixins: [ChatMessageMixin],
        extends: MediaViewer,
        async created() {
            if (this.index > -1) {
                this.currImgIndex = this.index
                this.loadMediaItemFile()
            } else {
                await this.loadMessageFile({
                    id: this.message.id,
                    file: this.message.msg.file,
                    size: this.message.msg.size,
                    remoteHost: this.message.remoteServerHost,
                })
                this.preload = false
            }
            if (this.readOnly) {
                this.setWindowProtection(true)
            }
        },
        watch: {
            currImgIndex(val) {
                this.loadMediaItemFile()
            }
        },
        asyncComputed: {
            preview() {
                if (this.type === 'pdf') return ''
                if (this.index > -1) {
                    return this.getPreviewByIndex(this.index)
                } else return this.getPreview(this.message.msg)
            },
        },
        computed: {
            user() {
                return this[GET_MERGED_CONTACT_BY_ID](this.message.senderId)
            },
            path() {
                if (this.mediaItem) {
                    const fileBlob = this[GET_FILE_BLOB](this.mediaItem.file)
                    return fileBlob
                }
                return this[GET_FILE_BLOB](this.src.file)
            },
            time() {
                let msg = this[GET_MESSAGE_BY_ID](this.message.id)
                let time = msg ? msg.time : this.message.time
                return time && this.getTimeFromSecondsLeft(time) || ''
            },
            extension() {
                if (this.mediaItem) return this.mediaItem.extension || ''
                return this.src.extension || ''
            },
            subtitle() {
                if (this.message && this.message.msg && this.message.msg.text) return this.message.msg.text
            },
            mediaItems() {
                return this.message && this.message.groupMsgs
            },
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
            ...mapGetters(CHAT, [GET_MESSAGE_BY_ID]),
            ...mapGetters(BLOB, [GET_FILE_BLOB]),
        },
        methods: {
            onItemSaved() {
                this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, {
                    fileName:  this.message.msg.file,
                    sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                    sourceData: {
                        id: this.message.id,
                        cid: this.message.cid,
                        cidType: this.message.cidType,
                    }
                })
            },
            async loadMediaItemFile() {
                let blob_url = app.store.getters['blob/getFileBlob'](this.mediaItem.file);
                if (blob_url) {
                    this.preload = false
                    return
                }
                await this[ACT_AJAX_GET_FILE]({url: this.mediaItem.file, fileSize: this.mediaItem.size, link: true})
                this.preload = false
            },
            getPreviewByIndex(index) {
                const blob = this[GET_FILE_BLOB](this.mediaItems[index].previewFile)
                if (blob) return blob
                else {
                    const filePathUrl = this.$store.getters['userdata/getHost'] + '/' + declarations.http_post_dirs.CHAT_DATA_DIR
                    return filePathUrl + this.mediaItems[index].previewFile
                }
            },
            close() {
                this.setWindowProtection(false)
                this.$modal.hide('viewer')
            },
            ...mapActions(AJAX, [ACT_AJAX_GET_FILE])
        },
    }
