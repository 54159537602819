// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrcode-entrance[data-v-4775bba4] {
  display: flex;
  flex-direction: column;
}
.qrcode-entrance .component-section .drop-area[data-v-4775bba4] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: calc(100% - 4px);
  border: 2px dashed #888;
  border-radius: 5px;
}
.qrcode-entrance .component-section .drop-area.dragover[data-v-4775bba4] {
  border-color: #005aab;
}
.qrcode-entrance .component-section .drop-area .area-content[data-v-4775bba4] {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.qrcode-entrance .component-section .drop-area .area-content > *[data-v-4775bba4] {
  margin: auto;
}
.qrcode-entrance .component-section .drop-area .area-content[data-v-4775bba4] > :first-child {
  color: var(--login-color);
  margin-top: 10%;
  margin-bottom: 10%;
}
.qrcode-entrance .component-section .drop-area .area-content .qr-file-select[data-v-4775bba4] {
  width: 60% !important;
  margin-bottom: 20%;
}
.qrcode-entrance .component-section .drop-area .area-content p[data-v-4775bba4] {
  font-size: 18px;
  padding: 0 25px;
}
.qrcode-entrance .component-section .input[data-v-4775bba4] {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 38%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/login/QrFile.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gCAAgC;EAChC,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV;AACA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".qrcode-entrance[data-v-4775bba4] {\n  display: flex;\n  flex-direction: column;\n}\n.qrcode-entrance .component-section .drop-area[data-v-4775bba4] {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding-bottom: calc(100% - 4px);\n  border: 2px dashed #888;\n  border-radius: 5px;\n}\n.qrcode-entrance .component-section .drop-area.dragover[data-v-4775bba4] {\n  border-color: #005aab;\n}\n.qrcode-entrance .component-section .drop-area .area-content[data-v-4775bba4] {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n.qrcode-entrance .component-section .drop-area .area-content > *[data-v-4775bba4] {\n  margin: auto;\n}\n.qrcode-entrance .component-section .drop-area .area-content[data-v-4775bba4] > :first-child {\n  color: var(--login-color);\n  margin-top: 10%;\n  margin-bottom: 10%;\n}\n.qrcode-entrance .component-section .drop-area .area-content .qr-file-select[data-v-4775bba4] {\n  width: 60% !important;\n  margin-bottom: 20%;\n}\n.qrcode-entrance .component-section .drop-area .area-content p[data-v-4775bba4] {\n  font-size: 18px;\n  padding: 0 25px;\n}\n.qrcode-entrance .component-section .input[data-v-4775bba4] {\n  text-align: center;\n  margin-top: 10%;\n  margin-bottom: 38%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
