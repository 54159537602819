// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu-item[data-v-4907c7a5] {
  display: flex;
  flex-direction: row;
  -webkit-app-region: no-drag;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--text-color);
}
.context-menu-item[data-v-4907c7a5]:hover {
  background-color: var(--bg-color-hover);
}
.context-menu-item .checked[data-v-4907c7a5] {
  width: 20px;
}
.context-menu-item .text[data-v-4907c7a5] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/contextMenu/ContextMenuItem.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,iBAAiB;EACjB,eAAe;EACf,wBAAwB;AAC1B;AACA;EACE,uCAAuC;AACzC;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".context-menu-item[data-v-4907c7a5] {\n  display: flex;\n  flex-direction: row;\n  -webkit-app-region: no-drag;\n  padding: 5px 10px;\n  cursor: pointer;\n  color: var(--text-color);\n}\n.context-menu-item[data-v-4907c7a5]:hover {\n  background-color: var(--bg-color-hover);\n}\n.context-menu-item .checked[data-v-4907c7a5] {\n  width: 20px;\n}\n.context-menu-item .text[data-v-4907c7a5] {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
