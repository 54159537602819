// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-text[data-v-0a6b6f6c] {
  word-break: break-word;
  color: var(--message-grey-color);
  height: 22px;
}
.message-text span[data-v-0a6b6f6c] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/MessageText.vue"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".message-text[data-v-0a6b6f6c] {\n  word-break: break-word;\n  color: var(--message-grey-color);\n  height: 22px;\n}\n.message-text span[data-v-0a6b6f6c] {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
