var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("msg-sending"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "load-files" }, [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "div",
                {
                  staticClass: "preview",
                  style: _vm.styles,
                  on: {
                    dragenter: _vm.dragenter,
                    dragleave: _vm.dragleave,
                    drop: _vm.drop,
                    onresize: _vm.doLoad,
                  },
                },
                [
                  _vm.dropFile
                    ? _c("div", { staticClass: "add-files" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("add")))]),
                      ])
                    : _vm._e(),
                  !_vm.files.length
                    ? _c(
                        "div",
                        {
                          staticClass: "preview-empty",
                          class: { "preview-empty-danger": _vm.filesEmpty },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("empty-list")))])]
                      )
                    : _vm._e(),
                  _vm.isLoading
                    ? _c("content-loader", {
                        staticStyle: {
                          "min-height": "50px",
                          "min-width": "150px",
                          position: "relative",
                        },
                      })
                    : !_vm.isGroupFiles && _vm.onlyImageVideo
                    ? _c(
                        "div",
                        {
                          ref: "previewImages",
                          staticClass: "preview-images",
                          class: { "preview-images-scroll": _vm.imagesScroll },
                        },
                        _vm._l(_vm.previewFiles, function (item, index) {
                          return _c("div", { staticClass: "preview-image" }, [
                            _vm.enc
                              ? _c("div", { staticClass: "lock-icon" }, [
                                  _c("i", { staticClass: "fas fa-lock" }),
                                ])
                              : _vm._e(),
                            _c("img", { attrs: { src: item.img.src } }),
                            item.type === "video"
                              ? _c(
                                  "div",
                                  { staticClass: "message-btn-wrapper" },
                                  [
                                    _c("div", { staticClass: "message-btn" }, [
                                      _c("i", { staticClass: "fa fa-play" }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "preview-image-hover" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "delete-btn-img",
                                  attrs: { title: _vm.$t("chat.delete") },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fal fa-times-circle",
                                  }),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      )
                    : !_vm.isGroupFiles
                    ? _c(
                        "div",
                        { staticClass: "preview-files" },
                        _vm._l(_vm.previewFiles, function (item, index) {
                          return _c("div", { staticClass: "preview-file" }, [
                            _vm.enc
                              ? _c(
                                  "div",
                                  { staticClass: "preview-file-icon" },
                                  [_c("i", { staticClass: "fas fa-lock" })]
                                )
                              : item.type === "image" || item.type === "video"
                              ? _c("div", { staticClass: "icon-image" }, [
                                  _c("img", { attrs: { src: item.img.src } }),
                                  item.type === "video"
                                    ? _c(
                                        "div",
                                        { staticClass: "video-btn-wrapper" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "video-btn" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-play",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "preview-file-icon" },
                                  [
                                    _vm.enc
                                      ? _c("i", { staticClass: "fas fa-lock" })
                                      : item.type === "audio"
                                      ? _c("i", { staticClass: "fas fa-music" })
                                      : item.type !== "image"
                                      ? _c("i", { staticClass: "far fa-file" })
                                      : _vm._e(),
                                  ]
                                ),
                            _c("div", { staticClass: "preview-file-info" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-file-name",
                                  attrs: { title: item.name },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c("div", { staticClass: "preview-file-size" }, [
                                _vm._v(_vm._s(item.size)),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "delete-btn-img",
                                attrs: { title: _vm.$t("chat.delete") },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fal fa-times-circle" })]
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm.isGroupFiles && _vm.onlyImageVideo
                    ? _c("div", { staticClass: "preview-files" }, [
                        _c(
                          "div",
                          { staticClass: "preview-files-group" },
                          _vm._l(_vm.previewFiles, function (item, index) {
                            return _c("div", { staticClass: "preview-file" }, [
                              _c("img", { attrs: { src: item.img.src } }),
                              item.type === "video"
                                ? _c(
                                    "div",
                                    { staticClass: "message-btn-wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "message-btn" },
                                        [_c("i", { staticClass: "fa fa-play" })]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "preview-image-hover" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete-btn-img",
                                      attrs: { title: _vm.$t("chat.delete") },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItem(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fal fa-times-circle",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ])
                    : _vm.isGroupFiles
                    ? _c(
                        "div",
                        { staticClass: "preview-files groupped" },
                        [
                          _vm._l(_vm.nonIvFiles, function (item, index) {
                            return _c("div", { staticClass: "preview-file" }, [
                              _c("div", { staticClass: "preview-file-icon" }, [
                                item.type === "audio"
                                  ? _c("i", { staticClass: "fas fa-music" })
                                  : _c("i", { staticClass: "far fa-file" }),
                              ]),
                              _c("div", { staticClass: "preview-file-info" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-file-name",
                                    attrs: { title: item.name },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "preview-file-size" },
                                  [_vm._v(_vm._s(item.size))]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "preview-image-hover" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete-btn-img",
                                      attrs: { title: _vm.$t("chat.delete") },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItem(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fal fa-times-circle",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          _c(
                            "div",
                            { staticClass: "preview-files-group" },
                            _vm._l(_vm.ivFiles, function (item, index) {
                              return _c(
                                "div",
                                { staticClass: "preview-file" },
                                [
                                  _c("div", { staticClass: "icon-image" }, [
                                    _c("img", { attrs: { src: item.img.src } }),
                                  ]),
                                  item.type === "video"
                                    ? _c(
                                        "div",
                                        { staticClass: "message-btn-wrapper" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "message-btn" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-play",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "preview-image-hover" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "delete-btn-img",
                                          attrs: {
                                            title: _vm.$t("chat.delete"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeItem(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-times-circle",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isShowGroupCheckBox && !_vm.enc
                ? _c(
                    "div",
                    { staticClass: "group-files" },
                    [
                      _c("custom-checkbox", {
                        staticClass: "input",
                        attrs: {
                          default: _vm.isGroupFiles,
                          checkbox: _vm.isGroupFiles,
                          label: _vm.$t("group-files"),
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isGroupFiles = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("custom-textarea", {
                key: "inputElemModal",
                ref: "inputElem",
                staticClass: "custom-textarea input-title",
                class: { focus: _vm.focus },
                attrs: {
                  custom_placeholder: _vm.$t("add-caption"),
                  default_text: _vm.text,
                  text: _vm.text,
                  focus: _vm.focus,
                  caret_position: _vm.text.length,
                  isFormatter: false,
                },
                on: {
                  "update:text": function ($event) {
                    _vm.text = $event
                  },
                  "update:focus": function ($event) {
                    _vm.focus = $event
                  },
                  inputmounted: _vm.focusInput,
                  send: function ($event) {
                    !_vm.isSending ? _vm.send() : ""
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.close } }, [
              _vm._v(_vm._s(_vm.$t("cancel"))),
            ]),
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    !_vm.isSending ? _vm.send() : ""
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("channel-comp.send")))]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }