var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-dropdown",
      class: { required: _vm.input_required, disabled: _vm.readonly },
      attrs: { tabindex: _vm.tabindex },
      on: {
        click: function ($event) {
          _vm.readonly ? "" : (_vm.open = !_vm.open)
        },
        blur: function ($event) {
          _vm.open = false
        },
      },
    },
    [
      _vm.placeholder
        ? _c("div", { staticClass: "placeholder", class: { up: _vm.up } }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
      !_vm.options.length
        ? _c("div", { staticClass: "nooptions" }, [
            _vm._v(_vm._s(_vm.noOptions)),
          ])
        : _vm._e(),
      _c("div", { staticClass: "selected" }, [
        _vm.selected.icon
          ? _c("img", { attrs: { src: _vm.selected.icon } })
          : _vm._e(),
        _c("span", { class: { disabled: _vm.readonly } }, [
          _vm._v(_vm._s(_vm.selected[_vm.labelField])),
        ]),
      ]),
      _vm.options.length
        ? _c(
            "div",
            { staticClass: "items", class: { selectHide: !_vm.open } },
            _vm._l(_vm.options, function (option, i) {
              return _c(
                "div",
                {
                  key: i,
                  class: _vm.itemsClass,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClick(option)
                    },
                  },
                },
                [
                  option.icon
                    ? _c("img", { attrs: { src: option.icon } })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(option[_vm.labelField]))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }