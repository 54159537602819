// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-contact-message-wrapper[data-v-93ad003a] {
  display: flex;
  flex-direction: column;
}
.chat-contact-message-wrapper .fwd[data-v-93ad003a] {
  font-size: .9em;
  display: flex;
  flex-direction: column;
  color: var(--fwd-message-color);
  margin-bottom: 0.33em;
}
.chat-contact-message-wrapper .fwd.title[data-v-93ad003a] {
  display: flex;
  flex-direction: row;
  height: 1em;
  margin-bottom: 0.4em;
}
.chat-contact-message-wrapper .fwd.title i[data-v-93ad003a] {
  margin-top: 0.4em;
  margin-right: 0.2em;
}
.chat-contact-message[data-v-93ad003a] {
  cursor: pointer;
  display: flex;
  padding-top: 2px;
  padding-left: 2px;
}
.chat-contact-message > *[data-v-93ad003a] {
  margin: auto;
}
.chat-contact-message .contact-name[data-v-93ad003a] {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.chat-contact-message .contact-name .lbl[data-v-93ad003a] {
  color: var(--ref-link-color);
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatContactMessage.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":[".chat-contact-message-wrapper[data-v-93ad003a] {\n  display: flex;\n  flex-direction: column;\n}\n.chat-contact-message-wrapper .fwd[data-v-93ad003a] {\n  font-size: .9em;\n  display: flex;\n  flex-direction: column;\n  color: var(--fwd-message-color);\n  margin-bottom: 0.33em;\n}\n.chat-contact-message-wrapper .fwd.title[data-v-93ad003a] {\n  display: flex;\n  flex-direction: row;\n  height: 1em;\n  margin-bottom: 0.4em;\n}\n.chat-contact-message-wrapper .fwd.title i[data-v-93ad003a] {\n  margin-top: 0.4em;\n  margin-right: 0.2em;\n}\n.chat-contact-message[data-v-93ad003a] {\n  cursor: pointer;\n  display: flex;\n  padding-top: 2px;\n  padding-left: 2px;\n}\n.chat-contact-message > *[data-v-93ad003a] {\n  margin: auto;\n}\n.chat-contact-message .contact-name[data-v-93ad003a] {\n  display: flex;\n  flex-direction: column;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n.chat-contact-message .contact-name .lbl[data-v-93ad003a] {\n  color: var(--ref-link-color);\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
