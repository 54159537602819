var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "main", staticClass: "main-section" },
    [
      _c(
        _vm.videoComp,
        _vm._g(
          {
            ref: "videoComp",
            tag: "component",
            attrs: {
              type: _vm.type,
              id: _vm.id,
              schema: _vm.schema,
              members: _vm.members,
              moderator: _vm.isModerator,
            },
          },
          _vm.$listeners
        )
      ),
      _c(
        "video-conf-members",
        _vm._g(
          {
            attrs: {
              show: _vm.membersListOpened,
              type: _vm.type,
              id: _vm.id,
              members: _vm.members,
              isModerator: _vm.isModerator,
            },
            on: {
              close: function ($event) {
                return _vm.$emit("members-open", false)
              },
            },
          },
          _vm.$listeners
        )
      ),
      _c(
        "video-conf-status",
        _vm._b({}, "video-conf-status", _vm.$props, false)
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }