var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-item",
      class: _vm.classes,
      style: _vm.styles,
      on: { linkcontextmenu: _vm.emitLinkContextMenu },
    },
    [
      _vm.has_media_slot
        ? _c("div", { staticClass: "item-media" }, [_vm._t("media")], 2)
        : _vm._e(),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "item-text" }, [
          _vm.topAccountId
            ? _c("span", { staticClass: "top-account-id" }, [
                _vm._v(_vm._s(_vm.topAccountId)),
              ])
            : _vm._e(),
          _vm.header
            ? _c("span", { staticClass: "item-header" }, [
                _vm._v(_vm._s(_vm.header)),
              ])
            : _vm._e(),
          _vm.htmlTitle
            ? _c("span", {
                staticClass: "item-title-html",
                domProps: { innerHTML: _vm._s(_vm.formHtmlLinks(_vm.title)) },
              })
            : _c("span", { staticClass: "item-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
          _vm.footer
            ? _c("span", { staticClass: "item-footer" }, [
                _vm._v(_vm._s(_vm.footer)),
              ])
            : _vm._e(),
        ]),
        _vm.link
          ? _c("div", { staticClass: "item-link" }, [
              _c("span", { staticClass: "item-after" }, [
                _vm._v(_vm._s(_vm.after)),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }