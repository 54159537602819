// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-block[data-v-acba46ba] {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin: auto 0 auto auto;
}
.right-block .unwatched-wrapper[data-v-acba46ba] {
  margin-left: auto;
}
.right-block .unwatched-wrapper .chat-marked[data-v-acba46ba] {
  font-weight: 600;
  margin-left: 5px;
  color: var(--base-blue-color);
  font-size: 0.8em;
}
.right-block .unwatched-wrapper .unwatched[data-v-acba46ba] {
  font-size: 12px;
  padding: 1px 6px 0px 5px;
  margin-left: 5px;
  border-radius: 13px;
  background: var(--badge-red-color);
  color: white;
}
.right-block .unwatched-wrapper .unwatched.shrink[data-v-acba46ba] {
  padding: 1px 4px 0px 4px;
}
.right-block .unwatched-wrapper .unwatched .chat-marked[data-v-acba46ba] {
  color: #f0f0f0;
  margin-left: auto;
}
.right-block .unwatched-wrapper.muted .unwatched[data-v-acba46ba] {
  background-color: var(--chat-item-right-block-bg);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/ChatItemRightBlock.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;EACnB,kCAAkC;EAClC,YAAY;AACd;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,iDAAiD;AACnD","sourcesContent":[".right-block[data-v-acba46ba] {\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  margin: auto 0 auto auto;\n}\n.right-block .unwatched-wrapper[data-v-acba46ba] {\n  margin-left: auto;\n}\n.right-block .unwatched-wrapper .chat-marked[data-v-acba46ba] {\n  font-weight: 600;\n  margin-left: 5px;\n  color: var(--base-blue-color);\n  font-size: 0.8em;\n}\n.right-block .unwatched-wrapper .unwatched[data-v-acba46ba] {\n  font-size: 12px;\n  padding: 1px 6px 0px 5px;\n  margin-left: 5px;\n  border-radius: 13px;\n  background: var(--badge-red-color);\n  color: white;\n}\n.right-block .unwatched-wrapper .unwatched.shrink[data-v-acba46ba] {\n  padding: 1px 4px 0px 4px;\n}\n.right-block .unwatched-wrapper .unwatched .chat-marked[data-v-acba46ba] {\n  color: #f0f0f0;\n  margin-left: auto;\n}\n.right-block .unwatched-wrapper.muted .unwatched[data-v-acba46ba] {\n  background-color: var(--chat-item-right-block-bg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
