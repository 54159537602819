var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-image-wrapper", on: { click: _vm.onChatImageClick } },
    [
      _vm.message.author
        ? _c("div", { staticClass: "fwd", class: _vm.sideClass }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isPending && !_vm.src ? _c("content-loader") : _vm._e(),
      _c("div", { staticClass: "blur-block", style: _vm.setStyle() }),
      _c("div", { staticClass: "chat-image-message" }, [
        _c("div", { staticClass: "preview-block" }, [
          !_vm.isSrcError && _vm.isPending
            ? _c("div", { staticClass: "pending" }, [
                _c(
                  "div",
                  {
                    staticClass: "upload-abort-btn message-btn",
                    on: { click: _vm.abortAjaxCall },
                  },
                  [
                    _c("progress-circle", {
                      attrs: {
                        load_progress: _vm.loadProgress,
                        radius: 16,
                        width: 40,
                        height: 40,
                      },
                    }),
                    _c("i", { staticClass: "fa fa-ban" }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "img-wrapper" }, [
            !_vm.src
              ? _c("img", {
                  attrs: {
                    src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                  },
                })
              : _c("img", {
                  attrs: { draggable: "false", src: _vm.src, alt: "" },
                }),
            _vm.isShowFailed
              ? _c("div", { staticClass: "failed-img-load" }, [_vm._m(0)])
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-wrapper" }, [
      _c("i", { staticClass: "far fa-arrow-alt-circle-down" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }