var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "two-fa" }, [
    _c("div", { staticClass: "component-header text-center" }, [
      _c("div", { staticClass: "description text-center" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("loginPage.login-completion"))),
          _c("span", { staticClass: "mail" }, [_vm._v(_vm._s(_vm.login))]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "component-section" }, [
      _c("div", { staticClass: "input-section" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input1,
              expression: "input1",
            },
            { name: "focus", rawName: "v-focus" },
          ],
          attrs: { maxlength: "1" },
          domProps: { value: _vm.input1 },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.input1 = $event.target.value
              },
              function ($event) {
                return _vm.tab($event, 1)
              },
            ],
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input2,
              expression: "input2",
            },
          ],
          attrs: { maxlength: "1" },
          domProps: { value: _vm.input2 },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.input2 = $event.target.value
              },
              function ($event) {
                return _vm.tab($event, 2)
              },
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              $event.stopPropagation()
              return _vm.del($event, 2)
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input3,
              expression: "input3",
            },
          ],
          attrs: { maxlength: "1" },
          domProps: { value: _vm.input3 },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.input3 = $event.target.value
              },
              function ($event) {
                return _vm.tab($event, 3)
              },
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              $event.stopPropagation()
              return _vm.del($event, 3)
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input4,
              expression: "input4",
            },
          ],
          attrs: { maxlength: "1" },
          domProps: { value: _vm.input4 },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.input4 = $event.target.value
              },
              function ($event) {
                return _vm.tab($event, 4)
              },
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              $event.stopPropagation()
              return _vm.del($event, 4)
            },
          },
        }),
      ]),
      _vm.resendingBtn
        ? _c("div", { staticClass: "resending text-center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.resending } },
              [_vm._v(_vm._s(_vm.$t("loginPage.resend")))]
            ),
          ])
        : _c("div", { staticClass: "resending text-center" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("loginPage.code-resending") +
                    _vm.timerMin +
                    _vm.timerSec
                )
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }