
import { mapGetters, mapActions } from "../../../vuex";
import { CHANNELS, CONTACTS } from "../../store/modulesNames"
import { GET_CHANNEL, GET_MERGED_CONTACT_BY_ID } from "../../store/gettersTypes"
import { ACT_GET_CHANNEL_USERS, ACT_SEARCH_CHANNEL_USERS } from "../../store/actionsTypes"

import MembersInfo from "./MembersInfo.vue"
import SelectContactsToChannel from "../modal/SelectContactsToChannel.vue"
import ChannelMembersInfoMixin from "./ChannelMembersInfoMixin"

const maxUsersCount = 50, inputSearchDelay = 500

export default {
    name: "ChannelMembersInfo",
    extends: MembersInfo,
    mixins: [ChannelMembersInfoMixin],
    data() {
        return {
            _title: this.$t('chat.subs'),
            _info: this.$t('information'),
            _owner: null,
            _admin_members: [],
            _user_members: [],
            all_members: [],
            showContentLoader: false,
            searchStr: '',
            lastUserId: 0,
            lastSearchedUserId: 0,
            lastSearchedUsersChunk: [],
            isSearchMoreUsers: true,
        }
    },
    computed: {
        btns() {
            const btns = []
            if (this._is_admin) btns.push({
                text: this.$t('information.invite-sub'),
                class: 'fal fa-user-plus',
                cb: () => {
                    this.modalOpen({
                        component: SelectContactsToChannel,
                        props: {
                            chId: this.params.chId,
                            privilege: 'user',
                            cb: (newMembers) => {
                                newMembers.forEach(member => this._user_members.push(member))
                            },
                        },
                    })
                },
            })
            return btns
        },
        _channel_data() {
            return this[GET_CHANNEL]({ chId: this.params.chId }) || {}
        },
        _is_admin() {
            if (!this._channel_data) return false;
            return [
                declarations.userPrivilege.ADMIN,
                declarations.userPrivilege.OWNER
            ].indexOf(this._channel_data.privilege) !== -1 && this._channel_data.status === declarations.channel_user_statuses.JOINED;
        },
        owner() {
            if (!this._owner) return {}
            const _owner = this._owner
            console.log("🚀 ~ ChannelMembersInfo.vue:70 ~ owner ~ _owner:", _owner)
            return _owner
        },
        admin_members() {
            console.log("🚀 ~ ChannelMembersInfo.vue:73 ~ admin_members ~ this._admin_members:", this._admin_members)
            if (!this._admin_members) return []
            const _admins = this._admin_members
            console.log("🚀 ~ ChannelMembersInfo.vue:78 ~ admin_members ~ _admins:", _admins)
            return _admins
        },
        user_members() {
            if (!this._user_members) return []
            const users = this._user_members
            return users
        },
        isSearchOn() {
            return this.searchStr && this.searchStr.length
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CHANNELS, [GET_CHANNEL]),
    },
    methods: {
        async getChannelMembers() {
            this.showContentLoader = true
            const ownerPrivilege = declarations.channel_user_privilege.OWNER
            const owner_request = await this[ACT_GET_CHANNEL_USERS]({ chId: this.params.chId, privilege: ownerPrivilege, count: 1 })
            const owner = owner_request[0] || null
            this._owner = this.prepareUser(owner)
            const adminPrivilege = declarations.channel_user_privilege.ADMIN
            const admin_members = await this[ACT_GET_CHANNEL_USERS]({ chId: this.params.chId, privilege: adminPrivilege, count: maxUsersCount })
            this._admin_members = this.prepareUsers(admin_members)
            const userPrivilege = declarations.channel_user_privilege.USER
            const user_members = await this[ACT_GET_CHANNEL_USERS]({ chId: this.params.chId, privilege: userPrivilege, count: maxUsersCount })
            this._user_members = this.prepareUsers(user_members)
            this.formAllMembers()
        },
        _buildMenu(cid) {
            const mainPage = this.$t('mainPage')
            const info = this.$t('information')
            const handlers = []
            handlers.push({ item_name: mainPage['contact-info'], handler: this.doOpenContactInfo, data: cid })
            const ownerCid = this.owner && this.owner.cid
            if (cid !== this.uid) {
                handlers.push({ item_name: this.$t('information.message'), handler: this.openChat, data: cid })
                if (this._is_admin && cid !== ownerCid) {
                    const isParticipantAdmin = this.admin_members.find(member => member.cid === cid)
                    if (!isParticipantAdmin) {
                        handlers.push({ item_name: info['promote-to-adm'], handler: this._setMemberUserPrivilege, data: { cid, privilege: declarations.channel_user_privilege.ADMIN } })
                    } else {
                        handlers.push({ item_name: info['demote-admin'], handler: this._setMemberUserPrivilege, data: { cid, privilege: declarations.channel_user_privilege.USER } })
                    }
                    handlers.push({ item_name: info['remove-member'], handler: this._delMember, data: cid })
                }
            }
            return handlers
        },
        openChat(cid) {
            const payload = { cid, cidType: 'user', isBot: false }
            this.$store.dispatch('chats/open', payload)
        },
        prepareUser(_user) {
            if (!_user || !('cid' in _user)) return null
            const contact = this[GET_MERGED_CONTACT_BY_ID](_user.cid)
            // const fio = Object.values(_user.fio).join(' ')
            const name = (_user.fio.surname || '') + ' ' + (_user.fio.name || '')
            const secondname = _user.fio.secondname || ''
            const user = {
                resultId: _user.resultId,
                cid: _user.cid,
                privilege: _user.privilege,
                photo: contact.photo,
                name, //contact.fio, //fio,
                secondname,
                objectId: contact?.objectId
            }
            if (_user.status === declarations.channel_user_statuses.INVITED) {
                user.text = info.invitation
            } else if (_user.status === declarations.channel_user_statuses.BANNED) {
                user.text = info.blocked
            }
            return user
        },
        prepareUsers(_users) {
            const users = _users.map((user) => this.prepareUser(user) )
            return users
        },
        async formAllMembers() {
            let resMembers = []
            const isSearch = this.isSearchOn, searchStr = this.searchStr
            let author =  this._owner
            let admins = this._admin_members || []
            let users = []
            if (isSearch) {
                admins = admins.filter(a => a.name.toLowerCase().indexOf(searchStr.toLowerCase()) > -1)
                users = this.lastSearchedUsersChunk
                if (users.length) {
                    users = users.filter(u => author.cid !== u.cid)
                    users = users.filter(u => !admins.some(a => a.cid === u.cid))
                }
                resMembers = [...admins, ...users]
            } else {
                users = this._user_members || []
                resMembers = [...admins, ...users]
            }
            const lastUser = users[users.length - 1]
            if (lastUser) this.lastUserId = lastUser.resultId
            if (typeof author === 'object' && Object.keys(author).length) {
                if (isSearch) {
                    const isAuthor = author && author.name.toLowerCase().indexOf(searchStr.toLowerCase()) > -1
                    if (isAuthor) resMembers = [author, ...admins, ...users]
                }
                else resMembers = [author, ...admins, ...users]
            }
            this.all_members = resMembers
            if (isSearch && users.length < maxUsersCount && this.isSearchMoreUsers) {
                await this.addMoreSearchMembers(true)
            }
            this.showContentLoader = false
        },
        async addMoreMembers(isFromScroll = false) {
            if (this.isSearchOn) {
                this.addMoreSearchMembers(isFromScroll)
                return
            }
            this.showContentLoader = true
            let lastUser = this._user_members[this._user_members.length - 1] || null
            let lastUserResultId = 0, fromId = 0
            if (lastUser) {
                lastUser && +lastUser.resultId
                fromId = ++lastUserResultId
            }
            const userPrivilege = declarations.channel_user_privilege.USER
            try {
                const new_user_members = await this[ACT_GET_CHANNEL_USERS]({ chId: this.params.chId, fromId, count: maxUsersCount, privilege: userPrivilege })
                const _new_users = this.prepareUsers(new_user_members)
                this._user_members = this._user_members.concat(_new_users)
                this.formAllMembers()
                return new_user_members && new_user_members.length === maxUsersCount
            } catch (error) {
                console.log("🚀 ~ ChannelMembersInfo.vue:193 ~ addMoreMembers ~ error:", error)
                this.showContentLoader = false
            }
        },
        async addMoreSearchMembers(isFromScroll) {
            if (!this.isSearchMoreUsers) return
            try {
                this.showContentLoader = true
                const filter = this.searchStr
                const fromId = this.isSearchOn ? this.lastSearchedUserId : this.lastUserId
                let new_search_user_members = await this[ACT_SEARCH_CHANNEL_USERS]({ chId: this.params.chId, filter, fromId, count: maxUsersCount})
                if (!new_search_user_members) new_search_user_members = []
                this.isSearchMoreUsers = new_search_user_members.length === maxUsersCount
                const new_prepared_users = this.prepareUsers(new_search_user_members)
                this.lastSearchedUsersChunk = isFromScroll ? this.lastSearchedUsersChunk.concat(new_prepared_users) : new_prepared_users
                if (new_prepared_users.length) this.lastSearchedUserId = new_prepared_users[new_prepared_users.length -1].resultId
                this.formAllMembers()
            } catch (error) {
                this.showContentLoader = false
            }
        },
        ...mapActions(CHANNELS, [ACT_GET_CHANNEL_USERS, ACT_SEARCH_CHANNEL_USERS])
    },
    watch: {
        searchStr(newVal, oldVal) {
            this._user_members = []
            this.lastSearchedUserId = 0
            const isNewSearchValAfterDel = oldVal && newVal && newVal.length < oldVal.length
            if (oldVal && !newVal) {
                this.getChannelMembers()
            } else if (!isNewSearchValAfterDel) {
                this.lastSearchedUsersChunk = []
                this.isSearchMoreUsers = true
            }
            else {
                this.isSearchMoreUsers = true
            }
            this.addMoreSearchMembers(false)
        },
    },
    created() {
        // this[ACT_SEARCH_CHANNEL_USERS] = _debounce(this[ACT_SEARCH_CHANNEL_USERS], inputSearchDelay)
        if (!this._admin_members) this._admin_members = []
        if (!this._user_members) this._user_members = []
        this.$bus.$on('info-contacts-search', (data) => {
            this.searchStr = data
        })
    },
    mounted() {
        this.getChannelMembers()
    }
}

const _debounce = (func, delay) => {
    let debounceTimer
    return function () {
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => { func.apply(this, args) }, delay)
    }
}
