// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-favourites-complete[data-v-161d7b0e] {
  font-size: 18px;
  color: var(--toolbtn-active-color);
}
.change-favourites-complete[data-v-161d7b0e]:hover {
  opacity: 0.7;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/side/ContactsList.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;AACpC;AACA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".change-favourites-complete[data-v-161d7b0e] {\n  font-size: 18px;\n  color: var(--toolbtn-active-color);\n}\n.change-favourites-complete[data-v-161d7b0e]:hover {\n  opacity: 0.7;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
