// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-14d65318] .v--modal-box.v--modal .header,
.custom-modal[data-v-14d65318] [data-theme="light"] .v--modal-box.v--modal .header,
.custom-modal[data-v-14d65318] [data-theme="dark"] .v--modal-box.v--modal .header {
  padding: 20px 20px 10px 24px;
  color: var(--custom-textarea-color);
}
.custom-modal[data-v-14d65318] .v--modal-box.v--modal .body,
.custom-modal[data-v-14d65318] [data-theme="light"] .v--modal-box.v--modal .body,
.custom-modal[data-v-14d65318] [data-theme="dark"] .v--modal-box.v--modal .body {
  height: 100%;
  overflow-y: initial;
}
.acsedit[data-v-14d65318] {
  width: 360px;
  position: fixed;
  color: var(--placeholder-color);
  font-size: 16px;
}
.acsedit[data-v-14d65318] .non-border-input {
  width: 100%;
}
.acsedit .edit-item[data-v-14d65318] {
  display: flex;
  align-items: flex-end;
  height: 60px;
}
.acsedit .edit-item .custom-input-text[data-v-14d65318] {
  width: 100%;
}
.acsedit .edit-item .status[data-v-14d65318] {
  display: flex;
}
.acsedit .edit-item .space-top[data-v-14d65318] {
  margin-top: 25px;
}
.title[data-v-14d65318] {
  display: flex;
  color: var(--custom-textarea-color);
  font-size: 18px;
  margin: 20px auto 0 5px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/acs/AcsEdit.vue"],"names":[],"mappings":"AAAA;;;EAGE,4BAA4B;EAC5B,mCAAmC;AACrC;AACA;;;EAGE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;EACf,+BAA+B;EAC/B,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mCAAmC;EACnC,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".custom-modal[data-v-14d65318] .v--modal-box.v--modal .header,\n.custom-modal[data-v-14d65318] [data-theme=\"light\"] .v--modal-box.v--modal .header,\n.custom-modal[data-v-14d65318] [data-theme=\"dark\"] .v--modal-box.v--modal .header {\n  padding: 20px 20px 10px 24px;\n  color: var(--custom-textarea-color);\n}\n.custom-modal[data-v-14d65318] .v--modal-box.v--modal .body,\n.custom-modal[data-v-14d65318] [data-theme=\"light\"] .v--modal-box.v--modal .body,\n.custom-modal[data-v-14d65318] [data-theme=\"dark\"] .v--modal-box.v--modal .body {\n  height: 100%;\n  overflow-y: initial;\n}\n.acsedit[data-v-14d65318] {\n  width: 360px;\n  position: fixed;\n  color: var(--placeholder-color);\n  font-size: 16px;\n}\n.acsedit[data-v-14d65318] .non-border-input {\n  width: 100%;\n}\n.acsedit .edit-item[data-v-14d65318] {\n  display: flex;\n  align-items: flex-end;\n  height: 60px;\n}\n.acsedit .edit-item .custom-input-text[data-v-14d65318] {\n  width: 100%;\n}\n.acsedit .edit-item .status[data-v-14d65318] {\n  display: flex;\n}\n.acsedit .edit-item .space-top[data-v-14d65318] {\n  margin-top: 25px;\n}\n.title[data-v-14d65318] {\n  display: flex;\n  color: var(--custom-textarea-color);\n  font-size: 18px;\n  margin: 20px auto 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
