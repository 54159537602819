import IOClient from './IOClient'
import radioRcProto from './protocols/radioRc'
import radioTetraProto from './protocols/radioTetra'

const protoList = [
    radioRcProto,
    radioTetraProto,
]

class RadioIOClient extends IOClient {
    constructor() {
        super({logName: 'RadioIOClient', transports:  ['websocket'], withCredentials: false, protoList})
    }
}

export default RadioIOClient