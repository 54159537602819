var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-icons" },
    [
      _vm.editIcon || _vm.propChange
        ? [
            _vm.propEdited
              ? _c(
                  "div",
                  { staticClass: "edit-item-text", on: { click: _vm.edit } },
                  [_c("i", { staticClass: "fa fa-save" })]
                )
              : _c(
                  "div",
                  { staticClass: "save-item-text", on: { click: _vm.edit } },
                  [_c("i", { staticClass: "fa fa-pencil" })]
                ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "del-item", on: { click: _vm.del } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }