// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper[data-v-ac3e37e6] {
  display: flex;
  flex-direction: column;
}
.content-wrapper .changes-item[data-v-ac3e37e6] {
  margin-bottom: 0.7em;
}
.content-wrapper .changes-item .version[data-v-ac3e37e6] {
  font-weight: 550;
  opacity: 70%;
}
.content-wrapper .changes-item .changelog[data-v-ac3e37e6] {
  white-space: pre-line;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ModalClientChanges.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".content-wrapper[data-v-ac3e37e6] {\n  display: flex;\n  flex-direction: column;\n}\n.content-wrapper .changes-item[data-v-ac3e37e6] {\n  margin-bottom: 0.7em;\n}\n.content-wrapper .changes-item .version[data-v-ac3e37e6] {\n  font-weight: 550;\n  opacity: 70%;\n}\n.content-wrapper .changes-item .changelog[data-v-ac3e37e6] {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
