
    import {mapActions, mapGetters} from 'vuex'
    import {CONTACTS} from '../../store/modulesNames'
    import {GET_MERGED_CONTACT_BY_EMAIL} from '../../store/gettersTypes'
    import {ACT_ADD_CONTACT_SHORT_INFO, ACT_SEARCH_CONTACTS} from '../../store/actionsTypes'

    import SelectContact from './SelectContact.vue'

    import {CONTACTS_SEARCH_TYPES} from '../../constants'

    export default {
        name: "SelectContactsToScheduler",
        extends: SelectContact,
        props: {
            preSelectedEmails: {
                type: Array,
                required: false,
                default: () => [],
            }
        },
        data() {
            return {
                filterFn: (item) => {
                    return Boolean(item.mail && item.mail.length)
                },
                listItemBindFn: (item) => {
                    let bindObj = {}
                    let mail = item && item.mail && item.mail[0]
                    if (mail) bindObj['subTitle'] = mail
                    return bindObj
                },
                loadedExcludeMembers: []
            }
        },
        created () {
            this.loadExclude().catch(() => {})
        },
        computed: {
            multiple: () => true,
            excludeMembers() { return this.loadedExcludeMembers },
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_EMAIL]),
        },
        methods: {
            async loadExclude() {
                //let excludeMembers = []
                for (let i = 0, count = this.preSelectedEmails.length; i < count; i++) {
                    let mail = this.preSelectedEmails[i]
                    let contact = this[GET_MERGED_CONTACT_BY_EMAIL](mail)
                    if (!contact) {
                        const searchResult = await this[ACT_SEARCH_CONTACTS]({
                            search: mail,
                            searchBots: false,
                            isLocal: false,
                            type: CONTACTS_SEARCH_TYPES.VIEW_CONTACT,
                            fromId: 0,
                            count: 1,
                        })
                        if (searchResult.contacts && searchResult.contacts.length) {
                            contact = searchResult.contacts[0]
                            await this[ACT_ADD_CONTACT_SHORT_INFO](contact)
                        }
                    }
                    if (contact) {
                        this.loadedExcludeMembers.push(contact.cid)
                    }
                }
            },
            ...mapActions(CONTACTS, [
                ACT_SEARCH_CONTACTS,
                ACT_ADD_CONTACT_SHORT_INFO,
            ]),
        }
    }
