// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acs-absence-reason[data-v-4a762230] {
  color: var(--message-grey-color);
  font-size: 14px;
}
.acs-absence-reason .reason-title[data-v-4a762230] {
  font-size: 1.5em;
}
.acs-absence-reason .date[data-v-4a762230] {
  color: var(--placeholder-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/acs/AcsAbsenceReason.vue"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".acs-absence-reason[data-v-4a762230] {\n  color: var(--message-grey-color);\n  font-size: 14px;\n}\n.acs-absence-reason .reason-title[data-v-4a762230] {\n  font-size: 1.5em;\n}\n.acs-absence-reason .date[data-v-4a762230] {\n  color: var(--placeholder-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
