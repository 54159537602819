var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notify-body", on: { click: _vm.doOnClickNotification } },
    [
      _vm.notify.alert
        ? _c("div", { staticClass: "icon" }, [
            _c("i", { staticClass: "fas fa-exclamation" }),
          ])
        : _c("icon-image", {
            staticClass: "icon-image",
            attrs: { src: _vm.notify.image, text: _vm.notify.title },
          }),
      _c("div", { staticClass: "info-section" }, [
        _vm.notify.header
          ? _c("span", { staticClass: "header" }, [
              _vm._v(_vm._s(_vm.notify.header)),
            ])
          : _vm._e(),
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.notify.title)),
        ]),
        _c("span", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.notify.subtitle)),
        ]),
      ]),
      _c("i", { staticClass: "fas fa-times", on: { click: _vm.hide } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }