var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "members-info" },
    [
      _vm._l(_vm.btns, function (btn, i) {
        return _c("actions-button", {
          key: i,
          staticClass: "custom-btn",
          attrs: { btnText: btn.text, btnClass: btn.class },
          on: {
            btnClicked: function ($event) {
              return btn.cb.call(this, _vm.$data, _vm.$props)
            },
          },
        })
      }),
      _c(
        "div",
        {
          staticClass: "members info-section",
          attrs: { id: "members-section" },
          on: { scroll: _vm.onMembersScroll },
        },
        [
          _vm.showContentLoader ? _c("content-loader") : _vm._e(),
          _vm.all_members.length
            ? _c(
                "div",
                { staticClass: "members-list item-cont" },
                _vm._l(_vm.all_members, function (member, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "member-list-item list-item",
                      on: {
                        click: function ($event) {
                          return _vm.doOpenContactInfo(member.cid, true)
                        },
                      },
                    },
                    [
                      _c("icon-image", {
                        attrs: { src: member.photo, text: member.name },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "fio-block",
                          class: _vm.fioClass(member),
                        },
                        [
                          _c("div", { staticClass: "fio name" }, [
                            _vm._v(_vm._s(member.name)),
                          ]),
                          member.secondname
                            ? _c("div", { staticClass: "fio name" }, [
                                _vm._v(_vm._s(member.secondname)),
                              ])
                            : _vm._e(),
                          member.text
                            ? _c("div", { staticClass: "member-text" }, [
                                _vm._v(_vm._s(member.text)),
                              ])
                            : _vm._e(),
                          member.objectId
                            ? _c("div", { staticClass: "member-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getRemoteServerDisplayName(
                                      member.objectId
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      member.privilege !== _vm.userPrivilege
                        ? _c("div", { staticClass: "member-privilege" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t(`mainPage.${member.privilege}`))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "member-menu circle-animation",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.doContextMenu($event, member.cid)
                            },
                          },
                        },
                        [_c("i", { staticClass: "far fa-ellipsis-v" })]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }