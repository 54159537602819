// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-info .contact-main-block[data-v-5fb2dc53] .common-text .text {
  margin-bottom: 0;
}
.contact-info .contact-btns .main-btn[data-v-5fb2dc53] {
  margin: 0 20px;
  width: 40px;
  height: 40px;
}
.contact-info .contact-btns .main-btn.shrink[data-v-5fb2dc53] {
  margin: 0 10px;
}
.contact-info .contact-btns .main-btn[data-v-5fb2dc53]:first-child {
  margin-left: auto;
}
.contact-info .contact-btns .main-btn[data-v-5fb2dc53]:last-child {
  margin-right: auto;
}
.contact-info .contact-btns .main-btn i[data-v-5fb2dc53] {
  font-size: 14px;
}
.contact-info .contact-btns .main-btn i.i-special[data-v-5fb2dc53] {
  font-size: 15px;
  margin-bottom: 11px;
}
.contact-info .contacts .contact-icon-wrapper[data-v-5fb2dc53] {
  margin-right: 15px;
}
.contact-info .contacts .contact-icon-wrapper i[data-v-5fb2dc53] {
  font-size: 16px;
}
.contact-info .color-blue[data-v-5fb2dc53] {
  color: var(--icon-special-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/ContactInfo.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".contact-info .contact-main-block[data-v-5fb2dc53] .common-text .text {\n  margin-bottom: 0;\n}\n.contact-info .contact-btns .main-btn[data-v-5fb2dc53] {\n  margin: 0 20px;\n  width: 40px;\n  height: 40px;\n}\n.contact-info .contact-btns .main-btn.shrink[data-v-5fb2dc53] {\n  margin: 0 10px;\n}\n.contact-info .contact-btns .main-btn[data-v-5fb2dc53]:first-child {\n  margin-left: auto;\n}\n.contact-info .contact-btns .main-btn[data-v-5fb2dc53]:last-child {\n  margin-right: auto;\n}\n.contact-info .contact-btns .main-btn i[data-v-5fb2dc53] {\n  font-size: 14px;\n}\n.contact-info .contact-btns .main-btn i.i-special[data-v-5fb2dc53] {\n  font-size: 15px;\n  margin-bottom: 11px;\n}\n.contact-info .contacts .contact-icon-wrapper[data-v-5fb2dc53] {\n  margin-right: 15px;\n}\n.contact-info .contacts .contact-icon-wrapper i[data-v-5fb2dc53] {\n  font-size: 16px;\n}\n.contact-info .color-blue[data-v-5fb2dc53] {\n  color: var(--icon-special-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
