
    import { mapActions, mapGetters } from 'vuex'
    import { GET_UID } from "../../store/gettersTypes"
    import { USERDATA, PHONE_CNTL } from "../../store/modulesNames"
    import { CALLS_CONTROLLER } from "../../store/actionsTypes"

    import SelectContact from "./SelectContact.vue"
    import {CONTACTS_SEARCH_TYPES, PHONE_TYPES} from '../../constants'

    export default {
        name: "CreateConf",
        extends: SelectContact,
        data() {
            return {
                searchType: CONTACTS_SEARCH_TYPES.MAKE_CALL,
            }
        },
        computed: {
            excludeMembers() {
                let uid = this[GET_UID]
                return [uid]
            },
            multiple: () => true,
            okText() { return this.$t('continue') },
            ...mapGetters(USERDATA, [GET_UID])
        },
        methods: {
            onOk(users) {
                if(!users) return
                let contacts = users.map(({cid}) => cid)                
                let options = { audio: true, video: this.props.video }
                this[CALLS_CONTROLLER]({
                    type: PHONE_TYPES.CONFERENCE,
                    data: { contacts, options }
                })
                this.modalClose()
            },
            ...mapActions(PHONE_CNTL, [CALLS_CONTROLLER])
        }
    }
