// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings[data-v-7387fca8] {
  padding: 0 16px 18px 16px;
  min-width: 375px;
}
.settings[data-v-7387fca8] .main-content-container {
  margin: 20px auto 0 auto;
  flex-basis: auto !important;
  background: var(--content-container-bgcolor);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;
}
.settings[data-v-7387fca8] .main-content-container .main-content-container-header {
  margin-bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  font-size: var(--titleFontSize);
  font-weight: 600;
}
.settings[data-v-7387fca8] .main-content-container .main-content-container-main {
  background: inherit;
  box-shadow: none;
  margin-bottom: 0px;
}
.settings-placeholder[data-v-7387fca8] {
  display: flex;
  min-height: 58px;
  margin: auto;
  font-size: 20px;
}
.settings-placeholder .loading-spinner[data-v-7387fca8] {
  display: flex;
  margin: auto;
}
.settings-placeholder .loading-spinner i[data-v-7387fca8] {
  font-weight: 100;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/MainContentSettings.vue"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,4CAA4C;EAC5C,kBAAkB;EAClB,6EAA6E;AAC/E;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".settings[data-v-7387fca8] {\n  padding: 0 16px 18px 16px;\n  min-width: 375px;\n}\n.settings[data-v-7387fca8] .main-content-container {\n  margin: 20px auto 0 auto;\n  flex-basis: auto !important;\n  background: var(--content-container-bgcolor);\n  border-radius: 4px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.15) 0 1px 3px 1px;\n}\n.settings[data-v-7387fca8] .main-content-container .main-content-container-header {\n  margin-bottom: 0px;\n  padding-top: 12px;\n  padding-left: 12px;\n  font-size: var(--titleFontSize);\n  font-weight: 600;\n}\n.settings[data-v-7387fca8] .main-content-container .main-content-container-main {\n  background: inherit;\n  box-shadow: none;\n  margin-bottom: 0px;\n}\n.settings-placeholder[data-v-7387fca8] {\n  display: flex;\n  min-height: 58px;\n  margin: auto;\n  font-size: 20px;\n}\n.settings-placeholder .loading-spinner[data-v-7387fca8] {\n  display: flex;\n  margin: auto;\n}\n.settings-placeholder .loading-spinner i[data-v-7387fca8] {\n  font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
