
    import { mapGetters } from 'vuex';
    import {
        PHONE_CNTL,
    } from "../../store/modulesNames"
    import {
        GET_PHONE_IN_FULLSCREEN,
        GET_ACTIVE_ANSWERED_CALL,
        GET_IS_PHONE_MINIMIZED
    } from "../../store/gettersTypes"

    import BaseContainerWebWrapper from "./BaseContainerWebWrapper.vue"

    import PhoneContainer from "./PhoneContainer.vue"

    export default {
        name: "PhoneContainerWebWrapper",
        extends: BaseContainerWebWrapper,
        data() {
            return {
                component: PhoneContainer,
                notFullScreenHeight: 'auto',
                notFullScreenWidth: 'auto',
            }
        },
        computed: {
            call() {
                return this[GET_ACTIVE_ANSWERED_CALL]
            },
            minimized() {
                return this[GET_IS_PHONE_MINIMIZED]
            },
            fullScreen () {
                return this[GET_PHONE_IN_FULLSCREEN]
            },
            ...mapGetters(PHONE_CNTL, [GET_ACTIVE_ANSWERED_CALL, GET_IS_PHONE_MINIMIZED, GET_PHONE_IN_FULLSCREEN])
        },
    }
