var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "central sessions" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c("list-item", {
                        staticClass: "session-item",
                        attrs: {
                          header: _vm._f("clientFormat")(_vm.current.client),
                          title: _vm._f("deviceFormat")(_vm.current.device),
                          footer: _vm.current.ip,
                        },
                      }),
                      _c("list-item", {
                        staticClass: "color-red",
                        attrs: {
                          title: _vm.$t("mainPage.end-sessions"),
                          link: true,
                          chevron: false,
                          footer: _vm.$t("mainPage.logout-all"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.doCloseSessions.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("mainPage.connection"))),
          ]),
        ],
        2
      ),
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _vm.sessions.length
                        ? _vm._l(_vm.sessions, function (session, i) {
                            return _c(
                              "list-item",
                              {
                                key: i,
                                staticClass: "session-item",
                                attrs: {
                                  header: _vm._f("clientFormat")(
                                    session.client
                                  ),
                                  title: _vm._f("deviceFormat")(session.device),
                                  footer: session.ip,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "after" }, slot: "after" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(session.start_time)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          })
                        : _c("list-item", {
                            attrs: {
                              title: "",
                              header: _vm.$t("mainPage.no-other-sessions"),
                            },
                          }),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("mainPage.sessions"))),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }