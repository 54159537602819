import { render, staticRenderFns } from "./ChatItemRightBlock.vue?vue&type=template&id=acba46ba&scoped=true&lang=pug"
import script from "./ChatItemRightBlock.vue?vue&type=script&lang=js"
export * from "./ChatItemRightBlock.vue?vue&type=script&lang=js"
import style0 from "./ChatItemRightBlock.vue?vue&type=style&index=0&id=acba46ba&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acba46ba",
  null
  
)

export default component.exports