// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svgwrapper svg[data-v-64637038] {
  width: 14px;
  height: 14px;
  fill: var(--toolbtn-color);
}
.svgwrapper svg path[data-v-64637038] {
  fill-opacity: 0.5;
}
.svgwrapper svg path.active[data-v-64637038] {
  fill-opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/SignalLevel.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,0BAA0B;AAC5B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB","sourcesContent":[".svgwrapper svg[data-v-64637038] {\n  width: 14px;\n  height: 14px;\n  fill: var(--toolbtn-color);\n}\n.svgwrapper svg path[data-v-64637038] {\n  fill-opacity: 0.5;\n}\n.svgwrapper svg path.active[data-v-64637038] {\n  fill-opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
