// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity[data-v-6ff6a631] {
  width: 100%;
  height: 42px;
  background-color: var(--bg-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/activity/ActivityBase.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iCAAiC;AACnC","sourcesContent":[".activity[data-v-6ff6a631] {\n  width: 100%;\n  height: 42px;\n  background-color: var(--bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
