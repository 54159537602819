var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c(
        "div",
        { staticClass: "selected-wrapper" },
        [
          _c(_vm.component, {
            tag: "component",
            attrs: {
              load_id: _vm.load_id,
              id: _vm.id,
              commentId: _vm.commentId,
            },
            on: { clear: _vm.clear, loaded: _vm.fireLoaded },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }