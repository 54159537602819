var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "speakers" }, [
    _c(
      "div",
      { staticClass: "speakers-header" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("speaker")))]),
        _c("custom-drop-down", {
          key: "3",
          ref: "select_speaker",
          staticClass: "speaker-select",
          attrs: {
            options: _vm.speakersList,
            default: _vm.selectedSpeaker,
            noOptions: _vm.$t("no-speakers-found"),
            firstOption: "true",
          },
          on: { "dd-selected-value": _vm.setActiveSpeaker },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "speakers-level" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("speaker-level")))]),
      _c(
        "div",
        { staticClass: "speakers-range-slider" },
        [
          _c("i", { staticClass: "fas fa-volume-off" }),
          _c("range-slider", {
            staticClass: "slider",
            attrs: {
              disabled: _vm.sliderDisabled,
              min: "0",
              max: "100",
              step: "1",
            },
            model: {
              value: _vm.sliderValue,
              callback: function ($$v) {
                _vm.sliderValue = $$v
              },
              expression: "sliderValue",
            },
          }),
          _c("i", { staticClass: "fas fa-volume-up volume-up" }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "speakers-level-check" }, [
      _c(
        "div",
        { staticClass: "linkBtn", on: { click: _vm.togglePlaySound } },
        [_vm._v(_vm._s(_vm.playBtnText))]
      ),
      _c(
        "div",
        { staticClass: "speakers-visualizer" },
        _vm._l(_vm.elements, function (i) {
          return _c("div", [
            i % 2 > 0
              ? _c("div", { staticClass: "speakers-visualizer-elem" }, [
                  i < _vm.activeElements
                    ? _c("div", {
                        staticClass: "speakers-visualizer-elem-active",
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
    _c("audio", {
      ref: "speakersRef",
      attrs: { src: "audio/hold.mp3" },
      on: { ended: _vm.stopPlay },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }