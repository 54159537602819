var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-info", class: { loading: _vm.loading } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "loading-spinner-wrapper",
        },
        [_vm._m(0)]
      ),
      _c("div", { staticClass: "region-photo-wrapper" }, [
        _vm.regionImg
          ? _c("img", {
              style: _vm.imgStyle,
              attrs: { src: _vm.regionImg },
              on: { error: _vm.onRegionImgError, load: _vm.onRegionImgLoad },
            })
          : _vm._e(),
      ]),
      _c("acs-status", {
        staticClass: "small",
        attrs: {
          contact_id: _vm.contact_id,
          link: false,
          loading: _vm.loading,
        },
      }),
      _c("div", { staticClass: "acs-journal-wrapper" }, [
        _c(
          "div",
          { staticClass: "acs-journal" },
          [
            _vm.show
              ? _c("span", { staticClass: "journal-title" }, [
                  _vm._v(_vm._s(_vm.$t("mainPage.journal"))),
                ])
              : _vm._e(),
            _vm.hasRights
              ? _c("acs-add-btn", { on: { "record-saved": _vm.updateAcsInfo } })
              : _vm._e(),
            _c("acs-journal-list", {
              ref: "journal_list",
              attrs: { loading: _vm.loading },
              on: { show: _vm.showTitle },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-spinner" }, [
      _c("i", { staticClass: "fal fa-spinner fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }