// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-msg[data-v-368afa4c] {
  width: 100%;
  height: 100%;
}
.survey-msg .survey-msg-header[data-v-368afa4c] {
  display: flex;
  cursor: pointer;
}
.survey-msg .survey-msg-header img[data-v-368afa4c] {
  height: 40px;
}
.survey-msg .survey-msg-header span[data-v-368afa4c] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  max-width: var(--max-width);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.survey-msg .survey-msg-header .survey-msg-title[data-v-368afa4c] {
  display: flex;
  flex-direction: column;
}
.survey-msg .survey-msg-header .survey-msg-title .subtitle[data-v-368afa4c] {
  font-size: 13px;
  color: var(--elem-grey-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chatSurveyMessage.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".survey-msg[data-v-368afa4c] {\n  width: 100%;\n  height: 100%;\n}\n.survey-msg .survey-msg-header[data-v-368afa4c] {\n  display: flex;\n  cursor: pointer;\n}\n.survey-msg .survey-msg-header img[data-v-368afa4c] {\n  height: 40px;\n}\n.survey-msg .survey-msg-header span[data-v-368afa4c] {\n  padding-left: 0.5em;\n  padding-right: 0.5em;\n  max-width: var(--max-width);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.survey-msg .survey-msg-header .survey-msg-title[data-v-368afa4c] {\n  display: flex;\n  flex-direction: column;\n}\n.survey-msg .survey-msg-header .survey-msg-title .subtitle[data-v-368afa4c] {\n  font-size: 13px;\n  color: var(--elem-grey-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
