'use strict'

import {NOTIFY_DATA_TYPES} from './notificationsNew'

let tempId = -1

import moment from 'moment'

import {
    GET_DND,
    // GET_INCOMING_CALLS_PRIORITY,
    GET_OPEN_DIALER,
    GET_OPEN_INCOMMING_CALL,
    GET_DIALER_NUMBER,
    GET_OPTIONS,
    GET_TYPE_ASISTANT,
    GET_ANSWERED_CALLS,
    GET_IS_PHONE_MINIMIZED,
    GET_ACTIVE_ANSWERED_CALL,
    GET_CALL_TALK_TIME,
    GET_CALL_TALK_TITLE,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_ASSISTANT_CALL_BY_ID,
    GET_PHONE_IN_FULLSCREEN,
    GET_ACTIVE_MICROPHONE,
    GET_INCOMING_CALL,
    GET_INCOMING_CALLS,
    GET_CALL_STATUS,
    GET_ACTIVE_WEBCAMERA,
    GET_MINUTES_RELATIVE_TO_TIME,
    GET_ASSISTANT_CALL_PARTICIPANTS,
    GET_CALL_PARTICIPANTS,
    GET_UID,
    GET_ANSWERED_CALL,
    GET_ANSWERED_CALLS_BY_TYPE,
} from '../gettersTypes'
import {
    CALLS_EVENTS_CONTROLLER,
    ACT_CONFERENCE_EVENT,
    CALLS_CONTROLLER,
    ACT_SHOW_INCOMMING_CALL,
    ACT_HIDE_INCOMMING_CALL,
    ACT_ADD_INCOMMING_CALL,
    ACT_ANSWER_INCOMMING_CALL,
    ACT_TERMINATE_INCOMMING_CALL,
    ACT_ADD_CONF,
    ACT_MODAL_OPEN,
    ACT_DIAL_NUMBER,
    ACT_ADD_ANSWERED_CALL,
    ACT_SELECT_CALL,
    ACT_SET_POSITION_ANSWERED_CALL,
    ACT_TERMINATE_CALL,
    ACT_HOLD_CONF,
    ACT_ACTIVATE_CONF,
    ACT_HOLD_CALL,
    ACT_DELETE_ANSWERED_CALL,
    ACT_SET_ACTIVE_CALL,
    ACT_TERMINATE_ASISTANT,
    ACT_CALL_ASSISTANT,
    ACT_PHONE_SHOW,
    ACT_CHANGE_CONFERENCE_EVENT,
    ACT_PHONE_HIDE,
    ACT_UPDATE_INCOMMING_CALL,
    ACT_CONFERENCE_TERMINATION,
    ACT_TERMINATE_ALL_INCOMING_CALLS,
    ACT_TOGGLE_PHONE_MINIMIZE,
    ACT_MINIMIZE,
    ACT_TOGGLE_PHONE_FULLSCREEN,
    ACT_SET_PHONE_FULLSCREEN,
    ACT_SET_ANSWERED_CALL_STATUS,
    ACT_SET_CALL_STATUS,
    ACT_UPDATE_ANSWERED_CALL_ID,
    ACT_SUBSCRIBE_ON_FREE_REQUEST,
    ACT_CALL_CONTINUE,
    ACT_PHONE_CALL_CONTINUE,
    ACT_PHONE_COMMANDS,
    ACT_GET_RTC_CALL_AVAILABILITY,
    ACT_PHONE_SELECT,
    ACT_EVENT_PHONE,
    ACT_PHONE_RTC_CALL_TERMINATION_API,
    ACT_PHONE_RTC_CALL_RINGING_API,
    ACT_SUBSCRIBE_AVAILABILITY,
    ACT_SET_CALL_PARTICIPANTS,
    ACT_CHECK_MEDIA_DEVICES,
    ACT_ADD_NOTIFICATION,
    ACT_TETRA_MAKE_DUPLEX_CALL,
    ACT_TETRA_ANSWER_DUPLEX_CALL,
    ACT_TETRA_END_DUPLEX_CALL,
    ACT_TETRA_SELECT_DUPLEX_CALL,
    ACT_TETRA_TOGGLE_MICROPHONE,
    ACT_TETRA_PAUSE, ACT_SET_ON_HOLD_OTHER_CALLS,
    ACT_SET_CALL_MIC_MUTE_STATUS,
    ACT_NOTIFY_NEW_ADD,
    ACT_NOTIFY_NEW_REMOVE_BY_DATA,
} from '../actionsTypes'
import {
    SET_DIALER_NUMBER,
    MUT_ADD_ANSWERED_CALL,
    MUT_DELETE_ANSWERED_CALL,
    MUT_SET_MINIMIZED,
    MUT_SET_ANSWERED_CALL_STATUS,
    MUT_SET_CALL_STATUS,
    MUT_SET_PHONE_FULLSCREEN,
    MUT_UPDATE_ANSWERED_CALL_ID,
    MUT_MOVE_ANSWERED_CALL_POSITION,
    MUT_UPDATE_CALL_PARTICIPANTS,
    MUT_DELETE_INCOMING_CALL,
    MUT_PHONE_SET_MIC_MUTE_STATUS,
} from '../mutationsTypes'
import {
    USERDATA,
    PHONE,
    VIDEO_CONF,
    ASISTANTS,
    MODAL,
    CLIENTDATA,
    CONTACTS,
    NOTIFICATIONS,
    TETRA,
    NOTIFICATIONS_NEW,
} from '../modulesNames'

import ipc from '../../../electron/ipc'
import { i18n } from '../../../ext/i18n'
import Vue from 'vue'

import { formatPhoneNumber } from '../../mixins/otherMixins'

import {PHONE_TYPES, ANSWERED_CALL_STATUSES, CONTACT_FIELD_TYPES} from '../../constants'
import ContinueWithoutCamera from "../../components/modal/ContinueWithoutCamera.vue"

const locale = i18n.messages[i18n.locale]

const state = {
    opendDaler: false,
    dialerNumber: '',
    openIncommingCall: false,
    incommingCalls: [],
    answeredCalls: [],
    minimized: false,
    fullScreen: false,
}

const getters = {
    // [GET_INCOMING_CALLS_PRIORITY]: state => state.incommingCalls,
    [GET_OPEN_DIALER]: state => state.opendDaler,
    [GET_OPEN_INCOMMING_CALL]: state => state.openIncommingCall,
    [GET_DIALER_NUMBER]: state => state.dialerNumber,
    [GET_ANSWERED_CALLS]: state => state.answeredCalls,
    [GET_ANSWERED_CALLS_BY_TYPE]: state => callType => state.answeredCalls.filter(({type}) => callType),
    [GET_IS_PHONE_MINIMIZED]: state => state.minimized,
    [GET_PHONE_IN_FULLSCREEN]: state => state.fullScreen,
    [GET_ACTIVE_ANSWERED_CALL]: state => {
        let call
        for (let i = state.answeredCalls.length - 1; i >= 0; i--) {
            let _call = state.answeredCalls[i]
            if (_call.active) {
                call = _call
                break
            } else if (i === 0) {
                call = _call
            }
        }
        return call
    },
    [GET_ANSWERED_CALL]: state => (type, id) => {
        return findCall(state.answeredCalls, {type, id})
    },
    [GET_CALL_PARTICIPANTS]: (state, getters, rootState, rootGetters) => (type, id) => {
        const uid = rootGetters[`${USERDATA}/${GET_UID}`]
        let participants = []
        let excludeSelfContact = true
        let call = getters[GET_ANSWERED_CALL](type, id)
        switch (type) {
            case PHONE_TYPES.ASSISTANT:
                participants = rootGetters[`${ASISTANTS}/${GET_ASSISTANT_CALL_PARTICIPANTS}`](id)
                break
            case PHONE_TYPES.PHONE:
                //participants = [{number: 50230345}, {number: 3157}, {number: 3149}, {number: 5389},{number: 5101},{number: 5102},{number: 5103},{number: 5104},{number: 5105},{number: 5106}, {number: 50230345}, {number: 3157}, {number: 3149}, {number: 5389},{number: 5101},{number: 5102},{number: 5103},{number: 5104},{number: 5105},{number: 5106}]
                if (call) {
                    if (call.participants) {
                        participants = call.participants
                    } else if (call.data && call.data.contact) {
                        const contact = call.data.contact
                        participants = [{cid: contact.cid, number: call.data.number}]
                    } 
                    else {
                        participants = [{cid: call.data.cid, number: call.data.number}]
                        excludeSelfContact = false
                    }
                }
                break
            case PHONE_TYPES.TETRA:
                if (call) participants = [{ tetraUser: call.data.tetraUser }]
                break
        }
        return participants.reduce((participants, {cid, number, tetraUser}) => {
            let contact
            if (typeof number === 'number') number = `${number}`
            if (cid) {
                contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
            } else if (number) {
                contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_PHONE}`](number)
            }
            if (!excludeSelfContact || !contact || contact.cid !== uid) participants.push({cid, number, contact, tetraUser})
            return participants
        }, [])
    },
    [GET_CALL_TALK_TITLE]: (state, getters, rootState, rootGetters) => (type, id) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) {
            let title = ''
            switch (type) {
                case PHONE_TYPES.CONFERENCE:
                    let options = rootGetters[`${VIDEO_CONF}/${GET_OPTIONS}`] || {}
                    title = options.video ? locale['group-video-call'] : locale['group-call']
                    break
                case PHONE_TYPES.ASSISTANT:
                case PHONE_TYPES.PHONE: {
                    const participants = getters[GET_CALL_PARTICIPANTS](type, id)
                    title = participants.map(({cid, number, contact}) => {
                        let text
                        if (cid) {
                            let botTitleField = contact.fields.find(item => item.type === CONTACT_FIELD_TYPES.BOTTITLE)
                            text = botTitleField ? botTitleField.value : contact.fio
                        } else if (number) {
                            text = contact ? contact.fio : formatPhoneNumber(number)
                        } else {
                            text = locale.modal['not-defined-number']
                        }
                        return text
                    }).join(', ')
                    break
                }
                case PHONE_TYPES.TETRA: {
                    const tetraUser = call.data.tetraUser
                    return tetraUser && (tetraUser.name || tetraUser.ssi) || ''
                }
            }
            return title
        }
    },
    [GET_CALL_TALK_TIME]: (state, getters, rootState, rootGetters) => (type, id) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call && call.talkStartTime) {
            return rootGetters[`${CLIENTDATA}/${GET_MINUTES_RELATIVE_TO_TIME}`](call.talkStartTime)
        }
    },
    [GET_INCOMING_CALL]: state => (type, id) => {
        const call = findCall(state.incommingCalls, {type, id})
        return call
    },
    [GET_INCOMING_CALLS]: (state) => (filters = {}) => {
        let { type, id } = filters
        return state.incommingCalls.filter(call => !call.hidden && (!type || call.type === type) && (!id || call.id === id))
    },
    [GET_CALL_STATUS]: state => (type, id) => {
        let call = findCall(state.answeredCalls, {type, id})
        return call && call.statusText || ''
    },
}

const actions = {
    [CALLS_CONTROLLER]: async ({state, dispatch, rootGetters, getters, commit}, {type, data}) => {
        
        let size, callId

        switch(type) {
            case 'dialer':
                commit('toggleDialer')
                break
            case 'call':
                const temporaryId = generateTemporaryCallId(state.answeredCalls)

                if (!state.answeredCalls.length) {
                    await selectTypeCall(temporaryId)
                    return
                }

                let activeCall = getters[GET_ACTIVE_ANSWERED_CALL] || {}
                if (![ANSWERED_CALL_STATUSES.TALK, ANSWERED_CALL_STATUSES.HOLD].includes(activeCall.status))
                    return dispatch(ACT_TOGGLE_PHONE_MINIMIZE, {minimize : false})

                const typeActiveCall = state.answeredCalls[0].type
                let assistant = rootGetters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
                let typeCall

                if (!data.number) typeCall = PHONE_TYPES.PHONE
                else if (data.number && assistant !== 'sip' && assistant !== '') typeCall = PHONE_TYPES.ASSISTANT
                else if (data.number && assistant === 'sip') typeCall = PHONE_TYPES.PHONE
                else if (data.number && assistant === '') return
                if (typeCall !== typeActiveCall) return

                if (type !== 'dialer' && ![PHONE_TYPES.ASSISTANT, PHONE_TYPES.PHONE].includes(typeCall) && state.answeredCalls.length >= 2) return

                if (!isUniqueCall({ answeredCalls: state.answeredCalls, incommingCalls: state.incommingCalls, number: data.number, cid: data.cid }))
                    return dispatch(ACT_TOGGLE_PHONE_MINIMIZE, {minimize : false})

                if (typeActiveCall === PHONE_TYPES.PHONE) {
                    data.callId = temporaryId
                    //dispatch(ACT_HOLD_CALL, state.answeredCalls[0])
                    ipc.send('phone-set-call', PHONE_TYPES.PHONE)
                    ipc.send('phone-call', data)
                    dispatch(ACT_ADD_ANSWERED_CALL, { type: PHONE_TYPES.PHONE, id: temporaryId, isIncomming: false, data })
                } else if (typeActiveCall === PHONE_TYPES.ASSISTANT){
                    dispatch(`${ASISTANTS}/${ACT_DIAL_NUMBER}`, {cid: data.cid, digits: data.number}, { root: true })
                    dispatch(`${ASISTANTS}/${ACT_CALL_ASSISTANT}`, { number: data.number }, { root: true })    
                }
                break
            case PHONE_TYPES.CONFERENCE:
                if (state.answeredCalls.length) {
                    // ipc.send('phone-set-call', PHONE_TYPES.CONFERENCE)
                    // dispatch(ACT_HOLD_CALL)   
                } else {
                    let size = calculateSizeWindow(data.contacts.length + 1, data.options.video)
                    ipc.send('phone-container-show', { size, data: { type: PHONE_TYPES.CONFERENCE, isIncomming: false }})
                    let roomId = await dispatch(`${VIDEO_CONF}/${ACT_ADD_CONF}`, data, { root: true })
                    dispatch(ACT_ADD_ANSWERED_CALL, { type: PHONE_TYPES.CONFERENCE, id: roomId, isIncomming: false, status: ANSWERED_CALL_STATUSES.TALK })
                }
                break
            case PHONE_TYPES.TETRA:
                if (!state.answeredCalls.length) ipc.send('phone-container-show', { data: { type: PHONE_TYPES.TETRA, isIncomming: false, data } })
                let cp = await dispatch(`${TETRA}/${ACT_TETRA_MAKE_DUPLEX_CALL}`, { ssi: data.ssi }, {root: true})
                dispatch(ACT_ADD_ANSWERED_CALL, { type: PHONE_TYPES.TETRA, id: data.ssi, isIncomming: false, status: ANSWERED_CALL_STATUSES.CREATED, data: cp })
                dispatch(`${TETRA}/${ACT_TETRA_SELECT_DUPLEX_CALL}`, {ssi: data.ssi, active: true}, {root: true})
                break
        }

        async function selectTypeCall(callId) {
            data.callId = callId
            // if (data.options.video) size = { width: 600, height: 400 }
            if(data.number) {
                let assistant = rootGetters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
                if (assistant === '') {
                    dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                        name: 'alert',
                        props: {
                            title: locale.errors.error,
                            text: locale['communication-not-available']
                        }
                    }, { root: true })
                }
                if (assistant && assistant !== 'sip') {
                    // ipc.send('phone-container-show', { data: { type: PHONE_TYPES.ASSISTANT, isIncomming: false, data }})
                    dispatch(`${ASISTANTS}/${ACT_DIAL_NUMBER}`, {cid: data.cid, digits: data.number}, { root: true })
                    dispatch(`${ASISTANTS}/${ACT_CALL_ASSISTANT}`, { number: data.number }, { root: true })
                } else if (assistant === 'sip') {
                    const checkResult = await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, { audio: true }, { root: true })
                    if (!(checkResult.audio)) return
                    ipc.send('phone-container-show', { data: { type: PHONE_TYPES.PHONE, isIncomming: false, data } })
                    dispatch(ACT_ADD_ANSWERED_CALL, { type: PHONE_TYPES.PHONE, id: callId, isIncomming: false, data })
                } else {
                    dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                        name: 'select-assistant',
                        props: {
                            cb: async () => selectTypeCall()
                        }
                    }, { root: true })
                }
            } else {
                const checkResult = await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, { audio: true, video: data.video }, { root: true })
                if (!(checkResult.audio)) return
                if (data.video && !checkResult.video && !(await continueWithoutCamera(dispatch))) return
                ipc.send('phone-container-show', { size, data: { type: PHONE_TYPES.PHONE, isIncomming: false, data }})
                dispatch(ACT_ADD_ANSWERED_CALL, { type: PHONE_TYPES.PHONE, id: callId, isIncomming: false, data })
            }
        }
    },
    [CALLS_EVENTS_CONTROLLER]: ({state, dispatch, commit, rootGetters}, {type, data}) => {
        const dnd = rootGetters[`${USERDATA}/${GET_DND}`]
        const isElectron = rootGetters['clientdata/getIsElectron']
        let typeCall, name, icon, typeAnswerBtn, contact

        if(state.answeredCalls.length >= 2) return

        switch(type) {
            case 'conference-event':
                let confExistsInAnsweredCalls = state.answeredCalls.some(item => item.id === data.roomId)
                let confExistsInIncommingCalls = state.incommingCalls.some(item => item.id === data.roomId)
                if (confExistsInAnsweredCalls) {
                    dispatch(`${VIDEO_CONF}/${ACT_CHANGE_CONFERENCE_EVENT}`, data, {root: true})
                    return
                } else if (confExistsInIncommingCalls) {
                    commit('mutUpdateIncommingCall', { data, type: PHONE_TYPES.CONFERENCE, id: data.roomId })
                    return
                } else if (state.answeredCalls.length) return

                typeCall = locale.phone['incoming-call']
                name = data.options.video ? locale['group-video-call'] : locale['group-call']

                dispatch(ACT_SHOW_INCOMMING_CALL, {
                    show: true,
                    name: data.options.video ? locale['group-video-call'] : locale['group-call'],
                    type: PHONE_TYPES.CONFERENCE,
                    id: data.roomId
                })
                dispatch(ACT_ADD_INCOMMING_CALL, { data, type: PHONE_TYPES.CONFERENCE, id: data.roomId })
                break
        }
    },
    [ACT_PHONE_SHOW]: ({ dispatch }, { type, id, isIncomming, data }) => {
        switch (type) {
            case PHONE_TYPES.PHONE:
                break
            case PHONE_TYPES.CONFERENCE:
                break
            case PHONE_TYPES.ASSISTANT:
                if (!state.answeredCalls.length) ipc.send('phone-container-show', { data: { type, isIncomming, data }})
                if (data.active) dispatch(`${ASISTANTS}/${ACT_CALL_ASSISTANT}`, { number: data.digits }, { root: true })
                dispatch(ACT_ADD_ANSWERED_CALL, { type, id, isIncomming, data })
                if (state.incommingCalls.some(item => item.id === id)) dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                break
        }        
    },
    [ACT_PHONE_HIDE]: ({ dispatch }, { type }) => {
        switch (type) {
            case PHONE_TYPES.PHONE:
                break
            case PHONE_TYPES.CONFERENCE:
                break
            case PHONE_TYPES.ASSISTANT:
                state.answeredCalls.forEach(({ type, id }) => {
                    if (type === PHONE_TYPES.ASSISTANT) dispatch(ACT_DELETE_ANSWERED_CALL, { type, id })
                })                
                break
        }   
    },
    [ACT_ANSWER_INCOMMING_CALL]: async ({ state, dispatch, rootGetters }, { type, id }) => {
        let checkResult
        let incommingCall = findCall(state.incommingCalls, {type, id})
        if (!incommingCall) return
        let data = incommingCall.data

        switch (type) {
            case PHONE_TYPES.PHONE:
                checkResult = await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, { audio: true, video: data.options.video }, { root: true })
                if (!(checkResult.audio)) return
                if (data.options.video && !checkResult.video && !(await continueWithoutCamera(dispatch))) return

                if (rootGetters['clientdata/getIsElectron']) {
                    if (state.answeredCalls.length) {
                        dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, {
                            eventName: 'rtc-call-event',
                            data: {isIncomming: true, ...data}
                        }, {root: true})
                    } else ipc.send('phone-container-show', {data: {type, isIncomming: true, data}})
                }

                dispatch(ACT_ADD_ANSWERED_CALL, { type, id, isIncomming: true, data })
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                break
            case PHONE_TYPES.CONFERENCE:
                checkResult = await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, { audio: true, video: data.options.video }, { root: true })
                if (!(checkResult.audio)) return
                if (data.options.video && !checkResult.video && !(await continueWithoutCamera(dispatch))) return
                let size = calculateSizeWindow(data.contacts.length, data.options.video)
                ipc.send('phone-container-show', { size, data: { type: PHONE_TYPES.CONFERENCE, isIncomming: true } })
                dispatch(ACT_ADD_ANSWERED_CALL, { type, id, isIncomming: true, status: ANSWERED_CALL_STATUSES.TALK })
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })  
                dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_EVENT}`, data, { root: true })
                break
            case PHONE_TYPES.ASSISTANT:
                if (!state.answeredCalls.length) ipc.send('phone-container-show', { data: { type: PHONE_TYPES.ASSISTANT, isIncomming: false, data }})
                dispatch(ACT_ADD_ANSWERED_CALL, { type, id, isIncomming: data.isIncomming, data })
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                dispatch(`${ASISTANTS}/${ACT_CALL_ASSISTANT}`, { number: data.number }, { root: true })
                dispatch(`${ASISTANTS}/${ACT_SET_ACTIVE_CALL}`, id, { root: true })
                break
            case PHONE_TYPES.TETRA:
                checkResult = await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, { audio: true, video: false }, { root: true })
                if (!(checkResult.audio)) return
                if (!state.answeredCalls.length) ipc.send('phone-container-show', {data: {type, isIncomming: true, data}})
                dispatch(`${TETRA}/${ACT_TETRA_ANSWER_DUPLEX_CALL}`, { ssi: id }, { root: true })
                dispatch(ACT_ADD_ANSWERED_CALL, { type, id, status: ANSWERED_CALL_STATUSES.TALK, isIncomming: true, data })
                dispatch(`${TETRA}/${ACT_TETRA_SELECT_DUPLEX_CALL}`, {ssi: id, active: true}, {root: true})
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                break
        } 
    },
    [ACT_TERMINATE_ALL_INCOMING_CALLS]: ({ state, dispatch }) => {
        state.incommingCalls.forEach((call => dispatch(ACT_TERMINATE_INCOMMING_CALL, call)))
    },
    [ACT_TERMINATE_INCOMMING_CALL]: ({ commit, dispatch }, { type, id, remoteTerminate }) => {
        switch (type) {
            case PHONE_TYPES.PHONE:
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                dispatch(`${PHONE}/${ACT_PHONE_RTC_CALL_TERMINATION_API}`, { callId: id }, {root: true})
                break
            case PHONE_TYPES.CONFERENCE:
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                if (!remoteTerminate) dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_TERMINATION}`, id, { root: true })            
                break
            case PHONE_TYPES.ASSISTANT:
                if (!remoteTerminate) dispatch(`${ASISTANTS}/${ACT_TERMINATE_ASISTANT}`, id, { root: true })
                state.incommingCalls.forEach(item => {
                    if (item.type === type && item.id === id) dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                })
                break
            case PHONE_TYPES.TETRA:
                dispatch(ACT_HIDE_INCOMMING_CALL, { type, id })
                dispatch(`${TETRA}/${ACT_TETRA_END_DUPLEX_CALL}`, { ssi: id }, {root: true})
                break
        }        
    },
    [ACT_SHOW_INCOMMING_CALL]: ({ state, dispatch }, data) => {
        let isExistsCall = state.incommingCalls.some(item => item.id === data.id)
        if (isExistsCall) {
            ipc.send('incomming-call-update', data)
        } else ipc.send('incomming-call-show', data)
    },
    [ACT_UPDATE_INCOMMING_CALL]: ({commit}, data) => {
        commit('mutUpdateIncommingCall', data)
    },
    [ACT_HIDE_INCOMMING_CALL]: ({commit, dispatch}, data) => {
        commit(MUT_DELETE_INCOMING_CALL, data)
        dispatch(`${NOTIFICATIONS_NEW}/${ACT_NOTIFY_NEW_REMOVE_BY_DATA}`, {
            type: NOTIFY_DATA_TYPES.INCOMING_CALL,
            payload: data
        }, {root: true})
        ipc.send('destroy-incomming-call', data)
    },
    [ACT_ADD_INCOMMING_CALL]: ({commit, dispatch}, data) => {
        const payload = { type: data.type, id: data.id }
        if (data.data && data.data.contact) payload.contact = data.data.contact
        dispatch(ACT_SHOW_INCOMMING_CALL, payload)
        commit('mutAddIncommingCall', data)
    },
    [ACT_HOLD_CALL]: ({ state, dispatch, commit }, {type, id}) => {
        switch (type) {
            case PHONE_TYPES.PHONE:
                break
            case PHONE_TYPES.CONFERENCE:
                dispatch(`${VIDEO_CONF}/${ACT_HOLD_CONF}`, null, { root: true })
                break
            case PHONE_TYPES.ASSISTANT:
                break
            case PHONE_TYPES.TETRA:
                dispatch(`${TETRA}/${ACT_TETRA_TOGGLE_MICROPHONE}`, {ssi: id, state: false}, { root: true })
                break
        }
        commit(MUT_SET_ANSWERED_CALL_STATUS, { type, id, status: ANSWERED_CALL_STATUSES.HOLD})
    },
    [ACT_ADD_ANSWERED_CALL]: ({ state, dispatch, commit }, { status = ANSWERED_CALL_STATUSES.CREATED, talkStartTime = 0, active = true, ...call }) => {
        dispatch(ACT_SET_ON_HOLD_OTHER_CALLS, call)
        commit(MUT_ADD_ANSWERED_CALL, call)
        commit(MUT_SET_ANSWERED_CALL_STATUS, { type: call.type, id: call.id, status, talkStartTime, active })
    },
    [ACT_SET_ON_HOLD_OTHER_CALLS]: ({ state, dispatch, commit }, call) => {
        const diffTypesCalls = state.answeredCalls.filter((answeredCall) => answeredCall.type !== call.type && call.status !== ANSWERED_CALL_STATUSES.HOLD)
        diffTypesCalls.forEach((call) => {
            switch (call.type) {
                case PHONE_TYPES.PHONE:
                    dispatch(`${PHONE}/${ACT_TETRA_PAUSE}`,  {callId: call.id, hold: true}, {root: true})
                    break
                case PHONE_TYPES.CONFERENCE:
                    break
                case PHONE_TYPES.ASSISTANT:
                    break
                case PHONE_TYPES.TETRA:
                    dispatch(`${TETRA}/${ACT_TETRA_PAUSE}`, {ssi: call.id, pause: true}, {root: true})
                    break
            }
        })
    },
    [ACT_SET_POSITION_ANSWERED_CALL]: ({ commit }, payload) => {
        commit(MUT_MOVE_ANSWERED_CALL_POSITION, payload)
    },
    [ACT_SUBSCRIBE_ON_FREE_REQUEST]: async ({dispatch, rootGetters}, {type, id, ...payload}) => {
        let { error, availability } = await dispatch(ACT_SUBSCRIBE_AVAILABILITY, payload)

        if (error) {
            dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                name: 'alert',
                props: {
                    title: locale.errors.error,
                    text: locale.phone.subscriptionError
                }
            }, { root: true })
        } else if (availability) {
            dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                name: 'confirm',
                props: {
                    title: locale['action-confirm'],
                    text: locale.phone.abonentFreeNow,
                    btnOk: {
                        cb: () => dispatch(ACT_CALL_CONTINUE, {type, id})
                    },
                }
            }, { root: true })
        } else {
            dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, { params: {callId: id}, command: 'terminate'}, {root: true})
        }
    },
    [ACT_SUBSCRIBE_AVAILABILITY]: () => {},
    [ACT_CALL_CONTINUE]: ({dispatch}, {type, id}) => {
        switch (type) {
            case PHONE_TYPES.PHONE: {
                dispatch(`${PHONE}/${ACT_PHONE_CALL_CONTINUE}`, {id}, {root: true})
                break
            }
        }
    },
    [ACT_SELECT_CALL]: ({ state, commit, dispatch }, { type, id, data, auto }) => {
        dispatch(ACT_SET_ON_HOLD_OTHER_CALLS, { type, id })
        switch (type) {
            case PHONE_TYPES.PHONE:
                ipc.send('phone-set-call', type)
                dispatch(`${PHONE}/${ACT_PHONE_SELECT}`, {callId: id, active: !auto}, {root: true})
                dispatch(ACT_SET_POSITION_ANSWERED_CALL, { type, id })
                break
            case PHONE_TYPES.ASSISTANT:
                //@todo auto костыль для ASSISTANT, передается из ACT_DELETE_ANSWERED_CALL
                if (!auto) {
                    dispatch(`${ASISTANTS}/${ACT_CALL_ASSISTANT}`, {number: data.digits}, {root: true})
                    dispatch(`${ASISTANTS}/${ACT_SET_ACTIVE_CALL}`, id, {root: true})
                    dispatch(ACT_SET_POSITION_ANSWERED_CALL, {type, id})
                }
                break
            case PHONE_TYPES.TETRA:
                dispatch(`${TETRA}/${ACT_TETRA_SELECT_DUPLEX_CALL}`, {ssi: id, active: !auto}, {root: true})
        }
        //commit(MUT_SET_ANSWERED_CALL_STATUS, { type, id, active: true})
    },
    [ACT_TOGGLE_PHONE_MINIMIZE]: ({state, commit}, options) => {
        let newval = (options &&  'minimize' in options) ? options.minimize : !state.minimized
        
        if (newval) commit(MUT_SET_PHONE_FULLSCREEN, !newval)
        commit(MUT_SET_MINIMIZED, newval)
    },
    [ACT_MINIMIZE]: ({commit}, data) => {
        commit(MUT_SET_MINIMIZED, data)
    },
    [ACT_TOGGLE_PHONE_FULLSCREEN]: ({state, commit}) => {
        commit(MUT_SET_PHONE_FULLSCREEN, !state.fullScreen)
    },
    [ACT_SET_PHONE_FULLSCREEN]: ({commit}, data) => {
        commit(MUT_SET_PHONE_FULLSCREEN, data)
    },
    [ACT_GET_RTC_CALL_AVAILABILITY]: () => {
    },
    [ACT_SET_ANSWERED_CALL_STATUS]: ({commit}, data) => {
        commit(MUT_SET_ANSWERED_CALL_STATUS, data)
    },
    [ACT_SET_CALL_STATUS]: ({commit}, data) => {
        commit(MUT_SET_CALL_STATUS, data)
    },
    [ACT_TERMINATE_CALL]: ({ state, commit, dispatch }, data) => {
        if (!state.answeredCalls.length) ipc.send('phone-close')
        dispatch(ACT_DELETE_ANSWERED_CALL, data)
    },
    [ACT_UPDATE_ANSWERED_CALL_ID]: ({commit}, data) => {
        commit(MUT_UPDATE_ANSWERED_CALL_ID, data)
    },
    [ACT_DELETE_ANSWERED_CALL]: ({ state, commit, dispatch }, { type, id }) => {
        commit(MUT_DELETE_ANSWERED_CALL, { type, id })
        if (!state.answeredCalls.length) {
            commit(MUT_SET_MINIMIZED, false)
            ipc.send('phone-close')
        } else {
            let lastTypeCall = state.answeredCalls.slice().reverse().find((call) => call.type === type)
            if (lastTypeCall) dispatch(ACT_SELECT_CALL, {...lastTypeCall, auto: true})
        }
    },
    [ACT_SET_CALL_PARTICIPANTS]: ({commit}, payload) => {
        commit(MUT_UPDATE_CALL_PARTICIPANTS, payload)
    },
    [ACT_SET_CALL_MIC_MUTE_STATUS]: ({commit}, payload) => {
        commit(MUT_PHONE_SET_MIC_MUTE_STATUS, payload)
    },
}

const mutations = {
    toggleDialer: state => state.opendDaler = !state.opendDaler,
    showIncommingCall: state => state.openIncommingCall = true,
    hideIncommingCall: state => state.openIncommingCall = false,
    mutAddIncommingCall: (state, data) => state.incommingCalls.push(data),
    [MUT_DELETE_INCOMING_CALL]: (state, { type, id }) => {
        for (let i = state.incommingCalls.length - 1; i >= 0; i--) {
            if (state.incommingCalls[i].type === type && state.incommingCalls[i].id === id) state.incommingCalls.splice(i, 1)
        }
    },
    [MUT_ADD_ANSWERED_CALL]: (state, call) => {
        let newCall = true
        state.answeredCalls.forEach((item, i) => {
            let statusText = state.answeredCalls[i].statusText
            if (call.data.rtcCallId && call.data.rtcCallId === item.id && call.id !== item.id) {
                state.answeredCalls.splice(i, 1, {statusText, ...call})
                newCall = false
            } else if (call.type === item.type && call.data.id && call.data.id === item.data.id) {
                state.answeredCalls.splice(i, 1, {statusText, ...call})
                newCall = false
            }
        })
        if (newCall) state.answeredCalls.unshift(call) //@todo
    },
    [MUT_DELETE_ANSWERED_CALL]: (state, { type, id } ) => {       
        for (let i = state.answeredCalls.length - 1; i >= 0; i--) {
            if (state.answeredCalls[i].type === type && state.answeredCalls[i].id === id) state.answeredCalls.splice(i, 1)
        }    
    },
    [MUT_MOVE_ANSWERED_CALL_POSITION]: (state, {type, id}) => {
        let index = state.answeredCalls.findIndex(call => call.type === type && call.id === id)
        if (index >= 0) {
            let call = state.answeredCalls[index]
            state.answeredCalls.splice(index, 1)
            state.answeredCalls.unshift(call) //@todo
        }
    },
    [SET_DIALER_NUMBER]: (state, data) => state.dialerNumber = data,
    mutUpdateIncommingCall: (state, {id, type, data}) => {
        state.incommingCalls.forEach((item, i) => {
            if (item.id === id && item.type) Vue.set(state.incommingCalls[i], 'data', data)
        })
    },
    [MUT_SET_MINIMIZED]: (state, minimized) => {
        state.minimized = minimized
    },
    [MUT_SET_PHONE_FULLSCREEN]: (state, fullScreen) => {
        state.fullScreen = fullScreen
    },
    [MUT_SET_ANSWERED_CALL_STATUS]: (state, {type, id, status = null, talkStartTime, active}) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) {
            if (!call.talkStartTime && ANSWERED_CALL_STATUSES.TALK === status ||talkStartTime ) {
                Vue.set(call, 'talkStartTime', talkStartTime || moment().unix())
            }
            if (status !== null && call.status !== status) {
                Vue.set(call, 'status', status)
            }
            if (active && !call.active) state.answeredCalls.forEach(call => Vue.set(call, 'active', call.type === type && call.id === id))
        }
    },
    [MUT_SET_CALL_STATUS]: (state, {type, id, status}) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) Vue.set(call, 'statusText', status)
    },
    [MUT_UPDATE_ANSWERED_CALL_ID]: (state, {type, id, newId}) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) Vue.set(call, 'id', newId)
    },
    [MUT_UPDATE_CALL_PARTICIPANTS]: (state, {type, id, participants}) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) Vue.set(call, 'participants', participants)
    },
    [MUT_PHONE_SET_MIC_MUTE_STATUS]: (state, {type, id, status}) => {
        let call = findCall(state.answeredCalls, {type, id})
        if (call) Vue.set(call, 'micMute', status)
    }
}

function findCall(calls, {type, id}) {
    return calls.find(call => call.type === type && call.id === id)
}

function isUniqueCall({answeredCalls, incommingCalls, number, cid}) {
    return !answeredCalls.concat(incommingCalls).find(item => {
        if (item.type === PHONE_TYPES.ASSISTANT && item.data.digits === number) return true
        if (item.type === PHONE_TYPES.PHONE && ((number && (item.data.number === number)) || item.data.cid === cid)) return true
    })
}

function generateTemporaryCallId(answeredCalls) {
    return tempId--
}

function getContactCall (data, rootGetters, locale) {
    if('number' in data && data.number) {
        let contact = rootGetters['contacts/getMergedContactByPhone'](data.number)
        if(contact && contact.fio) return { name: contact.fio, icon: contact.photo }
        else if( data.number === '') return { name: locale.modal['not-defined-number'], icon: '' }
        else return { name: data.number, icon: '' }
    } else {
        let user = rootGetters['contacts/getMergedContactById'](data.cid)
        return { name: user.fio, icon: user.photo }
    }
}

function calculateSizeWindow (countUsers, video) {
    if (!video) return { width: 344, height: 544 }
    switch (countUsers) {
        case 1:
        case 2:
            return { width: 444, height: 644 }
        case 3:
        case 4:
            return { width: 844, height: 644 }
        default:
            return { width: 1244, height: 644 }
    }
}

async function continueWithoutCamera(dispatch) {
    return new Promise((resolve) => {
        dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
            component: ContinueWithoutCamera,
            props: {
                btnOk: {
                    cb () { resolve(true) },
                },
                btnCancel: {
                    cb () { resolve(false) },
                },
            },
        }, { root: true })
    })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
