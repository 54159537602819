var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-record-btn", on: { click: _vm.openAcsEdit } },
    [
      _c("i", { staticClass: "btn-icon fal fa-user-plus" }),
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("acs.add-note"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }