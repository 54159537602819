// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-wrapper[data-v-6825f7c2] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-wrapper .icon[data-v-6825f7c2] {
  display: flex;
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 50%;
  margin: auto;
}
.icon-wrapper .icon i[data-v-6825f7c2] {
  margin: auto;
  font-size: 16px;
  color: var(--icon-color-icon);
}
.icon-wrapper.link:not(.disabled) .icon[data-v-6825f7c2] {
  cursor: pointer;
}
.icon-wrapper.link:not(.disabled) .icon[data-v-6825f7c2]:hover {
  background-color: var(--icon-btn-hover);
}
.icon-wrapper.with-background .icon[data-v-6825f7c2] {
  background-color: var(--icon-btn-with-bg);
}
.icon-wrapper.position-left i[data-v-6825f7c2] {
  margin-left: 0;
  font-size: 18px;
}
.icon-wrapper .sub-text[data-v-6825f7c2] {
  font-size: var(--sub-title-font-size);
  color: var(--icon-color);
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomRoundBtn.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;EACZ,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,eAAe;AACjB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,qCAAqC;EACrC,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".icon-wrapper[data-v-6825f7c2] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.icon-wrapper .icon[data-v-6825f7c2] {\n  display: flex;\n  width: var(--btn-size);\n  height: var(--btn-size);\n  border-radius: 50%;\n  margin: auto;\n}\n.icon-wrapper .icon i[data-v-6825f7c2] {\n  margin: auto;\n  font-size: 16px;\n  color: var(--icon-color-icon);\n}\n.icon-wrapper.link:not(.disabled) .icon[data-v-6825f7c2] {\n  cursor: pointer;\n}\n.icon-wrapper.link:not(.disabled) .icon[data-v-6825f7c2]:hover {\n  background-color: var(--icon-btn-hover);\n}\n.icon-wrapper.with-background .icon[data-v-6825f7c2] {\n  background-color: var(--icon-btn-with-bg);\n}\n.icon-wrapper.position-left i[data-v-6825f7c2] {\n  margin-left: 0;\n  font-size: 18px;\n}\n.icon-wrapper .sub-text[data-v-6825f7c2] {\n  font-size: var(--sub-title-font-size);\n  color: var(--icon-color);\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
