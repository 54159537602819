var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "media-search-audio", attrs: { id: "media-search-area" } },
    _vm._l(Object.keys(_vm.messages), function (key) {
      return _c("div", { staticClass: "months-group" }, [
        _c("span", { staticClass: "months-name" }, [_vm._v(_vm._s(key))]),
        !_vm.isChannel
          ? _c(
              "div",
              { staticClass: "audio-wrapper" },
              _vm._l(
                _vm.prepareMessages(_vm.messages[key]),
                function (message, i) {
                  return _c("chat-audio-message", {
                    key: i,
                    staticClass: "media-audio-search",
                    attrs: {
                      message: message,
                      withDownload: "true",
                      showSize: true,
                    },
                    nativeOn: {
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.onContextMenu($event, message)
                      },
                    },
                  })
                }
              ),
              1
            )
          : _vm._e(),
        _vm.isChannel
          ? _c(
              "div",
              { staticClass: "audio-wrapper" },
              _vm._l(_vm.messages[key], function (message, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "publications-audio-wrapper",
                    attrs: { message: message },
                  },
                  [
                    _c("publication-audio-item-search", {
                      key: i,
                      attrs: { message: message },
                      nativeOn: {
                        contextmenu: function ($event) {
                          $event.preventDefault()
                          return _vm.onContextMenu($event, message)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }