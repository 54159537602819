
    import SelectContact from "./SelectContact.vue"
    import ChatEdit from "./ChatEdit.vue"
    import { i18n } from '../../../ext/i18n'
    import {CONTACTS_SEARCH_TYPES} from '../../constants'

    export default {
        name: "CreateChat",
        extends: SelectContact,
        data() {
            return {
                searchType: CONTACTS_SEARCH_TYPES.SEND_MESSAGE,
            }
        },
        computed: {
            multiple: () => true,
            okText: () => i18n.messages[i18n.locale].continue
        },
        methods: {
            onOk(contacts) {
                this.$refs.modal.$on('closed', ()=> {
                    if (!contacts.length) return
                    this.modalOpen({
                        component: ChatEdit,
                        props: {contacts}
                    })
                })
                this.modalClose()
            },
        }
    }
