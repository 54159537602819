// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-msg[data-v-0696f9dc] {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
}
.schedule-msg .schedule-img[data-v-0696f9dc] {
  display: flex;
  align-items: center;
}
.schedule-msg .schedule-img .icon-block[data-v-0696f9dc] {
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  padding: 6px;
}
.schedule-msg .schedule-img .icon-block .icon-wrapper[data-v-0696f9dc] {
  height: 27px;
  width: 27px;
  display: flex;
}
.schedule-msg .schedule-img .icon-block .icon-wrapper img[data-v-0696f9dc] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.schedule-msg .schedule-msg-title[data-v-0696f9dc] {
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
  max-width: 90%;
}
.schedule-msg .schedule-msg-title .subtitle[data-v-0696f9dc] {
  font-size: 13px;
  color: var(--elem-grey-color);
}
.schedule-msg .schedule-msg-title .subject[data-v-0696f9dc] {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 2px;
  margin-right: 2px;
}
.schedule-msg .schedule-msg-title .subject span[data-v-0696f9dc] {
  padding-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chatScheduleMessage.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;AACf;AACA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".schedule-msg[data-v-0696f9dc] {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  cursor: pointer;\n}\n.schedule-msg .schedule-img[data-v-0696f9dc] {\n  display: flex;\n  align-items: center;\n}\n.schedule-msg .schedule-img .icon-block[data-v-0696f9dc] {\n  overflow: hidden;\n  border-radius: 50%;\n  background-color: white;\n  padding: 6px;\n}\n.schedule-msg .schedule-img .icon-block .icon-wrapper[data-v-0696f9dc] {\n  height: 27px;\n  width: 27px;\n  display: flex;\n}\n.schedule-msg .schedule-img .icon-block .icon-wrapper img[data-v-0696f9dc] {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n  object-position: center;\n}\n.schedule-msg .schedule-msg-title[data-v-0696f9dc] {\n  display: flex;\n  flex-direction: column;\n  padding-left: 0.5em;\n  max-width: 90%;\n}\n.schedule-msg .schedule-msg-title .subtitle[data-v-0696f9dc] {\n  font-size: 13px;\n  color: var(--elem-grey-color);\n}\n.schedule-msg .schedule-msg-title .subject[data-v-0696f9dc] {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  padding-right: 2px;\n  margin-right: 2px;\n}\n.schedule-msg .schedule-msg-title .subject span[data-v-0696f9dc] {\n  padding-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
