
    import PhoneAudio from './PhoneAudio.vue'
    import {ANSWERED_CALL_STATUSES, PHONE_TYPES} from '../../constants'
    import {PHONE_BTN_TYPES} from './PhoneFunctionButton.vue'
    import {
        GET_CALL_TALK_TIME,
        GET_TETRA_ACTIVE_DUPLEX_CALL,
        GET_TETRA_DUPLEX_CALLS,
    } from '../../store/gettersTypes'
    import {mapActions, mapGetters} from 'vuex'
    import {TETRA, PHONE_CNTL} from '../../store/modulesNames'
    import {
        ACT_SET_CALL_STATUS,
        ACT_TETRA_TOGGLE_MICROPHONE,
        ACT_TETRA_TRANSIT,
        ACT_TETRA_PAUSE,
        ACT_TETRA_SET_ACTIVE_CALL,
        ACT_TETRA_END_DUPLEX_CALL,
    } from '../../store/actionsTypes'

    export default {
        name: 'PhoneTetra',
        extends: PhoneAudio,
        computed: {
            // *** Переопределение start *** //
            type() {
                return PHONE_TYPES.TETRA
            },
            id() {
                return this.activeCall && this.activeCall.id
            },
            number() {
                return this.activeCall && this.activeCall.id
            },
            btnsDisabled() {
                return !this.activeCall
            },
            btns() {
                return [
                    PHONE_BTN_TYPES.TRANSIT,
                    PHONE_BTN_TYPES.PAUSE,
                    PHONE_BTN_TYPES.MICROPHONE,
                ]
            },
            statusText() {
                if (this.stateCall === ANSWERED_CALL_STATUSES.TALK) return this.time
                else return this.stateCallText
            },
            typeCallText() {
                return this.numberText
            },
            // *** Переопределение stop  *** //

            calls () {
                return this.$store.getters[`${TETRA}/${GET_TETRA_DUPLEX_CALLS}`]
            },
            activeCall () {
                return this.$store.getters[`${TETRA}/${GET_TETRA_ACTIVE_DUPLEX_CALL}`]
            },
            digits () {
                return ''
                //return this.activeCall && this.activeCall.dtmfDigits || ''
            },
            stateCall () {
                return this.activeCall && this.activeCall.status || ''
            },
            stateCallText() {
                switch (this.stateCall) {
                    case ANSWERED_CALL_STATUSES.CREATED:
                        return this.$t('tetra-call-status-calling')
                    case ANSWERED_CALL_STATUSES.HOLD:
                        return this.$t('tetra-call-status-hold')
                    case ANSWERED_CALL_STATUSES.ON_HOLD:
                        return this.$t('tetra-call-status-on-hold')
                    case ANSWERED_CALL_STATUSES.DISCONNECTED:
                        return this.$t('tetra-call-status-ended')
                }
                return ''
            },
            microphoneState () {
                return this.activeCall ? !this.activeCall.micMute : true
            },
            pause() {
                return this.stateCall === ANSWERED_CALL_STATUSES.HOLD
            },
            time() {
                return this.activeCall && this[GET_CALL_TALK_TIME](PHONE_TYPES.TETRA, this.activeCall.id)
            },
            micDisabled() {
                return !this.activeCall
            },
            inTalkState() {
                return this.stateCall && this.stateCall !== ANSWERED_CALL_STATUSES.TALK
            },
            transitDisabled() {
                return this.inTalkState
            },
            terminateDisabled() {
                if (!this.activeCall) return true
                return [ANSWERED_CALL_STATUSES.DISCONNECTED].includes(this.stateCall)
            },
            ...mapGetters(PHONE_CNTL, [GET_CALL_TALK_TIME]),
        },
        watch: {
            stateCallText(status) {
                this[ACT_SET_CALL_STATUS]({ type: PHONE_TYPES.TETRA, id: this.activeCall.id, status })
            },
            stateCall(state, oldState) {
                this.handleCallState(state, oldState)
            },
        },
        methods: {
            // *** Переопределение start *** //
            isFuncBtnActive(btn) {
                switch (btn) {
                    case PHONE_BTN_TYPES.MICROPHONE:
                        return !this.isFuncBtnDisabled(btn) && !this.microphoneState
                    case PHONE_BTN_TYPES.PAUSE:
                        return this.pause
                }
            },
            isFuncBtnDisabled (btnType) {
                return false
            },
            toggleMicrophone () {
                this.$store.dispatch(`${TETRA}/${ACT_TETRA_TOGGLE_MICROPHONE}`, { ssi: this.id })
            },
            doTransit () {
                this.$store.dispatch(`${TETRA}/${ACT_TETRA_TRANSIT}`)
            },
            togglePause() {
                this.$store.dispatch(`${TETRA}/${ACT_TETRA_PAUSE}`, { ssi: this.id, pause: !this.pause })
            },
            terminate() {
                if (this.terminateDisabled) return
                this.$store.dispatch(`${TETRA}/${ACT_TETRA_END_DUPLEX_CALL}`, { ssi: this.activeCall.id })
            },
            setActiveCall (id) {
                this.$store.dispatch(`${TETRA}/${ACT_TETRA_SET_ACTIVE_CALL}`, id)
            },
            // *** Переопределение stop *** //
            onCreated() {
                this.handleCallState(this.stateCall)
            },
            handleCallState(state, oldState) {
                let src = ''
                switch (state) {
                        /* case rtcCallStates.rtcCallStateBusy: //2
                         src = 'audio/call_ring_second_alert.wav'
                         break*/
                    case ANSWERED_CALL_STATUSES.CREATED:
                        src = 'audio/call_ring_ringing.wav'
                        break
                    case ANSWERED_CALL_STATUSES.ON_HOLD:
                        src = 'audio/hold.mp3'
                        break
                }
                this.setAudioSrc(src)
            },
            ...mapActions(PHONE_CNTL, [ACT_SET_CALL_STATUS])
        },
    }
