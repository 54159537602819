var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "svgwrapper" }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "42.64407",
          height: "36.881355",
          viewBox: "0 0 11.28291 9.7581921",
          id: "signallevel",
        },
      },
      [
        _c("defs", { attrs: { id: "defs11" } }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              id: "svgGroupLayer",
              transform:
                "matrix(0.0720339,0,0,0.0720339,-1.6554078,-5.8419494)",
            },
          },
          [
            _c("path", {
              class: _vm.level > 0 ? "active" : "",
              attrs: {
                id: "id1",
                d: "m 39.914285,182.7 h -12.7 c -2.33891,0 -4.23333,1.89441 -4.23333,4.23333 v 25.4 c 0,2.33892 1.89442,4.23333 4.23333,4.23333 h 12.7 c 2.33892,0 4.23334,-1.89441 4.23334,-4.23333 v -25.4 c 0,-2.33892 -1.89442,-4.23333 -4.23334,-4.23333 z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              class: _vm.level > 3.1 ? "active" : "",
              attrs: {
                id: "id2",
                d: "m 73.780955,157.3 h -12.7 c -2.33892,0 -4.23333,1.89442 -4.23333,4.23333 v 50.8 c 0,2.33892 1.89441,4.23333 4.23333,4.23333 h 12.7 c 2.33892,0 4.23333,-1.89441 4.23333,-4.23333 v -50.8 c 0,-2.33891 -1.89441,-4.23333 -4.23333,-4.23333 z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              class: _vm.level > 3.5 ? "active" : "",
              attrs: {
                id: "id3",
                d: "M 107.64763,131.9 H 94.947625 c -2.33892,0 -4.23334,1.89442 -4.23334,4.23333 v 76.2 c 0,2.33892 1.89442,4.23333 4.23334,4.23333 h 12.700005 c 2.33891,0 4.23333,-1.89441 4.23333,-4.23333 v -76.2 c 0,-2.33891 -1.89442,-4.23333 -4.23333,-4.23333 z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              class: _vm.level > 4.03 ? "active" : "",
              attrs: {
                id: "id4",
                d: "m 141.51429,106.5 h -12.7 c -2.33891,0 -4.23333,1.89442 -4.23333,4.23333 v 101.6 c 0,2.33892 1.89442,4.23333 4.23333,4.23333 h 12.7 c 2.33892,0 4.23334,-1.89441 4.23334,-4.23333 v -101.6 c 0,-2.33891 -1.89442,-4.23333 -4.23334,-4.23333 z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              class: _vm.level > 4.33 ? "active" : "",
              attrs: {
                id: "id5",
                d: "m 175.38096,81.100001 h -12.7 c -2.33892,0 -4.23333,1.89442 -4.23333,4.23333 V 212.33333 c 0,2.33892 1.89441,4.23333 4.23333,4.23333 h 12.7 c 2.33892,0 4.23333,-1.89441 4.23333,-4.23333 V 85.333331 c 0,-2.33891 -1.89441,-4.23333 -4.23333,-4.23333 z",
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }