import {
    ASISTANTS,
    PHONE_CNTL,
} from "../modulesNames"

import {
    GET_CONNECTION_STATUS,
    SOCKET_IS_CONNECTED,
} from '../gettersTypes'

import {
    ACT_SOCKET_INIT,
    ACT_SOCKET_RECONNECT,
    ACT_SET_CONNECTION_STATUS,
    ACT_TERMINATE_ALL_INCOMING_CALLS,
    ACT_ASSISTANT_TERMINATE_ALL_CALLS,
    ACT_SOCKET_CONNECT,
} from '../actionsTypes'

import {
    MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT,
    MUT_SOCKET_SET_CONNECTION_STATUS,
    MUT_SOCKET_SET_RECONNECT_TIMEOUT,
} from '../mutationsTypes'

export const SOCKET_STATUSES = {
    CLOSED: 'closed',
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    CONNECTED: 'connected'
}

// state
const state = {
    sid: 'disconnected',
    connection_status: SOCKET_STATUSES.DISCONNECTED,
    reconnectTimeouts: [],
    encryption: false,
}

// getters
const getters = {
    [GET_CONNECTION_STATUS]: (state) => state.connection_status,
    [SOCKET_IS_CONNECTED]: (state) => state.connection_status === SOCKET_STATUSES.CONNECTED,
}

// actions
const actions = {
    // ------------------ Abstract actions --------------------------
    async [ACT_SOCKET_INIT] () {},
    [ACT_SOCKET_RECONNECT] () {},
    async [ACT_SOCKET_CONNECT] () {},
    // --------------------------------------------------------------
    [ACT_SET_CONNECTION_STATUS] ({state, dispatch, commit}, status) {
        if (state.connection_status === status) return
        if (state.connection_status === SOCKET_STATUSES.CONNECTED) {
            dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_ALL_INCOMING_CALLS}`, null, { root: true })
            dispatch(`${ASISTANTS}/${ACT_ASSISTANT_TERMINATE_ALL_CALLS}`, null, { root: true })
        }
        commit(MUT_SOCKET_SET_CONNECTION_STATUS, status)
    },
}

const mutations = {
    [MUT_SOCKET_SET_CONNECTION_STATUS] (state, status) {
        console.log('MUT_SOCKET_SET_CONNECTION_STATUS', status)
        state.connection_status = status
    },
    [MUT_SOCKET_SET_RECONNECT_TIMEOUT] (state, timeOutId) {
        state.reconnectTimeouts.push(timeOutId)
    },
    [MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT] (state) {
        let timeoutId
        while (timeoutId = state.reconnectTimeouts.shift()) clearTimeout(timeoutId)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
