var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "div" }, [
    _vm.message.author
      ? _c("div", { staticClass: "forward" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "chat-call-availability-message" },
      [
        _c("icon-image", { attrs: { src: _vm.photo, text: _vm.title } }),
        _c("div", { staticClass: "text-block" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("chat.abonentIsAvailable")))]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }