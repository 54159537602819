// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog[data-v-bf8b0fc6] .body {
  padding: 0;
  flex-direction: column;
  height: 600px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNew/videoConfModal.vue"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,sBAAsB;EACtB,aAAa;AACf","sourcesContent":[".dialog[data-v-bf8b0fc6] .body {\n  padding: 0;\n  flex-direction: column;\n  height: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
