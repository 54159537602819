var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "switch-item item-cont",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "switch" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "switch-value-wrapper" },
          [
            _vm.switchValue && _vm.switchText
              ? _c("span", { staticClass: "switch-value-text" }, [
                  _vm._v(_vm._s(_vm.switchText)),
                ])
              : _vm._e(),
            _c("custom-checkbox-slider", {
              staticClass: "switch-btn",
              attrs: { default: _vm.switchValue, readonly: true },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }