// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-commands[data-v-2669b8e6] {
  width: 100%;
  bottom: 50px;
  background-color: var(--bg-color);
  overflow-y: auto;
  max-height: 200px;
  padding: 0px 10px;
  border-top: 1px solid var(--main-block-border-top);
}
.bot-commands .bot-command[data-v-2669b8e6] {
  border-bottom: 1px solid var(--list-item-bordercolor);
  padding: 5px 0px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
}
.bot-commands .bot-command .command[data-v-2669b8e6] {
  font-weight: bold;
  color: var(--toolbtn-color);
}
.bot-commands .bot-command .description[data-v-2669b8e6] {
  color: #BDBDBD;
  padding-left: 15px;
  font-size: 13px;
}
.bot-commands .bot-command[data-v-2669b8e6]:last-child {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/bots/BotCommands.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,kDAAkD;AACpD;AACA;EACE,qDAAqD;EACrD,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".bot-commands[data-v-2669b8e6] {\n  width: 100%;\n  bottom: 50px;\n  background-color: var(--bg-color);\n  overflow-y: auto;\n  max-height: 200px;\n  padding: 0px 10px;\n  border-top: 1px solid var(--main-block-border-top);\n}\n.bot-commands .bot-command[data-v-2669b8e6] {\n  border-bottom: 1px solid var(--list-item-bordercolor);\n  padding: 5px 0px;\n  cursor: pointer;\n  height: 50px;\n  display: flex;\n  align-items: center;\n}\n.bot-commands .bot-command .command[data-v-2669b8e6] {\n  font-weight: bold;\n  color: var(--toolbtn-color);\n}\n.bot-commands .bot-command .description[data-v-2669b8e6] {\n  color: #BDBDBD;\n  padding-left: 15px;\n  font-size: 13px;\n}\n.bot-commands .bot-command[data-v-2669b8e6]:last-child {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
