// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section-share[data-v-cc0f5518] {
  height: 30px;
  display: flex;
  -webkit-app-region: drag;
  color: white;
}
.header-section-share .title[data-v-cc0f5518] {
  margin: auto 5px;
}
.header-section-share .minimize-screen[data-v-cc0f5518] {
  -webkit-app-region: no-drag;
  margin: auto 0 auto auto;
  cursor: pointer;
  width: 30px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNew/videoConfSectionHeaderShare.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,2BAA2B;EAC3B,wBAAwB;EACxB,eAAe;EACf,WAAW;AACb","sourcesContent":[".header-section-share[data-v-cc0f5518] {\n  height: 30px;\n  display: flex;\n  -webkit-app-region: drag;\n  color: white;\n}\n.header-section-share .title[data-v-cc0f5518] {\n  margin: auto 5px;\n}\n.header-section-share .minimize-screen[data-v-cc0f5518] {\n  -webkit-app-region: no-drag;\n  margin: auto 0 auto auto;\n  cursor: pointer;\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
