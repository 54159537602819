// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label.custom-checkbox[data-v-17a72b7b] {
  cursor: pointer;
  position: relative;
  text-indent: 30px;
  font-weight: normal;
  line-height: 20px;
  color: gray;
  font-size: 15px;
  min-height: 18px;
  min-width: 18px;
  margin-bottom: auto;
}
label.custom-checkbox input[type='checkbox'][data-v-17a72b7b] {
  position: absolute;
  left: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  line-height: inherit;
  margin: auto;
  opacity: 0;
}
label.custom-checkbox input[type='checkbox']:checked + i[data-v-17a72b7b]::before {
  color: gray;
  opacity: 1;
}
label.custom-checkbox input[type='checkbox']:focus + i[data-v-17a72b7b] {
  border: 1px solid #2688c2;
}
label.custom-checkbox i[data-v-17a72b7b] {
  border: 1px solid lightgray;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  text-align: center;
  text-indent: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: inherit;
  margin-top: 2px;
}
label.custom-checkbox i[data-v-17a72b7b]::before {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomCheckbox.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,eAAe;EACf,SAAS;EACT,QAAQ;EACR,oBAAoB;EACpB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;AACjB;AACA;EACE,UAAU;AACZ","sourcesContent":["label.custom-checkbox[data-v-17a72b7b] {\n  cursor: pointer;\n  position: relative;\n  text-indent: 30px;\n  font-weight: normal;\n  line-height: 20px;\n  color: gray;\n  font-size: 15px;\n  min-height: 18px;\n  min-width: 18px;\n  margin-bottom: auto;\n}\nlabel.custom-checkbox input[type='checkbox'][data-v-17a72b7b] {\n  position: absolute;\n  left: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n  line-height: inherit;\n  margin: auto;\n  opacity: 0;\n}\nlabel.custom-checkbox input[type='checkbox']:checked + i[data-v-17a72b7b]::before {\n  color: gray;\n  opacity: 1;\n}\nlabel.custom-checkbox input[type='checkbox']:focus + i[data-v-17a72b7b] {\n  border: 1px solid #2688c2;\n}\nlabel.custom-checkbox i[data-v-17a72b7b] {\n  border: 1px solid lightgray;\n  width: 18px;\n  height: 18px;\n  position: absolute;\n  left: 0;\n  text-align: center;\n  text-indent: 0;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: normal;\n  line-height: inherit;\n  margin-top: 2px;\n}\nlabel.custom-checkbox i[data-v-17a72b7b]::before {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
