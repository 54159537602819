import { CLIENTDATA, USERDATA } from './app/store/modulesNames'
import { GET_IS_ELECTRON, GET_APP_REDIRECTED_SERVER } from './app/store/gettersTypes'

export default class Utils {
    getPageProtoHostPort () {
        if (app.store.getters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            try {
                const serverUrl = new URL(app.store.getters[`${USERDATA}/${GET_APP_REDIRECTED_SERVER}`])
                console.log(`Server: ${serverUrl.origin}`)
                return `${serverUrl.origin}`
            } catch (e) {
                return null
            }
        }
        return window.location.origin
    }
    getPageProtoHost () {
        return window.location.protocol + '//' + window.location.hostname
    }

    cloneObject (obj) {
        return JSON.parse(JSON.stringify(obj))
    }
    copyTextToClipboard (elem, text) {
        let delAfter = true
        if (!elem) {
            elem = document.createElement('input')
            elem.type = 'text'
            elem.className = 'clipboard-temp'
            document.body.append(elem)
            delAfter = true
        }
        elem.value = text
        elem.select()
        elem.setSelectionRange(0, 99999)
        let copy = document.execCommand('copy')
        elem.blur()
        if (delAfter) elem.remove()
    }
    getOrientation (file) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader()
                reader.onload = function (event) {
                    let view = new DataView(event.target.result)
                    if (view.getUint16(0, false) !== 0xFFD8) resolve(-2)
                    let length = view.byteLength
                    let offset = 2
                    while (offset < length) {
                        let marker = view.getUint16(offset, false)
                        offset += 2
                        if (marker === 0xFFE1) {
                            if (view.getUint32(offset += 2, false) !== 0x45786966) resolve(-1)
                            let little = view.getUint16(offset += 6, false) === 0x4949
                            offset += view.getUint32(offset + 4, little)
                            let tags = view.getUint16(offset, little)
                            offset += 2
                            for (let i = 0; i < tags; i++) {
                                if (view.getUint16(offset + (i * 12), little) === 0x0112) { resolve(view.getUint16(offset + (i * 12) + 8, little)) }
                            }
                        } else if ((marker & 0xFF00) !== 0xFF00) break
                        else offset += view.getUint16(offset, false)
                    }
                    resolve(-1)
                }
                reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
            } catch (e) {
                reject(e)
            }
        })
    };

    declOfNum(number, words) {
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]]
    }

    textSortFn(a, b) {
        if (typeof a === "string") a = a.toLowerCase().replace(/ё/g,'еe')
        if (typeof b === "string") b = b.toLowerCase().replace(/ё/g,'еe')
        if (a > b) return 1
        else if (a < b) return -1
        return 0
    }

    chatAndChannelSortFn(a, b) {
        const a_time = a.unixLastTime || a.unixCreateTime || a.lastMessage && a.lastMessage.unixTime || a.time || 0
        const b_time = b.unixLastTime || b.unixCreateTime || b.lastMessage && b.lastMessage.unixTime || b.time || 0
        const isAPinned = (a.settings && a.settings.pinned || a.pinned) && 1 || -1
        const isBPinned = (b.settings && b.settings.pinned || b.pinned) && 1 || -1
        if (isAPinned === isBPinned) return b_time - a_time
        else return isBPinned - isAPinned
    }

}
