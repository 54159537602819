// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-info .contact-btns .main-btn[data-v-6d23b43b] {
  margin: -12px 20px;
}
.contact-info .contact-btns .main-btn[data-v-6d23b43b]:first-child {
  margin-left: auto;
}
.contact-info .contact-btns .main-btn[data-v-6d23b43b]:last-child {
  margin-right: auto;
}
.contact-info .contact-btns .main-btn i[data-v-6d23b43b] {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/TetraContactInfo.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".contact-info .contact-btns .main-btn[data-v-6d23b43b] {\n  margin: -12px 20px;\n}\n.contact-info .contact-btns .main-btn[data-v-6d23b43b]:first-child {\n  margin-left: auto;\n}\n.contact-info .contact-btns .main-btn[data-v-6d23b43b]:last-child {\n  margin-right: auto;\n}\n.contact-info .contact-btns .main-btn i[data-v-6d23b43b] {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
