var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "technical-journal-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c("list-item", {
                        attrs: {
                          title: _vm.$t("settings.tj-get-all"),
                          link: true,
                          footer: _vm.$t("settings.tj-get-last-week"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.getLogs()
                          },
                        },
                      }),
                      _c("list-item", {
                        attrs: {
                          title: _vm.$t("settings.tj-get-last"),
                          link: true,
                          footer: _vm.$t("settings.tj-get-last-day"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.getLogs(true)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("settings.technical-journals"))),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }