var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        headerClose: false,
        title: _vm.title,
      },
    },
    [
      _c(
        "div",
        { staticClass: "filter" },
        [
          _c("custom-search", {
            staticClass: "search-contact search-input-wrapper filter-search",
            attrs: { search: _vm.search, focus: true },
            on: {
              "update:search": function ($event) {
                _vm.search = $event
              },
            },
          }),
          _c("div", { staticClass: "filter-line" }),
          _c(
            "filter-type-list",
            {
              attrs: {
                searchType: _vm.itemType,
                filter: _vm.search,
                searchList: _vm.searchList,
              },
              on: { selected: _vm.onContactSelected },
            },
            [_c("template", { slot: "footer" })],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.$t("cancel"))),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.onOk } }, [
          _vm._v(_vm._s(_vm.$t("loginPage.ok"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }