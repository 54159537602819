// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ajax-progress[data-v-2f9996e6] {
  margin: auto;
  z-index: 2;
}
.ajax-progress i[data-v-2f9996e6] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ajax-progress .circular-chart[data-v-2f9996e6] {
  border-radius: 50%;
  transform: rotate(-90deg);
  fill: none;
}
.ajax-progress .circular-chart .progress__value[data-v-2f9996e6] {
  stroke: #fff;
  stroke-linecap: round;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/AjaxProgressCircle.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".ajax-progress[data-v-2f9996e6] {\n  margin: auto;\n  z-index: 2;\n}\n.ajax-progress i[data-v-2f9996e6] {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.ajax-progress .circular-chart[data-v-2f9996e6] {\n  border-radius: 50%;\n  transform: rotate(-90deg);\n  fill: none;\n}\n.ajax-progress .circular-chart .progress__value[data-v-2f9996e6] {\n  stroke: #fff;\n  stroke-linecap: round;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
