// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-create[data-v-9dd24190] {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 100%;
}
.chat-create > *[data-v-9dd24190] {
  margin: auto 10px;
}
.chat-create .first-line[data-v-9dd24190] {
  display: flex;
  flex-direction: column;
}
.chat-create .first-line .chat-icon[data-v-9dd24190] {
  margin: auto;
}
.chat-create .first-line .icon-label[data-v-9dd24190] {
  margin: 20px auto;
  font-size: 16px;
  color: gray;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/ChatEdit.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb","sourcesContent":[".chat-create[data-v-9dd24190] {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 5px;\n  width: 100%;\n}\n.chat-create > *[data-v-9dd24190] {\n  margin: auto 10px;\n}\n.chat-create .first-line[data-v-9dd24190] {\n  display: flex;\n  flex-direction: column;\n}\n.chat-create .first-line .chat-icon[data-v-9dd24190] {\n  margin: auto;\n}\n.chat-create .first-line .icon-label[data-v-9dd24190] {\n  margin: 20px auto;\n  font-size: 16px;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
