// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal[data-v-64d0d64a] .v--modal-box.v--modal {
  overflow: visible;
}
.custom-modal[data-v-64d0d64a] .header {
  padding: 15px 20px 4px 15px;
}
.custom-modal[data-v-64d0d64a] .body {
  overflow-y: visible !important;
  width: 100%;
}
.custom-modal[data-v-64d0d64a] .conference-settings > .conf-item {
  display: flex;
  align-items: flex-end;
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/videoConfNewManagment/scheduleConferenceSettings.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".custom-modal[data-v-64d0d64a] .v--modal-box.v--modal {\n  overflow: visible;\n}\n.custom-modal[data-v-64d0d64a] .header {\n  padding: 15px 20px 4px 15px;\n}\n.custom-modal[data-v-64d0d64a] .body {\n  overflow-y: visible !important;\n  width: 100%;\n}\n.custom-modal[data-v-64d0d64a] .conference-settings > .conf-item {\n  display: flex;\n  align-items: flex-end;\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
