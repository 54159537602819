var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "central support" },
    [
      _vm.support
        ? _c(
            "main-content-container",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "items-list" },
                          [
                            _vm.supportCid
                              ? _c("list-item", {
                                  attrs: {
                                    title: _vm.$t("mainPage.chat"),
                                    link: true,
                                    chevron: false,
                                    footer: _vm.$t("mainPage.support-chat"),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.doOpenChatSupport.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.supportMail
                              ? _c("list-item", {
                                  attrs: {
                                    title: _vm.$t("mainPage.write-email"),
                                    link: true,
                                    chevron: false,
                                    footer: _vm.$t("mainPage.write-to-support"),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.doSendMailSupport.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                234253571
              ),
            },
            [
              _c("template", { slot: "header" }, [
                _vm._v(_vm._s(_vm.$t("mainPage.support-header"))),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.isShowCheckGroup
        ? _c(
            "main-content-container",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "items-list" },
                          [
                            _c("list-item", {
                              attrs: {
                                title: _vm.$t("mainPage.voice-call"),
                                link: true,
                                footer: _vm.$t("mainPage.assistant-voice-call"),
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.makeCall({ cid: _vm.checkCallCid })
                                },
                              },
                            }),
                            _vm.checkCallPhoneNumber
                              ? _c("list-item", {
                                  attrs: {
                                    title:
                                      _vm.$t("settings.support-number") +
                                      " (" +
                                      _vm.checkCallPhoneNumber +
                                      ")",
                                    link: true,
                                    footer: _vm.$t(
                                      "mainPage.assistant-number-call"
                                    ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.makeCall({
                                        cid: _vm.checkCallCid,
                                        number: _vm.checkCallPhoneNumber,
                                      })
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.radioServer
                              ? _c("list-item", {
                                  attrs: {
                                    title: _vm.$t("radio"),
                                    link: true,
                                    footer: _vm.$t(
                                      "mainPage.assistant-radio-call"
                                    ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.radioCall()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1050135305
              ),
            },
            [
              _c("template", { slot: "header" }, [
                _vm._v(_vm._s(_vm.$t("settings.checking"))),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.isElectron ? _c("technical-journals") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }