var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c("div", { staticClass: "main-header" }, [
        _c(
          "div",
          { staticClass: "section-header", attrs: { id: "main-header" } },
          [
            _vm.isFullCompactMode && !_vm.isSubOptions
              ? _c(
                  "div",
                  {
                    staticClass: "header-back circle-animation",
                    on: {
                      click: function ($event) {
                        return _vm._setSideVisible()
                      },
                    },
                  },
                  [_c("i", { staticClass: "far fa-arrow-left" })]
                )
              : _vm._e(),
            _vm.canBack
              ? _c(
                  "div",
                  {
                    staticClass: "header-back circle-animation",
                    on: { click: _vm.goBack },
                  },
                  [_c("i", { staticClass: "far fa-arrow-left" })]
                )
              : _vm._e(),
            _c(
              _vm.component,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.componentProps,
                false
              )
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }