// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper[data-v-12e87c68] {
  display: flex;
  flex-direction: column;
}
.contact-to-sent[data-v-12e87c68] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-to-sent .fio[data-v-12e87c68] {
  padding-left: 1em;
  font-weight: 600;
}
.input-block[data-v-12e87c68] {
  margin-top: 1em;
}
.input-block[data-v-12e87c68] label.custom-checkbox {
  color: inherit;
  margin-top: 0.7em;
  line-height: 1.25em;
}
.input-block[data-v-12e87c68] label.custom-checkbox i {
  margin-top: 10px;
}
.input-block[data-v-12e87c68] .sublabel {
  color: var(--message-grey-color);
  padding-left: 22px;
  margin-top: -8px;
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/SendContactWithFields.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".wrapper[data-v-12e87c68] {\n  display: flex;\n  flex-direction: column;\n}\n.contact-to-sent[data-v-12e87c68] {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.contact-to-sent .fio[data-v-12e87c68] {\n  padding-left: 1em;\n  font-weight: 600;\n}\n.input-block[data-v-12e87c68] {\n  margin-top: 1em;\n}\n.input-block[data-v-12e87c68] label.custom-checkbox {\n  color: inherit;\n  margin-top: 0.7em;\n  line-height: 1.25em;\n}\n.input-block[data-v-12e87c68] label.custom-checkbox i {\n  margin-top: 10px;\n}\n.input-block[data-v-12e87c68] .sublabel {\n  color: var(--message-grey-color);\n  padding-left: 22px;\n  margin-top: -8px;\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
