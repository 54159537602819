
    import SelectContact from './SelectContact.vue'
    import { i18n } from '../../../ext/i18n'

    /**
     *  @param {Object} props
     *  @param {Number} props.chId ид. канала
     *  @param {String} props.privilege привилегии добавляемого контакта
     *  @param {Function} props.cb callback
     *  @return Object
     */
    export default {
        name: "SelectContactsToChannel",
        extends: SelectContact,
        computed: {
            multiple: () => true,
            okText: () => i18n.messages[i18n.locale].add,
            chId() { return this.props.chId },
            privilege() { return this.props.privilege },
            joinedMembers() {
                return (this.members || []).reduce((joined, {cid, status}) => {
                    if (status === 'joined') joined.push(cid)
                    return joined
                }, [])
            },
            excludeMembers() {
                return (this.members || []).reduce((exc, {cid, privilege}) => {
                    if (privilege === this.privilege || privilege === 'owner') exc.push(cid)
                    return exc
                }, [])
            },
        },
        asyncComputed: {
            async members() {
                return await this.$store.dispatch('channels/getChannelUsers', {
                    chId: this.chId
                })
            }
        },
        methods: {
            async onOk (contacts) {
                contacts = contacts.map(contact => {
                    return {
                        cid: contact.cid,
                        privilege: this.privilege,
                        status: this.joinedMembers.includes(contact.cid) ? 'joined' : 'invited'
                    }
                })
                await this.$store.dispatch('channels/changeChannelUsers', {
                    chId: this.chId,
                    contacts: contacts
                });
                this.modalClose()
                this.props.cb && this.props.cb(contacts)
            },
        },
    }
