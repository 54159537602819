// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-record-btn[data-v-4f47984b] {
  display: flex;
  flex-direction: row;
  line-height: 68px;
  height: 68px;
  cursor: pointer;
}
.add-record-btn .btn-icon[class*="btn-icon"][data-v-4f47984b] {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin: auto 10px;
  background-color: var(--icon-bgcolor2);
  border-radius: 50px;
  color: var(--icon-color);
}
.add-record-btn .title[data-v-4f47984b] {
  line-height: 68px;
  display: flex;
  flex-grow: 1;
  color: var(--message-grey-color);
  border-bottom: 1px solid var(--asc-item-border-bottom);
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/acs/AcsAddBtn.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,sCAAsC;EACtC,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,sDAAsD;EACtD,gBAAgB;AAClB","sourcesContent":[".add-record-btn[data-v-4f47984b] {\n  display: flex;\n  flex-direction: row;\n  line-height: 68px;\n  height: 68px;\n  cursor: pointer;\n}\n.add-record-btn .btn-icon[class*=\"btn-icon\"][data-v-4f47984b] {\n  width: 48px;\n  height: 48px;\n  text-align: center;\n  line-height: 48px;\n  margin: auto 10px;\n  background-color: var(--icon-bgcolor2);\n  border-radius: 50px;\n  color: var(--icon-color);\n}\n.add-record-btn .title[data-v-4f47984b] {\n  line-height: 68px;\n  display: flex;\n  flex-grow: 1;\n  color: var(--message-grey-color);\n  border-bottom: 1px solid var(--asc-item-border-bottom);\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
