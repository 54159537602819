import { render, staticRenderFns } from "./PublicationEditIcons.vue?vue&type=template&id=aced4d70&scoped=true&lang=pug"
import script from "./PublicationEditIcons.vue?vue&type=script&lang=js"
export * from "./PublicationEditIcons.vue?vue&type=script&lang=js"
import style0 from "./PublicationEditIcons.vue?vue&type=style&index=0&id=aced4d70&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aced4d70",
  null
  
)

export default component.exports