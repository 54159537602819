// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select[data-v-592eaedf] {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
[data-v-592eaedf] .item-name {
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/modal/FilterTypeList.vue"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".select[data-v-592eaedf] {\n  width: 100%;\n  padding: 0px;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n[data-v-592eaedf] .item-name {\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
