// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-default[data-v-52e6e9a1] {
  --bd-filter: none;
  --text-color: initial;
  --title-color: initial;
  --footer-color: initial;
  --border-color: initial;
}
.v-context[data-v-52e6e9a1] {
  background: var(--context-bgcolor);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.14), -1px -1px 0px 0 rgba(57, 57, 57, 0.1);
  display: block;
  margin: 0;
  border-radius: 3px;
  outline: none;
  position: fixed;
  min-width: 70px;
  z-index: 99999;
  backdrop-filter: var(--bd-filter);
  color: var(--context-color);
  -webkit-app-region: no-drag;
}
.v-context ul[data-v-52e6e9a1] {
  list-style: none;
  padding: 5px 0;
  margin: 0;
}
.v-context ul li[data-v-52e6e9a1] {
  margin: 0;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}
.v-context ul li.divider[data-v-52e6e9a1] {
  height: 1px;
  background-color: var(--divider-color);
  padding: 0;
  width: 80%;
  margin: 0 auto;
}
.v-context ul li[data-v-52e6e9a1]:hover {
  background-color: var(--context-bgcolor-hover);
}
.fade-contextmenu-enter-active[data-v-52e6e9a1],
.fade-contextmenu-leave-active[data-v-52e6e9a1] {
  transition: opacity .5s;
}
.fade-contextmenu-enter[data-v-52e6e9a1],
.fade-contextmenu-leave-to[data-v-52e6e9a1] {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/contextMenu/ContextMenu.vue"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,kCAAkC;EAClC,sFAAsF;EACtF,cAAc;EACd,SAAS;EACT,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,eAAe;EACf,cAAc;EACd,iCAAiC;EACjC,2BAA2B;EAC3B,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,SAAS;AACX;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,sCAAsC;EACtC,UAAU;EACV,UAAU;EACV,cAAc;AAChB;AACA;EACE,8CAA8C;AAChD;AACA;;EAEE,uBAAuB;AACzB;AACA;;EAEE,UAAU;AACZ","sourcesContent":[".theme-default[data-v-52e6e9a1] {\n  --bd-filter: none;\n  --text-color: initial;\n  --title-color: initial;\n  --footer-color: initial;\n  --border-color: initial;\n}\n.v-context[data-v-52e6e9a1] {\n  background: var(--context-bgcolor);\n  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.14), -1px -1px 0px 0 rgba(57, 57, 57, 0.1);\n  display: block;\n  margin: 0;\n  border-radius: 3px;\n  outline: none;\n  position: fixed;\n  min-width: 70px;\n  z-index: 99999;\n  backdrop-filter: var(--bd-filter);\n  color: var(--context-color);\n  -webkit-app-region: no-drag;\n}\n.v-context ul[data-v-52e6e9a1] {\n  list-style: none;\n  padding: 5px 0;\n  margin: 0;\n}\n.v-context ul li[data-v-52e6e9a1] {\n  margin: 0;\n  padding: 10px 20px;\n  cursor: pointer;\n  white-space: nowrap;\n}\n.v-context ul li.divider[data-v-52e6e9a1] {\n  height: 1px;\n  background-color: var(--divider-color);\n  padding: 0;\n  width: 80%;\n  margin: 0 auto;\n}\n.v-context ul li[data-v-52e6e9a1]:hover {\n  background-color: var(--context-bgcolor-hover);\n}\n.fade-contextmenu-enter-active[data-v-52e6e9a1],\n.fade-contextmenu-leave-active[data-v-52e6e9a1] {\n  transition: opacity .5s;\n}\n.fade-contextmenu-enter[data-v-52e6e9a1],\n.fade-contextmenu-leave-to[data-v-52e6e9a1] {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
