//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
   import { mapGetters } from 'vuex'
   import { INFO, CHAT } from '../../store/modulesNames'
   import { GET_INFO_OPEN, GET_MESSAGE_BY_ID, GET_COMMENT_BY_ID } from '../../store/gettersTypes'

   import IconImage from '../IconImage.vue';
   import ChatMessage from '../chat/ChatMessage.vue';
   import moment from 'moment'

   export default {
       name: 'details-msg',
       props: ['params'],
       data() {
           return {
               detailsGroupWatched: [],
               detailsGroupReceived: [],
               detailsGroupSent: [],
               sentDate: '',
               sentTime: '',
               displayStyle: 'block'
           };
       },
       components: {
           'icon-image': IconImage,
           'chat-message': ChatMessage
       },
       created() {
           this.getDetails()
       },
       computed: {
           position: function() {
               if(this.params.inout === 'out') return {'text-align': 'right'};
               if(this.params.inout === 'in') return {'text-align': 'left'};
           },
           time: function() {
               const isThreads = this.params.hasOwnProperty('isThreads') && this.params.isThreads
               let result = isThreads ? this[GET_COMMENT_BY_ID](this.params.id) : this[GET_MESSAGE_BY_ID](this.params.id)
               return result.timeDetails
           },
           message() {
               let msg = this.$store.getters[`${INFO}/${GET_INFO_OPEN}`];
               return msg.params;
           },          
           ...mapGetters(CHAT, [GET_MESSAGE_BY_ID, GET_COMMENT_BY_ID]),
       },
       methods: {
           messageMouthRight() {
               return !this.params.sender && (!!this.params.msg.text || this.params.mouth);
           },
           user: function(params) {
               return app.store.getters['contacts/getMergedContactById'](params).fio;
           },
           photo: function(params) {
               return app.store.getters['contacts/getMergedContactById'](params).photo;
           },
           showDetail: function(type, index) {
               let display = this.$refs[type][index].style.display;
               if(display === 'none') this.$refs[type][index].style.display = 'block';
               else this.$refs[type][index].style.display = 'none';
           },
           getDetails() {
               let obj = {cid: this.params.cid, id: this.params.id};
               this.detailsGroupWatched = []
               this.detailsGroupReceived = []
               this.detailsGroupSent = []
               app.proto.getMessageDetails(obj, (data) => {
                   // console.log("🚀 ~ file: DetailsMsg.vue:173 ~ app.proto.getMessageDetails ~ data:", data)
                   for(let i = 0; i < data.length; i++) {
                       let result = {};
                       if(data[i].watchedTime) {
                           result.watchedTime = moment().subtract(data[i].watchedTime, 'seconds').format('DD.MM.YYYY  HH:mm');
                           result.receivedTime = moment().subtract(data[i].receivedTime, 'seconds').format('DD.MM.YYYY  HH:mm');
                           result.cid = data[i].cid;
                           this.detailsGroupWatched.push(result);
                       } else if(data[i].receivedTime) {
                           result.receivedTime = moment().subtract(data[i].receivedTime, 'seconds').format('DD.MM.YYYY  HH:mm');
                           result.cid = data[i].cid;
                           this.detailsGroupReceived.push(result);
                       } else {
                           result.cid = data[i].cid;
                           this.detailsGroupSent.push(result);
                       }
                   }
               })
           },           
       },
       watch: {
           params: {
               handler() {
                   this.getDetails()
               },
               deep: true
           }
       }
   }
