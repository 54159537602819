var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "central calendar" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [_c("calendar")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }