var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conference" }, [
    _vm.isVideoConf
      ? _c(
          "div",
          {
            staticClass: "video-conf",
            class: { "video-conf-fullscreen": _vm.videoConfFullScreen },
            style: _vm.styleParams,
            on: {
              mouseover: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.showPanels($event, true)
              },
              mouseout: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.showPanels($event, false)
              },
            },
          },
          [
            _c(
              "div",
              { attrs: { id: "video-items" } },
              _vm._l(_vm.contacts, function (contact, index) {
                return _c(
                  "div",
                  {
                    staticClass: "video-cont",
                    style: { width: _vm.videoWidth },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "video-cont-block" },
                      [
                        _c("custom-video", {
                          attrs: {
                            id: "video-conf-item-" + contact.contact.cid,
                            autoplay: true,
                            muted: index === 0,
                          },
                          on: {
                            playing: function ($event) {
                              return _vm.setBackground(
                                index,
                                contact.contact.cid
                              )
                            },
                          },
                        }),
                        _vm.backgrounds[index]
                          ? _c("div", { staticClass: "video-info" }, [
                              _c("div", {
                                staticClass: "video-info-bg",
                                style: {
                                  "background-image": `url(${contact.contact.photo})`,
                                },
                              }),
                              _c("div", { staticClass: "video-info-block" }, [
                                _c("div", { staticClass: "video-info-fio" }, [
                                  _vm._v(_vm._s(contact.contact.fio)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "video-info-status" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getStatus(contact.status))
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "topbar" }),
            _c(
              "div",
              {
                staticClass: "top-panel",
                class: _vm.panelShow ? "top-panel-show" : "top-panel-hide",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "close-btn",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: _vm.doFullScreen,
                    },
                  },
                  [_c("i", { staticClass: "far fa-arrows-alt-v rotate-45" })]
                ),
                _c("div", { staticClass: "top-panel-info" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("number-of-participants") +
                        " - " +
                        _vm.contacts.length
                    )
                  ),
                ]),
                !_vm.videoConfFullScreen
                  ? _c(
                      "div",
                      {
                        staticClass: "close-btn",
                        on: {
                          mousedown: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                          click: _vm.toggleMinimize,
                        },
                      },
                      [_c("i", { staticClass: "fas fa-chevron-down" })]
                    )
                  : _c("div"),
              ]
            ),
            _c("div", { staticClass: "bottombar" }),
            _c(
              "div",
              {
                staticClass: "bottom-panel",
                class: _vm.panelShow
                  ? "bottom-panel-show"
                  : "bottom-panel-hide",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "func-button",
                    attrs: { title: _vm.$t("add") },
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: _vm.addContact,
                    },
                  },
                  [_c("i", { staticClass: "far fa-plus" })]
                ),
                _c(
                  "div",
                  {
                    staticClass: "func-button",
                    class: { "func-button-active": _vm.mute },
                    attrs: { title: _vm.$t("phone.mic") },
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: _vm.toggleMicrophone,
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-microphone-slash",
                      class: { "func-button-i-active": _vm.mute },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "func-button",
                    class: { "func-button-active": _vm.camera },
                    attrs: { title: _vm.$t("camera") },
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: _vm.toggleCamera,
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-video-slash",
                      class: { "func-button-i-active": _vm.camera },
                    }),
                  ]
                ),
                _c("div", { staticClass: "phone-btn-cont" }, [
                  _c(
                    "div",
                    {
                      staticClass: "phone-btn phone-red-btn",
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                        click: _vm.close,
                      },
                    },
                    [_c("i", { staticClass: "fa fa-phone fa-rotate" })]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "draggable" }),
            _c("calls-selector", { attrs: { type: "audio" } }),
            _c("canvas", {
              ref: "videoPlug",
              staticClass: "video-plug",
              attrs: { width: "480", height: "360" },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "audio-conf" },
          [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                {
                  staticClass: "close-btn",
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.toggleMinimize.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-chevron-down" })]
              ),
            ]),
            _c("div", { staticClass: "body" }, [
              _c(
                "div",
                { staticClass: "icons" },
                _vm._l(_vm.contacts, function (contact, index) {
                  return index !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "icon-cont",
                          style: { width: _vm.audioIconWidth },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "icon-image-wrapper" },
                            [
                              _c("icon-image", {
                                staticClass: "icon-image",
                                class: _vm.iconImageClass,
                                attrs: {
                                  src: contact.contact.photo,
                                  text: contact.contact.fio,
                                  title: contact.contact.fio,
                                },
                              }),
                              _c("custom-audio", {
                                attrs: {
                                  id: "video-conf-item-" + contact.contact.cid,
                                  autoplay: true,
                                },
                              }),
                              contact.status !== "joined"
                                ? _c("div", { staticClass: "status" }, [
                                    _c("i", {
                                      staticClass: "fa fa-phone fa-rotate",
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _c("span", { staticClass: "phone-type-call" }, [
                _vm._v(_vm._s(_vm.$t("group-call"))),
              ]),
              _c("span", { staticClass: "phone-status" }, [
                _vm._v(_vm._s(_vm.time)),
              ]),
              _c(
                "div",
                { staticClass: "func-buttons" },
                [
                  _c("phone-function-button", {
                    attrs: {
                      name: _vm.$t("add"),
                      type: "add",
                      disable: _vm.addDisabled,
                    },
                    on: { click: _vm.addContact },
                  }),
                  _c("phone-function-button", {
                    attrs: {
                      name: _vm.$t("phone.mic"),
                      type: "mic",
                      active: _vm.mute,
                    },
                    on: { click: _vm.toggleMicrophone },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c("phone-action-button", {
                  attrs: { type: "terminate" },
                  on: { click: _vm.close },
                }),
              ],
              1
            ),
            _c("calls-selector", { attrs: { type: "audio" } }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }