var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dnd-dialog" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("mainPage.status-select"))),
      ]),
      _c("div", { staticClass: "close-btn", on: { click: _vm.doClose } }, [
        _c("i", { staticClass: "fas fa-times" }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("custom-radio", {
          attrs: {
            value_change: _vm.hours,
            inputs: _vm.inputs,
            default_data: _vm.hours,
          },
          on: {
            "update:value_change": function ($event) {
              _vm.hours = $event
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-default", on: { click: _vm.doClose } },
        [_vm._v(_vm._s(_vm.$t("cancel")))]
      ),
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.doSetDnd } },
        [_vm._v(_vm._s(_vm.$t("apply")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }