// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-wrapper-single[data-v-7d583dd6] {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/channels/PublicationImageItem.vue"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB","sourcesContent":[".img-wrapper-single[data-v-7d583dd6] {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
