// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication[data-v-03dd0c60] {
  display: flex;
}
.publication[data-v-03dd0c60] .channel-type {
  display: flex;
  justify-content: center;
  margin-bottom: 12.5px;
}
.publication[data-v-03dd0c60] .channel-type > * {
  margin: auto 0;
}
.publication[data-v-03dd0c60] .channel-type > *:not(:first-child) {
  margin-left: 5px;
}
.publication .text[data-v-03dd0c60] {
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
}
.publication .btns[data-v-03dd0c60] {
  margin: auto;
  padding-bottom: 20px;
}
.publication .btns > button[data-v-03dd0c60] {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/channels/ChannelInvite.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,aAAa;AACf","sourcesContent":[".publication[data-v-03dd0c60] {\n  display: flex;\n}\n.publication[data-v-03dd0c60] .channel-type {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 12.5px;\n}\n.publication[data-v-03dd0c60] .channel-type > * {\n  margin: auto 0;\n}\n.publication[data-v-03dd0c60] .channel-type > *:not(:first-child) {\n  margin-left: 5px;\n}\n.publication .text[data-v-03dd0c60] {\n  padding: 0 20px;\n  text-align: center;\n  margin-bottom: 20px;\n}\n.publication .btns[data-v-03dd0c60] {\n  margin: auto;\n  padding-bottom: 20px;\n}\n.publication .btns > button[data-v-03dd0c60] {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
