// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-section[data-v-29adb7ea] {
  margin-top: 10px;
  height: 20px;
  color: #BDBDBD;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/radio/InfoSection.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".info-section[data-v-29adb7ea] {\n  margin-top: 10px;\n  height: 20px;\n  color: #BDBDBD;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
