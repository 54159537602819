// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-messages[data-v-46425541] {
  min-height: 40px;
}
.selected-messages-active[data-v-46425541] {
  width: 100%;
}
.selected-messages-active .manage-messages-info[data-v-46425541] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px;
  color: var(--custom-textarea-color);
  font-size: 16px;
  overflow-x: auto;
}
.selected-messages-active .manage-messages-info .actions[data-v-46425541] {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.selected-messages-active .manage-messages-info .actions .circle-animation i[data-v-46425541] {
  font-size: 20px;
  padding: 0 4px;
}
.selected-messages-active .manage-messages-info .actions .circle-animation[data-v-46425541]:hover {
  color: var(--toolbtn-color-hover);
}
.selected-messages-active .manage-messages-info .actions span[data-v-46425541] {
  margin: auto 5px;
}
.selected-messages-active .manage-messages-info .actions .cancel-item .circle-animation i[data-v-46425541] {
  font-size: 26px;
}
.selected-messages-active .manage-messages-info .actions .selection-info[data-v-46425541] {
  display: flex;
  min-width: 98px;
}
.selected-messages-active .manage-messages-info .actions .action-buttons[data-v-46425541] {
  display: flex;
  min-width: 184px;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ManageSelectedMessages.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,eAAe;EACf,mCAAmC;EACnC,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,iCAAiC;AACnC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".manage-messages[data-v-46425541] {\n  min-height: 40px;\n}\n.selected-messages-active[data-v-46425541] {\n  width: 100%;\n}\n.selected-messages-active .manage-messages-info[data-v-46425541] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 100%;\n  padding: 0 10px;\n  color: var(--custom-textarea-color);\n  font-size: 16px;\n  overflow-x: auto;\n}\n.selected-messages-active .manage-messages-info .actions[data-v-46425541] {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n.selected-messages-active .manage-messages-info .actions .circle-animation i[data-v-46425541] {\n  font-size: 20px;\n  padding: 0 4px;\n}\n.selected-messages-active .manage-messages-info .actions .circle-animation[data-v-46425541]:hover {\n  color: var(--toolbtn-color-hover);\n}\n.selected-messages-active .manage-messages-info .actions span[data-v-46425541] {\n  margin: auto 5px;\n}\n.selected-messages-active .manage-messages-info .actions .cancel-item .circle-animation i[data-v-46425541] {\n  font-size: 26px;\n}\n.selected-messages-active .manage-messages-info .actions .selection-info[data-v-46425541] {\n  display: flex;\n  min-width: 98px;\n}\n.selected-messages-active .manage-messages-info .actions .action-buttons[data-v-46425541] {\n  display: flex;\n  min-width: 184px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
