// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-publication-message[data-v-7ac26b82] {
  width: 300px;
  cursor: pointer;
}
.chat-publication-message .chat-publication-info[data-v-7ac26b82] {
  padding-left: 5px;
}
.chat-publication-message .chat-publication-info .channel-title[data-v-7ac26b82] {
  font-size: var(--titleFontSize);
}
.chat-publication-message .chat-publication-info .channel-name[data-v-7ac26b82] {
  font-size: var(--titleFontSize);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  filter: contrast(var(--fontContrast));
}
.chat-publication-message .chat-publication-info span[data-v-7ac26b82] {
  font-size: var(--subTitleFontSize);
  filter: contrast(var(--fontContrast));
}
.title-publication[data-v-7ac26b82] {
  margin-top: 10px;
  filter: contrast(var(--fontContrast));
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatPublicationMessage.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,qCAAqC;AACvC;AACA;EACE,kCAAkC;EAClC,qCAAqC;AACvC;AACA;EACE,gBAAgB;EAChB,qCAAqC;AACvC","sourcesContent":[".chat-publication-message[data-v-7ac26b82] {\n  width: 300px;\n  cursor: pointer;\n}\n.chat-publication-message .chat-publication-info[data-v-7ac26b82] {\n  padding-left: 5px;\n}\n.chat-publication-message .chat-publication-info .channel-title[data-v-7ac26b82] {\n  font-size: var(--titleFontSize);\n}\n.chat-publication-message .chat-publication-info .channel-name[data-v-7ac26b82] {\n  font-size: var(--titleFontSize);\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  filter: contrast(var(--fontContrast));\n}\n.chat-publication-message .chat-publication-info span[data-v-7ac26b82] {\n  font-size: var(--subTitleFontSize);\n  filter: contrast(var(--fontContrast));\n}\n.title-publication[data-v-7ac26b82] {\n  margin-top: 10px;\n  filter: contrast(var(--fontContrast));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
