// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-icons[data-v-aced4d70] {
  display: flex;
}
.edit-item-text[data-v-aced4d70],
.save-item-text[data-v-aced4d70],
.del-item[data-v-aced4d70] {
  cursor: pointer;
}
.edit-item-text[data-v-aced4d70]:hover,
.save-item-text[data-v-aced4d70]:hover,
.del-item[data-v-aced4d70]:hover {
  color: var(--toolbtn-color);
}
.edit-item-text[data-v-aced4d70]:hover::before,
.save-item-text[data-v-aced4d70]:hover::before,
.del-item[data-v-aced4d70]:hover::before,
.edit-item-text[data-v-aced4d70]:hover::after,
.save-item-text[data-v-aced4d70]:hover::after,
.del-item[data-v-aced4d70]:hover::after {
  border-color: var(--toolbtn-color);
}
.edit-item-text[data-v-aced4d70],
.save-item-text[data-v-aced4d70] {
  margin: auto 10px auto auto;
}
.del-item[data-v-aced4d70] {
  position: relative;
  top: 4px;
  right: 8px;
  width: 16px;
  height: 16px;
  margin: auto 0;
}
.del-item[data-v-aced4d70]::before {
  content: "";
  border-left: 2px solid var(--main-body-color);
  height: 20px;
  position: absolute;
  transform: rotate(45deg) translate(-1px, 1px);
  left: 50%;
}
.del-item[data-v-aced4d70]::after {
  content: "";
  border-left: 2px solid var(--main-body-color);
  height: 20px;
  position: absolute;
  transform: rotate(-45deg) translate(-1px, -1px);
  left: 50%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/channels/PublicationEditIcons.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;;;EAGE,eAAe;AACjB;AACA;;;EAGE,2BAA2B;AAC7B;AACA;;;;;;EAME,kCAAkC;AACpC;AACA;;EAEE,2BAA2B;AAC7B;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;AACA;EACE,WAAW;EACX,6CAA6C;EAC7C,YAAY;EACZ,kBAAkB;EAClB,6CAA6C;EAC7C,SAAS;AACX;AACA;EACE,WAAW;EACX,6CAA6C;EAC7C,YAAY;EACZ,kBAAkB;EAClB,+CAA+C;EAC/C,SAAS;AACX","sourcesContent":[".edit-icons[data-v-aced4d70] {\n  display: flex;\n}\n.edit-item-text[data-v-aced4d70],\n.save-item-text[data-v-aced4d70],\n.del-item[data-v-aced4d70] {\n  cursor: pointer;\n}\n.edit-item-text[data-v-aced4d70]:hover,\n.save-item-text[data-v-aced4d70]:hover,\n.del-item[data-v-aced4d70]:hover {\n  color: var(--toolbtn-color);\n}\n.edit-item-text[data-v-aced4d70]:hover::before,\n.save-item-text[data-v-aced4d70]:hover::before,\n.del-item[data-v-aced4d70]:hover::before,\n.edit-item-text[data-v-aced4d70]:hover::after,\n.save-item-text[data-v-aced4d70]:hover::after,\n.del-item[data-v-aced4d70]:hover::after {\n  border-color: var(--toolbtn-color);\n}\n.edit-item-text[data-v-aced4d70],\n.save-item-text[data-v-aced4d70] {\n  margin: auto 10px auto auto;\n}\n.del-item[data-v-aced4d70] {\n  position: relative;\n  top: 4px;\n  right: 8px;\n  width: 16px;\n  height: 16px;\n  margin: auto 0;\n}\n.del-item[data-v-aced4d70]::before {\n  content: \"\";\n  border-left: 2px solid var(--main-body-color);\n  height: 20px;\n  position: absolute;\n  transform: rotate(45deg) translate(-1px, 1px);\n  left: 50%;\n}\n.del-item[data-v-aced4d70]::after {\n  content: \"\";\n  border-left: 2px solid var(--main-body-color);\n  height: 20px;\n  position: absolute;\n  transform: rotate(-45deg) translate(-1px, -1px);\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
