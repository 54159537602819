import MeetingIOClient from '../../api/roschat/MeetingIOClient'
import { MEETINGS_API_EVENTS } from '../../api/roschat/protocols/meetings'
import EventEmitter from '../../common/EventEmitter'

import { STATUS } from '../../api/roschat/IOClient'

export const TRANSPORT_EVENTS = {
    API_EVENT: 'api-event',
    ERROR_EVENT: 'error-event',
    RECONNECT: 'reconnect',
    RECONNECTED: 'reconnected',
}

class MeetingTransport extends EventEmitter {
    constructor ({commonProto = null, remoteServer = '', remotePort = 0, remoteEncryption = false}) {
        super()
        this.remoteServer = remoteServer
        this.remotePort = remotePort
        this.remoteEncryption = remoteEncryption
        this.proto = commonProto
    }

    async init() {
        if (this.remoteServer) {
            this.proto = new MeetingIOClient(this.remoteEncryption)
            let prom = new Promise((resolve, reject) => {
                let timeOut = setTimeout(() => {
                    this.proto.disconnect()
                    this.proto = null
                    reject()
                }, 5000)
                this.proto.on('state-changed', ({state}) => {
                    if (state === 'connected') {
                        clearTimeout(timeOut)
                        resolve()
                    }
                })
            })
            this.proto.connect({server: `https://${this.remoteServer}${this.remotePort ? ':' + this.remotePort : ''}`})
            await prom
        }

        this._subscribeOnProto()
    }

    async meetingStart(payload) {
        return this.proto.meetingStart(payload)
    }
    async meetingStop(payload) {
        return this.proto.meetingStop(payload)
    }
    async getMeetingInfo(payload) {
        return this.proto.getMeetingInfo(payload)
    }
    async meetingEnter(payload) {
        return this.proto.meetingEnter(payload)
    }
    async meetingExit(payload) {
        return this.proto.meetingExit(payload)
    }
    async sendMeetingOffer(payload) {
        return this.proto.sendMeetingOffer(payload)
    }
    async sendMeetingAddCandidate(payload) {
        return this.proto.sendMeetingAddCandidate(payload)
    }
    async sendMeetingRemoveCandidate(payload) {
        return this.proto.sendMeetingRemoveCandidate(payload)
    }
    async changeMeetingUser(payload) {
        return this.proto.changeMeetingUser(payload)
    }
    async deleteMeetingUser(payload) {
        return this.proto.deleteMeetingUser(payload)
    }
    async meetingInviteUsers(payload) {
        return this.proto.meetingInviteUsers(payload)
    }
    async meetingRejectUser(payload) {
        return this.proto.meetingRejectUser(payload)
    }
    async sendMeetingCommand(payload) {
        return this.proto.sendMeetingCommand(payload)
    }

    async meetingSharingStart(payload) {
        return this.proto.meetingSharingStart(payload)
    }

    async meetingSharingStop(payload) {
        return this.proto.meetingSharingStop(payload)
    }

    async getIceServers(payload) {
        return this.proto.getTurnServer(payload)
    }

    _subscribeOnProto () {
        let prevProtoStatus = this.proto.state
        this.proto.on('state-changed', ({state}) => {
            console.log('~~~~~~~~~~_subscribeOnProto: state-changed to ' + state)
            switch (state) {
                case STATUS.CONNECTED:
                    if (prevProtoStatus === STATUS.RECONNECTING) this.emit(TRANSPORT_EVENTS.RECONNECTED)
                    prevProtoStatus = state
                    break
                case STATUS.RECONNECTING:
                    this.emit(TRANSPORT_EVENTS.RECONNECT) //@todo reconnect
                    prevProtoStatus = state
                    break
            }
        })
        /*this.proto.on('reconnected', () => {
            this.emit(TRANSPORT_EVENTS.RECONNECTED)
        })
        this.proto.on('disconnect', (reconnect) => {
            if (!reconnect) {
                //this.emit(TRANSPORT_EVENTS.ERROR_EVENT, new Error('socket disconnected'))
            }
        })
        this.proto.on('connect_error', (reconnect) => {
            if (!reconnect) {
                //this.emit(TRANSPORT_EVENTS.ERROR_EVENT, new Error('connect error')) //@todo reconnect
            }
        })*/
        this.proto.on(MEETINGS_API_EVENTS.CHANGE_MEETING, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.CHANGE_MEETING, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.DELETE_MEETING, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.DELETE_MEETING, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_STATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_STATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_CONNECT, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_CONNECT, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_DISCONNECT, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_DISCONNECT, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_ANSWER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_ANSWER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.CHANGE_MEETING_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.CHANGE_MEETING_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_INVITE_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_INVITE_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_REJECT_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_REJECT_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_INVITE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_INVITE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_COMMAND, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_COMMAND, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_SHARING_START, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_SHARING_START, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_SHARING_STOP, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_SHARING_STOP, payload})
        })
    }

}

export default MeetingTransport