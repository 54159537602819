// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch[data-v-4d6a83ca] {
  position: relative;
  display: inline-block;
  width: var(--switch-width);
  height: var(--switch-height);
  margin-bottom: 0;
}
.switch input[data-v-4d6a83ca] {
  display: none;
}
.switch input:checked + .checkbox-slider[data-v-4d6a83ca] {
  background-color: var(--switch-enabled-color);
}
.switch input:checked + .checkbox-slider[data-v-4d6a83ca]:before {
  -webkit-transform: translateX(var(--switch-slider-height));
  -ms-transform: translateX(var(--switch-slider-height));
  transform: translateX(var(--switch-slider-height));
}
.switch .checkbox-slider[data-v-4d6a83ca] {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--switch-disabled-color);
  -webkit-transition: .4s;
  transition: .4s;
}
.switch .checkbox-slider.round[data-v-4d6a83ca] {
  border-radius: var(--switch-height);
}
.switch .checkbox-slider[data-v-4d6a83ca]:before {
  position: absolute;
  content: "";
  height: var(--switch-slider-height);
  width: var(--switch-slider-height);
  left: calc((var(--switch-height) - var(--switch-slider-height)) / 2);
  bottom: calc((var(--switch-height) - var(--switch-slider-height)) / 2);
  background-color: var(--switch-btn-bgcolor);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomCheckboxSlider.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,0BAA0B;EAC1B,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,0DAA0D;EAC1D,sDAAsD;EACtD,kDAAkD;AACpD;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8CAA8C;EAC9C,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,mCAAmC;AACrC;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,mCAAmC;EACnC,kCAAkC;EAClC,oEAAoE;EACpE,sEAAsE;EACtE,2CAA2C;EAC3C,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".switch[data-v-4d6a83ca] {\n  position: relative;\n  display: inline-block;\n  width: var(--switch-width);\n  height: var(--switch-height);\n  margin-bottom: 0;\n}\n.switch input[data-v-4d6a83ca] {\n  display: none;\n}\n.switch input:checked + .checkbox-slider[data-v-4d6a83ca] {\n  background-color: var(--switch-enabled-color);\n}\n.switch input:checked + .checkbox-slider[data-v-4d6a83ca]:before {\n  -webkit-transform: translateX(var(--switch-slider-height));\n  -ms-transform: translateX(var(--switch-slider-height));\n  transform: translateX(var(--switch-slider-height));\n}\n.switch .checkbox-slider[data-v-4d6a83ca] {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: var(--switch-disabled-color);\n  -webkit-transition: .4s;\n  transition: .4s;\n}\n.switch .checkbox-slider.round[data-v-4d6a83ca] {\n  border-radius: var(--switch-height);\n}\n.switch .checkbox-slider[data-v-4d6a83ca]:before {\n  position: absolute;\n  content: \"\";\n  height: var(--switch-slider-height);\n  width: var(--switch-slider-height);\n  left: calc((var(--switch-height) - var(--switch-slider-height)) / 2);\n  bottom: calc((var(--switch-height) - var(--switch-slider-height)) / 2);\n  background-color: var(--switch-btn-bgcolor);\n  -webkit-transition: .4s;\n  transition: .4s;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
