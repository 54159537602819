import { render, staticRenderFns } from "./ChatSettings.vue?vue&type=template&id=022fb470&scoped=true&lang=pug"
import script from "./ChatSettings.vue?vue&type=script&lang=js"
export * from "./ChatSettings.vue?vue&type=script&lang=js"
import style0 from "./ChatSettings.vue?vue&type=style&index=0&id=022fb470&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022fb470",
  null
  
)

export default component.exports