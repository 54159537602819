var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "poll-msg",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.doContextMenu.apply(null, arguments)
        },
      },
    },
    [
      _vm.message.author
        ? _c("div", { staticClass: "forward" }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "poll-msg-question" }, [
        _vm._v(_vm._s(_vm.msg.topic)),
      ]),
      _vm._l(_vm.answers, function (item, index) {
        return _c(
          "div",
          {
            staticClass: "poll-msg-answer",
            on: {
              click: function ($event) {
                !_vm.voteUser ? _vm.doAnswer(index + 1, _vm.msg.pollId) : ""
              },
            },
          },
          [
            _c("div", { staticClass: "poll-answer-left-section" }, [
              !_vm.voteUser
                ? _c("div", { staticClass: "poll-radio-button" })
                : index === _vm.voteUser - 1
                ? _c("div", { staticClass: "poll-answer-value" }, [
                    _c("i", {
                      staticClass: "fal fa-check-circle poll-checked",
                    }),
                    _c("span", { staticClass: "poll-value" }, [
                      _vm._v(_vm._s(_vm.votes[index + 1] + "%")),
                    ]),
                  ])
                : _c("span", { staticClass: "poll-value" }, [
                    _vm._v(_vm._s(_vm.votes[index + 1] + "%")),
                  ]),
            ]),
            _c("div", { staticClass: "pol-answer-text" }, [
              _c("div", { staticClass: "poll-text" }, [_vm._v(_vm._s(item))]),
              _c("div", {
                staticClass: "poll-votes",
                style: { width: _vm.votes[index + 1] + "%" },
              }),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "poll-votes-all" }, [
        _vm._v(_vm._s(_vm.doVotesText(_vm.voteAll))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }