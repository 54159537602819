
    import Confirm from "./Confirm.vue"
    import {mapActions} from "vuex"
    import {CHATS} from "../../store/modulesNames"
    import {ACT_CHATS_REMOVE_MEMBERS} from "../../store/actionsTypes"

    export default {
        name: 'RemoveChatMember',
        extends: Confirm,
        props: {
            chat_cid: {
                type: Number,
                required: true,
            },
            cid: {
                type: Number,
                required: true,
            },
        },
        computed: {
            text() { return this.$t('delete-member-conf') },
        },
        methods: {
            async btnOkCb() {
                await this[ACT_CHATS_REMOVE_MEMBERS]({chat_cid: this.chat_cid, contacts: [{cid: this.cid}]})
            },
            ...mapActions(CHATS, [ACT_CHATS_REMOVE_MEMBERS]),
        }
    }
