var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _c(
      "div",
      { staticClass: "photo" },
      [
        _vm.isGroup
          ? _c("icon-image", {
              attrs: { src: _vm.chat.photo, text: _vm.chat.name },
            })
          : !_vm.isGroup && (_vm.isMessages || _vm.isComments)
          ? _c("icon-image", {
              attrs: { src: _vm.contact.photo, text: _vm.contact.fio },
            })
          : _vm.isChannel || _vm.isPublication
          ? _c("icon-image", {
              attrs: { src: _vm.channelPhoto, text: _vm.channelName },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item-list",
        on: {
          click: function ($event) {
            return _vm.goToItem(_vm.item)
          },
        },
      },
      [
        _c("div", { staticClass: "item-title" }, [
          _vm.isPublication
            ? _c("div", { staticClass: "title-left" }, [
                _c("div", { staticClass: "title-channel" }, [
                  _vm._v(_vm._s(_vm.channelName)),
                ]),
                _c("div", { staticClass: "title-pub" }, [
                  _vm._v(_vm._s(_vm.getTitle)),
                ]),
              ])
            : _c("div", { staticClass: "title-left" }, [
                _vm._v(_vm._s(_vm.getTitle)),
              ]),
          _c("div", { staticClass: "title-right" }, [
            _vm._v(_vm._s(_vm.getTitleTime)),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "item-subtitle",
            class: { pub: _vm.isPublication },
            attrs: { title: _vm.showTitle ? _vm.getTitle : "" },
            on: { mouseover: _vm.mouseOver },
          },
          [
            _vm.searchType === "comments"
              ? _c("div", {
                  staticClass: "subtitle-comment-message",
                  domProps: { innerHTML: _vm._s(_vm.commentMessage.text) },
                })
              : _vm._e(),
            _vm.getSubtitle.dataType !== "text"
              ? _c("div", { staticClass: "subtitle-media" }, [
                  _vm.getSubtitle.dataType === "file"
                    ? _c("div", { staticClass: "subtitle-file-icon" }, [
                        _c("i", { staticClass: "fa fa-file-alt" }),
                      ])
                    : _vm._e(),
                  _vm.getSubtitle.dataType === "image"
                    ? _c("div", { staticClass: "subtitle-file-icon" }, [
                        _c("i", { staticClass: "fa fa-file-image" }),
                        _c("div", { staticClass: "type-description" }, [
                          _vm._v(_vm._s(_vm.$t("image"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.getSubtitle.dataType === "video"
                    ? _c("div", { staticClass: "subtitle-file-icon" }, [
                        _c("i", { staticClass: "fa fa-file-video" }),
                        _c("div", { staticClass: "type-description" }, [
                          _vm._v(_vm._s(_vm.$t("video"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.getSubtitle.dataType === "file"
                    ? _c("div", { staticClass: "subtitle-file-name" }, [
                        _vm._v(_vm._s(_vm.getSubtitle.fileName)),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "subtitle-text",
              domProps: { innerHTML: _vm._s(_vm.getSubtitleHtmlText) },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }