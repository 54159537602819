// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-status[data-v-62f07618] {
  display: flex;
  flex-direction: column;
  color: var(--main-body-color);
}
.radio-status .title[data-v-62f07618],
.radio-status .sub-title[data-v-62f07618] {
  margin: 0 auto;
}
.radio-status .title[data-v-62f07618] {
  height: 30px;
  font-size: 18px;
}
.radio-status .sub-title[data-v-62f07618] {
  height: 20px;
  color: var(--toolbtn-color);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/radio/RadioStatus.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;AACA;;EAEE,cAAc;AAChB;AACA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".radio-status[data-v-62f07618] {\n  display: flex;\n  flex-direction: column;\n  color: var(--main-body-color);\n}\n.radio-status .title[data-v-62f07618],\n.radio-status .sub-title[data-v-62f07618] {\n  margin: 0 auto;\n}\n.radio-status .title[data-v-62f07618] {\n  height: 30px;\n  font-size: 18px;\n}\n.radio-status .sub-title[data-v-62f07618] {\n  height: 20px;\n  color: var(--toolbtn-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
