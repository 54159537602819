// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-alarm-enter-active[data-v-74dcb674],
.fade-alarm-leave-active[data-v-74dcb674] {
  animation: alert-grow-74dcb674 0.1s ease-in;
}
.fade-alarm-enter[data-v-74dcb674],
.fade-alarm-leave-to[data-v-74dcb674] {
  animation-delay: 1.5s;
  animation: alert-grow-74dcb674 0.1s ease-in reverse;
}
.top-alarm[data-v-74dcb674] {
  text-align: center;
  height: 30px;
  background-color: var(--elem-red-color);
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.top-alarm .top-alarm-msg[data-v-74dcb674] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.top-alarm .top-alarm-button[data-v-74dcb674] {
  color: #fff;
  line-height: 8px;
  margin-left: auto;
  outline: none;
}
.top-alarm .top-alarm-button[data-v-74dcb674]:focus,
.top-alarm .top-alarm-button[data-v-74dcb674]:active {
  outline: none;
}
.top-alarm .top-alarm-button:focus.top-alarm-button[data-v-74dcb674]:active {
  outline: none;
}
.top-alarm-wrapper[data-v-74dcb674] {
  height: 22px;
  display: flex;
  margin: auto;
}
.top-alarm-wrapper .top-alarm-msg[data-v-74dcb674] {
  margin: auto;
}
@keyframes alert-grow-74dcb674 {
from {
    height: 0px;
}
to {
    height: 30px;
}
}
`, "",{"version":3,"sources":["webpack://./js/app/components/Alarm.vue"],"names":[],"mappings":"AAAA;;EAEE,2CAA2C;AAC7C;AACA;;EAEE,qBAAqB;EACrB,mDAAmD;AACrD;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,uCAAuC;EACvC,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;AACA;;EAEE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA","sourcesContent":[".fade-alarm-enter-active[data-v-74dcb674],\n.fade-alarm-leave-active[data-v-74dcb674] {\n  animation: alert-grow-74dcb674 0.1s ease-in;\n}\n.fade-alarm-enter[data-v-74dcb674],\n.fade-alarm-leave-to[data-v-74dcb674] {\n  animation-delay: 1.5s;\n  animation: alert-grow-74dcb674 0.1s ease-in reverse;\n}\n.top-alarm[data-v-74dcb674] {\n  text-align: center;\n  height: 30px;\n  background-color: var(--elem-red-color);\n  color: #fff;\n  overflow: hidden;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.top-alarm .top-alarm-msg[data-v-74dcb674] {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.top-alarm .top-alarm-button[data-v-74dcb674] {\n  color: #fff;\n  line-height: 8px;\n  margin-left: auto;\n  outline: none;\n}\n.top-alarm .top-alarm-button[data-v-74dcb674]:focus,\n.top-alarm .top-alarm-button[data-v-74dcb674]:active {\n  outline: none;\n}\n.top-alarm .top-alarm-button:focus.top-alarm-button[data-v-74dcb674]:active {\n  outline: none;\n}\n.top-alarm-wrapper[data-v-74dcb674] {\n  height: 22px;\n  display: flex;\n  margin: auto;\n}\n.top-alarm-wrapper .top-alarm-msg[data-v-74dcb674] {\n  margin: auto;\n}\n@keyframes alert-grow-74dcb674 {\nfrom {\n    height: 0px;\n}\nto {\n    height: 30px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
