var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _vm.isRecurring
          ? _c(
              "div",
              { staticClass: "radioCheckBoxes" },
              [
                _c("custom-checkbox", {
                  key: 1,
                  staticClass: "checkBoxItem",
                  class: { checked: _vm.isOnDay },
                  attrs: {
                    value: _vm.isOnDay,
                    default: _vm.isOnDay,
                    label: _vm.$t("integrations.delete-on-day") + _vm.date,
                  },
                  on: { "update:checkbox": _vm.onDayChecked },
                }),
                _c("custom-checkbox", {
                  key: 2,
                  staticClass: "checkBoxItem",
                  class: { checked: _vm.isAllRecurrence },
                  attrs: {
                    value: _vm.isAllRecurrence,
                    default: _vm.isAllRecurrence,
                    label: _vm.$t("integrations.delete-all-series"),
                  },
                  on: { "update:checkbox": _vm.allRecurrenceChecked },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "confirm-message" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("integrations.confirm-delete-event"))),
              ]),
            ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.cancelText)),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.onOk } }, [
          _vm._v(_vm._s(_vm.okText)),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }