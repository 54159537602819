var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "central integrations" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.showExchange
                  ? _c(
                      "list-item",
                      {
                        attrs: {
                          title: _vm.$t("integrations.ms_exchange"),
                          link: true,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.msExchangeSettings.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("template", { slot: "media" }, [
                          _c("img", {
                            staticStyle: { "max-width": "42px" },
                            attrs: { src: _vm.icon_src },
                          }),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _c("list-item", {
                  attrs: {
                    title: _vm.$t("integrations.add-integration"),
                    link: true,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addIntegration.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }