var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-document-file-message" },
    [
      _vm.isPending
        ? _c(
            "div",
            {
              staticClass: "upload-abort-btn message-btn",
              on: { click: _vm.abortAjaxCall },
            },
            [
              _c("progress-circle", {
                attrs: {
                  load_progress: _vm.loadProgress,
                  radius: 16,
                  width: 40,
                  height: 40,
                },
              }),
              _c("i", { staticClass: "fa fa-ban" }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "document-file-download-btn message-btn",
              on: { click: _vm.doDownloadDocumentFile },
            },
            [_c("i", { staticClass: "fa fa-file" })]
          ),
      _c("publication-editable-item-text", {
        staticClass: "document-file-name name-text",
        attrs: {
          "default-text": _vm.name,
          edit: _vm.edit,
          text: _vm.name,
          "unix-last-time": _vm.unixLastTime,
        },
        on: {
          "update:text": function ($event) {
            _vm.name = $event
          },
        },
      }),
      _c("a", {
        ref: "downloadLink",
        staticStyle: { display: "none" },
        attrs: { href: _vm.link },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }