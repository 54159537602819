var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("audio", {
    ref: "audio",
    staticClass: "custom-audio",
    attrs: {
      src: _vm.src,
      autoplay: _vm.compAutoplay,
      loop: _vm.loop,
      type: _vm.type,
    },
    on: {
      ended: function ($event) {
        return _vm.$emit("ended")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }