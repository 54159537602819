import { render, staticRenderFns } from "./WebcamerasSettings.vue?vue&type=template&id=500a11d2&scoped=true&lang=pug"
import script from "./WebcamerasSettings.vue?vue&type=script&lang=js"
export * from "./WebcamerasSettings.vue?vue&type=script&lang=js"
import style0 from "./WebcamerasSettings.vue?vue&type=style&index=0&id=500a11d2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "500a11d2",
  null
  
)

export default component.exports