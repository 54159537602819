
    import CustomRoundBtn from "../custom/CustomRoundBtn.vue"

    export default {
        name: "CallDetailsButton",
        extends: CustomRoundBtn,
        props: {
            background: {
                type: Boolean,
                required: false,
                default: true
            },
            iconColor: {
                type: String,
                required: false,
                default: 'var(--icon-special-color)'
            },
            link: {
                type: Boolean,
                required: false,
                default: true
            },
            btnSize: {
                type: String,
                required: false,
                default: '48px'
            },
            subTitle: {
                type: [String, Boolean],
                required: false,
                default: true
            }
        }
    }
