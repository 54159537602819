var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "threads-wrapper" },
    [
      _c("content-loader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoader,
            expression: "isLoader",
          },
        ],
      }),
      _c(
        "div",
        { staticClass: "threads-content" },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDisabledArea,
                expression: "isDisabledArea",
              },
            ],
            attrs: { id: "disabled-area-info" },
          }),
          _c("threads", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoader,
                expression: "!isLoader",
              },
            ],
            attrs: { params: _vm.params },
            on: {
              isDisabledArea: _vm.setDisabledArea,
              loader: (val) => (_vm.isLoader = val),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }