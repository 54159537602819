var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("settings.versions-history"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            !_vm.changes.length
              ? _c("p", { staticClass: "changelog" }, [
                  _vm._v(_vm._s(_vm.$t("settings.no-history-found"))),
                ])
              : _vm._e(),
            _vm._l(_vm.changes, function (change, i) {
              return _c("div", { staticClass: "changes-item" }, [
                _c("p", { staticClass: "version" }, [
                  _vm._v(_vm._s(change.version)),
                ]),
                _c("p", { staticClass: "changelog" }, [
                  _vm._v(_vm._s(change.changelog)),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onOk } }, [
          _vm._v(_vm._s(_vm.$t("loginPage.ok"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }