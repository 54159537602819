var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "phone-participants" },
    _vm._l(_vm.contactsList, function (item, i) {
      return _c(
        "contacts-list-item",
        {
          key: i,
          attrs: {
            contact: item,
            noswitch: true,
            noSelection: true,
            subTitle: _vm.getSubtitle(i),
          },
        },
        [_c("div", { staticClass: "hor-line" })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }