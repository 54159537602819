// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conf-contain[data-v-87961dca] {
  height: 100%;
  display: flex;
  -webkit-app-region: drag;
}
.conf-contain .component[data-v-87961dca] {
  margin: auto;
}
.show-enter-active[data-v-87961dca] {
  animation: bounce-in-87961dca 0.6s;
}
.show-leave-active[data-v-87961dca] {
  animation: bounce-in-87961dca 0.6s reverse;
}
@keyframes bounce-in-87961dca {
0% {
    transform: scale(0);
}
50% {
    transform: scale(0);
}
100% {
    transform: scale(1);
}
}
`, "",{"version":3,"sources":["webpack://./js/app/components/phone/ConfContainer.vue"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,wBAAwB;AAC1B;AACA;EACE,YAAY;AACd;AACA;EACE,kCAAkC;AACpC;AACA;EACE,0CAA0C;AAC5C;AACA;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA","sourcesContent":[".conf-contain[data-v-87961dca] {\n  height: 100%;\n  display: flex;\n  -webkit-app-region: drag;\n}\n.conf-contain .component[data-v-87961dca] {\n  margin: auto;\n}\n.show-enter-active[data-v-87961dca] {\n  animation: bounce-in-87961dca 0.6s;\n}\n.show-leave-active[data-v-87961dca] {\n  animation: bounce-in-87961dca 0.6s reverse;\n}\n@keyframes bounce-in-87961dca {\n0% {\n    transform: scale(0);\n}\n50% {\n    transform: scale(0);\n}\n100% {\n    transform: scale(1);\n}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
