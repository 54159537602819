
    import { mapGetters, mapActions } from 'vuex'
    import { CHATS } from '../../store/modulesNames'
    import { IS_CHAT_PINNED } from '../../store/gettersTypes'
    import { ACT_SET_CHAT_PINED } from '../../store/actionsTypes'

    import PinSwitch from './PinSwitch.vue'

    import { CHAT_TYPES } from '../../constants'

    export default {
        name: 'ChatPinSwitch',
        extends: PinSwitch,
        props: {
            cid: {
                type: Number,
                required: true,
            },
            cidType: {
                type: String,
                default: CHAT_TYPES.USER
            }
        },
        computed: {
            switchValue() { return this[IS_CHAT_PINNED]({ cid: this.cid, cidType: this.cidType }) },
            ...mapGetters(CHATS, [IS_CHAT_PINNED])
        },
        methods: {
            pin() { this[ACT_SET_CHAT_PINED]({ cid: this.cid, cidType: this.cidType, pinned: true }) },
            unpin() { this[ACT_SET_CHAT_PINED]({ cid: this.cid, cidType: this.cidType, pinned: false }) },
            ...mapActions(CHATS, [ACT_SET_CHAT_PINED])
        },
    }
