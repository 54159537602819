
    import { mapActions } from 'vuex';
    import { CHATS } from "../../store/modulesNames"
    import { ACT_SET_CHAT_MUTED } from "../../store/actionsTypes"

    import MuteDialog from "./MuteDialog.vue"

    import { CHAT_TYPES } from "../../constants"

    export default {
        name: "ChatMute",
        extends: MuteDialog,
        props: {
            cid: {
                type: Number,
                required: true
            },
            cidType: {
                type: String,
                default: CHAT_TYPES.USER
            }
        },
        methods: {
            setMute(mute) {
                this[ACT_SET_CHAT_MUTED]({
                    cid: this.cid,
                    cidType: this.cidType,
                    mute,
                })
            },
            ...mapActions(CHATS, [ACT_SET_CHAT_MUTED])
        }
    }
