var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contacts" },
    [
      _c("recycle-contacts-list", {
        ref: "scroller",
        staticClass: "recycle-contacts-list",
        attrs: { contactsList: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item, i }) {
              return [
                _c("contacts-list-item", {
                  staticClass: "contacts-list-item",
                  attrs: {
                    "data-index": i,
                    contact: item,
                    noswitch: _vm.noswitch,
                    focused: _vm.focusOnItem === i,
                    parent: "search",
                    noSelection: true,
                    fullName: true,
                  },
                  on: {
                    "update:contact-selected": function ($event) {
                      return _vm.doSelect(item)
                    },
                  },
                }),
                _c("div", { staticClass: "hor-line light-line" }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }