var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { edit: _vm.edit }, on: { click: _vm.doOnClick } },
    [
      _c("i", { staticClass: "fa fa-map-marker-alt geo-location-icon" }),
      _c("publication-editable-item-text", {
        staticClass: "location-text name-text",
        attrs: {
          "default-text": _vm.text,
          edit: _vm.edit,
          text: _vm.text,
          "unix-last-time": _vm.unixLastTime,
        },
        on: {
          "update:text": function ($event) {
            _vm.text = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }