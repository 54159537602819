
    import SelectContact from './SelectContact.vue'
    import ContactProfileEdition from "../ContactProfoleEdition.vue"

    export default {
        name: 'SelectThenEditContact',
        extends: SelectContact,
        methods: {
            onOk({cid}) {
                this.modalClose()
                this.modalOpen({
                    component: ContactProfileEdition,
                    props: { type: this.props.type, cid, photoLink: this.props.photoLink, newFields: this.props.newFields }
                })
            },
        }
    }
