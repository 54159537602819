var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "context-menu-base",
    _vm._b({}, "context-menu-base", _vm.menuProps, false),
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("context-menu-group", {
            attrs: {
              header: _vm.$t("videomost.devices.choose-camera"),
              select: true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "context-menu-item",
                        _vm._b(
                          {
                            key: item.id,
                            attrs: { text: item.text, selected: item.selected },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onSelected(item.kind, item.id)
                              },
                            },
                          },
                          "context-menu-item",
                          props,
                          false
                        )
                      )
                    }),
                    !_vm.list.length
                      ? _c("span", { staticClass: "not-found" }, [
                          _vm._v(
                            _vm._s(_vm.$t("videomost.devices.no-cameras-found"))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }