// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-item .switch[data-v-dac960f6] {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background-color: var(--list-item-bgcolor);
  overflow: hidden;
}
.switch-item .switch .switch-value-wrapper[data-v-dac960f6] {
  display: flex;
  align-items: center;
}
.switch-item .switch .switch-value-wrapper .switch-value-text[data-v-dac960f6] {
  margin-right: 10px;
  color: gray;
  font-size: 13px;
  white-space: nowrap;
}
.switch-item .switch .switch-value-wrapper .switch-btn[data-v-dac960f6] {
  margin-bottom: 0px;
}
.switch-item .switch > *[data-v-dac960f6] {
  margin: auto 0;
  line-height: 45px;
}
.switch-item .switch .switch[data-v-dac960f6] {
  margin-left: auto;
}
.switch-item:not(:last-child) > .switch[data-v-dac960f6]::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--message-bg-color2);
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomSwitchItem.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,0CAA0C;EAC1C,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,iDAAiD;AACnD","sourcesContent":[".switch-item .switch[data-v-dac960f6] {\n  position: relative;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 20px;\n  background-color: var(--list-item-bgcolor);\n  overflow: hidden;\n}\n.switch-item .switch .switch-value-wrapper[data-v-dac960f6] {\n  display: flex;\n  align-items: center;\n}\n.switch-item .switch .switch-value-wrapper .switch-value-text[data-v-dac960f6] {\n  margin-right: 10px;\n  color: gray;\n  font-size: 13px;\n  white-space: nowrap;\n}\n.switch-item .switch .switch-value-wrapper .switch-btn[data-v-dac960f6] {\n  margin-bottom: 0px;\n}\n.switch-item .switch > *[data-v-dac960f6] {\n  margin: auto 0;\n  line-height: 45px;\n}\n.switch-item .switch .switch[data-v-dac960f6] {\n  margin-left: auto;\n}\n.switch-item:not(:last-child) > .switch[data-v-dac960f6]::after {\n  content: ' ';\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 1px;\n  border-bottom: 1px solid var(--message-bg-color2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
