var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reference-message",
      on: {
        contactcontextmenu: _vm.makeContextMenu,
        channelcontextmenu: _vm.makeContextMenu,
        publicationcontextmenu: _vm.makeContextMenu,
        contactclick: function ($event) {
          return _vm.performClick($event, "contact")
        },
        botclick: function ($event) {
          return _vm.performClick($event, "bot")
        },
        botcommandclick: function ($event) {
          return _vm.performClick($event, "botCommand")
        },
        openchannelevent: function ($event) {
          return _vm.performClick($event, "channel")
        },
        openpublicationevent: function ($event) {
          return _vm.performClick($event, "publication")
        },
      },
    },
    [
      _vm.message.author
        ? _c("div", { staticClass: "forward" }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
      _vm.textContinue
        ? _c(
            "div",
            {
              staticClass: "next",
              on: {
                click: function ($event) {
                  _vm.shortMsg = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("continue")))]
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }