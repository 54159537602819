var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "om-settings" }, [
    _c("div", { staticClass: "login" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("settings.username")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.login,
            expression: "login",
          },
        ],
        staticClass: "non-border-input",
        attrs: { type: "text" },
        domProps: { value: _vm.login },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.login = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "password" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.password")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password",
          },
        ],
        staticClass: "non-border-input",
        attrs: { type: "password" },
        domProps: { value: _vm.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.password = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "permissions" }, [
      _c("label", [
        _c("span", [_vm._v(_vm._s(_vm.$t("settings.extended-rights")))]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.is_admin,
              expression: "is_admin",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.is_admin)
              ? _vm._i(_vm.is_admin, null) > -1
              : _vm.is_admin,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.is_admin,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.is_admin = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.is_admin = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.is_admin = $$c
              }
            },
          },
        }),
      ]),
    ]),
    _c(
      "button",
      { staticClass: "btn btn-default check", on: { click: _vm.auth } },
      [_vm._v(_vm._s(_vm.$t("settings.check-conn")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }