function addChannelDefaultData (channel) {
    if (!('chType' in channel)) channel.chType = declarations.channel_types.PUBLIC
    if (!('privilege' in channel)) channel.privilege = declarations.channel_user_privilege.USER
    if (!('status' in channel)) channel.status = declarations.channel_user_statuses.ABSENT
    if (!('verified' in channel)) channel.verified = false
}

function addMessageDefaultData(msg) {
    if (!msg) return false
    try {
        if (msg.dataType && (msg.dataType === 'data' || msg.dataType === 'unstored')) {
            msg.data = JSON.parse(msg.data)
        }
        return true
    } catch (e) {
        return false
    }
}

export default {
    emitters: {
        addChannel(params) {
            return new Promise((resolve, reject) => {
                const data = { chType: params.chType, name: params.name, info: params.info }
                if (params.icon) data.icon = params.icon
                this.log('addChannel: >' + JSON.stringify(data))
                this.socket.emit('add-channel', data, (data) => {
                    if (data.error) {
                        this.log('addChannel error: ' + data.error)
                        return reject()
                    }
                    this.log('addChannel: < add-channel success, chId:' + data.chId)
                    resolve(data.chId)
                })
            })
        },
        changeChannel(params) {
            return new Promise((resolve, reject) => {
                const data = { chId: params.chId, chType: params.chType }
                if (params.name) data.name = params.name
                if (params.info) data.info = params.info
                if (typeof params.icon === 'string') data.icon = params.icon
                if (params.settings) data.settings = params.settings
                this.log('changeChannel: > ' + JSON.stringify(data))
                this.socket.emit('change-channel', data, (err) => {
                    if (err && err.error) {
                        this.log('changeChannel < error: ' + err.error)
                        return reject(err.error)
                    }
                    this.log('changeChannel: < success')
                    resolve()
                })
            })
        },
        delChannel(params) {
            return new Promise((resolve, reject) => {
                const data = { chId: params.chId }
                this.log('delChannel: > ' + JSON.stringify(data))
                this.socket.emit('delete-channel', data, (err) => {
                    if (err && err.error) {
                        this.log('delChannel: < error: ' + err.error)
                        return reject(err.error)
                    }
                    this.log('delChannel < success')
                    resolve()
                })
            })
        },
        getChannelDetails(params, cb) {
            return new Promise((resolve, reject) => {
                var data = { chId: params.chId }
                this.log('getChannelDetails: > ' + JSON.stringify(data))
                this.socket.emit('get-channel-details', data, channel => {
                    if (channel.error) reject('getChannelDetails: < error: ' + channel.error) // @todo как-то не очень
                    channel.chId = params.chId
                    this.log('getChannelDetails: < success')
                    resolve(channel)
                })
            })
        },
        getChannelUsers(params) {
            return new Promise((resolve, reject) => {
                const data = {
                    chId: params.chId,
                    fromId: params.fromId || 0,
                    count: params.count || 0,
                }
                if (params.privilege) data.privilege = params.privilege
                this.log('getChannelUsers: > ' + JSON.stringify(data))
                this.socket.emit('get-channel-users', data, (users) => {
                    if (users) {
                        this.log('getChannelUsers: < users length - ' + users.length)
                        resolve(users)
                    } else {
                        this.log('getChannelUsers: < error')
                        reject()
                    }
                })
            })
        },
        searchChannelUsers(params) {
            return new Promise((resolve, reject) => {
                const data = {
                    chId: params.chId,
                    filter: params.filter || '',
                    fromId: params.fromId || 0,
                    count: params.count || 0,
                }
                if (params.privilege) data.privilege = params.privilege
                this.log('searchChannelUsers: > ' + JSON.stringify(data))
                this.socket.emit('search-channel-users', data, (users) => {
                    if (users) {
                        this.log('searchChannelUsers: < users length - ' + users.length)
                        resolve(users)
                    } else {
                        this.log('searchChannelUsers: < error')
                        reject()
                    }
                })
            })
        },
        getChannels(params) {
            return new Promise((resolve, reject) => {
                const data = { minRev: params.minRev, count: params.count }
                this.log('getChannels: > ' + JSON.stringify(data))
                this.socket.emit('get-channels', data, response => {
                    this.log('getChannels: < rev - ' + response.rev + ' length - ' + response.channels.length)
                    resolve(response)
                })
            })
        },
        searchChannels(params, cb) {
            return new Promise((resolve, reject) => {
                let data = { filter: params.filter, shift: params.shift, count: params.count }
                this.log('searchChannels: > ' + JSON.stringify(data))
                this.socket.emit('search-channels', data, (chList) => {
                    this.log('searchChannels: < length - ' + chList.length)
                    resolve(chList)
                    cb && cb(chList)
                })
            })
        },
        searchChannelPublications(params) {
            return new Promise((resolve, reject) => {
                const { chId, filter, fromPubId, count } = params
                const data = { chId, filter, fromPubId, count }
                this.log('searchChannels: > ' + JSON.stringify(data))
                //console.log('searchChannels: > ', JSON.stringify(data))
                this.socket.emit('search-channel-publications', data, chList => {
                    this.log('searchChannelPublications: < chList.length - ' + chList.length)
                    //console.log('searchChannelPublications: < chList - ', chList)
                    resolve(chList)
                })
            })
        },
        addPublication(params) {
            return new Promise((resolve, reject) => {
                const data = {
                    chId: params.chId,
                    publisher: params.publisher,
                    title: params.title,
                    info: params.info,
                    data: params.data,
                }
                this.log('addPublication: > ' + JSON.stringify(data))
                this.socket.emit('add-publication', data, (data, pubId) => {
                    if (data.error) {
                        this.log('addPublication <  error: ' + data.error)
                        return reject(new Error(data.error))
                    }
                    this.log('addPublication < success, pubId:' + data.pubId)
                    resolve(data.pubId)
                })
            })
        },
        changePublication(params) {
            return new Promise((resolve, reject) => {
                const data = {
                    chId: params.chId,
                    pubId: params.pubId,
                    publisher: params.publisher,
                    title: params.title,
                    info: params.info,
                    data: params.data,
                }
                this.log('changePublication: > ' + JSON.stringify(data))
                this.socket.emit('change-publication', data, (err) => {
                    if (err && err.error) {
                        this.log('changePublication: < error: ' + err.error)
                        return reject(new Error(err.error))
                    }
                    this.log('changePublication < success')
                    resolve()
                })
            })
        },
        delPublication(params) {
            return new Promise((resolve, reject) => {
                const data = { pubId: params.pubId, chId: params.chId }
                this.log('delPublication: > ' + JSON.stringify(data))
                this.socket.emit('delete-publication', data, (err) => {
                    if (err && err.error) {
                        this.log('delPublication: < error: ' + err.error)
                        return reject()
                    }
                    this.log('delPublication: < success')
                    resolve()
                })
            })
        },
        getPublications({ chId, fromPubId = 0, count}, cb) {
            var data = { chId, fromPubId, count }
            this.log('getPublications: > ' + JSON.stringify(data))
            this.socket.emit('get-publications', data, (pubList) => {
                /* pubList.forEach((publication) => {
                 publication.data = publication.data.map((cur) => {
                 try {
                 cur = JSON.parse(cur);
                 } catch (e) {
                 this.log('getPublications: < error: invalid data: ' + cur);
                 }
                 return cur;
                 });
                 }); */
                this.log('getPublications: < ' + pubList.length)
                cb && cb(pubList)
            })
        },
        getPublicationDetails(params) {
            return new Promise((resolve, reject) => {
                const data = {chId: params.chId, pubId: params.pubId}
                this.log('getPublicationDetails: > ' + JSON.stringify(data))
                this.socket.emit('get-publication-details', data, publication => {
                    if (publication.error) reject('getPublicationDetails: < error: ' + publication.error)
                    publication.chId = params.chId
                    publication.pubId = params.pubId
                    this.log('getPublicationDetails: < success')
                    resolve(publication)
                })
            })
        },
        addChannelUser(params) {
            return new Promise((resolve, reject) => {
                const data = {chId: params.chId, contacts: params.contacts}
                this.log('addChannelUser: > ' + JSON.stringify(data))
                this.socket.emit('add-channel-users', data, err => {
                    if (err && err.error) {
                        reject('addChannelUser: < error: ' + err.error)
                    }
                    resolve()
                })
            })
        },
        deleteChannelUser(params, cb) {
            return new Promise((resolve, reject) => {
                const data = {chId: params.chId, contacts: params.contacts}
                this.log('deleteChannelUser: > ' + JSON.stringify(data))
                this.socket.emit('delete-channel-users', data, (err, response) => {
                    if (err && err.error) {
                        reject('deleteChannelUser: < error: ' + err.error)
                    } else {
                        this.log('deleteChannelUser: < OK')
                        resolve()
                    }
                })
            })
        },
        getPublicationLikes(params, cb) {
            var data = { pubId: params.pubId, chId: params.chId, fromLikeId: params.fromLikeId, count: params.count }
            this.log('getPublicationLikes: > ' + JSON.stringify(data))
            this.socket.emit('get-publication-likes', data, (likes_list) => {
                this.log('getPublicationLikes: < ' + likes_list.length)
                cb && cb(likes_list)
            })
        },
        publicationLike(params) {
            return new Promise((resolve, reject) => {
                let data = {pubId: params.pubId, chId: params.chId, state: params.state}
                this.log('publicationLikes > ' + JSON.stringify(data))
                this.socket.emit('publication-like', data, (err) => {
                    if (err && err.error) {
                        this.log('publicationLike: < error: ' + err.error)
                        return reject(new Error(err.error))
                    }
                    resolve()
                })
            })
        },
        getPublicationComments(params) {
            var data = { pubId: params.pubId, chId: params.chId, fromComId: params.fromComId, count: params.count }
            this.log('getPublicationComments: > ' + JSON.stringify(data))
            return new Promise((resolve, reject) => {
                this.socket.emit('get-publication-comments', data, result => {
                    this.log('getPublicationComments: < ' + result.length)
                    if (result) resolve(result)
                    else reject()
                })
            })
        },
        addPublicationComment(params) {
            return new Promise((resolve, reject) => {
                var data = { pubId: params.pubId, chId: params.chId, text: params.text }
                this.log('addPublicationComment > ' + JSON.stringify(data))
                this.socket.emit('add-publication-comment', data, (err) => {
                    if (err && err.error) {
                        this.log('addPublicationComment: < error: ' + err.error)
                        return reject(new Error(err.error))
                    }
                    resolve()
                })
            })
        },
        watchChannelPublications(params, cb) {
            var data = { chId: params.chId }
            this.log('watchChannelPublications > ' + JSON.stringify(data))
            this.socket.emit('watch-channel-publications', data, (err) => {
                if (err && err.error) {
                    return this.log('watchChannelPublications: < error: ' + err.error)
                }
                cb && cb()
            })
        },
        getPublicationsMedia(params) {
            return new Promise((resolve, reject) => {
                let data = { chId: params.chId, type: params.type,  filter: params.filter, fromPubId: params.fromPubId, count: params.count }
                this.log('getPublicationsMedia: > ' + JSON.stringify(data))
                this.socket.emit('get-publications-media', data, (data) => {
                    this.log('getPublicationsMedia: < ' + data.length)
                    data.forEach((cur, index) => {
                        if (!addMessageDefaultData(cur)) delete data[index]
                    })
                    resolve(data)
                })
            })
        },
    },
    listeners: {
        'publication-event'(data) {
            this.log('bind: < publication-event: ' + JSON.stringify(data))
            this.emit('publication-event', data)
        },
        'channel-change-event'(data) {
            this.log('bind: < channel-change-event: ' + JSON.stringify(data))
            addChannelDefaultData(data)
            this.emit('channel-change-event', data)
        }
    },
}