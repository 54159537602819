var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialer" }, [
    _c("div", { staticClass: "dialer-input" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input,
            expression: "input",
          },
        ],
        style: { fontSize: _vm.fontSizeInput + "px" },
        domProps: { value: _vm.input },
        on: {
          blur: function ($event) {
            return _vm.focus($event)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            $event.preventDefault()
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.input = $event.target.value
          },
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "dialer-keys" },
      _vm._l(_vm.rows, function (row) {
        return _c(
          "div",
          { staticClass: "dialer-keys-row" },
          _vm._l(row, function (key) {
            return _c(
              "span",
              {
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  },
                  click: function ($event) {
                    return _vm.keyPress(key)
                  },
                },
              },
              [_vm._v(_vm._s(key))]
            )
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }