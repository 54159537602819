var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: "video-phone",
      staticClass: "video-phone",
      class: { fullscreen: _vm.fullScreen },
      style: _vm.videoSize,
    },
    [
      _c(
        "div",
        {
          staticClass: "body",
          on: {
            mouseover: function ($event) {
              return _vm.showPanels(true)
            },
            mouseout: function ($event) {
              return _vm.showPanels(false)
            },
          },
        },
        [
          _c("div", { staticClass: "draggable" }),
          _c("div", { staticClass: "topbar" }),
          !this.phoneTerminated
            ? [
                _c("custom-video", {
                  attrs: { id: "remoteVideo", autoplay: true },
                  on: { loadedmetadata: _vm.loadedmetadata },
                }),
                _c("video", {
                  staticClass: "localVideo",
                  class: _vm.panelShow ? "panel-show" : "panel-hide",
                  attrs: { id: "localVideo", autoplay: "", muted: "" },
                  domProps: { muted: true },
                }),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "top-panel",
              class: _vm.panelShow ? "top-panel-show" : "top-panel-hide",
            },
            [
              _c(
                "div",
                {
                  staticClass: "top-btn",
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.doFullScreen.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "far fa-arrows-alt-v rotate-45" })]
              ),
              _c("div", { staticClass: "top-panel-info" }, [
                _c(
                  "span",
                  {
                    staticClass: "phone-name",
                    style: { "max-width": _vm.connected ? "350px" : "200px" },
                  },
                  [_vm._v(_vm._s(_vm.nameText))]
                ),
                _c("span", { staticClass: "phone-status" }, [
                  _vm._v(_vm._s(_vm.statusText)),
                ]),
              ]),
              !_vm.fullScreen
                ? _c(
                    "div",
                    {
                      staticClass: "top-btn",
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.minimizePhone.apply(null, arguments)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-chevron-down" })]
                  )
                : _c("div"),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "bottom-panel",
              class: _vm.panelShow ? "bottom-panel-show" : "bottom-panel-hide",
            },
            [
              _c(
                "div",
                {
                  staticClass: "func-button",
                  class: { "func-button-active": _vm.pause },
                  attrs: { title: _vm.$t("phone.pause") },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    click: _vm.togglePause,
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-pause",
                    class: { "func-button-i-active": _vm.pause },
                  }),
                ]
              ),
              _c("div", { staticClass: "phone-btn-cont" }, [
                _c(
                  "div",
                  {
                    staticClass: "phone-btn phone-red-btn",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: function ($event) {
                        return _vm.terminate()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-phone fa-rotate" })]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "func-button",
                  class: { "func-button-active": _vm.mute },
                  attrs: { title: _vm.$t("phone.mic") },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    click: _vm.toggleMicrophone,
                  },
                },
                [
                  _vm.mute
                    ? _c("i", {
                        staticClass: "fas fa-microphone-slash",
                        class: { "func-button-i-active": _vm.mute },
                      })
                    : _c("i", {
                        staticClass: "fas fa-microphone",
                        class: { "func-button-i-active": _vm.mute },
                      }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "bottombar" }),
        ],
        2
      ),
      _c("calls-selector", {
        class: _vm.panelShow
          ? "panel-show-calls-selector"
          : "panel-hide-calls-selector",
        attrs: { type: "video" },
      }),
      _c("video", {
        attrs: { id: "localAudio", autoplay: "", muted: "" },
        domProps: { muted: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }