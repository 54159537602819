// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes typing-06e7cd0c {
0% {
    transform: scale(0.6);
}
33% {
    transform: scale(1.2);
}
66% {
    transform: scale(0.6);
}
}
.typing-dots[data-v-06e7cd0c] {
  display: flex;
  flex-direction: row;
}
.typing-dots .wrapper[data-v-06e7cd0c] {
  display: flex;
  width: 8px;
  height: 12px;
}
.typing-dots .wrapper i[data-v-06e7cd0c] {
  margin: auto;
  font-size: 6px;
  animation: typing-06e7cd0c 0.75s infinite linear;
  animation-fill-mode: both;
  line-height: 12px;
  color: var(--message-grey-color);
  vertical-align: center;
  transform: scale(0.6);
}
.typing-dots .wrapper:nth-child(2) i[data-v-06e7cd0c] {
  animation-delay: 0.25s;
}
.typing-dots .wrapper:nth-child(3) i[data-v-06e7cd0c] {
  animation-delay: 0.5s;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/listItems/TypingDots.vue"],"names":[],"mappings":"AAAA;AACA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;AACA;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,UAAU;EACV,YAAY;AACd;AACA;EACE,YAAY;EACZ,cAAc;EACd,gDAAgD;EAChD,yBAAyB;EACzB,iBAAiB;EACjB,gCAAgC;EAChC,sBAAsB;EACtB,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,qBAAqB;AACvB","sourcesContent":["@keyframes typing-06e7cd0c {\n0% {\n    transform: scale(0.6);\n}\n33% {\n    transform: scale(1.2);\n}\n66% {\n    transform: scale(0.6);\n}\n}\n.typing-dots[data-v-06e7cd0c] {\n  display: flex;\n  flex-direction: row;\n}\n.typing-dots .wrapper[data-v-06e7cd0c] {\n  display: flex;\n  width: 8px;\n  height: 12px;\n}\n.typing-dots .wrapper i[data-v-06e7cd0c] {\n  margin: auto;\n  font-size: 6px;\n  animation: typing-06e7cd0c 0.75s infinite linear;\n  animation-fill-mode: both;\n  line-height: 12px;\n  color: var(--message-grey-color);\n  vertical-align: center;\n  transform: scale(0.6);\n}\n.typing-dots .wrapper:nth-child(2) i[data-v-06e7cd0c] {\n  animation-delay: 0.25s;\n}\n.typing-dots .wrapper:nth-child(3) i[data-v-06e7cd0c] {\n  animation-delay: 0.5s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
