var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.statusText
    ? _c("div", { staticClass: "conf-status" }, [
        _c("div", { staticClass: "status-text" }, [
          _vm._v(_vm._s(_vm.statusText)),
        ]),
        _vm.btn
          ? _c("div", { staticClass: "buttons theme-videoconf" }, [
              _c(
                "div",
                { staticClass: "handup-wrapper", on: { click: _vm.btn.fn } },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.btn.text)),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }