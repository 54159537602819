var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        id: _vm.modalName,
        name: _vm.modalName,
        "modal-props": _vm.compModalProps,
        title: "",
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("custom-input-hotkeys", {
              attrs: {
                hotKeysItem: _vm.hotKeysItem,
                cancelChange: _vm.cancelChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.cancelBtn } }, [
          _vm._v(_vm._s(_vm.$t("cancel"))),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.okBtn } }, [
          _vm._v(_vm._s(_vm.$t("apply"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }