// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-call-availability-message[data-v-07fe9884] {
  cursor: pointer;
  display: flex;
}
.chat-call-availability-message > *[data-v-07fe9884] {
  margin: auto;
}
.chat-call-availability-message .text-block[data-v-07fe9884] {
  margin-left: 10px;
}
.chat-call-availability-message .text-block .text[data-v-07fe9884] {
  color: var(--elem-grey-color);
}
.forward[data-v-07fe9884] {
  margin-bottom: 0.33em;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ChatCallAvailabilityMessage.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".chat-call-availability-message[data-v-07fe9884] {\n  cursor: pointer;\n  display: flex;\n}\n.chat-call-availability-message > *[data-v-07fe9884] {\n  margin: auto;\n}\n.chat-call-availability-message .text-block[data-v-07fe9884] {\n  margin-left: 10px;\n}\n.chat-call-availability-message .text-block .text[data-v-07fe9884] {\n  color: var(--elem-grey-color);\n}\n.forward[data-v-07fe9884] {\n  margin-bottom: 0.33em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
