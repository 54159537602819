var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "func-button",
      class: _vm.btnClasses,
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        click: _vm.click,
      },
    },
    [
      _c("div", { staticClass: "func-button-icon", class: _vm.iconClasses }, [
        _vm.typeIsButtons
          ? _c(
              "svg",
              { attrs: { width: "16px", height: "22px", fill: "#929292" } },
              [
                _c("circle", { attrs: { cx: "2", cy: "2", r: "2" } }),
                _c("circle", { attrs: { cx: "2", cy: "8", r: "2" } }),
                _c("circle", { attrs: { cx: "2", cy: "14", r: "2" } }),
                _c("circle", { attrs: { cx: "8", cy: "2", r: "2" } }),
                _c("circle", { attrs: { cx: "8", cy: "8", r: "2" } }),
                _c("circle", { attrs: { cx: "8", cy: "14", r: "2" } }),
                _c("circle", { attrs: { cx: "14", cy: "2", r: "2" } }),
                _c("circle", { attrs: { cx: "14", cy: "8", r: "2" } }),
                _c("circle", { attrs: { cx: "14", cy: "14", r: "2" } }),
                _c("circle", { attrs: { cx: "8", cy: "20", r: "2" } }),
              ]
            )
          : _c("i", { class: _vm.fontClasses }),
      ]),
      _c("span", { staticClass: "func-button-name" }, [
        _vm._v(_vm._s(_vm.name)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }