// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-wrapper[data-v-10c1be94] {
  position: relative;
}
.custom-input-wrapper .div-textarea[data-v-10c1be94] {
  border-bottom: 1px solid var(--list-item-bordercolor);
  outline: none;
  resize: none;
  overflow: hidden;
  font-weight: 700;
  min-height: 30px;
  padding-top: 5px;
}
.custom-input-wrapper .div-textarea[data-v-10c1be94]:focus {
  border-bottom: 1px solid var(--ref-link-color);
}
.custom-input-wrapper .div-textarea-empty[data-v-10c1be94] {
  border-bottom: 1px solid var(--elem-red-color) !important;
}
.custom-input-wrapper .div-textarea[data-v-10c1be94] {
  border-bottom: 1px solid var(--list-item-bordercolor);
}
.custom-input-wrapper .custom-input-placeholder[data-v-10c1be94] {
  position: absolute;
  top: 5px;
  left: 0px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/custom/CustomInput.vue"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,qDAAqD;EACrD,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,8CAA8C;AAChD;AACA;EACE,yDAAyD;AAC3D;AACA;EACE,qDAAqD;AACvD;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".custom-input-wrapper[data-v-10c1be94] {\n  position: relative;\n}\n.custom-input-wrapper .div-textarea[data-v-10c1be94] {\n  border-bottom: 1px solid var(--list-item-bordercolor);\n  outline: none;\n  resize: none;\n  overflow: hidden;\n  font-weight: 700;\n  min-height: 30px;\n  padding-top: 5px;\n}\n.custom-input-wrapper .div-textarea[data-v-10c1be94]:focus {\n  border-bottom: 1px solid var(--ref-link-color);\n}\n.custom-input-wrapper .div-textarea-empty[data-v-10c1be94] {\n  border-bottom: 1px solid var(--elem-red-color) !important;\n}\n.custom-input-wrapper .div-textarea[data-v-10c1be94] {\n  border-bottom: 1px solid var(--list-item-bordercolor);\n}\n.custom-input-wrapper .custom-input-placeholder[data-v-10c1be94] {\n  position: absolute;\n  top: 5px;\n  left: 0px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
