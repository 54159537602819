var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "notification" } },
    [
      _c(
        "transition-group",
        { attrs: { name: "slide-fade", mode: "out-in" } },
        _vm._l(_vm.notifies, function (notify) {
          return _c("custom-notification", {
            key: notify.id,
            staticClass: "notify",
            attrs: { notify: notify.notifyPayload, id: notify.id },
            on: { hide: _vm.hide },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }