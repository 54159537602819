// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-body.central[data-v-47cf142d] {
  padding: 0 16px;
}
.main-body.central .main-content-container[data-v-47cf142d] {
  margin: 20px auto 0 auto;
  flex-basis: auto !important;
  background: var(--bg-color);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0, rgba(0, 0, 0, 0.01) 0 1px 3px 1px;
}
.main-body.central .main-content-container .main-content-container-header[data-v-47cf142d] {
  margin-bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 600;
}
.main-body.central .main-content-container .main-content-container-main[data-v-47cf142d] {
  background: inherit;
  box-shadow: none;
  margin-bottom: 0px;
}
.main-body.central div.item-after ul[data-v-47cf142d] {
  margin-bottom: auto;
}
.main-body.central .middle[data-v-47cf142d] {
  display: flex;
  justify-content: center;
}
.main-body.central .middleExpTime[data-v-47cf142d] {
  display: flex;
  justify-content: center;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/main/body/MainContentProfile.vue"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,2BAA2B;EAC3B,kBAAkB;EAClB,6EAA6E;AAC/E;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".main-body.central[data-v-47cf142d] {\n  padding: 0 16px;\n}\n.main-body.central .main-content-container[data-v-47cf142d] {\n  margin: 20px auto 0 auto;\n  flex-basis: auto !important;\n  background: var(--bg-color);\n  border-radius: 4px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0, rgba(0, 0, 0, 0.01) 0 1px 3px 1px;\n}\n.main-body.central .main-content-container .main-content-container-header[data-v-47cf142d] {\n  margin-bottom: 0px;\n  padding-top: 12px;\n  padding-left: 12px;\n  font-size: 16px;\n  font-weight: 600;\n}\n.main-body.central .main-content-container .main-content-container-main[data-v-47cf142d] {\n  background: inherit;\n  box-shadow: none;\n  margin-bottom: 0px;\n}\n.main-body.central div.item-after ul[data-v-47cf142d] {\n  margin-bottom: auto;\n}\n.main-body.central .middle[data-v-47cf142d] {\n  display: flex;\n  justify-content: center;\n}\n.main-body.central .middleExpTime[data-v-47cf142d] {\n  display: flex;\n  justify-content: center;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
